import React from "react";
import styled from "styled-components";
import { transparentize } from "polished";

const statusColors = {
  connected: "#8dc63f",
  "ready-to-connect": "#FFD400",
  disconnected: "#F26419",
};

const StatusIndicator = styled.div`
  position: relative;
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: ${(props) => props.color};
  box-shadow: 0 0 0 0 rgba(#5a99d4, 0.5);

  ${(props) =>
    props.pulse === "true" &&
    `
  -webkit-animation: ${props.status} 1s infinite ease-in-out;
  animation: ${props.status} 1s infinite ease-in-out;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;`}

  @-webkit-keyframes pulse-green {
    50% {
      -webkit-box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.2),
        0 0 0 4px ${transparentize(0.5, statusColors["connected"])};
      box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.2),
        0 0 0 4px ${(props) => transparentize(0.5, statusColors["connected"])};
    }
  }

  @keyframes pulse-green {
    50% {
      -webkit-box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.2),
        0 0 0 4px ${(props) => transparentize(0.5, statusColors["connected"])};
      box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.2),
        0 0 0 4px ${(props) => transparentize(0.5, statusColors["connected"])};
    }
  }

  @-webkit-keyframes pulse-yellow {
    50% {
      -webkit-box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.2),
        0 0 0 4px ${transparentize(0.5, statusColors["ready-to-connect"])};
      box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.2),
        0 0 0 4px
          ${(props) => transparentize(0.5, statusColors["ready-to-connect"])};
    }
  }

  @keyframes pulse-yellow {
    50% {
      -webkit-box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.2),
        0 0 0 4px
          ${(props) => transparentize(0.5, statusColors["ready-to-connect"])};
      box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.2),
        0 0 0 4px
          ${(props) => transparentize(0.5, statusColors["ready-to-connect"])};
    }
  }

  @-webkit-keyframes pulse-red {
    50% {
      -webkit-box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.2),
        0 0 0 4px ${transparentize(0.5, statusColors["disconnected"])};
      box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.2),
        0 0 0 4px
          ${(props) => transparentize(0.5, statusColors["disconnected"])};
    }
  }

  @keyframes pulse-red {
    50% {
      -webkit-box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.2),
        0 0 0 4px
          ${(props) => transparentize(0.5, statusColors["disconnected"])};
      box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.2),
        0 0 0 4px
          ${(props) => transparentize(0.5, statusColors["disconnected"])};
    }
  }
`;

export const IntegrationStatusIndicator = ({ pulse = "true", status }) => {
  const color = statusColors[status] ? statusColors[status] : "#D2D7DF";

  const pulseStatuses = {
    connected: "pulse-green",
    "ready-to-connect": "pulse-yellow",
    disconnected: "pulse-red",
  };

  return (
    <StatusIndicator
      pulse={pulse}
      color={color}
      status={pulseStatuses[status]}
    />
  );
};
