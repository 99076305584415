import React from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";

import { IconLifebuoy, IconWrench, IconChevronDown } from "@saberapp/icons";

// Hooks
import { useAuth } from "../../../../hooks/useAuth";
import { useTheme } from "@saberapp/hooks";

// Component Library
import {
  Line,
  Avatar,
  DropdownButton,
  DropdownItem,
  Button,
} from "@saberapp/react-component-library";

const StyledFooter = styled.div`
  margin-top: auto;
  padding-bottom: 16px;
`;

const FooterButtons = styled.div`
padding: 8px 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
`

const TinyText = styled.div`
padding: 6px 18px;
    font-size: 11px;
`

const SidebarFooter = () => {
  const { user, organization, userHasRole, viewAsRole, viewingRole, signOut } = useAuth();
  const { currentTheme, handleThemeChange } = useTheme();
  const navigate = useNavigate();

  const handleSignOut = () => signOut(navigate);

  const handleRoleChange = (roleName) => {
    viewAsRole(roleName);
    navigate(`/${organization.slug}/`);
  };

  return (
    <StyledFooter>
      <FooterButtons>

        {/* <DropdownButton
          type="ui"
          size="small"
          position="bottom-left"
          title={viewingRole || "ADMIN"}
          iconAfter={<IconChevronDown />}
        >
          <DropdownItem onClick={() => handleRoleChange("ADMIN")}>
            Admin
          </DropdownItem>
          <DropdownItem onClick={() => handleRoleChange("SELLER")}>
            Seller
          </DropdownItem>
          <DropdownItem onClick={() => handleRoleChange("REVIEWER")}>
            Reviewer
          </DropdownItem>
        </DropdownButton> */}

        {/* <DropdownButton
          type="ui"
          size="small"
          position="bottom"
          title="Change theme"
          iconAfter={<IconChevronDown />}
        >
          <DropdownItem onClick={() => handleThemeChange("light")}>
            Light
          </DropdownItem>
          <DropdownItem onClick={() => handleThemeChange("dark")}>
            Dark
          </DropdownItem>
          <DropdownItem onClick={() => handleThemeChange("midnight")}>
            Midnight
          </DropdownItem>
          <DropdownItem onClick={() => handleThemeChange("ash")}>
            Ash
          </DropdownItem>
          <DropdownItem onClick={() => handleThemeChange("dawn")}>
            Dawn
          </DropdownItem>
        </DropdownButton> */}

        <DropdownButton
          type="ui"
          position="bottom"
          iconBefore={<Avatar url={user.avatar} name={user.name} />}
          title={user.name}
        >
          <DropdownItem
            onClick={() => navigate(`/${organization.slug}/settings/profile`)}
          >
            View Profile
          </DropdownItem>
          <Line distance="tiny" />
          <DropdownItem onClick={() => handleSignOut()}>Sign Out</DropdownItem>
        </DropdownButton>
        <Button size="small" iconBefore={<IconWrench />} onClick={() => navigate(`/${organization.slug}/settings`)} title="Settings" />
        {/* <Button size="small" iconBefore={<IconLifebuoy />} title="Help & Support" /> */}
      </FooterButtons>

      <TinyText>Version: 0.1.1</TinyText>
    </StyledFooter>
  );
};

export default SidebarFooter;
