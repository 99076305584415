import React from "react";
import { styled } from "styled-components";

import { UsersProvider } from "../../hooks/useUsers";

import SidebarSettings from "../layouts/settings-menu";

const App = styled.div`
  display: flex;
  height: 100%;
`;

const AppContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: calc(100% - 222px);
`;

const AppContent = styled.div`
  background: ${(props) => `${props.theme.colors.backgroundColor}`};
  color: ${(props) => `${props.theme.colors.backgroundTextColor}`};
  padding-top: 64px;
  padding-bottom: 64px;
  height: 100%;
  overflow: auto;
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
`;

const SettingsColumn = styled.div`
  max-width: 640px;
  padding-bottom: 64px;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const SettingsTemplate = ({ width, children }) => {
  return (
    <App>
      <UsersProvider>
        <SidebarSettings />
        <AppContentContainer>
          <AppContent>
            <SettingsColumn style={width && { maxWidth: width + "px" }}>
              {children}
            </SettingsColumn>
          </AppContent>
        </AppContentContainer>
      </UsersProvider>
    </App>
  );
};

export default SettingsTemplate;
