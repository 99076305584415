import React from 'react';

// Component Library
import {
  Button,
  Column,
  Heading,
  Paragraph
} from "@saberapp/react-component-library";

import { useTheme } from "@saberapp/hooks";

import SettingsTemplate from '../../templates/SettingsTemplate';
import SettingsHeader from './components/SettingsHeader';

const SettingsThemes = () => {

  const { handleThemeChange } = useTheme();

  return (
    <SettingsTemplate>
            <Column gap="s">
        <Heading size="h1">Appearance</Heading>
        <Paragraph>
          Customise the way Saber looks and have it match your brand
        </Paragraph>
      </Column>

      <SettingsHeader
        type="small"
        title="Updates Feed"
        description="Allow logins through your SAML identity provider single sign-on functionality."
        button={<Button type="primary" title="Configure" />}
        line={true}
      />
      <div>
        <button onClick={() => handleThemeChange("light")}>Light</button>
        <button onClick={() => handleThemeChange("dark")}>Dark</button>
        <button onClick={() => handleThemeChange("midnight")}>Midnight</button>
        <button onClick={() => handleThemeChange("ash")}>Ash</button>
        <button onClick={() => handleThemeChange("dawn")}>Dawn</button>
      </div>
    </SettingsTemplate>
  );
};

export default SettingsThemes;
