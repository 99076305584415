import React from "react";
import styled from "styled-components";

const Container = styled.div`
  overflow: auto;
  height: calc(100% - 80px);
  padding-top: 80px;
`;

const Page = ({ children }) => {
  return <Container>{children}</Container>;
};

export default Page;
