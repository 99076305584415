import React, { useContext, useEffect, useState } from "react";
import { styled } from "styled-components";

// Component Library
import {
  Pill,
  Heading,
  Paragraph,
  Button,
} from "@saberapp/react-component-library";

import ResourceTile from "../../../layouts/ResourceTile";
import FreshnessHeader from "./FreshnessHeader";
import FileTypeHeader from "./FileTypeHeader";
import NoResults from "./NoResults";
import ContentTypeHeader from "./ContentTypeHeader";
import { Resources } from "../../../../hooks/useResources";
import { ContextMenu } from "../../../../hooks/useContextMenu";
import { useAuth } from "../../../../hooks/useAuth";
import IndexStatusHeader from "./IndexStatusHeader";
import DocSourceHeader from "./DocSourceHeader";
import { useTheme } from "@saberapp/hooks";

const Tiles = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px;
  gap: 32px;
`;

const FlexTileContainer = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
`;

const Header = styled.div`
  padding: 32px;
  display: flex;
  flex-direction: column;
  width: 320px;
  align-items: flex-start;
  justify-content: center;
  gap: 16px;
`;

const HeaderAdmin = styled.div`
  padding: 12px 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  box-sizing: border-box;

  ${({ groupKey, mode }) => {
    if (groupKey === "OUTDATED" || groupKey === "PROCESSING_FAILED") {
      return mode === "light"
        ? `background: #ffd1d1;`
        : `background: var(--card-bg);
      border-left: 4px solid #cf1127;`;
    } else if (groupKey === "NEEDS_REVIEW" || groupKey === "NOT_SUPPORTED") {
      return mode === "light"
        ? `background: #ffead1;`
        : `background: var(--card-bg);
      border-left: 4px solid #f4b60c;`;
    } else {
      return `background: var(--card-bg);`;
    }
  }}
`;

const Row = styled.div`
  display: flex;
  border-bottom: 1px solid var(--border-color-1);
`;

const OtherRow = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 32px;
  gap: 16px;
`;

const TileView = ({ handleResourceClick, page }) => {
  const { userHasRole } = useAuth();
  const {
    getOrganisedResourcesByPage,
    organisedResources,
    selectedResources,
    isGroupedBy,
    activeGroup,
  } = useContext(Resources);
  const { currentTheme } = useTheme();

  const { contextMenu } = useContext(ContextMenu);

  const [resources, setResources] = useState([]);
  const [matches, setMatches] = useState({});
  const [others, setOthers] = useState({});
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [showOthers, setShowOthers] = useState(false);

  useEffect(() => {
    const r = getOrganisedResourcesByPage(page);
    setResources(r);
    const refGroups = { ...r };
    delete refGroups["Other"];
    console.log(refGroups);
    setMatches(refGroups);
    setOthers(r["Other"]);
    setShowOthers(false);
  }, [organisedResources, page]);

  const handleShowOthers = () => {
    setShowOthers(!showOthers);
  };

  return (
    <Tiles>
      {!selectedGroup && (
        <>
          {Object.keys(matches).map((groupKey, i) => (
            <>
            {console.log(activeGroup)}
              {/* {userHasRole("ADMIN") && ( */}
              <HeaderAdmin
                key={Math.random()}
                groupKey={groupKey}
                mode={currentTheme.type}
              >
                {/* {page === "pages-and-notes" ? (
                  <DocSourceHeader
                    groupKey={groupKey}
                    count={matches[groupKey].resources.length}
                  />
                ) : (
                  <> */}
                {activeGroup?.type === "property" &&
                  activeGroup?.slug === "indexStatus" && (
                    <IndexStatusHeader
                      groupKey={groupKey}
                      count={matches[groupKey].resources.length}
                    />
                  )}

                {activeGroup?.type === "property" &&
                  activeGroup?.slug === "freshnessScore" && (
                    <FreshnessHeader
                      groupKey={groupKey}
                      count={matches[groupKey].resources.length}
                    />
                  )}

                {activeGroup?.type === "property" &&
                  activeGroup?.slug === "mimeType" && (
                    <FileTypeHeader
                      groupKey={groupKey}
                      count={matches[groupKey].resources.length}
                    />
                  )}

                {activeGroup?.type === "property" &&
                  activeGroup?.slug === "docSource" && (
                    <DocSourceHeader
                      groupKey={groupKey}
                      count={matches[groupKey].resources.length}
                    />
                  )}

                {activeGroup?.type === "property" &&
                  activeGroup?.slug === "contentType" && (
                    <>
                      <ContentTypeHeader
                        groupKey={groupKey}
                        count={matches[groupKey].resources.length}
                      />
                    </>
                  )}

                {activeGroup?.type === "tagGroup" && (
                  <>
                    <Heading size="h4">{groupKey}</Heading>
                    <Paragraph>{matches[groupKey].description}</Paragraph>
                    <Button type="ui" title="Read more" />
                  </>
                )}
                {/* </>
                )} */}

                {/* <Heading size="h4">{groupKey}</Heading>
                  <Pill title={`${matches[groupKey].resources.length}`} /> */}
              </HeaderAdmin>
              {/* )} */}

              <Row key={Math.random()}>
                <FlexTileContainer>
                  {matches[groupKey]?.resources.map((resource, i) => (
                    <ResourceTile
                      resource={resource}
                      selected={
                        selectedResources.some(
                          (selectedResource) =>
                            selectedResource._id === resource._id
                        )
                          ? "true"
                          : "false"
                      }
                      onClick={(event) => handleResourceClick(resource, event)}
                      key={Math.random()}
                      onContextMenu={(e) => {
                        e.preventDefault();
                        // TODO: Move to Context Provider
                        setContextMenuData(resource);
                        contextMenu.open();
                        contextMenu.setPosition({ x: e.pageX, y: e.pageY });
                      }}
                    />
                  ))}
                </FlexTileContainer>
              </Row>
            </>
          ))}

          {Object.entries(matches)?.length === 0 ? (
            <NoResults />
          ) : (
            <>
              {others?.length > 0 && !showOthers && (
                <OtherRow>
                  <Paragraph>
                    There are {resources["Other"]?.length} docs that don't have
                    a {isGroupedBy} tag
                  </Paragraph>
                  <Button
                    type="ui"
                    title="Show all docs"
                    onClick={() => {
                      handleShowOthers();
                    }}
                  />{" "}
                </OtherRow>
              )}

              {others?.length > 0 && showOthers && (
                <Row>
                  <Header>
                    <Heading size="h4">Others</Heading>
                    <Paragraph>
                      These docs were not tagged with a {isGroupedBy} tag.
                    </Paragraph>
                    <Button
                      type="ui"
                      title="Hide these docs"
                      onClick={() => {
                        handleShowOthers();
                      }}
                    />
                  </Header>
                  <FlexTileContainer>
                    {others?.map((resource, i) => (
                      <ResourceTile
                        resource={resource}
                        onClick={(event) =>
                          handleResourceClick(resource, event)
                        }
                        key={i}
                      />
                    ))}
                  </FlexTileContainer>
                </Row>
              )}
            </>
          )}
        </>
      )}

      {selectedGroup && (
        <FlexTileContainer>
          {selectedGroup.map((rowData, i) => (
            <ResourceTile
              resource={rowData}
              onClick={(event) => handleResourceClick(rowData, event)}
              key={Math.random()}
            />
          ))}
        </FlexTileContainer>
      )}
    </Tiles>
  );
};

export default TileView;
