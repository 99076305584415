import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { useTheme } from "@saberapp/hooks";
import { IconChevronDown } from "@saberapp/icons";

// Component Library
import {
  Button,
  Column,
  Heading,
  Paragraph,
  Line,
  Avatar,
  Card,
  Pill,
  Row,
  Input,
  DropdownButton,
  DropdownItem,
} from "@saberapp/react-component-library";

import SettingsTemplate from "../../templates/SettingsTemplate";

// Hooks
import { useAuth } from "../../../hooks/useAuth";
import { useApi } from "../../../hooks/useApi";

import { HttpMethod } from "@saberapp/enums";

const CardColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const CardInner = styled.div`
  display: flex;
  gap: 32px;
`;
const CardRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const CardText = styled.div``;
const CardButton = styled.div``;

const AvatarContainer = styled.div`
  width: 20px;
  height: 20px;
`;

const SettingsProfile = () => {
  const { API } = useApi();
  const [isDeleting, setIsDeleting] = useState(false);
  const { organization, user, updateUserProfile } = useAuth();
  const { handleThemeChange, currentTheme } = useTheme();

  const [userTheme, setUserTheme] = useState();
  const [updatedName, setUpdatedName] = useState();
  const [isUpdatingUSer, setIsUpdatingUser] = useState(false);

  useEffect(() => {
    setUpdatedName(user.name);
  }, [user.name]);

  useEffect(() => {
    setUserTheme(currentTheme);
  }, [currentTheme]);

  const updateUser = async () => {
    setIsUpdatingUser(true);
    await API.call(HttpMethod.PUT, `/users/user/${user._id}`, {
      name: updatedName,
      theme: userTheme,
    }, (updatedUser) => {
      updateUserProfile(updatedUser);
      setIsUpdatingUser(false);
    });
  };

  return (
    <SettingsTemplate>
      <Column gap="s">
        <Heading size="h1">Profile</Heading>
        <Paragraph>Manage your Saber profile</Paragraph>
      </Column>

      <Card padding={16}>
        <CardInner>
          <CardColumn>
            <Heading size="h4">Avatar</Heading>
            <AvatarContainer>
              <Avatar url={user.avatar} />
            </AvatarContainer>
          </CardColumn>
          <CardColumn>
            <Heading size="h4">Email</Heading>
            {user.email}
          </CardColumn>
          <CardColumn>
            <Heading size="h4">Roles</Heading>
            {user?.roles.length > 0 &&
              user.roles.map((role) => (
                <Row>
                  <Pill title={role.name} /> {role.description}
                </Row>
              ))}
          </CardColumn>
        </CardInner>
      </Card>

      <Card padding={16}>
        <Heading size="h4">Full Name</Heading>
        <Input
          type="text"
          placeholder="Your full name"
          value={updatedName}
          onChange={(e) => {
            setUpdatedName(e.target.value);
          }}
        />
      </Card>

      <Card padding={16}>
        <CardRow>
          <CardText>
            <Heading size="h4">Theme</Heading>
            <Paragraph>Set your interface color scheme.</Paragraph>
          </CardText>
          <CardButton>
            <DropdownButton
              type="ui"
              size="small"
              title={currentTheme.name}
              iconAfter={<IconChevronDown />}
            >
              <DropdownItem
                onClick={() => {
                  handleThemeChange("ash");
                }}
              >
                Ash
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  handleThemeChange("dark");
                }}
              >
                Dark
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  handleThemeChange("dawn");
                }}
              >
                Dawn
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  handleThemeChange("light");
                }}
              >
                Light
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  handleThemeChange("midnight");
                }}
              >
                Midnight
              </DropdownItem>
            </DropdownButton>
          </CardButton>
        </CardRow>
      </Card>

      <Card padding={16}>
        <Heading size="h4">Timezone</Heading>
        <Paragraph>{user.timeZone}</Paragraph>
      </Card>

      <Button
        type="primary"
        size="regular"
        title={isUpdatingUSer ? "Updating" : "Update"}
        disabled={isUpdatingUSer}
        onClick={async () => {
          await updateUser();
        }}
      />

      {organization.slug === "rehmanabdur" && (
        <>
          <Line />

          <Button
            type="delete"
            title={
              isDeleting
                ? `Resetting your org...`
                : "This button resets your org, Rehman"
            }
            disabled={isDeleting}
            onClick={() => {
              if (API.isReady && !isDeleting) {
                setIsDeleting(true);

                API.call(HttpMethod.GET, "/rehman", {}, () => {
                  setIsDeleting(false);
                });
              }
            }}
          />
        </>
      )}
    </SettingsTemplate>
  );
};

export default SettingsProfile;
