import React from "react";
import { styled } from "styled-components";

// Component Library
import { Heading, Paragraph } from "@saberapp/react-component-library";

const Column = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`;

const Footer = styled.div`
  color: var(--heading-text);
  font-weight: 500;
  line-height: 1.5;
`;

const IntegrationsOverview = ({ slug, name }) => {
  return (
    <Column>
      {/* <Row>
        <Image />
        <Image />
      </Row> */}
      <Heading size="h3">Description</Heading>

      {slug === "gong" && (
        <>
          {/* <Paragraph>
            The Salesforce integration seamlessly connects your Salesforce data
            with our application, enabling efficient management of accounts,
            opportunities, and leads directly from our platform. With real-time
            notifications and updates, stay abreast of every sales stage and
            client interaction.
          </Paragraph> */}
        </>
      )}

      {slug === "salesforce" && (
        <>
          <Paragraph>
            The Salesforce integration seamlessly connects your Salesforce data
            with our application, enabling efficient management of accounts,
            opportunities, and leads directly from our platform. With real-time
            notifications and updates, stay abreast of every sales stage and
            client interaction.
          </Paragraph>
        </>
      )}

      <Footer>
        You'll need a {name} account with the right permissions to connect with
        Saber. If you don't have the right permissions, ask an admin.
      </Footer>
    </Column>
  );
};

export default IntegrationsOverview;
