import React from "react";
import styled from "styled-components";

import PageHeader from "./components/PageHeader";

import { Heading, Paragraph } from "@saberapp/react-component-library";
import ListItem from "./components/ListItem";
import PageFooter from "./components/PageFooter";
import Page from "./components/Page";
import PageContent from "./components/PageContent";

const Container = styled.div`
  height: 100%;
  overflow: auto;
`;

const LegalPage = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding-top: 80px;
  padding-bottom: 160px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const PrivacyPolicy = () => {
  return (
    <>
      <PageHeader />
      <Page>
        <PageContent>
          <Container>
            <LegalPage>
              <Heading size="h1">Privacy Policy</Heading>
              <Heading size="h4">Relating to Saber B.V.</Heading>
              <Heading size="h3">Effective date: 1st of January, 2024</Heading>
              <Paragraph>
                At Saber, we take your privacy seriously. Please read this
                Privacy Policy to learn how we treat your personal data. By
                using or accessing our Services in any manner, you acknowledge
                that you accept the practices and policies outlined below, and
                you hereby consent that we will collect, use and share your
                information as described in this Privacy Policy.
              </Paragraph>

              {/* <ListItem bullet="1.1" paragraph="" /> */}

              <Heading size="h3">1. Introduction</Heading>

              <ListItem
                bullet="1.1"
                paragraph="At Saber B.V. (us, we, our), we value your privacy and the importance of safeguarding your data and we are committed to comply with all the laws, rules and regulations related to data protection including, but not limited to, the General Data Protection Regulation (GDPR). 
"
              />
              <ListItem
                bullet="1.2"
                paragraph="At Saber B.V. (us, we, our), we value your privacy and the importance of safeguarding your data and we are committed to comply with all the laws, rules and regulations related to data protection including, but not limited to, the General Data Protection Regulation (GDPR). 
"
              />

              <ListItem
                bullet="1.3"
                paragraph="Saber, headquartered at De Entree 201, 1101 HG, Amsterdam, The Netherlands, is responsible for processing your Personal Data. 
"
              />

              <ListItem
                bullet="1.4"
                paragraph="This Policy applies to our websites, domains, applications, products and services, and applies when you interact with us by (i) making use of our products and services, (ii) visit our website or (iii) otherwise interacting with us.
"
              />

              <Heading size="h3">2. Processing of your Personal Data</Heading>

              <ListItem
                bullet="2.1"
                paragraph="The Personal Data we collect. The Personal Data we collect about you depends on the nature of your dealings with us or what you choose to share with us. Information collected in relation to our products and services may contain the type of actions performed, log data of activity, as well as other information. This information may be linked to you, and we may use this information to better provide technical support to you and to improve our products and services. The Personal Data we further collect about you may include: 
[name; 
email address; 
phone number; 
IP address; 
other Personal Data that you actively provide, for example through information requests, by creating a profile on our website, in correspondence and by telephone.] 
"
              />

              <ListItem
                bullet="2.2"
                paragraph="How we collect Personal Data. We receive your Personal Data in a few different ways, including when you share this information with us directly or through third parties. You may give us your information by filling in forms, using our products or services, entering information online or by corresponding with us by post, phone, email, telephone or otherwise. This includes Personal Data you provide, for example, when you:
[access our website; 
use our products and services; or
contact us (by phone, email, social media, or messaging service).] "
              />

              <ListItem
                bullet="2.3"
                paragraph="Interactions on our website. As you interact with our website, we may automatically collect the following types of data (all as described above): 
device data about your equipment, 
usage data about your browsing actions and patterns, and 
contact data where tasks carried out via our website remain uncompleted, such as incomplete orders or abandoned baskets. 
"
              />

              <ListItem
                bullet="2.4"
                paragraph="We collect this data by using cookies, server logs and other similar technologies. Please see our cookie section (below) for further details.
"
              />

              <Heading size="h3">3. Purpose and legal basis</Heading>

              <ListItem
                bullet="3.1"
                paragraph="Products and services. We use the information we collect from you to provide, maintain, protect, and improve products and services, and to develop new ones. In addition, we may use the information for one or more of the following purposes:
To provide information that you request from us relating to our products or services;
To provide you with information, such as information about any changes, offers, updates, or other announcements, related to the products and services provided by us;
To provide you with customer and/or technical support;
To gather analysis or valuable information so that we can improve our products and services;
To monitor the usage of our products and services, as well as our website;
To detect, prevent, and address technical issues;"
              />

              <ListItem
                bullet="3.2"
                paragraph="Website. When you use our website, we process technical data to offer you the functionalities of our website and to allow our website’s administrators to manage and improve our website’s performance (Log Data). This Log Data may include information such as your computer's IP address, browser type, browser version, the pages of our website that you visit, the time and date of your visit, the time spent on those pages and other statistics. In addition, if you enter data in our website, we process this data to provide you with information or functionalities. In addition, we process Personal Data to allow you to save your data, such as your preferences, we also use cookies to ensure that you can retrieve information from our website quickly. You can read more about our use of cookies in this Policy. We process Personal Data for this purpose based on our legitimate interest in providing the functionalities of our website and, if you agree to the use of non-strictly necessary cookies, based on your consent. "
              />

              <ListItem
                bullet="3.3"
                paragraph="Compliance with the law. We may be required to process your Personal Data to comply with applicable laws and regulations, for example in the event of tax-related obligations. In connection with this purpose, we may need to disclose your Personal Data to governmental or supervisory authorities. 
"
              />

              <ListItem
                bullet="3.4"
                paragraph="Legal basis. All in all, we rely on a number of legal bases to collect, use, share and otherwise process your Personal Data: 
as necessary to provide the products services and fulfil our obligations pursuant to any agreement you enter into with us;
where you have consented to the processing;
where necessary to comply with a legal obligation of whatever nature (including any compliance obligations), a court order, to prevent or limit any damages that we might sustain, or to exercise and defend legal claims; and
where necessary for the purposes of our or a third party’s legitimate interest."
              />

              <Heading size="h3">4. Transfers to third parties</Heading>

              <ListItem
                bullet="4.1"
                paragraph="Third parties engaged by us. We may share your Personal Data with trusted third-party service providers, consultants, and other agents that help us in providing, maintaining, protecting, and improving our products and services as well as our website. These service providers only have access to the Personal Data necessary to perform these limited functions on our behalf and are required to protect and secure your information. We take required contractual, technical and organizational measures to ensure that third parties only process your Personal Data to the extent necessary and in accordance with applicable laws.
"
              />

              <ListItem
                bullet="4.2"
                paragraph="Third-party links on our website. Our website may contain links to websites operated by third parties. If you access a third-party website through our website, your Personal Data may be collected by that third party through its website. We make no representations or warranties in relation to the privacy practices of any third-party provider or website and we are not responsible for the privacy policies or the content of any third-party provider or website. Third-party providers / websites are responsible for informing you about their own privacy practices and we encourage you to read their privacy policies.
"
              />

              <Heading size="h3">5. Data Transfers</Heading>

              <ListItem
                bullet="5.1"
                paragraph="Your personal data will only be stored or processed by us or by third parties engaged outside the European Union (and the European Economic Area) if this is in accordance with the applicable regulations (in particular, the GDPR) for the transfer of Personal Data to countries outside the European Union. This means that we will only pass on your Personal Data to countries outside the European Union if the European Union has decided that the third country concerned guarantees an adequate level of protection, or if other appropriate safeguards are offered, such as an adequacy decision or the use of standard contractual clauses approved by the European Commission.
"
              />

              <Heading size="h3">6. Data Retention</Heading>

              <ListItem
                bullet="6.1"
                paragraph="Personal Data collected. We only collect Personal Data in relation to the purposes set out in this Policy and do not collect more Personal Data than necessary for those purposes. We also ensure that the Personal Data we collect is accurate and sufficient to properly fulfill those purposes. 
"
              />

              <ListItem
                bullet="6.2"
                paragraph="Retention period. We will only retain your Personal Data for as long as necessary for the purpose for which your Personal Data was collected and to the extent required by applicable law. When we no longer need your Personal Data, we will remove it from our systems and/or take steps to anonymize it so you can no longer be identified on the basis of this information. 
"
              />

              <Heading size="h3">7. Protection of Personal Data</Heading>

              <ListItem
                bullet="7.1"
                paragraph="We take several measures to safeguard the collection, transmission and storage of the data we collect. We have implemented adequate technical, physical and organizational security measures aimed at protecting against unauthorized access, alteration, disclosure or destruction of your Personal Data, as well as all other forms of unlawful processing.
"
              />

              <Heading size="h3">8. Cookies</Heading>

              <ListItem
                bullet="8.1"
                paragraph="Our website uses cookies. A cookie is a small file of letters and numbers the website puts on your device if you allow it. These cookies recognize when your device has visited our website(s) before, so we can distinguish you from other users of the website. This improves your experience and our website. We do not use cookies to identify you, just to improve your experience on our website(s). If you do not wish to use the cookies, you can amend the settings on your internet browser so it will not automatically download cookies. However, if you remove or block cookies on your computer, please be aware that your browsing experience and our website’s functionality may be affected.
"
              />

              <Heading size="h3">9. Your Rights</Heading>

              <ListItem
                bullet="9.1"
                paragraph="Right to withdraw consent. If we process Personal Data based on your consent, you are allowed to withdraw your consent at any time.
"
              />

              <ListItem
                bullet="9.2"
                paragraph="Right to information and access. You have the right to learn whether we are processing your Personal Data and to request a copy of the Personal Data we are processing about you.
"
              />

              <ListItem
                bullet="9.3"
                paragraph="Right to rectification, restriction, limitation, deletion. You have a right to have your information rectified, restricted, limited or deleted. 
"
              />

              <ListItem
                bullet="9.4"
                paragraph="Right to object. Under certain circumstances, you have the right to object to the processing of your Personal Data when we process your Personal Data on the basis of our legitimate interest. In such cases, we will cease processing your Personal Data unless there are compelling legitimate grounds to continue processing or where it is needed for legal reasons. 
"
              />

              <ListItem
                bullet="9.5"
                paragraph="Right to portability. You have the right to obtain the Personal Data we hold about you, in a structured, electronic format, and to transfer such Personal Data to another data controller, where this is (a) Personal Data which you have provided to us, and (b) we are processing that data on the basis of your consent or to perform a contract with you.
"
              />

              <ListItem
                bullet="9.6"
                paragraph="Complaints. If you have a complaint about this Policy or any element of the Personal Data that we hold, then please contact us. If you are not satisfied, then you have the right to lodge a complaint with the local data protection authority.
"
              />

              <Heading size="h3">10. Changes</Heading>

              <ListItem
                bullet="10.1"
                paragraph="We may modify this Policy at any time. If we make changes to this Policy, then we will post an updated version of this Policy at this website. When using our services, you will be asked to review and accept our Policy. In this manner, we may record your acceptance and notify you of any future changes to this Policy.
"
              />

              <Heading size="h3">Contact us</Heading>

              <Paragraph>
                To contact us, please email us at{" "}
                <a href="mailto:privacy@saber.app">privacy@saber.app</a>.
              </Paragraph>
            </LegalPage>
          </Container>
        </PageContent>
        <PageFooter />
      </Page>
    </>
  );
};

export default PrivacyPolicy;
