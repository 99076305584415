import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

import { styled } from "styled-components";

// Component Library
import {
  Button,
  Card,
  Heading,
  Paragraph,
} from "@saberapp/react-component-library";

// Enums
import { HttpMethod } from "@saberapp/enums";

// Hooks
import { useConnectors } from "../../../../hooks/useConnectors";
import { useApi } from "../../../../hooks/useApi";

dayjs.extend(relativeTime);
dayjs.locale("en");

const Column = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;

  &:last-child {
    margin-left: auto;
  }
`;

const Row = styled.div`
  display: flex;
  gap: 8px;
`;

const RowSpaced = styled.div`
  display: flex;
  gap: 32px;
  justify-content: space-between;
  align-items: center;
`;

const CardDescription = styled.div`
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid var(--button-border);

  ul {
    margin: 0;
  }
`;

const Table = styled.table`
  width: 100%;
  border-spacing: 0;
`;
const Tr = styled.tr`
  &:nth-child(even) {
    background: var(--card-border);
  }
`;
const Td = styled.td`
  padding: 8px;
`;

const ExplanationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid var(--button-border);
`;
const Explanation = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const IntegrationSync = ({
  connector,
  heading,
  isSyncEnabled = true,
  disabledMessage,
  disabledDescription,
  customConfig,
  customConfigButtonTitle,
  customConfigButtonTitleHide,
  customConfigVisibility,
  customConfigData
}) => {
  const { API } = useApi();

  const { isConnectorsLoading, getConnectorByName, doesConnectorExist } =
    useConnectors();

  const [isSyncing, setIsSyncing] = useState(false);
  const [showSyncResults, setShowSyncResults] = useState(false);
  const [syncResults, setSyncResults] = useState({});
  const [showCustomConfig, setShowCustomConfig] = useState(
    customConfigVisibility
  );

  useEffect(()=>{
    setShowCustomConfig(customConfigVisibility);
  },[customConfigVisibility])

  const sync = async () => {
    setIsSyncing(true);
    try {
      await API.call(
        HttpMethod.POST,
        `/connectors/${connector.slug}/sync`,
        {
          connectorId: connector._id,
          requestData: customConfigData
        },
        (data) => {
          setIsSyncing(false);
          console.log(data);
          setSyncResults(data.results);
          setShowSyncResults(true);
        }
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <>
      {!isConnectorsLoading && (
        <>
          {getConnectorByName(connector.source)?.status === "connected" && (
            <>
              <Card padding={16}>
                <Row>
                  <Column>
                    {isSyncEnabled ? (
                      <>
                        <Heading size="h4">
                          {heading || `Sync your ${connector.name} data`}
                        </Heading>
                        <Paragraph>
                          {isSyncing
                            ? `Sync is in progress`
                            : getConnectorByName(connector.source)?.lastSynced
                            ? `Last synced 
                      ${dayjs(
                        getConnectorByName(connector.source)?.lastSynced
                      ).fromNow()}`
                            : `Never synced`}
                        </Paragraph>
                      </>
                    ) : (
                      <>
                        <Heading size="h4">{disabledMessage}</Heading>
                        <Paragraph>{disabledDescription}</Paragraph>
                      </>
                    )}
                  </Column>
                  <Column>
                    <Row>
                      {customConfig && (
                        <Button
                          type="ui"
                          size="small"
                          title={
                            !showCustomConfig
                              ? customConfigButtonTitle || `Edit`
                              : customConfigButtonTitleHide || `Hide`
                          }
                          onClick={() => {
                            setShowCustomConfig(!showCustomConfig);
                          }}
                        />
                      )}
                      <Button
                        type="ui"
                        size="small"
                        disabled={isSyncing || !isSyncEnabled}
                        title={
                          isSyncing ? "Syncing..." : `Sync ${connector.name}`
                        }
                        onClick={() => {
                          sync();
                        }}
                      />
                    </Row>
                  </Column>
                </Row>

                {customConfig && showCustomConfig && <Row>{customConfig}</Row>}

                {showSyncResults && (
                  <>
                    <CardDescription>
                      {/* <Table>
                        <Tr style={{ paddingBottom: 16 }}>
                          <Td>
                            <Heading size="h4">Result Type</Heading>
                          </Td>
                          <Td>
                            <Heading size="h4">Number of Users</Heading>
                          </Td>
                        </Tr>

                        <Tr>
                          <Td>Newly Synced Users</Td>
                          <Td>{syncResults.newUsers}</Td>
                        </Tr>
                        <Tr>
                          <Td>Existing Saber Users</Td>
                          <Td>{syncResults.existingUsers}</Td>
                        </Tr>
                        <Tr>
                          <Td>Non-Saber Users</Td>
                          <Td>{syncResults.suggestedUsers}</Td>
                        </Tr>
                        <Tr>
                          <Td>Deactivated Users</Td>
                          <Td>{syncResults.deactivatedUsers}</Td>
                        </Tr>
                      </Table>

                      <ExplanationsContainer>
                        <Explanation>
                          <Heading size="h4">Non-Saber Users</Heading>
                          <Paragraph>
                            These are users exist in your Slack workspace, but
                            don't have a Saber account. We've collected these
                            for you, so you can easily invite them in{" "}
                            <a href="#">Members & Roles</a>.
                          </Paragraph>
                        </Explanation>
                        <Explanation>
                          <Heading size="h4">Deactivated Users</Heading>
                          <Paragraph>
                            Deactivated users are users that are deactivated in
                            your Workspace. We simply ignore these.
                          </Paragraph>
                        </Explanation>
                      </ExplanationsContainer> */}
                    </CardDescription>
                  </>
                )}
              </Card>
            </>
          )}
        </>
      )}
    </>
  );
};

export default IntegrationSync;
