import React, { useEffect } from "react";
import { styled } from "styled-components";
import { useNavigate } from "react-router-dom";

// Hooks
import { useAuth } from "../../../hooks/useAuth";
import { useConnectors } from "../../../hooks/useConnectors";

// Component Library
import { SidebarLink, SidebarHeading } from "@saberapp/react-component-library";

// Icon
import {
  IconBuildingOffice as IconOrganization,
  IconUserCircle as IconAccount,
  IconArrowUturnLeft as IconBack,
  IconPuzzlePiece,
} from "@saberapp/icons";
import { IntegrationStatusIndicator } from "../../pages/settings/components/IntegrationStatusIndicator";

const StyledSidebar = styled.nav`
  background: var(--sidebar-bg);
  border-right: 1px solid var(--sidebar-border);
  width: 204px;
  padding-left: 16px;
  padding-right: 16px;
  min-width: 220px;
  height: 100%;
  display: flex;
  flex-direction: column;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 16px 0;
  gap: 8px;
`;
const BackLink = styled.a`
  width: 16px;
  border-radius: 6px;
  padding: 8px;
  color: var(--heading-text);

  &:hover {
    background: var(--dropdown-bg);
  }
`;
const Title = styled.h2`
  font-weight: 500;
`;

const StatusContainer = styled.div`
  top: 12px;
  position: absolute;
  right: 32px;
`;

const Scroll = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow: auto;
  padding-bottom: 16px;
`;

const SidebarSettings = () => {
  const navigate = useNavigate();
  const { organization, userHasRole } = useAuth();
  const {
    doesConnectorExist,
    getConnectorByName,
    doesUserHaveConnector,
    getUserConnectorByName,
    connectorCatalog,
    isConnectorCatalogLoading,
  } = useConnectors();

  return (
    <StyledSidebar>
      <TitleContainer>
        <BackLink onClick={() => navigate(`/${organization.slug}/`)}>
          <IconBack />
        </BackLink>
        <Title>Settings</Title>
      </TitleContainer>

      <Scroll>
        <ul>
          <SidebarLink
            path={`/${organization.slug}/settings`}
            title="Overview"
          />
        </ul>

        {userHasRole("ADMIN") && (
          <>
            <SidebarHeading title="Organization" icon={<IconOrganization />} />
            <ul>
              <SidebarLink
                path={`/${organization.slug}/settings/general`}
                title="General"
              />
              <SidebarLink
                path={`/${organization.slug}/settings/members`}
                title="Members & Roles"
              />
            </ul>
            <SidebarHeading title="Content" icon={<IconOrganization />} />
            <ul>
              {/* <SidebarLink
                path={`/${organization.slug}/settings/tags`}
                title="Tags"
              />
              <SidebarLink
                path={`/${organization.slug}/settings/content-types`}
                title="Content Types"
              /> */}
              <SidebarLink
                path={`/${organization.slug}/settings/content-quality`}
                title="Content Quality"
              />
            </ul>
          </>
        )}

        <>
          {userHasRole("ADMIN") && (
            <>
              <SidebarHeading title="Connectors" icon={<IconPuzzlePiece />} />
              <ul>
                {!isConnectorCatalogLoading ? (
                  connectorCatalog.map((integration) => (
                    <SidebarLink
                      path={`/${organization.slug}/connector/${integration.slug}`}
                      title={
                        doesConnectorExist(integration.source) ? (
                          <>
                            {integration.name}{" "}
                            <StatusContainer>
                              <IntegrationStatusIndicator
                                pulse={"false"}
                                status={
                                  getConnectorByName(integration.source)?.status
                                }
                              />
                            </StatusContainer>
                          </>
                        ) : (
                          `${integration.name}`
                        )
                      }
                    />
                  ))
                ) : (
                  <></>
                )}
              </ul>
            </>
          )}
        </>

        {/* {userHasRole("ADMIN") && (
          <SidebarLink
            path={`/${organization.slug}/connector/slack`}
            title={
              doesConnectorExist("slack") ? (
                <>
                  Slack{" "}
                  <StatusContainer>
                    <IntegrationStatusIndicator
                      pulse={"false"}
                      status={getConnectorByName("slack")?.status}
                    />
                  </StatusContainer>
                </>
              ) : (
                `Slack`
              )
            }
          />
        )} */}
        {/* {userHasRole("ADMIN") && (
          <SidebarLink
            path={`/${organization.slug}/connector/google-drive`}
            title={
              doesConnectorExist("google_drive") ? (
                <>
                  Google Drive{" "}
                  <StatusContainer>
                    <IntegrationStatusIndicator
                      pulse={"false"}
                      status={getConnectorByName("google_drive")?.status}
                    />
                  </StatusContainer>
                </>
              ) : (
                `Google Drive`
              )
            }
          />
        )} */}
        {/* {userHasRole("ADMIN") && (
          <SidebarLink
            path={`/${organization.slug}/connector/pipedrive`}
            title={
              doesConnectorExist("pipedrive") ? (
                <>
                  Pipedrive{" "}
                  <StatusContainer>
                    <IntegrationStatusIndicator
                      pulse={"false"}
                      status={getConnectorByName("pipedrive")?.status}
                    />
                  </StatusContainer>
                </>
              ) : (
                `Pipedrive`
              )
            }
          />
        )} */}
        {/* {userHasRole("ADMIN") && (
          <SidebarLink
            path={`/${organization.slug}/connector/salesforce`}
            title={
              doesConnectorExist("salesforce") ? (
                <>
                  Salesforce{" "}
                  <StatusContainer>
                    <IntegrationStatusIndicator
                      pulse={"false"}
                      status={getConnectorByName("salesforce")?.status}
                    />
                  </StatusContainer>
                </>
              ) : (
                `Salesforce`
              )
            }
          />
        )} */}
        {/* <SidebarLink
          path={`/${organization.slug}/connector/chrome-extension`}
          title={
            doesUserHaveConnector("gmail") ? (
              <>
                Gmail{" "}
                <StatusContainer>
                  <IntegrationStatusIndicator
                    pulse={"false"}
                    status={getUserConnectorByName("gmail")?.status}
                  />
                </StatusContainer>
              </>
            ) : (
              `Gmail`
            )
          }
        /> */}
        {/* <SidebarLink
          path={`/${organization.slug}/connector/google-calendar`}
          title={
            doesUserHaveConnector("google_calendar") ? (
              <>
                Google Calendar{" "}
                <StatusContainer>
                  <IntegrationStatusIndicator
                    pulse={"false"}
                    status={getUserConnectorByName("google_calendar")?.status}
                  />
                </StatusContainer>
              </>
            ) : (
              `Google Calendar`
            )
          }
        /> */}

        <SidebarHeading title="My Account" icon={<IconAccount />} />
        <ul>
          <SidebarLink
            path={`/${organization.slug}/settings/profile`}
            title="Profile"
          />
        </ul>
      </Scroll>
    </StyledSidebar>
  );
};

export default SidebarSettings;
