import React from "react";
import { styled } from "styled-components";
import { useData } from "../../../../hooks/useData";

import {
  IconSquare2Stack,
  IconAtSymbol,
  IconBuildingOffice,
} from "@saberapp/icons";
import { Paragraph } from "@saberapp/react-component-library";

import { ReactComponent as BrandMark } from "../../../icons/saber/brandmark.svg";
import { useAuth } from "../../../../hooks/useAuth";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  justify-content: center;
  height: calc(100vh - 340px);
  width: 80%;
  margin: 0 auto;
  text-align: center;
`;
const BrandMarkContainer = styled.div`
  display: flex;
  justify-content: center;

  svg {
    width: 40px;
  }
`;

const PresetsContainer = styled.div`
  gap: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;
const Preset = styled.div`
  background: var(--card-bg);

  box-sizing: border-box;
  border-radius: 6px;
  border: 1px solid var(--button-ui-border);
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  line-height: 1.5;

  &:hover {
    border: 1px solid var(--mid-shadow-2);
    cursor: pointer;
  }
`;

const Icon = styled.div`
  width: 16px;
`;

const EmptyState = ({ setQuestion }) => {
  const { organization } = useAuth();

  return (
    <Container>
      <BrandMarkContainer>
        <BrandMark />
      </BrandMarkContainer>
      <Paragraph>
        Here you can test Saber by asking questions your team may ask or
        generate other examples. The feedback you provide here helps us produce
        more accurate results.
      </Paragraph>
      <PresetsContainer>
        <Preset onClick={() => setQuestion("What products do we offer?")}>
          <Icon>
            <IconSquare2Stack />
          </Icon>
          What products does {organization.name} offer?
        </Preset>
        <Preset
          onClick={() => setQuestion("What outbound template can I use?")}
        >
          <Icon>
            <IconAtSymbol />
          </Icon>
          What outbound template can I use?
        </Preset>
        <Preset
          onClick={() =>
            setQuestion(`What does ${organization.name} help customers do?`)
          }
        >
          <Icon>
            <IconBuildingOffice />
          </Icon>
          What does {organization.name} help customers do?
        </Preset>
      </PresetsContainer>
    </Container>
  );
};

export default EmptyState;
