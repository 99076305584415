import React from 'react';
import styled from 'styled-components';

// Component Library
import {
  Tab,
  Tabs
} from "@saberapp/react-component-library";

// Components
import ResourceDetails from './ResourceDetails';
// import ResourceActions from './ResourceActions';

// Icons
import { ReactComponent as IconActions } from "../../../icons/20/solid/sparkles.svg";
import { ReactComponent as IconDetails } from "../../../icons/24/outline/information-circle.svg";

const StyledSidebar = styled.aside`
  max-width: 360px;
  min-width: 360px;
  background: var(--main-bg);
  border-left: 1px solid var(--sidebar-border);
  border-bottom: 0;
  right: ${(props) => (props.isopen === "true" ? "0" : "-300px")};
  max-height: 100%;
  overflow-y: auto;
  transition: right 0.3s ease;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  // box-shadow: -10px 0px 20px -15px rgba(0,0,0,0.75);
  z-index: 1;
`;

const Sidebar = ({
  isopen,
  layout,
  mode,
  handleSwitchMode,
  setReviewRequested,
  reviewRequested,
  handleConfirmationModal,
  handleEditVisibility,
  handleEditContentType,
  handleEditTags,
}) => {

  return (
    <StyledSidebar isopen={isopen}>
      {layout !== "view" ? (
        <ResourceDetails
        mode={mode}
        layout={layout}
        handleSwitchMode={handleSwitchMode}
        setReviewRequested={setReviewRequested}
        reviewRequested={reviewRequested}
        handleEditVisibility={handleEditVisibility}
        handleEditContentType={handleEditContentType}
        handleEditTags={handleEditTags}
      />
        // <Tabs layout="full-width">
        //   <Tab label="Details" icon={<IconDetails />}>
            
        //   </Tab>
        //   <Tab label="Actions" icon={<IconActions />}>
        //     <ResourceActions
        //       handleConfirmationModal={handleConfirmationModal}
        //     />
        //   </Tab>
        // </Tabs>
      ) : (
        <ResourceDetails
          mode={mode}
          layout={layout}
          handleSwitchMode={handleSwitchMode}
          setReviewRequested={setReviewRequested}
          reviewRequested={reviewRequested}
          handleEditVisibility={handleEditVisibility}
          handleEditContentType={handleEditContentType}
          handleEditTags={handleEditTags}
        />
      )}
    </StyledSidebar>
  );
};

export default Sidebar;
