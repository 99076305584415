import React, { useEffect, useState } from "react";
import styled from "styled-components";

// Component Library
import { Heading } from "@saberapp/react-component-library";

import { useAuth } from "../../../hooks/useAuth";

import AuthTemplate from "../../templates/AuthTemplate";

import { ReactComponent as IconGoogle } from "../../icons/connectors/google.svg";
import { useNavigate, useSearchParams } from "react-router-dom";

// Icons
import { ReactComponent as BrandMark } from "../../icons/saber/brandmark.svg";

const TinyText = styled.p`
  font-family: "Inter", sans-serif !important;
  color: var(--body-text);
  margin: 0;
  padding: 0;
  font-size: 12px;
  line-height: 1.6;
  width: 300px;
  opacity: .5;
  transition: all .5s ease-out;

  a {
    color: var(--heading-text);
    cursor: pointer;

    &:hover {
      color: var(--button-cta-bg);
    }
  }

  &:hover {
    opacity: 1;
  }
`;

const LogoWrapper = styled.div`
  color: #fff;
  font-size: 32px;
  font-family: "Bungee", sans-serif;
  color: var(--heading-text);
  width: 32px;

  svg {
    width: 100%;
    height: auto;
  }
`;

const SignInBox = styled.div`
  width: 300px;
  background: var(--card-bg);
  border: 1px solid var(--card-border);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 32px;
  align-items: flex-start;
  text-align: left;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

const SignInButton = styled.button`
  font-family: "Inter", sans-serif;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 12px;
  font-weight: 500;
  max-width: 100%;
  min-width: 32px;
  min-height: 28px;
  justify-content: center;
  box-shadow: var(--soft-shadow-2) 0px 2px 4px;
  font-size: 14px;
  background: var(--button-cta-bg);
  color: var(--button-cta-text);
  width: 100%;
  padding: 12px;
  border: 1px solid rgba(255, 255, 255, 0.3);

  &:hover {
    background: var(--button-cta-bg-hover);
  }

  svg {
    width: 16px;
  }
`;

const Disclaimer = styled.p`
  font-family: "Inter", sans-serif !important;
  color: var(--body-text);
  margin: 0;
  padding: 0;
  font-size: 12px;
  line-height: 1.6;

  a {
    color: var(--heading-text);
    font-weight: 600;
  }
`;

const SignIn = () => {
  // Hooks
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { isAuthenticated, signInWithGoogle, signInUsingCustomToken, signOut, isLoading, organization } = useAuth();

  useEffect(()=>{
    const customToken = searchParams.get('customToken');
    console.log(customToken)
    if (customToken) {
      signOut()
      signInUsingCustomToken(customToken, navigate)
    }
  }, [])

  useEffect(() => {
    if (isAuthenticated && organization?.slug) {
      navigate(`/${organization.slug}`)
    }
  }, [isAuthenticated])

  const signIn = () => {
    signInWithGoogle(navigate)
  }

  return (
    <AuthTemplate>
      <link rel="preconnect" href="https://fonts.googleapis.com"></link>
      <link
        rel="preconnect"
        href="https://fonts.gstatic.com"
        crossorigin
      ></link>
      <link
        href="https://fonts.googleapis.com/css2?family=Bungee&display=swap"
        rel="stylesheet"
      ></link>
      {isLoading ? (
        "Signing in ..."
      ) : (
        <Column>
          <LogoWrapper>
            <BrandMark />
          </LogoWrapper>
          <SignInBox>
            <Heading size="h4">Saber Alpha Program</Heading>
            <Disclaimer>
              Saber is currently only available to test customers as part of our
              Alpha program. If you'd like to be part of the Alpha program,
              reach out to <a href="mailto:sales@saber.app">sales@saber.app</a>.
            </Disclaimer>
            <Heading size="h4">Already have an account?</Heading>

            <SignInButton
              disabled={isLoading}
              onClick={signIn}
            >
              <IconGoogle /> Sign in with Google
            </SignInButton>
          </SignInBox>
          <TinyText>
            By signing up, you agree to our <a onClick={() => {
              navigate("/terms");
            }}>Terms of Services</a>,{" "}<a onClick={() => {
              navigate("/privacy");
            }}>Privacy Policy</a>{" "}
            and <a onClick={() => {
              navigate("/dpa");
            }}>Data Protection Agreement</a>
          </TinyText>
        </Column>
      )}
    </AuthTemplate>
  );
};

export default SignIn;
