import React from "react";
import styled from "styled-components";

const Container = styled.div`
  min-height: calc(100vh - 280px);
`;

const PageContent = ({ children }) => {
  return <Container>{children}</Container>;
};

export default PageContent;
