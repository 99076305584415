import React from "react";
import styled from "styled-components";

import PageHeader from "./components/PageHeader";

import { Heading, Paragraph } from "@saberapp/react-component-library";
import PageFooter from "./components/PageFooter";
import Page from "./components/Page";
import PageContent from "./components/PageContent";
import ListItem from "./components/ListItem";

const LegalPage = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 160px;
`;

const TermsOfService = () => {
  return (
    <>
      <PageHeader />
      <Page>
        <PageContent>
          <LegalPage>
            <Heading size="h1">Terms of Service</Heading>
            <Heading size="h3">Effective date: 1st of January, 2024</Heading>

            <Heading size="h3">1. Introduction</Heading>

            <ListItem
              bullet="1.1"
              paragraph="Saber B.V. (Saber or we) provides services as specified in the
                Order Form (the Services) through the Saber application or
                platform (the Platform). Saber will provide these Services and
                access to the Platform to a customer (the Customer) upon signing
                of an order form by Saber and Customer (the Order Form) and will
                do so as of the Subscription Start Date. These terms of Service
                (Terms) are intended for and apply to any Customer who purchases
                our Services and anyone using our Platform. Except for any
                Customer who has signed the Order Form, no party can derive any
                rights from these Terms."
            />

            <ListItem
              bullet="1.2"
              paragraph="In accepting these Terms by signing an Order Form, Customer agrees to be bound by these Terms."
            />
            <ListItem
              bullet="1.3"
              paragraph="Any terms and conditions of Customer are hereby expressly rejected."
            />
            <ListItem
              bullet="1.4"
              paragraph="We may change these Terms from time to time, for example because our business model has changed or implement changed laws and regulations. If we change the Terms, we will notify Customers and (if applicable) Users via e-mail. The latest version of these Terms can always be accessed through our Website."
            />

            <Heading size="h3">2. Definitions</Heading>

            <ListItem
              bullet="2.1"
              paragraph="Capitalized terms shall have the meaning ascribed to them throughout these Terms. The following terms shall be defined as follows:"
            />

            <ListItem
              bullet="(i)"
              indent={46}
              paragraph="Agreement means the agreement between Saber and the Customer entered into by signing of the Order Form, and includes these Terms."
            />

            <ListItem
              bullet="(ii)"
              indent={46}
              paragraph="Confidential Information means any information in any form (written, digital, oral or otherwise, and including any copies) regarding a party, its affiliates, and its business that is marked as or could reasonably be considered confidential, including information shared before the Subscription Start Date, as well as the negotiations, existence and terms of the Agreement between Saber and the Customer.
                "
            />
            <ListItem
              bullet="(iii)"
              indent={46}
              paragraph="Customer means the customer identified on the Order Form.
                "
            />
            <ListItem
              bullet="(iv)"
              indent={46}
              paragraph="Customer Content means data, information, or materials submitted by or on behalf of Customer or Users to the Platform, but excludes Feedback.
                "
            />
            <ListItem
              bullet="(v)"
              indent={46}
              paragraph="Feedback means suggestions, feedback, or comments about the Platform or related offerings.
                "
            />
            <ListItem
              bullet="(vi)"
              indent={46}
              paragraph="Fees means the fees payable by Customer for the Platform, and any Services provided hereunder, as specified in the Order Form.
                "
            />
            <ListItem
              bullet="(vii)"
              indent={46}
              paragraph="IP Rights means any rights under patent, copyright, trade secret, trademark, whether registered or not, or other intellectual or industrial property laws worldwide, as well as any know-how contained therein or related to the same.
                "
            />
            <ListItem
              bullet="(viii)"
              indent={46}
              paragraph="Material Breach means a substantial failure or violation by a party in performing its material obligations or complying with the material provisions of these Terms. A Material Breach occurs when the breaching party's non-performance or violation significantly or repeatedly impairs the other party's ability to receive the intended benefits or its ability to carry out its functions pursuant to these Terms.
                "
            />
            <ListItem
              bullet="(ix)"
              indent={46}
              paragraph="Order Form means a written order form that specifies the Services provided under these Terms and describes the Term, the Fees, the Services (if any) and other information relevant to a specific transaction between the Customer and Saber. Each Order Form shall be issued pursuant to these Terms, shall require Customer to be bound to these Terms and must be executed by both Saber and Customer in order to be effective.
                "
            />
            <ListItem
              bullet="(x)"
              indent={46}
              paragraph="Payment Term means the payment term identified in the Order Form.
                "
            />
            <ListItem
              bullet="(xi)"
              indent={46}
              paragraph="Permissions means the permissions to Users to use the Platform.
                "
            />
            <ListItem
              bullet="(xii)"
              indent={46}
              paragraph="Platform means the online software platform or application made available by Saber to Customer hereunder and described in the heading of this Agreement, including any machine learning models, source code, libraries, software architecture, APIs, interfaces, tools, workflows, content, user interfaces, graphics, visualizations, documents, technical guidance and the design, structure, organization, selection, coordination, expression, “look and feel” and arrangement of such materials, underlying structure, ideas, know-how or algorithms developed by Saber (regardless whether developed expressly in relation to the platform) without use of customer data.  
                "
            />
            <ListItem
              bullet="(xiii)"
              indent={46}
              paragraph="Saber or we means Saber B.V.
                "
            />
            <ListItem
              bullet="(xiv)"
              indent={46}
              paragraph="Services means any services described in the heading of these Terms and specified in the Order Form (including access to the Platform) provided by Saber to Customer.
                "
            />
            <ListItem
              bullet="(xv)"
              indent={46}
              paragraph="Service Level Agreement or SLA means the Service Level Agreement applicable hereto. 
                "
            />
            <ListItem
              bullet="(xvi)"
              indent={46}
              paragraph="Statement of Work or SOW means any Statement of Work.
                "
            />
            <ListItem
              bullet="(xvii)"
              indent={46}
              paragraph="Subscription Start Date means the start date of the subscription as specified in the Order Form.
                "
            />
            <ListItem
              bullet="(xviii)"
              indent={46}
              paragraph="Term means the term as specified in the Order Form during which Saber will render Services to Customer.
                "
            />
            <ListItem
              bullet="(xix)"
              indent={46}
              paragraph="Terms means these Terms of Service.
                "
            />
            <ListItem
              bullet="(xx)"
              indent={46}
              paragraph="Usage Data means data and information about the provision, use, and performance of the Platform and related offerings based on Customer’s or User’s use of the Platform.
                "
            />
            <ListItem
              bullet="(xxi)"
              indent={46}
              paragraph="User means an employee or contractor of Customer that is authorized by Customer to access and use the Services.
                "
            />

            <Heading size="h3">3. Services</Heading>

            <ListItem
              bullet="3.1"
              paragraph="Services. We shall use commercially reasonable efforts to provide the Services as specified in the Order Form and to ensure that the Platform is available in accordance with the applicable Service Level Agreement (the SLA). The Parties may add additional Services through subsequent mutually agreed Statement of Work (SOW). All SOWs must be signed by authorized representatives of both Saber and the Customer in order to be effective, shall be deemed incorporated herein and shall be subject to these Terms.
                "
            />
            <ListItem
              bullet="3.2"
              paragraph="Service Level. If Services do not meet the service levels specified in the SLA, we will provide the responses outlined in the SLA and will not be responsible for any other responses or remedies. In any event, if the Services are temporarily unavailable for scheduled maintenance, for unscheduled emergency maintenance, or because of other causes beyond our reasonable control, no remedies will accrue. We will inform Customer before scheduled service disruptions by e-mail or through the Platform (status page: status.saber.app).
                "
            />
            <ListItem
              bullet="3.3"
              paragraph="Modifications. We may modify, add, or remove some or all of the Platform functionalities at any time in our sole discretion, provided that at least seven (7) days’ notice is provided for any modifications or removals. 
                "
            />
            <ListItem
              bullet="3.4"
              paragraph="Usage right. We own the Services and the Platform and any other content or materials provided to Customer. We retain ownership of all right, title, and interest in and to the IP Rights pertaining thereto, all related and underlying technology and any updates, enhancements, upgrades, modifications, patches, workarounds, and fixes thereto and all derivative works of or modifications to any of the foregoing. Subject to Customer’s compliance with these Terms and for the duration of the agreed Term, we grant Customer and each authorized User a non-exclusive, non-sublicensable and non-transferable right to access and use the Platform during the Term. The use of the Platform and the Services will not in whatever form result in any transfer of IP Rights from us to the Customer or User. 
                "
            />
            <ListItem
              bullet="3.5"
              paragraph="Feedback and Usage Data. Customer may, but is not required to, give us Feedback, in which case Customer gives such Feedback “as is”. We may use all Feedback freely without any restriction or obligation. In addition, We may collect and analyze data and information about the provision, use, and performance of the Platform and related offerings based on Customer’s or User’s use of the Platform (Usage Data), and may freely use such Usage Data to maintain, improve, and enhance our products and services without restriction or obligation. However, we may only share Usage Data with others if the Usage Data is aggregated and does not identify Customer or Users.
                "
            />
            <ListItem
              bullet="3.6"
              paragraph="Customer Content. Anything Customer posts, uploads, shares, stores or otherwise provides on the Platform is considered Customer Content. We may copy, display, modify, and use Customer Content only as needed to provide and maintain the Platform and related offerings. For this purpose, Customer grants us and our subcontractors a non-exclusive, worldwide, royalty-free, paid-up, transferable right and license to use, process, and display (to Users) Customer Content for the sole purpose of providing the Services to Customer. Customer will not provide Customer Content to us or use the Services in connection with Customer Content that (as determined by us, in our sole discretion): (a) is infringing, illegal, or likely to cause harm to any individuals; (b) contains a bug, virus or other similar attribute or code that may infect, disable or otherwise impair our systems, software, and Services or (c) may otherwise infringe or violate rights of a third party. We reserve the right to remove any such Customer Content from the Platform.
                "
            />
            <ListItem
              bullet="3.7"
              paragraph="Customer logo. We may use the Customer’s name, logo, and trademarks for marketing and promotional purposes in accordance with these Terms.
                "
            />

            <Heading size="h3">
              4. Customer’s responsibilities and Restrictions
            </Heading>

            <ListItem
              bullet="4.1"
              paragraph="Permissions and User accounts. The Services allow Customer to give permissions to Users to use the Services (Permissions). It is solely Customer’s responsibility to set, manage and monitor such Permissions. Customer will establish user login credentials such as usernames, passwords and PINs for Users to access the Platform. Customer must protect the confidentiality of these passwords and login credentials. Customer will promptly notify us if it suspects or knows of any fraudulent activity with its accounts, passwords, or credentials, or if they become compromised.
                "
            />
            <ListItem
              bullet="4.2"
              paragraph="Customer Responsibilities. Customer shall be responsible for:"
            />
            <ListItem
              bullet="(i)"
              indent={46}
              paragraph="obtaining and maintaining any equipment and ancillary services needed to connect to, access or otherwise use the Services;
                "
            />
            <ListItem
              bullet="(ii)"
              indent={46}
              paragraph="all activities on and maintaining the security of User accounts, passwords (including but not limited to administrative and user passwords) and files, and for all uses with or without Customer’s knowledge or consent;
                "
            />
            <ListItem
              bullet="(iii)"
              indent={46}
              paragraph="all actions on User accounts and for Users’ compliance with these Terms; and
                "
            />
            <ListItem
              bullet="(iv)"
              indent={46}
              paragraph="the accuracy, quality and legality of Customer Content and the means by which Customer acquires Customer Content.
                "
            />
            <ListItem
              bullet="4.3"
              paragraph="Customer Restrictions. Customer will not, and will not allow Users to, directly or indirectly: (a) sell, lease, license, sublicense, or otherwise make available the Platform to any third party; (b) reverse engineer, decompile, disassemble or otherwise attempt to discover the Platform; (c) modify, translate, or create derivative works based on the Services or the Platform; (d) use the Platform in violation of any applicable laws, regulations, and rights, (e) attempt to gain unauthorized access to, interfere with, damage or disrupt any parts of the Service, including, without limitation, by introducing viruses and other harmful code or by using flood pings, denial-of-service attacks, or similar methods or technology, or (f) use or access the Service to build or support and/or assist a third party in building or supporting products or services competitive to the Service
                "
            />
            <ListItem
              bullet="4.4"
              paragraph="Suspension. We may suspend access to the Platform in case of violation of this Clause 3, in case of a breach of any obligations of these Terms or if we determine in good faith that such suspension is necessary to avoid possible harm to us, other customers, or any third party’s property, systems, or information. We will notify Customer of the suspension and reason thereof as soon as commercially practicable.
                "
            />
            <Heading size="h3">5. Fees and Payment</Heading>

            <ListItem
              bullet="5.1"
              paragraph="Fees and payment. We will invoice Customer in advance for all applicable Fees specified in the Order Form and any SOW hereto. Fees are due and payable in the currency and within the Payment Term as specified in the Order Form. If Customer fails to pay the invoice within Payment Term, statutory interest (wettelijke handelsrente) shall be applicable and accrue. We have the right to suspend the Services until payment is received.
                "
            />
            <ListItem
              bullet="5.2"
              paragraph="Fees amendments. We reserve the right to change the Fees or applicable charges and to institute new charges and Fees at the end of the Term or then current renewal Term, upon thirty (30) days prior notice to Customer (which may be sent by email). 
                "
            />
            <ListItem
              bullet="5.3"
              paragraph="Payment. If Customer has a good-faith disagreement about the amounts charged on an invoice, Customer must notify us about the dispute during the Payment Term as defined in the Order Form and must pay all undisputed amounts on time. We will work together to resolve the dispute within fifteen (15) days after the end of the Payment Term. If no resolution is agreed, each party may pursue any remedies available under these Terms or Applicable Laws.
                "
            />
            <ListItem
              bullet="5.4"
              paragraph="Taxes. All Fees are exclusive of, and Customer is solely responsible for payment of, all applicable value-added, sales, use, right of use and other taxes and all applicable export and import fees, customs duties, and similar charges (other than taxes based on our net income) arising from the transactions hereunder.
                "
            />

            <Heading size="h3">6. Term and Termination</Heading>

            <ListItem
              bullet="6.1"
              paragraph="Subscription Period. Each Order Form will start on the Subscription Start Date as defined in the Order Form and continue for the Term. The Term will and automatically renew for additional Terms unless Saber or Customer give notice of non-renewal to the other thirty (30) days before the end of a Term. 
                "
            />
            <ListItem
              bullet="6.2"
              paragraph="Termination. Either party may terminate this Agreement if the other party (a) fails to cure a Material Breach of these Terms within thirty (30) days after receiving notice of the breach; (b) dissolves or stops conducting business without a successor; (c) makes an assignment for the benefit of creditors, or becomes the debtor in insolvency, receivership, or bankruptcy proceedings that continue for more than sixty (60) days. In addition, either party may terminate an affected Order Form if a Force Majeure Event prevents the Platform from materially operating for thirty (30) or more consecutive days. A party must notify the other of its reason for termination.
                "
            />
            <ListItem
              bullet="6.3"
              paragraph="Waiver. Both Saber and Customer waive the right, if any, to annul, rescind or dissolve (ontbinden) or cancel this Agreement in whole or in part, or to request annulment, rescission, dissolution or cancellation of this Agreement, in whole or in part, after the Subscription Start Date on the basis of articles 6:228 or 6:265 of the Dutch Civil Code.
                "
            />
            <ListItem
              bullet="6.4"
              paragraph="Effect of Termination. Upon the expiration or termination of this Agreement: (i) Customer’s right to access the Platform will immediately end, (ii) all SOW’s will terminate concurrently, (iii) Parties will immediately delete and/or destroy, and will not keep in its possession, recreate, or deliver to anyone else, any and all of the other party’s property, including, but not limited to Confidential Information, all devices and equipment belonging to the other party, all electronically-stored information and data, and any reproductions of any of the foregoing items that a party may have in its possession or control, and (iv) Customer will pay, or we will refund (e.g., with respect to pre-paid amounts and credits) as applicable, all accrued Fees within sixty (60) days of the invoice or the date termination occurred, whichever is earlier. 
                "
            />
            <ListItem
              bullet="6.5"
              paragraph="Surviving provisions. The provisions of Sections 3.1, 5, 6.5, 7, 8 and 9 of these Terms will survive the expiration or termination of this Agreement. Termination or expiration of this Agreement will not affect any already-accrued obligations or liabilities.
                "
            />

            <Heading size="h3">7. Warranty and Disclaimer</Heading>

            <ListItem
              bullet="7.1"
              paragraph="Customer warranty. Customer represents and warrants that all Customer Content submitted by Customer is correct, does not infringe any third-party IP rights or other rights and is in compliance with all applicable laws, rules and regulations.
                "
            />
            <ListItem
              bullet="7.2"
              paragraph="Warranty from us. We shall use reasonable efforts consistent with prevailing industry standards to maintain the Services in a manner which minimizes errors and interruptions in the Services. Services may be temporarily unavailable for scheduled maintenance or for unscheduled emergency maintenance, either by us or by third-party providers, or because of other causes beyond our reasonable control, but we shall use reasonable efforts to provide advance notice by e-mail or through the Platform (status page: status.saber.app) of any scheduled service disruption. However, we do not warrant that the Services will be uninterrupted or error free; nor do we make any warranty as to the results that may be obtained from use of the Services. 
                "
            />
            <ListItem
              bullet="7.3"
              paragraph="Disclaimer. The Platform is not intended for, and neither Customer nor User will use it for, any use case where failure could result in any damage to or loss of property. Customer will be solely responsible for any such usage. Except as expressly set forth herein, we (and our affiliates, agents, licensors and suppliers) provide the Platform and Services “as is” and “as available” and do not guarantee the availability or uptime of the Services. Third-party providers of products that are not ours may provide their own warranties. We will not be liable for Customer’s failure to back-up its files and data under any circumstances nor will we be liable for any loss of data, including without limitation the cost of reconstructing data lost during performance of services. The foregoing limitations will apply even if the above-stated warranty fails of its essential purpose.
                "
            />

            <Heading size="h3">8. Limitation of Liability</Heading>

            <ListItem
              paragraph="Neither party (nor any of its affiliates, agents, licensors or suppliers) will be liable to the other for: (a) any indirect, special, incidental, exemplary, punitive, or consequential damages of any kind in connection with these Terms, whether arising out of contract, tort, negligence, strict liability, or any other legal or equitable theory, even if advised of the possibility of such damages; or (b) any total amounts exceeding the fees paid by Customer to us under these Terms in the twelve (12) months preceding the events giving rise to the claim.

                "
            />
            <Heading size="h3">9. Confidentiality</Heading>

            <ListItem
              bullet="9.1"
              paragraph="Confidentiality Obligation. During the Term and any renewal thereof and for a period of three (3) years after the end of the Term, the parties undertake to treat as confidential the Confidential Information of the respective other party that have been entrusted to it or of which it has become aware in connection with the Agreement, not to share them, to use them exclusively for execution of the Agreement, and not to exploit them for themselves or others even after the end of the Agreement. 
                "
            />
            <ListItem
              bullet="9.2"
              paragraph="Exclusions. The obligation to keep confidential and not share Confidential Information does not apply to information that:
                "
            />
            <ListItem
              bullet="(i)"
              indent={46}
              paragraph="is already generally known;"
            />
            <ListItem
              bullet="(ii)"
              indent={46}
              paragraph="was already provided to the party under the confidentiality obligation before the Subscription Start Date  on a non-confidential basis;
                "
            />
            <ListItem
              bullet="(iii)"
              indent={46}
              paragraph="was made available to the party under the confidentiality obligation by third parties on a non-confidential basis, unless this third party in turn violated a confidentiality agreement by sharing the information.
                "
            />
            <ListItem
              bullet="9.3"
              paragraph="Disclosure of Confidential Information. No confidentiality obligation exists if the party under a confidentiality obligation is legally obligated to disclose Confidential Information in judicial, official, or other proceedings. If one party believes itself to be obligated in this way, it shall inform the other party in writing in a timely fashion before the disclosure, insofar as this is legally permissible, so the latter can prevent the disclosure through legal steps. In this notification, the obligated party shall inform the other party in an appropriate form, for example on the basis of a written report of a legal advisor, what confidential information must be shared. The obligated party shall only disclose the portion of the Confidential Information that must be disclosed.
                "
            />

            <Heading size="h3">10. Data Protection</Heading>
            <ListItem
              paragraph="Customer understands and acknowledges that, in connection with the use of the Services, we process Personal Data. We will process Personal Data in accordance with our Privacy Policy, and the Data Processing Agreement in place between Saber and Customer. 
"
            />
            <Heading size="h3">11. Governing Law and Competent Court</Heading>

            <ListItem
              paragraph="These Terms, and any action in relation thereto, shall be governed by the laws of the Netherlands. The competent courts of Amsterdam, the Netherlands, shall have exclusive jurisdiction in relation to any actions regarding these Terms, the Order Form, any SOW, and any other aspects of Customer’s relationship with Saber.
"
            />

            <Heading size="h3">12. Miscallenious</Heading>

            <ListItem
              bullet="12.1"
              paragraph="Notice. Notices will be considered properly received: (i) when delivered, if delivered in person or (ii) if transmitted by e-mail to the e-mail address specified in the Order Form. Both Saber and Customer may update its address for notice by providing written notice to the other party in accordance with this paragraph.
                "
            />
            <ListItem
              bullet="12.2"
              paragraph="Relationship. Nothing herein will be construed to create the relationship of employer and employee, principal and agent, partnership, or joint venture.
                "
            />
            <ListItem
              bullet="12.3"
              paragraph="Force Majeure. Except for payment obligations, neither Saber or Customer will be liable for any delays or failures to perform to the extent due to a cause beyond such party’s reasonable control, which may include natural disasters or acts of God, strikes or work stoppages, acts of war or terrorism, telecommunications disruptions, pandemics or epidemics, quarantines, or other government orders.
                "
            />
            <ListItem
              bullet="12.4"
              paragraph="Assignment. Neither this Agreement nor any rights or responsibilities hereunder may be assigned, delegated, or otherwise transferred by Customer, whether voluntarily or by operation of law, without our prior written consent. We may freely assign and transfer this Agreement without consent, in whole or in part, to a successor to substantially all of its business or assets to which these Terms relate. Subject to the foregoing, these Terms will be binding upon and will inure to the benefit of the parties and their respective successors and assigns.
                "
            />
            <ListItem
              bullet="12.5"
              paragraph="Severability. Should any provision of these Terms be held to be void, invalid or inoperative, the remaining provisions of these Terms will not be affected and will continue in effect and the invalid provision will be deemed modified or severed to the least degree necessary to remedy such invalidity.
                "
            />
            <ListItem
              bullet="12.6"
              paragraph="Entire Agreement; Amendments; Waivers. These Terms, including the Order Form and any SOW, contain the entire agreement between the parties relating to the subject matter hereof and supersedes any prior agreements, oral or written, between the parties regarding the subject matter. No amendments, modifications or changes will be effective unless they are in a writing signed by authorized representatives of the parties. No waiver of a breach of any provision of these Terms by either Saber or Customer will constitute a waiver of any subsequent breach of the same or any other provision hereof, and no waiver will be effective unless made in writing and signed by a duly authorized representative of the waiving party.
                "
            />

            <Heading size="h1">Service Level</Heading>

            <Heading size="h3">1. Technical Support</Heading>

            <ListItem paragraph="Technical Support Hours" />
            <ListItem paragraph="9.00-21.00 CET" />
            <ListItem paragraph="Available Methods to Request Support: E-mail, and Slack for Early Design Partners" />

            <Heading size="h3">2. Technical Support Terms</Heading>

            <ListItem
              bullet="2.1"
              paragraph="General - 
Saber will provide Customer with technical support, as described in Section 1 above (Technical Support), subject to Customer’s ongoing compliance with all obligations hereunder. Saber will have the right to modify its Technical Support offerings at any time.
"
            />
            <ListItem
              bullet="2.2"
              paragraph="Additional Features; Updates and Upgrades -
Customer agrees that Technical Support does not include the development or provision of any new enhancements, features, or modules related to the Platform outside of any work defined in the SOW. Provided Customer is in compliance with the Terms, during the Term Saber will make available to Customer any updates or upgrades to the Platform that it makes generally available to its other customers at no charge.
"
            />
            <ListItem
              bullet="2.3"
              paragraph="Exclusions -
Saber’s Technical Support and service level obligations set forth herein will not apply with regard to any problems caused by: (i) factors beyond Saber’s reasonable control; (ii) hardware or software (including any third party connections) not provided by Saber; (iii) modification of Customer’s implementation other than by Saber; or (iv) Customer’s misuse of the Platform (collectively, the Exclusions).
"
            />

            <Heading size="h3">3. Service Level</Heading>

            <ListItem
              bullet="3.1"
              paragraph="Availability - 
Saber will use commercially reasonable efforts to make the Platform available twenty-four (24) hours a day, seven (7) days a week. Notwithstanding the foregoing, Saber reserves the right to suspend Customer’s access to the Platform: (i) for scheduled or emergency maintenance, (ii) for scheduled or emergency disruption due to beta testing or experimental features of the Platform, and (iii) outages caused by Exclusions. 
"
            />
            <ListItem
              bullet="3.1"
              paragraph="Response Times - 
All problems reported to Saber’s technical support team will receive a response within one (1) business day (business day being any business day in the Netherlands). Certain problems may be easier to address than others, and Saber may not be able to completely resolve Customer’s problem with an initial response. If Saber cannot resolve Customer’s problem, Saber will make a good faith effort to give an assessment of the issue and an estimated time for resolution. Customer acknowledges that the time needed to remedy a reported error may vary according to the nature and seriousness of such error, and that no guarantee is made by Saber as to the time within which remedies will be complete.
"
            />
          </LegalPage>
        </PageContent>
        <PageFooter />
      </Page>
    </>
  );
};

export default TermsOfService;
