import React from "react";
import { styled } from "styled-components";
import { useNavigate } from "react-router-dom";
import { ReactComponent as IconChevron } from "../../icons/24/solid/chevron-right.svg";

const HeaderContainer = styled.div`
  height: 61px;
  width: 100%;
  position: relative;
`;

const Header = styled.header`
  width: calc(100% - 238px);
  min-height: 60px;
  display: flex;
  align-items: center;
  position: fixed;
  z-index: 8000;
  padding: ${({ padding }) => padding !== null ? `${padding}px` : `16px`};
  padding-left: 16px;
  padding-right: 32px;
  box-sizing: border-box;
  background: var(--main-bg);
  border-bottom: 1px solid var(--sidebar-border);
`;

const BreadcrumbsContainer = styled.div`
  padding-left: 16px;
  display: flex;
  align-items: center;
  gap: 8px;

  svg {
    width: 10px;
  }
`;

const BreadcrumbItem = styled.a`
cursor: pointer;
  font-size: 13px;
  font-weight: 500;
  white-space: nowrap;
  color: ${(props) =>
    props.islast === "true"
      ? "var(--heading-text)"
      : "var(--body-text)"};
`;

const TopNavigation = ({ children, links, padding }) => {
  return (
    <HeaderContainer>
      <Header padding={padding}>
        <Breadcrumbs links={links} />
        {children}
      </Header>
    </HeaderContainer>
  );
};

const Breadcrumbs = ({ links }) => {

  const navigate = useNavigate();
  return (
    <BreadcrumbsContainer>
      {links.map((link, index) => (
        <React.Fragment key={index}>
          <BreadcrumbItem
            onClick={() => {
              navigate(link.path)
            }}
            islast={`${index === links.length - 1}`}
          >
            {link.title}
          </BreadcrumbItem>
          {index !== links.length - 1 && <IconChevron />}
        </React.Fragment>
      ))}
    </BreadcrumbsContainer>
  );
};

export default TopNavigation;
