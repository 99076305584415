import React, { useState, useEffect } from "react";
import styled from "styled-components";

// Component Library
import {
  Column,
  Heading,
  Paragraph,
  Line,
  Input,
  TextArea,
  Button,
  Card,
} from "@saberapp/react-component-library";

import SettingsTemplate from "../../../templates/SettingsTemplate";
import SettingsHeader from "../components/SettingsHeader";
import { useAuth } from "../../../../hooks/useAuth";
import { useApi } from "../../../../hooks/useApi";
import { HttpMethod } from "@saberapp/enums";

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const SettingsOrganization = () => {
  const { organization, updateOrganization } = useAuth();
  const { API } = useApi();

  const [updatedName, setUpdatedName] = useState();
  const [updatedDescription, setUpdatedDescription] = useState();
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    console.log(organization)
    setUpdatedName(organization.name);
    setUpdatedDescription(organization.description);
  }, [organization]);

  const saveOrganization = () => {
    if(API.isReady && !isSaving){
      setIsSaving(true);
      API.call(HttpMethod.PUT, '/', {
        name: updatedName,
        description: updatedDescription
      }, (updatedOrganization) => {
        updateOrganization(updatedOrganization);
        setIsSaving(false);
        
      })
    }
  }

  return (
    <SettingsTemplate>
      <Column gap="s">
        <Heading size="h1">General</Heading>
        <Paragraph>Manage organization name and description</Paragraph>
      </Column>
      {/* 
      <Line distance="tiny" /> */}

      <Card>
        <Flex>
          <Heading size="h3">Company Name</Heading>
          <Paragraph>
            Your company name helps us distinguish between you, your prospects,
            customers or competitors.
          </Paragraph>
          <Input
            placeholder="Your company name"
            value={updatedName}
            onChange={(e) => {
              setUpdatedName(e.target.value);
            }}
          />
        </Flex>
      </Card>

      <Card>
        <Flex>
          <Heading size="h3">Company Description</Heading>
          <Paragraph>
            Understanding what your company does and what services it provides
            allows us to generate more personalised answers and recommendations.
          </Paragraph>
          <TextArea
            placeholder="A paragraph or two on what your company does..."
            value={updatedDescription}
            onChange={(e) => {
              setUpdatedDescription(e.target.value);
            }}
          />
        </Flex>
      </Card>

      <Button type="primary" size="regular" disabled={isSaving} title={isSaving ? 'Saving...' : 'Save Organization'} onClick={saveOrganization} />
    </SettingsTemplate>
  );
};

export default SettingsOrganization;
