import React from "react";
import styled from "styled-components";

// Component Library
import {
    Button,
    FileTypeIcon,
    Heading,
  } from "@saberapp/react-component-library";

import { IconWrapper, IconXMark } from "@saberapp/icons";

const StyledModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
`;

const SelectedResources = styled.div`
  padding: 4px 4px 0px;
  padding-bottom: 0;
  display: flex;
  gap: 8px;
`;

const Resource = styled.div`
  display: inline-flex;
  gap: 8px;
  align-items: center;
  background: var(--context-menu-item-bg-hover);
  padding: 4px 8px;
  border-radius: 4px;
`;

export const EditHeader = ({ resources, closeModal }) => {
    return (
        <StyledModalHeader>
        {resources.length > 0 && (
          <SelectedResources>
            <Resource>
              <IconWrapper size="12">
                <FileTypeIcon mimeType={resources[0].mimeType} />
              </IconWrapper>
              <Heading size="h5">{resources[0].name}</Heading>
            </Resource>
            {resources.length > 1 && (
              <Resource>+{resources.length - 1}</Resource>
            )}
          </SelectedResources>
        )}

        <Button
          type="ui"
          size="small"
          iconBefore={<IconXMark />}
          noborder={true}
          onClick={(e) => {
            closeModal(e);
          }}
        />
      </StyledModalHeader>
    )
}