import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import { motion } from "framer-motion";

import IconWrapper from "../../icons/IconWrapper";

// Hooks
import { useAuth } from "../../../hooks/useAuth";
import { Search } from "../../../hooks/useSearch";

// Component Library
import {
  Loader,
  FileTypeIcon,
  Heading,
} from "@saberapp/react-component-library";

import { IconArrowDown, IconArrowUp } from "@saberapp/icons";

const SearchOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  z-index: 9000;
`;

const SearchBoxContainer = styled(motion.div)`
  margin-top: 64px;
  height: fit-content;
  width: 640px;
  backdrop-filter: blur(2px);
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background: var(--modal-bg);
  border: 1px solid var(--modal-border);
  box-shadow: var(--mid-shadow-2) 0px 16px 70px;
`;

const SearchFieldContainer = styled.div`
  max-height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid var(--modal-border);
  padding: 0 16px;
`;

const SearchBar = styled.input`
  width: 100%;
  padding: 16px;
  padding-left: 0;
  border: 0;
  background: none;
  outline: none;
  font-size: 16px;
  color: var(--heading-text);
`;

const Header = styled.div`
  font-weight: 500;
  font-size: 12px;
  margin: 0;
  padding: 0 16px;
  height: 32px;
  min-height: 32px;
  display: flex;
  align-items: center;
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 4px;
  max-height: 320px;
  overflow: auto;
`;

const Action = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 16px;
  border-radius: 4px;
  color: var(--heading-text);
  background: ${({ selected }) =>
    selected ? `var(--context-menu-item-bg-hover)` : `none`};
  cursor: pointer;
  justify-content: space-between;
`;

const Left = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  pointer-events: none;
`;
const Right = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  pointer-events: none;
`;

const PromptAnswer = styled.div`
  font-size: 16px;
  padding: 16px;
  line-height: 1.6;
  color: var(--heading-text);
`;

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px;
`;

const SelectedResources = styled.div`
  padding: 16px;
  padding-bottom: 0;
  display: flex;
  gap: 8px;
`;

const Resource = styled.div`
  display: inline-flex;
  gap: 8px;
  align-items: center;
  background: var(--context-menu-item-bg-hover);
  padding: 4px 8px;
  border-radius: 4px;
`;

const SearchBoxFooter = styled.div`
  padding: 16px;
  border-top: 1px solid var(--dropdown-bg);
  font-size: 12px;
  display: flex;
  gap: 8px;
  justify-content: space-between;
`;

const KeyBoardButton = styled.span`
  font-size: 12px;
  color: var(--button-ui-color);
  background: var(--bg-color-4);
  height: 16px;
  min-width: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 1px 4px;
  box-sizing: border-box;

  div {
    color: var(--button-ui-color);
  }
`;

const SearchBox = ({
  pageSpecificActions,
  isLoading,
  selectedResources = [],
  resourceSpecificActions = [],
}) => {
  const { searchBox } = useContext(Search);
  const { organization } = useAuth();
  const navigate = useNavigate();

  const [placeholderText, setPlaceholderText] = useState(
    `Search or type a command...`
  );

  let index = 0;

  // When selectedResources changes, and there are selected resources,
  // show resource specific actions. Show page specific actions when
  // no resources have been selected
  useEffect(() => {
    if (selectedResources.length > 0) {
      searchBox.handleAddActions(resourceSpecificActions);
      setPlaceholderText(`Type a command...`);
    } else {
      searchBox.handleAddActions(pageSpecificActions);
      setPlaceholderText(`Search or type a command...`);
    }
  }, [selectedResources]);

  // When it's done loading, set the page specific actions
  useEffect(() => {
    searchBox.handleAddActions(pageSpecificActions);
  }, [isLoading]);

  // Below is all for the animation
  const variants = {
    open: { opacity: 1, scale: 1, y: 0 },
    closed: { opacity: 0, scale: 0.95, y: "-5%" },
  };

  const [isDisplayed, setIsDisplayed] = useState(searchBox.isOpen);

  useEffect(() => {
    if (searchBox.isOpen) {
      setIsDisplayed(true);
    }
  }, [searchBox.isOpen]);

  const handleAnimationComplete = () => {
    if (!searchBox.isOpen) {
      setIsDisplayed(false);
    }
  };

  return (
    <>
      {isDisplayed && (
        <SearchOverlay>
          <SearchBoxContainer
            initial="closed"
            animate={searchBox.isOpen ? "open" : "closed"}
            variants={variants}
            transition={{ duration: 0.1 }}
            onAnimationComplete={handleAnimationComplete}
            ref={searchBox.ref}
          >
            {selectedResources.length > 0 && (
              <SelectedResources>
                <Resource>
                  <IconWrapper size="12">
                    <FileTypeIcon mimeType={selectedResources[0].mimeType} />
                  </IconWrapper>
                  <Heading size="h5">{selectedResources[0].name}</Heading>
                </Resource>
                {selectedResources.length > 1 && (
                  <Resource>+{selectedResources.length - 1}</Resource>
                )}
              </SelectedResources>
            )}

            <SearchFieldContainer>
              <SearchBar
                type="text"
                placeholder={placeholderText}
                value={searchBox.query}
                onChange={(e) => searchBox.handleUpdateQuery(e.target.value)}
                autoFocus
              />
            </SearchFieldContainer>

            {!searchBox.showResults && !searchBox.isLoadingResults && (
              <ActionsContainer>
                {searchBox.filteredActions.length > 0
                  ? searchBox.filteredActions.map((group, i) => {
                      return (
                        group && (
                          <>
                            <Header key={i} size="h4">
                              {group?.title}
                            </Header>
                            {group?.actions.length > 0 &&
                              group?.actions.map((action) => {
                                const actionComponent = (
                                  <Action
                                    key={index}
                                    index={index}
                                    ref={(el) => {
                                      if (el) {
                                        searchBox.listItemsRef.current[
                                          el.getAttribute("index")
                                        ] = el;
                                      }
                                    }}
                                    selected={
                                      searchBox.selectedActionIndex === index
                                    }
                                    onMouseOver={(e) => {
                                      const index =
                                        e.target.getAttribute("index");
                                      searchBox.handleUpdateSelectedActionIndex(
                                        index
                                      );
                                    }}
                                    onClick={(e) => {
                                      const index =
                                        e.target.getAttribute("index");
                                      searchBox.handlePerformAction(index);
                                    }}
                                  >
                                    <Left>
                                      <IconWrapper
                                        size={16}
                                        color="--bg-color-5"
                                      >
                                        {action.icon}
                                      </IconWrapper>
                                      {action?.title}
                                    </Left>
                                    {searchBox.selectedActionIndex ===
                                      index && (
                                      <Right>
                                        <KeyBoardButton>return</KeyBoardButton>
                                      </Right>
                                    )}
                                  </Action>
                                );
                                index++;
                                return actionComponent;
                              })}
                          </>
                        )
                      );
                    })
                  : ""}
              </ActionsContainer>
            )}

            {searchBox.isLoadingResults && searchBox.showResults && (
              <LoaderContainer>
                <Loader />
              </LoaderContainer>
            )}

            {!searchBox.isLoadingResults &&
              searchBox.showResults &&
              searchBox.results.length > 0 && (
                <ActionsContainer>
                  <Header size="h4">Results</Header>
                  {searchBox.results.map((document) => {
                    const actionComponent = (
                      <Action
                        key={index}
                        selected={searchBox.selectedActionIndex === index}
                        onClick={() => {
                          navigate(
                            `/${organization.slug}/documents/${document._id}`
                          );
                        }}
                      >
                        <Left>
                          <IconWrapper size={12} color="--bg-color-5">
                            <FileTypeIcon mimeType={document.mimeType} />
                          </IconWrapper>
                          {document.name}
                        </Left>
                        {searchBox.selectedActionIndex === index && (
                          <Right>
                            <KeyBoardButton>return</KeyBoardButton>
                          </Right>
                        )}
                      </Action>
                    );
                    index++;
                    return actionComponent;
                  })}
                </ActionsContainer>
              )}

            {!searchBox.isLoadingResults &&
              searchBox.showResults &&
              searchBox.results.answer && (
                <ActionsContainer>
                  <Header size="h4">Response</Header>
                  <PromptAnswer>{searchBox.results.answer}</PromptAnswer>

                  <Header size="h4">Reference Documents</Header>

                  {searchBox.results.resources.map((document) => {
                    const actionComponent = (
                      <Action
                        key={index}
                        selected={searchBox.selectedActionIndex === index}
                        onClick={() => {}}
                      >
                        <Left>
                          <IconWrapper size={12} color="--bg-color-5">
                            <FileTypeIcon mimeType={document.mimeType} />
                          </IconWrapper>
                          {document.name}
                        </Left>
                        {searchBox.selectedActionIndex === index && (
                          <Right>
                            <KeyBoardButton>return</KeyBoardButton>
                          </Right>
                        )}
                      </Action>
                    );
                    index++;
                    return actionComponent;
                  })}
                </ActionsContainer>
              )}

            {!searchBox.isLoadingResults &&
              searchBox.showResults &&
              searchBox.results?.length === 0 &&
              typeof searchBox.results?.answer === "undefined" && (
                <>No results...</>
              )}

            <SearchBoxFooter>
              <Left>
                <span>Navigate</span>
                <KeyBoardButton>
                  <IconWrapper>
                    <IconArrowDown />
                  </IconWrapper>
                </KeyBoardButton>
                <KeyBoardButton>
                  <IconWrapper>
                    <IconArrowUp />
                  </IconWrapper>
                </KeyBoardButton>
                <span>Select</span>
                <KeyBoardButton>return</KeyBoardButton>
              </Left>

              <Right>
                <span>Close</span>
                <KeyBoardButton>esc</KeyBoardButton>
              </Right>
            </SearchBoxFooter>
          </SearchBoxContainer>
        </SearchOverlay>
      )}
    </>
  );
};

export default SearchBox;
