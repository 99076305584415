import React from "react";
import { styled } from "styled-components";

// Component Library
import { Heading, Paragraph } from "@saberapp/react-component-library";

const Column = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`;

const Row = styled.div`
  display: flex;
  gap: 16px;
`;

const LogoContainer = styled.div`
  display: flex;
  width: 56px;
  height: 56px;
  border-radius: 8px;
  background: #fff;
  padding: 12px;
  box-sizing: border-box;
  border: 1px solid var(--card-border);

  img {
    width: 32px;
  }
`;

const IntegrationsHeader = ({ icon, name, description }) => {
  return (
    <Row>
      <Column>
        {icon && (
          <LogoContainer>
            {typeof icon === "string" ? <img src={icon} /> : icon}
          </LogoContainer>
        )}
      </Column>
      <Column>
        <Heading size="h3">{name}</Heading>
        <Paragraph>{description}</Paragraph>
      </Column>
    </Row>
  );
};

export default IntegrationsHeader;
