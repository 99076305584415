import React, {useContext, useEffect, useRef, useState} from 'react';
import styled from 'styled-components';

// Component Library
import {Button, Input, Loader} from '@saberapp/react-component-library';

import {EditHeader} from './components/EditHeader';
import {EditFooter} from './components/EditFooter';
import {IconLink} from '@saberapp/icons';
import {useCopyToClipboard} from '@saberapp/hooks';
import {useAuth} from '../../../../hooks/useAuth';
import {ContactPicker} from './components/ContactPicker';
import {Resources} from '../../../../hooks/useResources';

const ShareDocContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const CopyLinkContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const LoadOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  padding: 22px 22px 0;
  gap: 16px;
`;

const SharedListTitle = styled.h4`
  margin: 0;
`;

const NotShareableTitle = styled.h3`
  margin: 16px;
`;

const ContactList = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;

const ContactInputGroup = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;

  input {
    width: 240px;
  }
`;

const inputStyle = {
    background: "var(--dropdown-bg)",
    border: "1px solid var(--border-color-2)",
    color: "var(--heading-text)",
};

const StyledInput = styled.input` // todo copied from npm packages
  ${inputStyle}

  width: calc(100% - 24px);
  border-radius: 4px;
  margin-top: 5px;
  padding: 6px 12px;
  min-height: 32px;
  cursor: text;
`;

const shareableLinksUrl = process.env.SHAHEABLE_LINKS_URL || 'https://staging.qvr.to/';

const ShareResource = ({closeModal, resourceToShare}) => {
    const {user} = useAuth();
    const {copy} = useCopyToClipboard();
    const {updateContactsForResource, getShareContactsForResource} = useContext(Resources);
    const [isLoading, setLoading] = useState(true);

    const inputRef = useRef(null);

    const [resource, setResource] = useState();
    // const [availableContacts, setAvailableContacts] = useState([]);
    const [contacts, setContacts] = useState([]);
    const [emailValid, setEmailValid] = useState(false);
    const [newEmail, setNewEmail] = useState();


    useEffect(() => {
        // todo handle multiple resources?
        if (resourceToShare.length > 1) {
            alert('Only one selected resource supported');
            closeModal();
        }

        const _resource = Array.isArray(resourceToShare) ? resourceToShare[0] : resourceToShare;
        setResource(_resource);
        getContacts(_resource);
    }, []);

    function getContacts(_resource) {
        const runAsync = async () => {
            const {data} = await getShareContactsForResource(_resource._id);
            // setAvailableContacts(data.contacts);
            setContacts(data.contacts);
            setLoading(false);
        };
        runAsync(); // useEffect  paramater cant be a promise
    }

    const handleButtonClick = async () => {
        inputRef.current.value = '';
        setContacts([...contacts, {email: newEmail}]);
    };

    const isEmailValid = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email) && !contacts.map(c => c.email).includes(email);

    const emailInputChange = (e) => {
        const email = e.target.value;
        const isValid = isEmailValid(email);
        if (emailValid !== isValid) {
            setEmailValid(isValid);
        }
        setNewEmail(email);
    };

    const handleContactSelection = (contact) => {
        setContacts((currentContacts) => {
            return !currentContacts.some((currentContact) => currentContact._id === contact._id)
                ? [...currentContacts, contact]
                : currentContacts.filter((currentContact) => currentContact._id !== contact._id);
        });
    };

    return (
        <ShareDocContainer>
            {isLoading && (
                <LoadOverlay>
                    <Loader></Loader>
                </LoadOverlay>
            )}
            {resource &&
                <>
                    <EditHeader resources={[resource]} closeModal={closeModal}/>

                    {!resource.sharedResourceId ?
                        <NotShareableTitle>This document cannot be shared externally :(</NotShareableTitle> :
                        <>
                            <CopyLinkContainer>
                                <Button
                                    type="ui"
                                    size="small"
                                    iconBefore={<IconLink/>}
                                    title="Copy share Link"
                                    disabled={!resource?.sharedResourceId}
                                    onClick={() => copy(shareableLinksUrl + resource?.sharedResourceId)}
                                />
                            </CopyLinkContainer>

                            <Section>
                                <SharedListTitle>Contacts this document was shared with:</SharedListTitle>
                                <ContactList>
                                    {contacts &&
                                        <ContactPicker
                                            /*availableContacts={availableContacts}*/
                                            currentContacts={contacts}
                                            handleContactSelection={handleContactSelection}
                                        ></ContactPicker>
                                    }
                                </ContactList>
                                <ContactInputGroup>
                                    <StyledInput type="email" ref={inputRef} onChange={e => emailInputChange(e)}/>
                                    <Button type="secondary" onClick={handleButtonClick} disabled={!emailValid}>Add
                                        contact</Button>
                                </ContactInputGroup>
                            </Section>

                            <EditFooter
                                closeModal={closeModal}
                                onSave={() => {
                                    setLoading(true);
                                    const data = {contacts, sharedBy: user._id};
                                    updateContactsForResource(resource._id, data)
                                        .then(() => closeModal())
                                        .catch(e => alert(e.message))
                                        .finally(() => setLoading(false));
                                }}
                            />
                        </>
                    }
                </>

            }
        </ShareDocContainer>
    );
};

export default ShareResource;
