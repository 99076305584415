import React, { useContext, useRef } from "react";

// Hooks
// import { useAnalytics } from "../../../../hooks/useAnalytics";
import { useCopyToClipboard } from "@saberapp/hooks";
import { Resources } from "../../../../hooks/useResources";
import { useAuth } from "../../../../hooks/useAuth";

// Enums
import {
  ResourceEvents,
  EventTargetObjects,
  FreshnessScoreValues,
  FreshnessScoreValuesReadable,
  VisibilityStatus,
  ContentTypes,
} from "@saberapp/enums";

// Component Library
import { FreshnessScore } from "@saberapp/react-component-library";

// Icons
import {
  IconEye as IconView,
  IconArrowTopRightOnSquare as IconOpen,
  IconLink,
  IconTag as IconTags,
  IconArchiveBox as IconArchive,
  IconQuestionMarkCircle as IconRequest,
} from "@saberapp/icons";

import { ReactComponent as IconGoogle } from "../../../icons/connectors/google.svg";

const ResourceContextMenu = ({ handleShareDoc, handleEditTags, handleEditContentType, handleEditReviewers }) => {
  const { organization } = useAuth();
  // const { analytics } = useAnalytics();
  const { copy } = useCopyToClipboard();
  const {
    selectedResources,
    handleRequestReview,
    handleMarkAsReviewed,
    handleUpdateFreshnessScore,
    handleUpdateVisibility,
    handleArchive,
    handleUpdateContentType,
  } = useContext(Resources);

  const menuOptions = [
    {
      icon: <IconView />,
      title: "View",
      onClick: (_e, resource) => {
        // analytics.track(
        //   ResourceEvents.RESOURCE_VIEWED,
        //   EventTargetObjects.RESOURCE,
        //   {
        //     resourceId: resource?._id,
        //   }
        // );
        window.location.href = `/${organization.slug}/documents/${resource?._id}`;
      },
    },
    {
      icon: <IconOpen />,
      title: "Open in Google",
      onClick: (_e, resource) => {
        // analytics.track(
        //   ResourceEvents.RESOURCE_OPENED,
        //   EventTargetObjects.RESOURCE,
        //   {
        //     resourceId: resource?._id,
        //   }
        // );
        window.open(resource?.webViewLink, "_blank");
      },
    },
    {
      icon: <IconLink />,
      title: "Copy",
      items: [
        {
          icon: <IconGoogle />,
          title: "Copy Google Link",
          onClick: (_e, resource) => {
            copy(resource.webViewLink);
          },
        },
        // {
        //   icon: <IconLink />,
        //   title: "Copy Saber Link",
        //   onClick: (_e, resource) => {
        //     copy(resource.webViewLink);
        //   },
        // },
      ],
    },
    // {
    //   divider: true,
    // },
    // {
    //   showOption: selectedResources?.length <= 1,
    //   icon: <IconTags />,
    //   title: "Share...",
    //   onClick: (_e, resource) => {
    //     if (selectedResources.some((selectedResource) => selectedResource._id === resource._id)) {
    //       handleShareDoc(selectedResources)
    //     } else {
    //       handleShareDoc(resource)
    //     }
    //   },
    // },    {
    //   icon: <IconTags />,
    //   title: "Tags...",
    //   onClick: (_e, resource) => {
    //     if (selectedResources.some((selectedResource) => selectedResource._id === resource._id)) {
    //       handleEditTags(selectedResources)
    //     } else {
    //       handleEditTags(resource)
    //     }
    //   },
    // },
    // {
    //   icon: <IconTags />,
    //   title: "Content Type...",
    //   onClick: (_e, resource) => {
    //     if (selectedResources.some((selectedResource) => selectedResource._id === resource._id)) {
    //       handleEditContentType(selectedResources)
    //     } else {
    //       handleEditContentType(resource)
    //     }
    //   },
    // },
    // {
    //   icon: <IconTags />,
    //   title: "Reviewers...",
    //   onClick: (_e, resource) => {
    //     if (selectedResources.some((selectedResource) => selectedResource._id === resource._id)) {
    //       handleEditReviewers(selectedResources)
    //     } else {
    //       handleEditReviewers(resource)
    //     }
    //   },
    // },
    // {
    //   icon: <IconTags />,
    //   title: "Visibility",
    //   items: [
    //     {
    //       icon: <></>,
    //       title: "Remove Label",
    //       onClick: (_e, resource) => {
    //         if (selectedResources.some((selectedResource) => selectedResource._id === resource._id)) {
    //           handleUpdateVisibility(selectedResources, VisibilityStatus.UNKNOWN)
    //         } else {
    //           handleUpdateVisibility(resource._id, VisibilityStatus.UNKNOWN)
    //         }
    //       }
    //     },
    //     {
    //       icon: <></>,
    //       title: "Internal",
    //       onClick: (_e, resource) =>
    //         {
    //           if (selectedResources.some((selectedResource) => selectedResource._id === resource._id)) {
    //             handleUpdateVisibility(selectedResources, VisibilityStatus.INTERNAL)
    //           } else {
    //             handleUpdateVisibility(resource._id, VisibilityStatus.INTERNAL)
    //           }
    //         }
    //     },
    //     {
    //       icon: <></>,
    //       title: "External",
    //       onClick: (_e, resource) =>
    //         {
    //           if (selectedResources.some((selectedResource) => selectedResource._id === resource._id)) {
    //             handleUpdateVisibility(selectedResources, VisibilityStatus.EXTERNAL)
    //           } else {
    //             handleUpdateVisibility(resource._id, VisibilityStatus.EXTERNAL)
    //           }
    //         }
    //     },
    //   ],
    // },
    {
      divider: true,
    },
    (resource) => {
      return resource.freshnessScore !== FreshnessScoreValues.VERIFIED // TODO: And if the role is a reviewer or admin
        ? {
            icon: <FreshnessScore score={FreshnessScoreValues.VERIFIED} />,
            title: "Mark as Reviewed",
            onClick: (_e) => {
              handleMarkAsReviewed(resource._id);
            },
          }
        : null;
    },
    // (resource) => {
    //   return resource.freshnessScore !== FreshnessScoreValues.VERIFIED // TODO: And if the role is a seller
    //     ? {
    //         icon: <IconRequest />,
    //         title: "Request Review",
    //         onClick: (_e, resource) => handleRequestReview(resource),
    //       }
    //     : null;
    // },
    (resource) => {
      return {
        icon: <FreshnessScore score={resource.freshnessScore} />,
        title: "Freshness",
        items: [
          {
            isActive: resource.freshnessScore === FreshnessScoreValues.VERIFIED,
            icon: <FreshnessScore score={FreshnessScoreValues.VERIFIED} />,
            title: FreshnessScoreValuesReadable.VERIFIED,
            onClick: (_e) =>
              handleUpdateFreshnessScore(
                resource._id,
                FreshnessScoreValues.VERIFIED
              ),
          },
          {
            isActive:
              resource.freshnessScore === FreshnessScoreValues.NEEDS_REVIEW,
            icon: <FreshnessScore score={FreshnessScoreValues.NEEDS_REVIEW} />,
            title: FreshnessScoreValuesReadable.NEEDS_REVIEW,
            onClick: (_e) =>
              handleUpdateFreshnessScore(
                resource._id,
                FreshnessScoreValues.NEEDS_REVIEW
              ),
          },
          {
            isActive: resource.freshnessScore === FreshnessScoreValues.OUTDATED,
            icon: <FreshnessScore score={FreshnessScoreValues.OUTDATED} />,
            title: FreshnessScoreValuesReadable.OUTDATED,
            onClick: (_e) =>
              handleUpdateFreshnessScore(
                resource._id,
                FreshnessScoreValues.OUTDATED
              ),
          },
        ],
      };
    },
    // {
    //   icon: <IconReviewers />,
    //   title: "Reviewers",
    //   items: [
    //     {
    //       icon: <IconLink />,
    //       title: "Internal",
    //       onClick: (_e, resource) => copy(resource.webViewLink),
    //     },
    //     {
    //       icon: <IconLink />,
    //       title: "External",
    //       onClick: (_e, resource) => copy(resource.webViewLink),
    //     },
    //   ],
    // },
    {
      divider: true,
    },
    (resource) => {
      return {
        icon: <IconArchive />,
        title: resource.isArchived ? "Unarchive" : "Archive",
        onClick: (_e) => handleArchive(resource._id, !resource.isArchived),
      };
    },
  ];
  // filter out context menu options when showOption has value false
  // todo: prefer disabling option but keeping it visible?
  return menuOptions.filter(o => o.showOption !== false || !o.hasOwnProperty('showOption'));
};

export default ResourceContextMenu;
