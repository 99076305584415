import dayjs from "dayjs";
import React, { useEffect } from "react";
import styled from "styled-components";
import { IconCubeTransparent } from "@saberapp/icons";
import { Icon } from "./shared/components";

const QueueItems = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 400px;
  width: 440px;
  border-right: 1px solid var(--card-border);
  padding: 16px 16px;
  box-sizing: border-box;
  overflow: auto;
`;

const QueueItem = styled.div`
  display: flex;
  border-bottom: 1px solid var(--card-bg);
  background: ${({ isActive }) => (isActive ? `var(--button-bg)` : `none`)};
  padding: 12px 16px;
  border-radius: 6px;
  align-items: center;
  gap: 16px;

  &:hover {
    cursor: pointer;
  }

  &:last-child {
    border: none;
  }
`;

const LoadMoreItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  cursor: pointer;
  color: var(--primary);
  &:hover {
    text-decoration: underline;
  }
`;

const IconColumn = styled.div``;

const TitleColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;

const Title = styled.div`
  font-weight: 500;
  color: var(--heading-text);
  height: auto;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  line-height: 1.5;
`;

const Description = styled.div`
  font-size: 12px;
`;

const MetaColumn = styled.div``;

const formatTimeAgo = (createdAt) => {
  const now = dayjs();
  const created = dayjs(createdAt);
  const diff = now.diff(created);

  // Use dayjs duration to find the difference and then format it
  const duration = dayjs.duration(diff);

  // Calculate the difference and format it in a compact form
  if (duration.asWeeks() >= 1) {
    return `${Math.floor(duration.asWeeks())}w`;
  } else if (duration.asDays() >= 1) {
    return `${Math.floor(duration.asDays())}d`;
  } else if (duration.asHours() >= 1) {
    return `${Math.floor(duration.asHours())}h`;
  } else {
    return `${Math.floor(duration.asMinutes())}m`;
  }
};

const Queue = ({
  queueItems,
  currentIndex,
  setCurrentIndex,
  hasMoreTasks,
  loadMoreTasks,
}) => {
  const getIcon = (item) => {
    if (item.type === "FeedbackAnalytics") {
      if (item.event.isThumbsUp) {
        return { icon: <div>👍</div>, color: "#D1F0DF" };
      } else {
        return { icon: <div>👎</div>, color: "#fff0f0" };
      }
    } else {
      return { icon: <IconCubeTransparent />, color: "var(--card-border)" }; // Default icon
    }
  };

  return (
    <QueueItems>
      {queueItems.length > 0 &&
        queueItems.map((item, index) => (
          <QueueItem
            key={item._id}
            isActive={item._id === queueItems[currentIndex]?._id}
            onClick={() => {
              item._id === queueItems[currentIndex]?._id
                ? setCurrentIndex(null)
                : setCurrentIndex(index);
            }}
          >
            <IconColumn>
              <Icon color={getIcon(item).color}>{getIcon(item).icon}</Icon>
            </IconColumn>
            <TitleColumn>
              <Title>{item.questionData?.questionRef.question}</Title>
              <Description>
                {item.type === "FeedbackAnalytics" && `Feedback provided`}
                {item.type === "UserQuestion" && `Gap discovered`} by{" "}
                {item.userName}
              </Description>
            </TitleColumn>
            <MetaColumn>{formatTimeAgo(item.createdAt)}</MetaColumn>
          </QueueItem>
        ))}
      {hasMoreTasks && (
        <LoadMoreItem onClick={loadMoreTasks}>Load More Items</LoadMoreItem>
      )}
    </QueueItems>
  );
};

export default Queue;
