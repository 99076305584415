import React, { useEffect, useState, useContext } from "react";

import { HttpMethod } from "@saberapp/enums";

import { useApi } from "./useApi";
import { useAuth } from "./useAuth";
import { useData } from "./useData";

// Set the context
export const Insights = React.createContext();

export const InsightsProvider = ({ children }) => {
  const { API } = useApi();
  const { isAuthenticated } = useAuth();
  const { gaps, feedback } = useData();

  // const [gaps, setGaps] = useState([]);
  const [activeGaps, setActiveGaps] = useState([]);
  const [categories, setCategories] = useState([]);
  const [activeCategories, setActiveCategories] = useState([]);
  const [categoriesLoaded, setCategoriesLoaded] = useState(false);
  // const [feedback, setFeedback] = useState([]);
  const [activeFeedback, setActiveFeedback] = useState([]);

  useEffect(()=>{
    setActiveGaps(gaps);
    setActiveFeedback(feedback);
  },[gaps, feedback])

  useEffect(() => {
    if (!isAuthenticated) return;
    if (API.isReady) {
      (async () => {
        await getGaps();
      })();
    }
  }, [isAuthenticated, API.isReady]);

  // TODO Rename
  const getGaps = async () => {
    // CATEGORIES
    await API.call(HttpMethod.GET, `/content-gap`, {}, (data) => {
      data = sortObjectsByProperty(data);
      setCategories(data);
      setActiveCategories(data);
      setCategoriesLoaded(true);
    }).catch((e) => {
      console.error(e);
      setCategoriesLoaded(true);
    });
  };

  function sortObjectsByProperty(objects, property = "_id") {
    return objects.slice().sort((a, b) => {
      if (a[property] < b[property]) return -1;
      if (a[property] > b[property]) return 1;
      return 0;
    });
  }

  function categoryIsActive(categoryId) {
    return activeCategories.some((obj) => obj._id === categoryId);
  }

  function countLowScore() {
    return activeGaps.filter((gap) => gap.confidenceScore < 0.3).length;
  }

  function getBiggestCategory() {
    if (activeCategories.length === 0) return null; // Return null if the array is empty

    let maxObj = activeCategories[0]; // Start by assuming the first object has the highest count

    for (const obj of activeCategories) {
      if (obj.count > maxObj.count) {
        maxObj = obj; // Update maxObj if the current object has a higher count
      }
    }

    return maxObj._id; // Return the _id of the object with the highest count
  }

  const toggleCategory = (categoryId) => {
    setActiveCategories((prev) => {
      const index = prev.findIndex((item) => item._id === categoryId);
      if (index > -1) {
        return prev.filter((item) => item._id !== categoryId); // Remove the item if it exists
      } else {
        return [...prev, categories.find((cat) => cat._id === categoryId)]; // Add the item if it doesn't exist
      }
    });

    setActiveGaps((prev) => {
      const isCategoryActive = prev.some((gap) => gap.category === categoryId);

      if (isCategoryActive) {
        // If any gaps in the category are active, deactivate all gaps in the category
        return prev.filter((gap) => gap.category !== categoryId);
      } else {
        // If no gaps in the category are active, activate all gaps in the category
        return sortObjectsByProperty(
          [...prev, ...gaps.filter((gap) => gap.category === categoryId)],
          "confidenceScore"
        );
      }
    });
  };

  return (
    <Insights.Provider
      value={{
        gaps,
        activeGaps,
        categories,
        activeCategories,
        categoriesLoaded,
        feedback,
        activeFeedback,
        countLowScore,
        categoryIsActive,
        getBiggestCategory,
        toggleCategory
      }}
    >
      {children}
    </Insights.Provider>
  );
};

// Custom hook to use the AuthContext
export const useInsights = () => useContext(Insights);
