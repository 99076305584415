import React, { useEffect, useState } from "react";
import { motion, useAnimation } from "framer-motion";

const FreshnessScoreAnimation = () => {
  const [progress, setProgress] = useState(0);
  const controls = useAnimation();
  const controlsArrow = useAnimation();

  useEffect(() => {
    const animateProgressBar = async () => {
      // Animate the progress bar from 0 to 1 (red to yellow) in 1 second
      await setProgress(1 / 3);
      await Promise.all([
        controls.start({ width: "66%", transition: { duration: 1 } }),
        controlsArrow.start({ x: "66%", transition: { duration: 1 } }),
      ]);

      // Animate the progress bar from 1/3 to 2/3 (yellow to green) in 1 second
      await setProgress(2 / 3);
      await Promise.all([
        controls.start({ width: "100%", transition: { duration: 1 } }),
        controlsArrow.start({ x: "100%", transition: { duration: 1 } }),
      ]);

      // Animate the arrow with a little shake
      await controlsArrow.start(shakeAnimation);
    };

    animateProgressBar();
  }, [controls, controlsArrow]);

  const shakeAnimation = {
    x: [0, -5, 5, -5, 5, -5, 5, 0],
    transition: { duration: 0.5 },
  };

  return (
    <svg
      width="25"
      height="13"
      viewBox="0 0 25 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_208_18463)">
        <rect y="0.439453" width="7.0263" height="6.26093" fill="#23BB5A" />
        <rect
          x="8.50781"
          y="0.439453"
          width="7.0263"
          height="6.26093"
          fill="#23BB5A"
        />
        <rect
          x="17.0195"
          y="0.439453"
          width="7.0263"
          height="6.26093"
          fill="#23BB5A"
        />
      </g>
      <g clip-path="url(#clip1_208_18463)">
        <path
          d="M20.4555 8.18359L22.281 11.7054H18.6301L20.4555 8.18359Z"
          fill="#D9D9D9"
        />
      </g>
      <defs>
        <clipPath id="clip0_208_18463">
          <rect
            y="0.439453"
            width="24.0451"
            height="6.26093"
            rx="2.96618"
            fill="white"
          />
        </clipPath>
        <clipPath id="clip1_208_18463">
          <rect
            y="8.18359"
            width="24.0451"
            height="4.69569"
            rx="2.34785"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FreshnessScoreAnimation;
