import React, { useEffect, useState } from "react";
import styled from "styled-components";

// Icons
import { ReactComponent as BrandMark } from "../../icons/saber/brandmark.svg";
import {
  Button,
  Card,
  Heading,
  Avatar,
  Row,
  Pill,
  Paragraph,
} from "@saberapp/react-component-library";
import { useAuth } from "../../../hooks/useAuth";
import { useConnectors } from "../../../hooks/useConnectors";
import { useNavigate } from "react-router";

const Container = styled.div`
  top: 100%;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 640px;
  height: 100%;
  margin: 0 auto;
  padding: 64px 0;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Left = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Right = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f4b60c;
  border-radius: 6px;
  width: 16px;
  height: 16px;
  padding: 6px;
  color: #0e1016;

  svg {
    width: 14px;
  }
`;
const OrgName = styled.div`
  color: var(--heading-text);
  font-weight: 600;
`;

const CardColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const CardInner = styled.div`
  display: flex;
  gap: 32px;
`;

const CardInnerSmall = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
`;

const AvatarContainer = styled.div`
  width: 20px;
  height: 20px;
`;

const Image = styled.div`
  background: url("/images/slackExample.png");
  background-size: cover;
  border-radius: 6px;
  height: 220px;
`;

const SellerHome = () => {
  const { user, signOut } = useAuth();
  const { isConnectorsLoading, getConnectorByName } = useConnectors();
  const navigate = useNavigate();

  const [slackConnector, setSlackConnector] = useState(null);

  const handleSignOut = () => signOut(navigate);

  useEffect(() => {
    if (!isConnectorsLoading) {
      setSlackConnector(getConnectorByName("slack"));
    }
  }, [isConnectorsLoading]);

  return (
    <Container>
      <Column>
        <Header>
          <Left>
            <LogoContainer>
              <BrandMark />
            </LogoContainer>
            <OrgName>Saber</OrgName>
          </Left>
          <Right>
            {user.name}
            <Button
              type="ui"
              size="small"
              title="Sign out"
              onClick={() => {
                handleSignOut();
              }}
            />
          </Right>
        </Header>
        <Card padding={16}>
          <CardInnerSmall>
            {slackConnector && slackConnector.status === "connected" && (
              <>
                <Heading size="h4">Hi {user.name} 👋</Heading>
                <Paragraph>
                  You can use Saber right within{" "}
                  <a href="https://slack.com" target="_blank">
                    Slack
                  </a>{" "}
                  by either going to the Saber App and ask it a question, or add
                  and mention @Saber in the channel of your choosing.
                </Paragraph>
                <Image />
              </>
            )}

            {slackConnector && slackConnector.status === "disconnected" && (
              <>
                <Heading size="h4">Hi {user.name} 👋</Heading>
                <Paragraph>
                  It seems that the Slack connector was disconnected. If this is
                  a mistake, flag it with your manager.
                </Paragraph>
              </>
            )}

            {!slackConnector && (
              <>
                <Heading size="h4">Hi {user.name} 👋</Heading>
                <Paragraph>
                  Soon you'll be able to use Saber on Slack! We're waiting for your admin to enable the Slack integration.
                </Paragraph>
              </>
            )}
          </CardInnerSmall>
        </Card>

        <Card padding={16}>
          <CardInner>
            <CardColumn>
              <Heading size="h4">Avatar</Heading>
              <AvatarContainer>
                <Avatar url={user.avatar} />
              </AvatarContainer>
            </CardColumn>
            <CardColumn>
              <Heading size="h4">Email</Heading>
              {user.email}
            </CardColumn>
            <CardColumn>
              <Heading size="h4">Roles</Heading>
              {user?.roles.length > 0 &&
                user.roles.map((role) => (
                  <Row>
                    <Pill title={role.name} /> {role.description}
                  </Row>
                ))}
            </CardColumn>
          </CardInner>
        </Card>

        {/* <Card padding={16}> */}
        <Paragraph>
          If you have any feedback or ideas you'd love to share with us, we're
          happy to hear it! Send us an email at feedback@saber.app. Not a
          seller? Ask your admin for additional rights!
        </Paragraph>
        {/* </Card> */}
      </Column>
    </Container>
  );
};

export default SellerHome;
