import React from "react";

import ResourcesTable from "./ResourcesTable";

const TableView = ({
  layout,
  handleResourceClick,
  // handleEditResource,
  isArchive,
  handleManageResourceTags,
  setContextMenuData,
  contextMenu,
  page,
}) => {
  return (
    <>
      <ResourcesTable
        layout={layout}
        handleResourceClick={handleResourceClick}
        // handleEditResource={handleEditResource}
        handleManageResourceTags={handleManageResourceTags}
        isArchive={isArchive}
        setContextMenuData={setContextMenuData}
        contextMenu={contextMenu}
        page={page}
      />
    </>
  );
};

export default TableView;
