import React from "react";

// Context
import { ResourcesProvider } from "../../../hooks/useResources";

// Template
import DashboardTemplate from "../../templates/DashboardTemplate";

// Page
import Resource from "./Resource";

const ResourceIndex = () => {
  return (
    <DashboardTemplate>
      <ResourcesProvider>
        <Resource />
      </ResourcesProvider>
    </DashboardTemplate>
  );
};

export default ResourceIndex;
