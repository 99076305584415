import { useState, useEffect, useRef, createContext } from "react";

// Hooks
import { useOutsideClick } from "@saberapp/hooks";
import { useApi } from "./useApi";
import { useAuth } from "./useAuth";
import { useNavigate } from "react-router-dom";

// Icons
import {
  IconMagnifyingGlass as IconSearch,
  IconSparkles,
  IconChartPie as IconInsights,
  IconTableCells as IconDocs,
  IconBookOpen as IconPages,
} from "@saberapp/icons";

export const Search = createContext();

export const useSearch = () => {
  // Hooks
  const { API } = useApi();
  const ref = useRef();
  const { organization } = useAuth();
  const navigate = useNavigate();

  // States
  // We use a bunch of refs here because the state is used within
  // event listeners that fire in different environments
  const [isOpen, setIsOpen] = useState(false);
  const isOpenRef = useRef(isOpen);

  const [query, setQuery] = useState("");

  const [addedActions, setAddedActions] = useState([]);

  const [actions, setActions] = useState([]);
  const actionsRef = useRef(actions);

  const [filteredActions, setFilteredActions] = useState([]);

  const [actionsCount, setActionsCount] = useState(0);
  const actionsCountRef = useRef(actionsCount);

  const [selectedAction, setSelectedAction] = useState({});
  const selectedActionRef = useRef(selectedAction);

  const [selectedActionIndex, setSelectedActionIndex] = useState(0);
  const selectedActionIndexRef = useRef(selectedActionIndex);

  const [results, setResults] = useState([]);
  const [isLoadingResults, setIsLoadingResults] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const listItemsRef = useRef([]);

  // Search Actions
  const defaultActions = [
    {
      title: "Navigate",
      actions: [
        {
          icon: <IconInsights />,
          title: "Go to Home",
          action: () => {
            navigate(`/${organization.slug}/`);
          },
        },
        {
          icon: <IconInsights />,
          title: "Go to Insights",
          action: () => {
            navigate(`/${organization.slug}/insights/`);
          },
        },
        {
          icon: <IconDocs />,
          title: "Go to Documents",
          action: () => {
            navigate(`/${organization.slug}/documents/`);
          },
        },
        {
          icon: <IconInsights />,
          title: "Go to Settings",
          action: () => {
            navigate(`/${organization.slug}/settings/`);
          },
        },
      ],
    },
  ];

  // Search Actions
  const searchActions = [
    {
      title: "Search",
      actions: [
        // {
        //   icon: <IconSparkles />,
        //   title: "Ask a question...",
        //   action: () => {
        //     promptSearch();
        //   },
        //   dismissAfterAction: false,
        // },
        // {
        //   icon: <IconSearch />,
        //   title: "Semantic Search Resources...",
        //   action: () => {
        //     semanticSearch();
        //   },
        //   dismissAfterAction: false,
        // },
      ],
    },
  ];

  // useEffects to make sure the state is accurate and event listeners are added up when needed

  useEffect(() => {
    setActionsCount(getTotalActionsCount());
    actionsRef.current = filteredActions;
  }, [filteredActions]);

  useEffect(() => {
    setActions([...addedActions, ...defaultActions]);
    setFilteredActions([...addedActions, ...defaultActions]);
  }, [addedActions]);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    if (ref.current) {
      ref.current.querySelector("input").focus();
    }

    // Return a cleanup function
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const { outsideClick } = useOutsideClick(() => {
    close();
  }, [ref]);

  useEffect(() => {
    isOpen && outsideClick.startListening();

    isOpenRef.current = isOpen;
  }, [isOpen]);

  useEffect(() => {
    actionsCountRef.current = actionsCount;
  }, [actionsCount]);

  useEffect(() => {
    selectedActionRef.current = selectedAction;
  }, [selectedAction]);
  useEffect(() => {
    selectedActionIndexRef.current = selectedActionIndex;
  }, [selectedActionIndex]);

  useEffect(() => {
    filterActionsByTitle();

    if(query.length > 5){
      // TODO: console.log('Do the search')
    }
  }, [query]);

  // Handlers

  const open = () => {
    setIsOpen(true);
  };
  const close = () => {
    setIsOpen(false);
  };
  const toggle = (value = !isOpen) => {
    setIsOpen(value);
  };

  const handleKeyDown = (e) => {
    // Check if CMD (Meta key) + K is pressed
    if ((e.ctrlKey || e.metaKey) && e.key === 'k') {
      e.preventDefault();
      toggle(!isOpenRef.current);
    }

    if (e.key === "Escape") {
      e.preventDefault();
      close();
    }

    if (isOpenRef.current) {
      if (e.key === "Enter") {
        e.preventDefault();
        handlePerformAction(selectedActionIndexRef.current);
      } else if (e.key === "ArrowUp") {
        e.preventDefault();
        setSelectedActionIndex(getIndexMin());
        scrollToSelectedAction(getIndexMin());
      } else if (e.key === "ArrowDown") {
        e.preventDefault();
        setSelectedActionIndex(getIndexMax());
        scrollToSelectedAction(getIndexMax());
      }
    }
  };

  const handlePerformAction = (index) => {
    const action = getNthAction(index);
    console.log(index, "action", action);
    if (typeof action?.action === "function") {
      action.action();
      action?.dismissAfterAction !== false && close();
    }
  };

  const handleUpdateFilteredActions = (actions) => {
    setSelectedActionIndex(0);
    setFilteredActions(actions);
  };

  const handleAddActions = (actionsToAdd) => {
    setAddedActions(actionsToAdd);
  };

  const handleUpdateQuery = (q) => {
    setQuery(q);
  };

  const handleUpdateSelectedActionIndex = (i) => {
    setSelectedActionIndex(parseInt(i));
  };

  const handleResetResults = () => {
    setIsLoadingResults(false);
    setShowResults(false);
    setResults([]);
  };

  // Helper functions

  const getIndexMin = () => {
    const i = selectedActionIndexRef.current;
    const listLength = showResults ? results.length : actionsCountRef.current;
    return i > 0 ? i - 1 : listLength - 1;
  };

  const getIndexMax = () => {
    const i = selectedActionIndexRef.current;
    const listLength = showResults ? results.length : actionsCountRef.current;
    return listLength - 1 > i ? i + 1 : 0;
  };

  const getNthAction = (n) => {
    if (
      actionsRef &&
      actionsRef.current &&
      Array.isArray(actionsRef.current) &&
      actionsRef.current.length > 0
    ) {
      const actionsArray = actionsRef.current;
      let flattenedActions = [];

      // Flatten the nested actions arrays
      actionsArray.forEach((item) => {
        if (item.actions && Array.isArray(item.actions)) {
          flattenedActions = flattenedActions.concat(item.actions);
        }
      });

      // Check if the desired index is within bounds
      if (n >= 0 && n < flattenedActions.length) {
        return flattenedActions[n];
      }
    }

    // Return a default value or handle error as needed
    return null;
  };

  const getTotalActionsCount = () => {
    return actions.reduce((total, group) => {
      if (group.actions && Array.isArray(group.actions)) {
        return total + group.actions.length;
      }
      return total;
    }, 0);
  };

  const filterActionsByTitle = () => {
    if (query.length > 0) {
      let newActions = actions.map((group) => {
        // Check if the current group has the specified groupTitle and actions
        if (group.actions) {
          const filteredActions = group.actions.filter((action) => {
            return action?.title.toLowerCase().includes(query.toLowerCase());
          });
          // Return the group with filtered actions if any actions match the filter
          if (filteredActions.length > 0) {
            return {
              ...group,
              actions: filteredActions,
            };
          }
        }
      });

      // Get rid of some undefineds, probably a better solution for this
      newActions = newActions.filter(element => element !== undefined)

      if (newActions[0]) {
        handleUpdateFilteredActions([...searchActions, ...newActions]);
      } else {
        handleUpdateFilteredActions([...searchActions]);
      }
    } else {
      handleResetResults();
      handleUpdateFilteredActions([...addedActions, ...defaultActions]);
    }
  };

  // Function to scroll the selected item into view
  const scrollToSelectedAction = (index) => {
    if (listItemsRef.current[index]) {
      listItemsRef.current[index].scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  };

  // Default search & prompt functions

  const semanticSearch = async () => {
    setIsLoadingResults(true);
    setShowResults(true);
    await API.semanticSearch(
      {
        query: { q: query },
      },
      ({ resources, totalResources }) => {
        console.log('Search matched the following text snippets in the vector store', resources.map(r => r.searchMatchData));
        setIsLoadingResults(false);
        setResults(resources);
      }
    );
  };

  const promptSearch = async () => {
    setIsLoadingResults(true);
    setShowResults(true);
    await API.promptSearch({ query: { q: query } }, (data) => {
      setIsLoadingResults(false);
      const { text, resources, totalResources } = data.answer;
      console.log('Search matched the following text snippets in the vector store', resources.map(r => r.searchMatchData));
      const answer = text;

      setResults({ answer, resources });
    });
  };

  return {
    searchBox: {
      // States
      ref,
      isOpen,
      actions,
      filteredActions,
      selectedActionIndex,
      query,
      listItemsRef,
      // Handlers
      open,
      close,
      toggle,
      handleAddActions,
      handleUpdateQuery,
      handleUpdateSelectedActionIndex,
      handlePerformAction,
      // Results
      isLoadingResults,
      results,
      showResults,
    },
  };
};

export const SearchProvider = ({ children }) => {
  const searchHook = useSearch();
  return <Search.Provider value={searchHook}>{children}</Search.Provider>;
};
