import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';

// Hooks
import {useOutsideClick} from '@saberapp/hooks';
import {Checkbox, Tag} from '@saberapp/react-component-library'; // todo Using Tag

const inputStyle = {
    background: "var(--dropdown-bg)",
    border: "1px solid var(--border-color-2)",
    color: "var(--heading-text)",
};

const ContactPickerWrapper = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
`;

const ContactInput = styled.input`
  padding: 8px;
  outline: none;
  font-size: 13px;
  background: none;
  border: 0;
  color: #fff;
`;

const ContactContainer = styled.div`
  ${inputStyle}

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-radius: 4px;
  padding: 12px;
  min-height: 32px;
  gap: 6px;
  cursor: text;
`;

const RelativeContainer = styled.div`
  position: relative;
`;

const ContactDropdown = styled.ul`
  position: absolute;
  top: calc(100% - 4px);
  left: 0;
  width: calc(100% - 2px);
  background-color: #191e24;
  border: 1px solid var(--border-color-2);
  border-radius: 0 0 4px 4px;
  list-style: none;
  padding: 0;
  margin: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  max-height: 240px;
  overflow: auto;
`;

const ContactOption = styled.li`
  display: flex;
  padding: 12px;
  cursor: pointer;
  gap: 8px;
  margin: 0;

  &:hover {
    background-color: var(--context-menu-item-bg-hover);
  }
`;

// todo: Move to npm packages?


export const ContactPicker = ({
                           // availableContacts,
                           currentContacts,
                           handleContactSelection = (_) => {},
                           name = "hiddenContactPickerValues",
                       }) => {
    // Refs
    const inputRef = useRef(null);
    const contactDropdownRef = useRef(null);

    // States
    const [selectedContacts, setSelectedContacts] = useState(currentContacts || []);
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    // const [filteredContacts, setFilteredContacts] = useState(availableContacts);

    const { outsideClick } = useOutsideClick(() => {
        setDropdownOpen(false);
    }, [contactDropdownRef, inputRef]);

    // useEffect(() => {
    //     isDropdownOpen && outsideClick.startListening();
    // }, [isDropdownOpen]);

    useEffect(() => {
        setSelectedContacts(currentContacts);
    }, [currentContacts]);

    // const handleSelectContact = (contact) => {
    //     if (selectedContacts.some((selectedContact) => selectedContact._id === tag._id)) {
    //         setSelectedContacts(
    //             selectedContacts.filter((selectedContact) => selectedContact._id !== tag._id)
    //         );
    //         handleContactSelection(contact);
    //     } else {
    //         setSelectedContacts([...selectedContacts, contact]);
    //         handleContactSelection(contact);
    //     }
    //     setDropdownOpen(false);    };

    const handleRemoveContact = (contactToRemove) =>
    {
        const updatedContacts = selectedContacts.filter(
            (contact) => contact._id !== contactToRemove._id
        );
        setSelectedContacts(updatedContacts);
        handleContactSelection(contactToRemove);
    };

    return (
        <ContactPickerWrapper>
            {/* Hidden input that makes the values accessible to a form element */}
            <input
                type="hidden"
                name={name}
                id={name}
                value={JSON.stringify(selectedContacts)}
            />

            <RelativeContainer>
                {/* The container of the selected contacts and the input */}
                <ContactContainer onClick={() => inputRef.current?.focus()}>
                    {selectedContacts?.map((contact) => (
                        contact?.email ?
                        <Tag
                            key={contact._id}
                            title={contact.email}
                            onClose={() => handleRemoveContact(contact)}
                        /> : undefined
                    ))}
                    <ContactInput
                        // placeholder={''}
                        // ref={inputRef}
                        // disabled={true}
                        // value={inputValue}
                        // onChange={handleInputChange}
                        // onKeyDown={handleInputKeyDown}
                        // onFocus={() => setDropdownOpen(true)}
                    />
                </ContactContainer>

                {isDropdownOpen && filteredContacts.length > 0 && (
                    <ContactDropdown ref={contactDropdownRef}>
                        {filteredContacts.map((contact) => (
                            <ContactOption key={contact._id} onClick={() => handleSelectContact(contact)}>
                                <Checkbox
                                    checked={selectedContacts?.some(
                                        (selectedContact) => selectedContact._id === contact._id
                                    )}
                                    onChange={() => handleSelectContact(contact)}
                                />
                                {contact.email}
                            </ContactOption>
                        ))}
                    </ContactDropdown>
                )}
            </RelativeContainer>
        </ContactPickerWrapper>
    );
};