import React, { useContext, useEffect, useState } from "react";
import { styled } from "styled-components";
import { motion, AnimatePresence } from "framer-motion";

// Component Library
import {
  Button,
  FreshnessScore,
  LoadingCard,
  ContentContainer,
  OptionsDivider,
} from "@saberapp/react-component-library";

// Enums
import {
  FreshnessScoreValues,
  ResourceEvents,
  EventTargetObjects,
} from "@saberapp/enums";

// Hooks
import { Resources as ResourcesContext } from "../../../hooks/useResources";
import { ContextMenu as ContextMenuContext } from "../../../hooks/useContextMenu";
import { useAuth } from "../../../hooks/useAuth";
import { useNavigate, useLocation } from "react-router-dom";
// import { useAnalytics } from "../../../hooks/useAnalytics";

// Layouts
import TopNavigation from "../../layouts/main-top-bar";
import ContextMenu from "../../layouts/context-menu";
import SearchBox from "../../layouts/search";

// Modals
import Modal from "../../layouts/modals";
import { ConfirmationModalContent } from "../../layouts/modals/views/ConfirmationModalContent";
import EditTags from "../../layouts/modals/views/EditTags";
import EditReviewers from "../../layouts/modals/views/EditReviewers";
import EditContentType from "../../layouts/modals/views/EditContentType";
import EditVisibility from "../../layouts/modals/views/EditVisibility";
import ShareDoc from "../../layouts/modals/views/ShareResource";

// Section-specific components
import Sidebar from "./components/Sidebar";
import TableView from "./components/TableView";
import TileView from "./components/TileView";
import GroupOptionsDropdown from "./components/GroupOptionsDropdown";
import ResourceContextMenu from "../../layouts/context-menu/components/ResourceContextMenu";

// Icons
import {
  IconTag as IconTags,
  IconBars3 as IconTable,
  IconSquares2x2 as IconTiles,
  IconArrowLeftOnRectangle as IconSidebar,
  IconSquare3Stack3d as IconGroup,
  IconArchiveBox as IconArchive,
  IconArchiveBox,
  IconEye,
} from "@saberapp/icons";
import EmptyState from "./components/EmptyState";
import LoadingState from "../../layouts/LoadingState";

const Options = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const RowContainer = styled.div`
  display: flex;
  height: calc(100% - 62px);
  max-width: 100%;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
`;

const OptionsButtons = styled(motion.div)`
  display: flex;
  align-items: center;
  gap: 8px;
  position: absolute;
  bottom: 0;
  background: var(--modal-bg);
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 6px 16px;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 24px;
`;

const Showing = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: var(--button-ui-color);
  padding-right: 8px;
  white-space: nowrap;
`;

const ResourcesContent = styled.div`
  overflow: auto;
  height: 100%;
  padding-bottom: 80px;
  box-sizing: border-box;
`;

const Resources = () => {
  // Get organization
  const { organization, userHasRole } = useAuth();

  // Get Resources context
  const {
    isLoadingResources,
    // Resource collections
    resources,
    filteredResources,
    selectedResources,
    // Handlers
    handleUpdate,
    handleBulkAction,
    handleSelectedResources,
    handleShowArchive,
    handleFilterChange,
    handleSortChange,
    handleGroupingChange,
    // Counts
    needsReviewCount,
    outdatedCount,
    groupOptions,
    getFilteredResourcesByPage,
    // Other
    sort,
  } = useContext(ResourcesContext);

  // Get Context Menu context
  const { contextMenu } = useContext(ContextMenuContext);

  const { isAuthenticated } = useAuth();
  // const { analytics } = useAnalytics();
  const navigate = useNavigate();
  let location = useLocation();

  // Layout & UI States
  const [page, setPage] = useState(null);
  const [path, setPath] = useState([]);
  // TODO: Switch to ENUMS
  // tableView, tableViewCompact, tileView
  const [layout, setLayout] = useState("tileView");
  // const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isArchive, setIsArchive] = useState(false);

  // Edit Resource States
  const [resourceToEdit, setResourceToEdit] = useState(null);
  const [isShareDocModalVisible, setIsShareDocModalVisible] = useState(false);
  const [isEditTagsModalVisible, setIsEditTagsModalVisible] = useState(false);
  const [isEditContentTypeModalVisible, setIsEditContentTypeModalVisible] =
    useState(false);
  const [isEditReviewersModalVisible, setIsEditReviewersModalVisible] =
    useState(false);
  const [isEditVisibilityModalVisible, setIsEditVisibilityModalVisible] =
    useState(false);

  // Bulk Actions States
  const [confirmationModalData, setConfirmationModalData] = useState({});

  /**
   * Runs on load
   */

  useEffect(() => {
    if (!isAuthenticated) return;
    updatePageState();
  }, [isAuthenticated, location]);

  const updatePageState = () => {
    setIsArchive(location.pathname.includes("archive"));
    handleShowArchive(!location.pathname.includes("archive"));

    const p = location.pathname.split("/")[2];
    if (p !== page) {
      setPage(p);

      if (location.pathname.includes("archive")) {
        setPath([{ title: "Archive", path: `/${organization.slug}/archive` }]);
      } else if (p === "documents") {
        setPath([
          { title: "Content & Data", path: `/${organization.slug}/documents` },
          { title: "Documents", path: `/${organization.slug}/documents` },
        ]);
      } else if (p === "pages-and-notes") {
        setPath([
          {
            title: "Content & Data",
            path: `/${organization.slug}/pages-and-notes`,
          },
          {
            title: "Pages & Notes",
            path: `/${organization.slug}/pages-and-notes`,
          },
        ]);
      }
    }
  };

  /**
   * When clicking on a resources in either views, including single click, cmd click and shift click
   * @param {*} resource
   * @param {*} event
   */
  const handleResourceClick = (resource, event) => {
    event.preventDefault();

    if (event.button === 2) {
      return;
    }

    if (event.target.localName !== "td" && event.target.localName !== "div") {
      return;
    }

    // If the user is a seller, just go to the doc
    // TODO: Maybe we don't want this
    if (userHasRole("SELLER")) {
      // analytics.track(
      //   ResourceEvents.RESOURCE_VIEWED,
      //   EventTargetObjects.RESOURCE,
      //   { resourceId: resource?._id }
      // );
      navigate(`/${organization.slug}/documents/${resource?._id}`);

      // If its a shift key where users can do multi-select
    } else if (event.shiftKey) {
      if (selectedResources.length === 0) {
        handleSelectedResources("set", resource);
      } else {
        const prevSelectedIndex = resources.findIndex(
          (row) => row._id === selectedResources[0]._id
        );
        const currentRowIndex = resources.findIndex(
          (row) => row._id === resource._id
        );
        const startIndex = Math.min(prevSelectedIndex, currentRowIndex);
        const endIndex = Math.max(prevSelectedIndex, currentRowIndex);
        const rowsInRange = resources.slice(startIndex, endIndex + 1);
        handleSelectedResources("set", rowsInRange);
      }

      // If it's a CMD key, add or remove from the selected resources
    } else if (event.metaKey) {
      handleSelectedResources("toggle", resource);

      // If it's a double click
    } else if (event.detail === 2) {
      navigate(`/${organization.slug}/documents/${resource?._id}`);

      // Just a regular click
    } else {
      handleSelectedResources("set", resource);
    }
  };

  // useEffect(() => {
  //   selectedResources.length === 0
  //     ? setIsSidebarOpen(false)
  //     : setIsSidebarOpen(true);
  // }, [selectedResources]);

  // ------------------
  // EDITING & UPDATING
  // ------------------

  /**
   * Handler to open the confirmation modal before performing bulk actions
   * @param {String} action
   * @param {Boolean} targetSelectedResources
   * TODO: Can probably be refactored
   */
  const handleConfirmationModal = (action, targetSelectedResources = false) => {
    if (action === "archive") {
      setConfirmationModalData({
        isVisible: true,
        title: `Are you sure?`,
        description: `You are about to archive ${
          targetSelectedResources ? selectedResources.length : outdatedCount
        } docs, meaning they will not be available to your team anymore. You can undo this action any time by going to the Archive.`,
        buttonTitle: `Yes, archive ${
          targetSelectedResources ? selectedResources.length : outdatedCount
        } docs`,
        width: 420,
        handleSubmit: () => {
          setConfirmationModalData({});
          handleBulkAction(action, targetSelectedResources);
        },
      });
    } else if (action === "unarchive") {
      setConfirmationModalData({
        isVisible: true,
        title: `Are you sure?`,
        description: `You are about to unarchive ${
          targetSelectedResources ? selectedResources.length : outdatedCount
        } docs, meaning they will be available to your team again.`,
        buttonTitle: `Yes, unarchive ${
          targetSelectedResources ? selectedResources.length : outdatedCount
        } docs`,
        width: 420,
        handleSubmit: () => {
          setConfirmationModalData({});
          handleBulkAction(action, targetSelectedResources);
        },
      });
    } else if (action === "review") {
      setConfirmationModalData({
        isVisible: true,
        title: `Are you sure?`,
        description: `You are about to mark ${
          targetSelectedResources ? selectedResources.length : needsReviewCount
        } docs as reviewed. Make sure the content in these docs is still relevant and accurate.`,
        buttonTitle: `Yes, mark ${
          targetSelectedResources ? selectedResources.length : needsReviewCount
        } docs as reviewed`,
        width: 420,
        handleSubmit: () => {
          console.log("hello 3");
          setConfirmationModalData({});
          handleBulkAction(action, targetSelectedResources);
        },
      });
    }
  };

  /**
   * Handle to open the modal to share a resource externally
   * @param {Object} resource
   */

  const handleShareDoc = (resource = selectedResources) => {
    setResourceToEdit(resource);
    setIsShareDocModalVisible(true);
  };

  /**
   * Handle to open the modal to edit a resource' tags
   * @param {Object} resource
   */

  const handleEditTags = (resource = selectedResources) => {
    setResourceToEdit(resource);
    setIsEditTagsModalVisible(true);
  };

  /**
   * Handle to open the modal to edit a resource' tags
   * @param {Object} resource
   */

  const handleEditContentType = (resource = selectedResources) => {
    setResourceToEdit(resource);
    setIsEditContentTypeModalVisible(true);
  };

  /**
   * Handle to open the modal to edit a resource' tags
   * @param {Object} resource
   */

  const handleEditReviewers = (resource = selectedResources) => {
    setResourceToEdit(resource);
    setIsEditReviewersModalVisible(true);
  };

  const handleEditVisibility = (resource = selectedResources) => {
    setResourceToEdit(resource);
    setIsEditVisibilityModalVisible(true);
  };

  // const handleSidebarToggle = (newState = !isSidebarOpen) => {
  //   setIsSidebarOpen(newState);
  // };

  return (
    <>
      <TopNavigation links={path}>
        {!isLoadingResources && resources.length > 0 && (
          <Options>
            {!isLoadingResources ? (
              <Showing>
                {`Showing ${getFilteredResourcesByPage(page).length}${
                  isArchive ? ` archived` : ``
                } resources`}
              </Showing>
            ) : (
              <LoadingCard height="20px" width="160px" />
            )}

            <GroupOptionsDropdown page={page} />

            {/* <FilterOptionsDropdown tags={tags} /> */}

            <Button
              title={`Sorted by ${sort.by}, ${sort.order}`}
              size="small"
              onClick={() => {
                handleSortChange();
              }}
            />

            {/* {userHasRole("ADMIN") && (
              <Button
                type="ui"
                size="small"
                iconBefore={<IconArchive />}
                // title={isArchive ? `Back to Docs` : `Show Archive`}
                onClick={() => {
                  navigate(
                    isArchive
                      ? `/${organization.slug}/documents`
                      : `/${organization.slug}/archive`
                  );
                }}
              />
            )} */}

            {/* <Button
              type="ui"
              size="small"
              iconBefore={<IconTable />}
              onClick={() => setLayout("tableView")}
            />
            <Button
              type="ui"
              size="small"
              iconBefore={<IconTiles />}
              onClick={() => setLayout("tileView")}
            />

            <OptionsDivider /> */}

            {/* <Button
              type="ui"
              size="small"
              iconBefore={<IconSidebar />}
              onClick={() => handleSidebarToggle()}
            /> */}
          </Options>
        )}
      </TopNavigation>

      <SearchBox
        isLoading={isLoadingResources}
        pageSpecificActions={[
          {
            title: "Group",
            actions: [
              {
                icon: <IconGroup />,
                title: "Group by Content Type",
                action: () => {
                  // TODO instead of raw-dogging the array, write a getter
                  handleGroupingChange(groupOptions[0]);
                },
              },
              {
                icon: <IconGroup />,
                title: "Group by File Type",
                action: () => {
                  handleGroupingChange(groupOptions[1]);
                },
              },
            ],
          },
          {
            title: "Freshness",
            actions: [
              {
                icon: <IconGroup />,
                title: "Group by Freshness Score",
                action: () => {
                  handleGroupingChange(groupOptions[2]);
                },
              },
              // TODO: fix this, it doesnt seem to pull the right context
              // {
              //   icon: <FreshnessScore score={FreshnessScoreValues.VERIFIED} />,
              //   title: "Mark all as reviewed",
              //   action: () => {
              //     handleConfirmationModal("review");
              //   },
              // },
              {
                icon: (
                  <FreshnessScore score={FreshnessScoreValues.NEEDS_REVIEW} />
                ),
                title: "Show docs that need review",
                action: () => {
                  handleFilterChange({
                    freshnessScore: [
                      FreshnessScoreValues.VERIFIED,
                      FreshnessScoreValues.OUTDATED,
                    ],
                  });
                },
              },
              {
                icon: <FreshnessScore score={FreshnessScoreValues.OUTDATED} />,
                title: "Show docs that can be archived",
                action: () => {
                  handleFilterChange({
                    freshnessScore: [
                      FreshnessScoreValues.VERIFIED,
                      FreshnessScoreValues.NEEDS_REVIEW,
                    ],
                  });
                },
              },
            ],
          },
        ]}
        selectedResources={selectedResources}
        resourceSpecificActions={[
          {
            title: "Resource",
            actions: [
              // {
              //   icon: <IconTags />,
              //   title: "Edit Content Type...",
              //   action: () => {
              //     handleEditContentType();
              //   },
              // },
              // {
              //   icon: <IconTags />,
              //   title: "Share doc...",
              //   action: () => {
              //     handleShareDoc();
              //   },
              // },
              // {
              //   icon: <IconTags />,
              //   title: "Edit Tags...",
              //   action: () => {
              //     handleEditTags();
              //   },
              // },
              // {
              //   icon: <IconTags />,
              //   title: "Edit Reviewers...",
              //   action: () => {
              //     handleEditReviewers();
              //   },
              // },
              {
                icon: <FreshnessScore score={FreshnessScoreValues.VERIFIED} />,
                title: "Mark as Reviewed",
                action: () => {
                  handleConfirmationModal("review", true);
                },
              },
              // {
              //   icon: <IconArchiveBox />,
              //   title: "Archive",
              //   action: () => {
              //     const action = isArchive ? "unarchive" : "archive";
              //     handleConfirmationModal(action, true);
              //   },
              // },
              {
                icon: <IconTags />,
                title: "Clear Selected Resources",
                action: () => {
                  handleSelectedResources("clear");
                },
                dismissAfterAction: false,
              },
            ],
          },
        ]}
      />

      <ContextMenu
        menuItems={ResourceContextMenu({
          handleShareDoc,
          handleEditTags,
          handleEditContentType,
          handleEditReviewers,
        })}
        context={contextMenu}
      />

      {/**
       * Manage Share docs Modal
       */}
      {/* <Modal
        chrome={true}
        isVisible={isShareDocModalVisible}
        setIsVisible={setIsShareDocModalVisible}
      >
        <ShareDoc resourceToShare={resourceToEdit} />
      </Modal> */}

      {/**
       * Manage Edit Tags Modal
       */}
      {/* <Modal
        chrome={true}
        isVisible={isEditTagsModalVisible}
        setIsVisible={setIsEditTagsModalVisible}
      >
        <EditTags resourceToEdit={resourceToEdit} />
      </Modal> */}

      {/**
       * Manage Edit Content Type Modal
       */}
      {/* <Modal
        chrome={true}
        isVisible={isEditContentTypeModalVisible}
        setIsVisible={setIsEditContentTypeModalVisible}
      >
        <EditContentType resourceToEdit={resourceToEdit} />
      </Modal> */}

      {/**
       * Manage Edit Reviewers Modal
       */}
      {/* <Modal
        chrome={true}
        isVisible={isEditReviewersModalVisible}
        setIsVisible={setIsEditReviewersModalVisible}
      >
        <EditReviewers resourceToEdit={resourceToEdit} />
      </Modal> */}

      {/**
       * Manage Edit Reviewers Modal
       */}
      {/* <Modal
        chrome={true}
        isVisible={isEditVisibilityModalVisible}
        setIsVisible={setIsEditVisibilityModalVisible}
      >
        <EditVisibility resourceToEdit={resourceToEdit} />
      </Modal> */}

      {/**
       * Confirm Bulk Action Modal
       */}
      <Modal
        isVisible={confirmationModalData.isVisible}
        setIsVisible={(state) => {
          setConfirmationModalData((prevData) => {
            return { ...prevData, isVisible: state };
          });
        }}
        title={confirmationModalData.title}
        buttonTitle={confirmationModalData.buttonTitle}
        width={confirmationModalData.width}
        handleSubmit={() => { console.log('handling submit'); confirmationModalData.handleSubmit() }}
      >
        <ConfirmationModalContent
          description={confirmationModalData.description}
        />
      </Modal>

      <RowContainer>
        <Column>
          {!isLoadingResources &&
            getFilteredResourcesByPage(page).length > 0 && (
              <ContentContainer isopen={"false"}>
                <ResourcesContent>
                  {layout === "tableView" && (
                    <TableView
                      layout={layout}
                      handleResourceClick={handleResourceClick}
                      handleManageResourceTags={handleEditTags} // TODO fix
                      isArchive={isArchive}
                      page={page}
                    />
                  )}

                  {layout === "tileView" && (
                    <TileView
                      handleResourceClick={handleResourceClick}
                      page={page}
                    />
                  )}

                  {selectedResources.length > 1 && (
                    <AnimatePresence>
                      <OptionsButtons
                        initial={{ bottom: -100, opacity: 0 }}
                        animate={{ bottom: 0, opacity: 1 }}
                        exit={{ bottom: 0, opacity: 0 }}
                        transition={{ duration: 0.1 }}
                      >
                        <Showing>{`${selectedResources.length} selected`}</Showing>
                        {/* <Button
                          type="ui"
                          size="small"
                          iconBefore={<IconTags />}
                          title="Edit Content Type"
                          onClick={() => handleEditContentType()}
                        />
                        <Button
                          type="ui"
                          size="small"
                          iconBefore={<IconTags />}
                          title="Share doc"
                          onClick={() => handleShareDoc()}
                        />
                        <Button
                          type="ui"
                          size="small"
                          iconBefore={<IconTags />}
                          title="Edit Tags"
                          onClick={() => handleEditTags()}
                        />
                        <Button
                          type="ui"
                          size="small"
                          iconBefore={<IconEye />}
                          title="Edit Visibility"
                          onClick={() => handleEditVisibility()}
                        /> */}
                        <Button
                          type="ui"
                          size="small"
                          title="Mark as Reviewed"
                          iconBefore={
                            <FreshnessScore
                              score={FreshnessScoreValues.VERIFIED}
                            />
                          }
                          onClick={() =>
                            handleConfirmationModal("review", true)
                          }
                        />
                        {/* <Button
                          type="ui"
                          size="small"
                          title="Archive"
                          iconBefore={<IconArchive />}
                          onClick={() => {
                            const action = isArchive ? "unarchive" : "archive";
                            handleConfirmationModal(action, true);
                          }}
                        /> */}
                      </OptionsButtons>
                    </AnimatePresence>
                  )}
                </ResourcesContent>
              </ContentContainer>
            )}

          {!isLoadingResources &&
            getFilteredResourcesByPage(page).length === 0 && (
              <EmptyState page={page} />
            )}

          {isLoadingResources && <LoadingState />}
        </Column>

        {/* {isSidebarOpen && (
          <Sidebar
            isopen={isSidebarOpen.toString()}
            handleConfirmationModal={handleConfirmationModal}
            handleEditVisibility={handleEditVisibility}
            handleEditContentType={handleEditContentType}
            handleEditTags={handleEditTags}
          />
        )} */}
      </RowContainer>
    </>
  );
};

export default Resources;
