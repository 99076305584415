import React from "react";
import styled from "styled-components";

// Component Library
import {
  Heading,
  Paragraph,
  Pill,
  fileGroupsMap,
} from "@saberapp/react-component-library";
import IconWrapper from "../../../icons/IconWrapper";

const Row = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;

  svg {
    width: 20px;
  }
`;

const IndexStatusHeader = ({ groupKey, count }) => {

  console.log(groupKey)

    const status = {
        PROCESSING_FAILED: {
          name: "Processing Failed",
          description: "These are most likely files that we don't yet support or they contain content we don't support"
        },
        NOT_SUPPORTED: {
          name: "File Type Not Supported",
          description: "We don't support these files types just yet. We're constantly adding support for more file types"
        },
        INDEXED: {
          name: "Indexed",
          description: "These filese are good to go and will be used to generate answers and recommendations"
        },
        NOT_INDEXED: {
          name: "Not Indexed",
          description: "These files can be indexed, but haven't been indexed yet"
        },
        PENDING: {
          name: "Pending",
          description: "These files are waiting to be indexed."
        },
      };

  return (
    <>
      <Row>
        {/* <IconWrapper size="16">{status[groupKey].name}</IconWrapper> */}
        <Heading size="h3">{status[groupKey]?.name}</Heading>
        <Pill title={`${count}`} />
        <Paragraph>{status[groupKey]?.description}</Paragraph>
      </Row>
      {/* <Paragraph>TEST: {fileGroupsMap[groupKey]?.description}</Paragraph> */}
    </>
  );
};

export default IndexStatusHeader;
