import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

// Hooks
import { useConnectors } from "../../../../hooks/useConnectors";

// Component Library
import {
  Heading,
  Card,
  DropdownButton,
  DropdownItem,
  Button,
  Paragraph,
  User,
} from "@saberapp/react-component-library";
import { IconWrapper, IconLifebuoy } from "@saberapp/icons";

// Icons
import { IconChevronDown, IconSquaresPlus } from "@saberapp/icons";
import { IntegrationStatusIndicator } from "./IntegrationStatusIndicator";

dayjs.extend(relativeTime);
dayjs.locale("en");

const Column = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;

  &:last-child {
    margin-left: auto;
  }
`;

const Row = styled.div`
  display: flex;
  gap: 32px;
`;

const UserRow = styled.div`
  display: flex;
  align-items: center;

  // TOOD: fix in component
  img {
    width: 20px;
    height: 20px;
  }
`;

const HeadingWithIconRow = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const Buttons = styled.div`
  display: flex;
  gap: 8px;
`;

const IntegrationsStatus = ({
  connector,
  onConnect,
  onDisconnect,
}) => {
  const {
    isConnectorsLoading,
    isDisconnecting,
    setIsDisconnecting,
    isConnecting,
  } = useConnectors();

  return (
    <>
      {isConnectorsLoading ? (
        <Card padding={16}>Loading...</Card>
      ) : (
        <>
          <Card padding={16}>
            <Row>
      
                <Column>
                  {connector.status === "connected" || connector.status === "ready-to-connect" ? (
                    <>
                    {console.log(connector.enabledBy, 'pic')}
                      <Heading size="h4">Connected by</Heading>
                      <UserRow>
                        <User
                          name={connector.enabledBy?.name}
                          avatar={connector.enabledBy?.avatar}
                        />
                        , {dayjs(connector.updatedAt).fromNow()}
                      </UserRow>
                    </>
                  ) : (
                    <>
                      <Heading size="h4">Disconnected</Heading>
                      <Paragraph>
                        {dayjs(connector.updatedAt).fromNow()}
                      </Paragraph>
                    </>
                  )}
                </Column>
    
              <Column>
                <Heading size="h4"> Have questions?</Heading>
                <Paragraph>Contact us</Paragraph>
              </Column>
              <Column>
                {connector.status ? (
                  <Buttons>
                    {connector.status === "connected" || connector.status === "ready-to-connect" ? (
                      <>
                        <DropdownButton
                          type="ui"
                          iconBefore={
                            <IntegrationStatusIndicator status={connector.status} />
                          }
                          title={
                            isDisconnecting
                              ? "Disconnecting..."
                              : connector.status === "connected"
                              ? "Connected"
                              : "Action Required"
                          }
                          iconAfter={<IconChevronDown />}
                          position="bottom-right"
                          disabled={isDisconnecting}
                        >
                          <DropdownItem
                            onClick={() => {
                              setIsDisconnecting(true);
                              onDisconnect();
                            }}
                          >
                            Disconnect...
                          </DropdownItem>
                        </DropdownButton>
                      </>
                    ) : (
                      <>
                        <Button
                          iconBefore={<IconSquaresPlus />}
                          type="primary"
                          title={
                            isConnecting
                              ? "Connecting..."
                              : connector.name
                              ? `Reconnect ${connector.name}`
                              : `Reconnect`
                          }
                          disabled={isConnecting}
                          onClick={() => {
                            onConnect();
                          }}
                        />
                      </>
                    )}
                  </Buttons>
                ) : (
                  <Button
                    iconBefore={<IconSquaresPlus />}
                    type="primary"
                    title={
                      isConnecting
                        ? "Connecting..."
                        : connector.name
                        ? `Connect ${connector.name}`
                        : `Connect`
                    }
                    disabled={isConnecting}
                    onClick={() => {
                      onConnect();
                    }}
                  />
                )}
              </Column>
            </Row>
          </Card>
        </>
      )}
    </>
  );
};

export default IntegrationsStatus;
