import {
  useState,
  useEffect,
  useRef,
  createContext,
  useLayoutEffect,
} from "react";

export const ContextMenu = createContext();

export const useContextMenu = () => {
  const menuRef = useRef(null);
  const subMenuRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [clickPosition, setClickPosition] = useState({ x: 0, y: 0 });
  const [data, setData] = useState(null);

  // Sub menu
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [subMenuPosition, setSubMenuPosition] = useState({ y: 0, x: 0 });
  const [subMenuInitialPosition, setSubMenuInitialPosition] = useState({ y: 0, x: 0 });
  const [subMenuData, setSubMenuData] = useState([]);

  useEffect(() => {
    if (!isOpen) {
      closeSubMenu();
    }
  }, [isOpen]);

  useLayoutEffect(() => {
    if (menuRef.current) {
      let x = clickPosition.x;
      let y = clickPosition.y;

      // If the context menu goes off the bottom of the page
      if(window.innerHeight < y + menuRef.current.offsetHeight){
        y = window.innerHeight - (menuRef.current.offsetHeight + 32)
      }

      setPosition({ x, y });
    }
  }, [isOpen]);

  useLayoutEffect(() => {
    if (menuRef.current && subMenuRef.current) {
        let x = subMenuInitialPosition.x + menuRef.current.offsetWidth;
        let y = subMenuInitialPosition.y;

      // If the context menu goes off the bottom of the page
      if(window.innerHeight < y + subMenuRef.current.offsetHeight){
        y = window.innerHeight - (subMenuRef.current.offsetHeight + 32)
      }

      setSubMenuPosition({ x, y });
    }
  }, [isSubMenuOpen, subMenuInitialPosition]);

  const toggle = () => {
    setIsOpen(!menu.isOpen);
  };

  const open = (data, position) => {
    setData(data);
    setClickPosition(position);
    setIsOpen(true);
  };

  const openSubMenu = (e, data) => {
    let x = position.x;
    let y = position.y + e.target.offsetTop;
    setSubMenuInitialPosition({ x, y });
    setIsSubMenuOpen(true);
    setSubMenuData(data);
  };

  const close = () => {
    setIsOpen(false);
  };

  const closeSubMenu = () => {
    setSubMenuPosition({ x: 0, y: 0 });
    setIsSubMenuOpen(false);
    setSubMenuData([]);
  };

  return {
    contextMenu: {
      menuRef,
      subMenuRef,
      isOpen,
      data,
      toggle,
      open,
      openSubMenu,
      close,
      closeSubMenu,
      x: position.x,
      y: position.y,
      isSubMenuOpen,
      subMenuPosition,
      subMenuData,
    },
  };
};

export const ContextMenuProvider = ({ children }) => {
  const contextMenuHook = useContextMenu();
  return (
    <ContextMenu.Provider value={contextMenuHook}>
      {children}
    </ContextMenu.Provider>
  );
};
