import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';

const firebaseConfig = process.env.REACT_APP_SABER_ENV === 'production' ? {
  apiKey: "AIzaSyDTcYvT5_wLAaZszSai1q8j2u_xs-om1rE",
  authDomain: "rest-api-397118.firebaseapp.com",
  projectId: "rest-api-397118",
  storageBucket: "rest-api-397118.appspot.com",
  messagingSenderId: "1025171430386",
  appId: "1:1025171430386:web:eabc85a7fce36ad344a96b"
} : {
  apiKey: "AIzaSyBDSK0eHOLPgCX-ksBBoefURBe09SYHoPM",
  authDomain: "api-staging-1.firebaseapp.com",
  projectId: "api-staging-1",
  storageBucket: "api-staging-1.appspot.com",
  messagingSenderId: "814813326880",
  appId: "1:814813326880:web:3245193fbe56b318049645"
};

// For Super Admin
// {
//   apiKey: "AIzaSyAVjyhhqWMIgJDVvgD7nVqQBsTcNuE6_jU",
//   authDomain: "saber-staging.firebaseapp.com",
//   projectId: "saber-staging",
//   storageBucket: "saber-staging.appspot.com",
//   messagingSenderId: "708333425260",
//   appId: "1:708333425260:web:142f847a8f2156e8b27e49",
//   measurementId: "G-HYVJ6S8H7W"
// }

console.log('Initializing')

export const app = initializeApp(firebaseConfig);
console.log('APP: ', app)
export const auth = getAuth(app);