import React, { useState, useEffect } from "react";
import styled from "styled-components";

// Component Library
import {
  Card,
  Heading,
  Paragraph,
  Button,
  DropdownButton,
  DropdownItem,
  Pill,
} from "@saberapp/react-component-library";

// Hooks
import { useConnectors } from "../../../../hooks/useConnectors";

// Icons
import { IconChevronDown, IconSquaresPlus } from "@saberapp/icons";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../hooks/useAuth";

const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  margin-bottom: 12px;
`;

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 96px;;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 8px;
`;

const LogoContainer = styled.div`
  display: flex;
  width: 32px;
  height: 32px;
  border-radius: 8px;
  background: #fff;
  padding: 6px;
  box-sizing: border-box;

  img {
    width: 32px;
  }
`;

const StatusIndicator = styled.div`
  position: relative;
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #8dc63f;
  box-shadow: 0 0 0 0 rgba(#5a99d4, 0.5);
  -webkit-animation: pulse 1s infinite ease-in-out;
  animation: pulse 1s infinite ease-in-out;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;

  @-webkit-keyframes pulse {
    50% {
      -webkit-box-shadow: 0 0 0 2px rgba(141, 198, 63, 0.2),
        0 0 0 4px rgba(141, 198, 63, 0.5);
      box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.2),
        0 0 0 4px rgba(141, 198, 63, 0.5);
    }
  }

  @keyframes pulse {
    50% {
      -webkit-box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.2),
        0 0 0 4px rgba(141, 198, 63, 0.5);
      box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.2),
        0 0 0 4px rgba(141, 198, 63, 0.5);
    }
  }
`;

const IntegrationCard = ({
  source,
  slug,
  name,
  description,
  icon,
  comingSoon,
  userSpecific,
}) => {
  const { organization } = useAuth();
  const {
    doesConnectorExist,
    getConnectorByName,
    isConnectorsUpdating,
    isConnectorsLoading,
    getUserConnectorByName,
    doesUserHaveConnector,
  } = useConnectors();
  const navigate = useNavigate();

  const [status, setStatus] = useState(null);

  const [isCreated, setIsCreated] = useState(null);

  useEffect(() => {
    if (!isConnectorsLoading) {
      if (userSpecific) {
        setIsCreated(doesUserHaveConnector(source));
        if (doesUserHaveConnector(source)) {
          setStatus(getUserConnectorByName(source).status);
        }
      } else {
        setIsCreated(doesConnectorExist(source));
        if (doesConnectorExist(source)) {
          setStatus(getConnectorByName(source).status);
        }
      }
    }
  }, [isConnectorsLoading, isConnectorsUpdating]);

  return (
    <Card padding={16} key={slug}>
      <CardHeader>
        {icon && (
          <LogoContainer>
            {typeof icon === "string" ? <img src={icon} /> : icon}
          </LogoContainer>
        )}

        <Heading size="h4">{name}</Heading>
      </CardHeader>
      <CardBody>
        <Paragraph>{description}</Paragraph>
        <Buttons>
          {comingSoon ? (
            <Pill title="Coming Soon" />
          ) : (
            <>
              {isCreated ? (
                <>
                  {status === "connected" ? (
                    <>
                      <DropdownButton
                        type="ui"
                        iconBefore={<StatusIndicator />}
                        title={
                          isConnectorsUpdating
                            ? "Disconnecting..."
                            : "Connected"
                        }
                        iconAfter={<IconChevronDown />}
                        position="bottom-right"
                      >
                        <DropdownItem
                          onClick={() => {
                            navigate(`/${organization.slug}/connector/${slug}`);
                          }}
                        >
                          Manage...
                        </DropdownItem>
                      </DropdownButton>
                    </>
                  ) : (
                    <>
                      <Button
                        iconBefore={<IconSquaresPlus />}
                        type="ui"
                        size="small"
                        title="Reconnect"
                        onClick={() => {
                          navigate(`/${organization.slug}/connector/${slug}`);
                        }}
                      />
                    </>
                  )}
                </>
              ) : (
                <Button
                  iconBefore={<IconSquaresPlus />}
                  type="ui"
                  size="small"
                  title="Connect"
                  onClick={() => {
                    navigate(`/${organization.slug}/connector/${slug}`);
                  }}
                />
              )}
            </>
          )}
        </Buttons>
      </CardBody>
    </Card>
  );
};

export default IntegrationCard;
