import React from "react";
import styled from "styled-components";

import IconWrapper from "../../../icons/IconWrapper";

// Component Library
import {
  Heading,
  Paragraph,
  Pill,
  contentTypesMap
} from "@saberapp/react-component-library";

const Row = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;

  svg {
    width: 20px;
  }
`;

const ContentTypeHeader = ({ groupKey, count }) => {
  return (
    <>
      <Row>
        <IconWrapper size="16">{contentTypesMap[groupKey]?.icon}</IconWrapper>
        <Heading size="h3">{groupKey}</Heading>
        <Pill title={`${count}`} />
      </Row>
      {/* <Paragraph>{contentTypesMap[groupKey]?.description}</Paragraph> */}
    </>
  );
};

export default ContentTypeHeader;
