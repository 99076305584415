import React from "react";
import styled from "styled-components";

import PageHeader from "./components/PageHeader";

import { Heading, Paragraph } from "@saberapp/react-component-library";
import ListItem from "./components/ListItem";
import PageFooter from "./components/PageFooter";
import PageContent from "./components/PageContent";
import Page from "./components/Page";

const Container = styled.div`
  height: 100%;
`;

const LegalPage = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding-top: 80px;
  padding-bottom: 160px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Dpa = () => {
  return (
    <>
      <PageHeader />
      <Page>
        <PageContent>
          <Container>
            <LegalPage>
              <Heading size="h1">Data Protection Agreement</Heading>
              <Paragraph>
                This Data Processing Agreement (hereinafter referred to as DPA)
                by and between Saber B.V., a private limited liability company
                (besloten vennootschap met beperkte aansprakelijkheid) duly
                organized and existing under the Laws of the Netherlands, with
                its statutory seat (statutaire zetel) in Amsterdam, the
                Netherlands, and its offices at De Entree 201, 1101HG Amsterdam,
                the Netherlands and registered with the Dutch chamber of
                commerce under number 17182045 (Saber), and [●] (Customer).
              </Paragraph>

              {/* <ListItem bullet="1.1" paragraph="" /> */}

              <Heading size="h3">Whereas:</Heading>

              <ListItem
                bullet="(A)"
                paragraph="Saber is in the business of developing and selling software-as-a-service solutions for businesses with an aim to make sales teams more efficient, including by but not limited to (i) centralizing data across the tools sellers use including but not limited to a seller's CRM, content repository, and their productivity and communications tools, (ii) automating sales workflows, and (iii) providing solutions for continuous learning and development for sellers (the Business)."
              />

              <ListItem
                bullet="(B)"
                paragraph="Customer has retained the Services of Saber under the Services Agreement."
              />

              <ListItem
                bullet="(C)"
                paragraph="In doing so, Saber will be Processing Personal Data on behalf of Customer, whereby Saber will be acting as Data Processor and Customer will be acting as Data Controller. "
              />

              <ListItem
                bullet="(D)"
                paragraph="The Parties seek to implement this DPA to comply with the requirements of the GDPR."
              />

              <ListItem
                bullet="(E)"
                paragraph="This DPA will be supplemental to the Services Agreement between the Parties, and this DPA will follow the terms thereof and definitions therein."
              />

              <Heading size="h3">
                THEREFORE IT IS HEREBY AGREED as follows:
              </Heading>

              <Heading size="h3">1. Definitions</Heading>

              <ListItem
                bullet="1.1"
                paragraph="Unless the context requires otherwise, capitalized terms and expressions are defined terms and expressions which will have the meaning as set out in this Section:
"
              />

              <ListItem
                bullet="(a)"
                indent={46}
                paragraph="Data Breach: a breach of security leading to the accidental or unlawful destruction, loss, alteration, unauthorized disclosure of, or access to transmitted, stored, or otherwise processed Personal Data.
"
              />

              <ListItem
                bullet="(b)"
                indent={46}
                paragraph="Data Controller: a natural or legal person, public authority, agency, or other body which, alone or jointly with others, determines the purposes and means of the processing of Personal Data; where the purposes and means of such processing are determined by Union or Member State law, the data controller or the specific criteria for its nomination may be provided for by Union or Member State law. 
"
              />

              <ListItem
                bullet="(c)"
                indent={46}
                paragraph="Data Processing Agreement or DPA: this agreement including its appendices. 
"
              />

              <ListItem
                bullet="(d)"
                indent={46}
                paragraph="Data Processor: a natural or legal person, public authority, agency, or other body that Processes Personal Data on behalf of the Data Controller. 
"
              />

              <ListItem
                bullet="(e)"
                indent={46}
                paragraph="Data Subject: an identified or identifiable natural person to whom the processed Personal Data relates. "
              />

              <ListItem
                bullet="(f)"
                indent={46}
                paragraph="GDPR: Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC (General Data Protection Regulation). 
"
              />

              <ListItem
                bullet="(g)"
                indent={46}
                paragraph="Personal Data: any information relating to an identified or identifiable natural person (Data Subject); an identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier, or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural, or social identity of that natural person. 
"
              />

              <ListItem
                bullet="(h)"
                indent={46}
                paragraph="Process, Processes, Processed or Processing: any operation or set of operations which is performed on Personal Data or on sets of Personal Data, whether or not by automated means, such as collection, recording, organization, structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction, erasure, or destruction of data. 
"
              />

              <ListItem
                bullet="(i)"
                indent={46}
                paragraph="Processing Purposes: the purposes for which Personal Data are processed, as described in Annex A.
"
              />

              <ListItem
                bullet="(j)"
                indent={46}
                paragraph="Services Agreement: has the meaning given to it in Recital (B).
"
              />

              <ListItem
                bullet="(k)"
                indent={46}
                paragraph="Sub-Processors: those who process (part of) the Personal Data on behalf of the Data Processor. 
"
              />

              <ListItem
                bullet="(l)"
                indent={46}
                paragraph="Supervisory Authority: an independent public authority responsible for monitoring compliance with the law in relation to the Processing of Personal Data. In the Netherlands, this is the Dutch Data Protection Authority (Autoriteit Persoonsgegevens).
"
              />

              <Heading size="h3">2. Scope</Heading>

              <Paragraph>
                During the term of the Services Agreement, Saber will process
                Personal Data on behalf of Customer and in accordance with
                applicable laws and regulations. The relevant Personal Data
                Processed under this DPA are described in Annex A. Saber
                Processes the Personal Data solely for the specified purpose or
                purposes of the Processing (the Processing Purposes), as
                described in Annex A, unless further written instructions are
                provided by Customer.
              </Paragraph>

              <Heading size="h3">3. Nature of processing</Heading>

              <ListItem
                bullet="3.1"
                paragraph="Saber Processes the Personal Data solely on behalf of Customer and based on Customer’s instructions. Saber Processes the Personal Data only to the extent necessary for the performance of the Services Agreement and in accordance with the documented instructions of Customer. Customer may reasonably provide additional or different instructions in writing. Saber will follow all instructions from Customer regarding the Processing of Personal Data. Saber will immediately notify Customer if, in its opinion, an instruction is in violation of applicable laws and regulations concerning the Processing of Personal Data.
"
              />

              <ListItem
                bullet="3.2"
                paragraph="If Saber determines the purpose and means for the Processing of Personal Data, Saber will be considered a Data Controller for those Processing activities.
"
              />

              <ListItem
                bullet="3.3"
                paragraph="Without prejudice to any other contractual confidentiality obligation binding on Saber, Saber guarantees that all Personal Data will be treated as strictly confidential. In this regard, Saber will inform all its employees, representatives, and subcontractors (Sub-Processors, see Section 7) involved in this Processing of this confidentiality requirement and ensure that they will act accordingly. 
"
              />

              <Heading size="h3">4. Security of Personal Data</Heading>

              <ListItem
                bullet="4.1"
                paragraph="Taking into account the state of the art, the costs of implementation and the nature, scope, context and purposes of Processing as well as the risk and severity for the rights and freedoms of natural persons, Saber shall implement appropriate technical and organizational measures to ensure a level of security appropriate to that risk, including, as appropriate, the measures referred to in Article 32(1) of the GDPR. An overview of the security measures is included in Annex B.
"
              />

              <ListItem
                bullet="4.2"
                paragraph="In assessing the appropriate level of security, Saber shall take account of the risks that are presented by Processing, in particular from a Data Breach.
"
              />

              <ListItem
                bullet="4.3"
                paragraph="Saber will monitor security breaches and maintain a record of any security incidents.
"
              />

              <ListItem
                bullet="4.4"
                paragraph="In the event of a Data Breach, whether actual, potential, or suspected, Saber will notify Customer as soon as possible, but no later than 36 hours after Saber becomes aware of the breach. The notification will include all relevant information regarding the nature of the incident, the affected Personal Data, and any measures taken or to be taken to mitigate the consequences.  
"
              />

              <ListItem
                bullet="4.5"
                paragraph="Saber will always investigate any Data Breach, determine an appropriate response, and take necessary measures, including potentially informing the Supervisory Authority and the Data Subjects.
"
              />

              <Heading size="h3">5. Audit</Heading>

              <Paragraph>
                Saber makes available to Customer all information necessary to
                demonstrate compliance with the obligations laid down in Article
                28 of the GDPR and allow for and contribute to audits, including
                inspections, conducted by the Data Controller or another auditor
                mandated by the Data Controller.
              </Paragraph>
              <Heading size="h3">6. Data Transfers</Heading>

              <Paragraph>
                Saber will not transfer Personal Data to countries outside the
                EU and/or the European Economic Area (EEA) without the prior
                written consent of Customer. If Personal Data Processed under
                this Agreement is transferred from a country within the European
                Economic Area to a country outside the European Economic Area,
                the Parties shall ensure that the Personal Data is adequately
                protected. To achieve this, the Parties shall, unless agreed
                otherwise, rely on EU approved standard contractual clauses for
                the transfer of personal data.
              </Paragraph>
              <Heading size="h3">7. Sub-processors</Heading>

              <Paragraph>
                Saber shall only grant Sub-Processors access to Personal Data
                with prior written consent from Customer, which consent shall
                not be unreasonably withheld. Saber confirms that a
                Sub-Processor shall be held to the same conditions as outlined
                in this DPA.
              </Paragraph>
              <Heading size="h3">8. Data Subject Rights</Heading>

              <Paragraph>
                When Customer receives a request from a Data Subject regarding
                the exercise of their rights under Chapter III of the GDPR,
                Saber will promptly cooperate.
              </Paragraph>

              <Heading size="h3">9. Liability</Heading>

              <ListItem
                bullet="9.1"
                paragraph="Saber shall not be liable for any indirect, consequential, special, incidental, or punitive damages, including but not limited to loss of profits, business interruption, loss of data, or loss of goodwill, arising out of or in connection with this DPA.
"
              />

              <ListItem
                bullet="9.2"
                paragraph="Customer shall indemnify Saber against all claims, fines, and/or measures from third parties, including Data Subjects and the Supervisory Authority, brought against or imposed upon Saber due to a breach of the GDPR and/or other applicable laws and regulations concerning the Processing of Personal Data by Saber and/or Sub-Processors engaged by Saber, except for when Saber (or any Sub-Processor engaged by Saber) has materially breached the provisions of this DPA or provisions of the GDPR directly applicable to Saber as Processor.
"
              />
              <Heading size="h3">10. Term</Heading>

              <ListItem
                bullet="10.1"
                paragraph="The term of the DPA shall be the same as the duration of the Services Agreement and begins upon signing. The DPA cannot be terminated independently from the Services Agreement. The DPA shall terminate automatically upon the termination of the Services Agreement.
"
              />

              <ListItem
                bullet="10.2"
                paragraph="Within two (2) months after the termination of the Services Agreement, Saber shall destroy or return all Personal Data and/or transfer it to Customer and/or another party designated by Customer, as chosen by Customer. All existing (other) copies of Personal Data, whether with Sub-Processors engaged by Saber or not, will be demonstrably permanently deleted or made inaccessible, unless storage of the Personal Data is legally required. Customer shall bear the costs for the destruction, return, and/or transfer of the Personal Data.
"
              />

              <ListItem
                bullet="10.3"
                paragraph="Obligations from the DPA that, by their nature, are intended to continue after the termination of the DPA, shall continue to apply after the termination of the DPA.
"
              />

              <Heading size="h3">
                11. Applicable law and dispute resolution
              </Heading>

              <ListItem
                bullet="11.1"
                paragraph="The DPA and its execution are governed by Dutch law. Any disputes arising between the Parties in connection with the DPA shall be submitted to the competent court of the District Court of Amsterdam.
"
              />

              <ListItem
                bullet="11.3"
                paragraph="This DPA is an integral part of the Services Agreement. Therefore, all rights and obligations from the Services Agreement also apply to the DPA.
"
              />

              <ListItem
                bullet="11.3"
                paragraph="Deviations from this DPA are only valid if agreed upon in writing by the Parties."
              />
            </LegalPage>
          </Container>
        </PageContent>
        <PageFooter />
      </Page>
    </>
  );
};

export default Dpa;
