import { HttpMethod } from "@saberapp/enums";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Button,
  Column,
  Heading,
  Paragraph,
  Row,
} from "@saberapp/react-component-library";
import {
  IconArrowUp,
  IconCubeTransparent,
  IconExclamationCircle,
  IconSparkles,
  IconWrapper,
  IconPencil,
} from "@saberapp/icons";
import { useApi } from "../../../hooks/useApi";
import { useData } from "../../../hooks/useData";

import { ReactComponent as IconConfluence } from "../../icons/connectors/confluence.svg";
import { ReactComponent as IconGoogleCalendar } from "../../icons/connectors/google-calendar.svg";
import { ReactComponent as IconGoogleDrive } from "../../icons/connectors/google-drive.svg";
import { ReactComponent as IconGoogleMail } from "../../icons/connectors/google-mail.svg";
import { ReactComponent as IconNotion } from "../../icons/connectors/notion.svg";
import { ReactComponent as IconPipedrive } from "../../icons/connectors/pipedrive.svg";
import { ReactComponent as IconSalesforce } from "../../icons/connectors/salesforce.svg";
import { ReactComponent as IconSlack } from "../../icons/connectors/slack.svg";
import { ReactComponent as BrandMark } from "../../icons/saber/brandmark.svg";

import { useNavigate } from "react-router-dom";
import Markdown from "react-markdown";
import dayjs from "dayjs";
import { Icon } from "./shared/components";
import ReferencesList from "./ReferencesList";
import Answer from "../answers/components/Answer";
import { useAuth } from "../../../hooks/useAuth";

const Item = styled.div`
  width: calc(100% - 440px);
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: space-between;

  position: relative;
`;

const ItemContent = styled.div`
  box-sizing: border-box;
  padding: 40px 48px 40px 42px;
  padding-bottom: 240px;
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const ItemAction = styled.div`
  width: 100%;
  padding: 20px 40px;
  box-sizing: border-box;

  position: absolute;
  bottom: 0;
  z-index: 1;
`;

const ItemActionBackground = styled.div`
  background: var(--main-bg);
  background: color-mix(in srgb, var(--main-bg) 50%, transparent);
  backdrop-filter: blur(5px);
  padding: 8px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const QuestionText = styled.div`
  font-size: 24px;
  color: var(--heading-text);
  font-weight: 600;
  line-height: 1.5;
`;

const TitlePill = styled.div`
  color: var(--heading-text);
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  ${({ color }) => color === "red" && `color: #fe4a49;`}
`;

const SmallLogoContainer = styled.div`
  display: flex;
  width: 20px;
  height: 20px;
  padding: 4px;
  border-radius: 6px;
  background: #fff;
  box-sizing: border-box;
  color: var(--heading-text);

  svg {
    width: 12px;
    height: 12px;
  }
`;

const BrandMarkContainer = styled(SmallLogoContainer)`
  background: #f4b60c;
  color: #0e1016;
`;

const AnswerParagraph = styled.div`
  color: var(--heading-text);
  margin: 0;
  // font-size: 16px;
  line-height: 1.5;

  p,
  ol,
  ul,
  li {
    margin: 0;
  }

  a {
    color: #0ca8f4;
  }
`;

const TextareaContainer = styled.div`
  box-sizing: border-box;
  //   border: 1px solid var(--card-border);
  //   background: var(--sidebar-bg);
  border-radius: 6px;
  padding: 16px;
  //   padding-right: 0;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
`;


const Textarea = styled.textarea`
  min-height: 100px;
  width: 100%;
  resize: none;
  color: var(--heading-text);
  outline: none;
  background: var(--main-bg);
  border: 1px solid var(--card-border);
  border-radius: 6px;
  box-sizing: border-box;
  padding: 16px;
`;

const EmptyState = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
`;

const EmptyDescription = styled.div`
  width: 360px;
  text-align: center;
`;

const Path = styled.div`
  position: relative;

  &:before {
    content: "";
    display: block;
    width: 2px;
    background: var(--card-border);
    position: absolute;
    top: 0;
    left: 9px;
    height: calc(100% + 24px);
    z-index: 0;
  }
`;

const Point = styled.div`
  width: 20px;
  height: 20px;
  background: var(--card-border);
  border-radius: 50%;
  z-index: 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ color }) =>
    color === "red" &&
    `
    background: #fe4a49;
    color: #fff0f0;
  `};

  svg {
    width: 12px;
  }
`;

const PointContent = styled.div`
  padding-bottom: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const PathSection = styled.div`
  display: flex;
  gap: 16px;
  padding-bottom: 24px;

  &:last-child div:before {
    display: none;
  }
`;

const Details = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: space-between;

  div {
    display: flex;
    align-items: center;
    gap: 4px;
  }
`;

const SmallText = styled.div`
  font-size: 11px;
  width: 280px;
  display: flex;
  align-items: center;
  line-height: 1.3;
`;

const QueueItem = ({ item, snippet, setSnippet }) => {
  const navigate = useNavigate();
  const [showTextarea, setShowTextarea] = useState(false);
  const [isAddingSnippet, setIsAddingSnippet] = useState(false);
  const [isRegeneratingAnswer, setIsRegeneratingAnswer] = useState(false);
  const [refs, setRefs] = useState([]);
  const [retryRefs, setRetryRefs] = useState([]);
  const { API } = useApi();
  const { organization } = useAuth();
  const { updateDataSet } = useData();

  const reasons = {
    incomplete: "Answer provided was flagged as incomplete",
    incorrect: "Answer provided was flagged as incorrect",
    irrelevant: "Answer provided was flagged as irrelevant",
    outdated: "Answer provided was flagged as not up to date",
    custom: "Answer generated was wrong",
  };

  const logos = {
    google_drive: <IconGoogleDrive />,
    google_calendar: <IconGoogleCalendar />,
    salesforce: <IconSalesforce />,
    pipedrive: <IconPipedrive />,
    slack: <IconSlack />,
    notion: <IconNotion />,
    gmail: <IconGoogleMail />,
    confluence: <IconConfluence />,
    webapp: <BrandMark />,
  };

  function determineRefsForQuestion(questionRef1, callback) {
    API.call(
      HttpMethod.POST,
      "/resources/refs",
      {
        refIds: questionRef1.resources.map(({ resourceId }) => resourceId),
      },
      callback
    );
  }

  useEffect(() => {
    if (!item) {
      return;
    }
    setShowTextarea(item.type === "UserQuestion");
    if (item.type === "FeedbackAnalytics") {
      determineRefsForQuestion(item.questionData.questionRef, (data) =>
        setRefs(data)
      );
      if (item.questionData.retries.length > 0) {
        determineRefsForQuestion(
          item.questionData.retries[item.questionData.retries.length - 1],
          (data) => setRetryRefs(data)
        );
      }
    }
  }, [item]);

  const submitSnippet = async (question, questionRef, content) => {
    if (!isAddingSnippet) {
      setIsAddingSnippet(true);

      const _snippetRef = item.questionData.snippetRef;
      const method = _snippetRef ? HttpMethod.PUT : HttpMethod.POST;
      const url = _snippetRef
        ? `/saber-snippet/${_snippetRef._id || _snippetRef}`
        : "/saber-snippet";
      await API.call(
        method,
        url,
        { question, questionRef, content, taskId: item._id },
        (data) => {
          console.log(data);
          setIsAddingSnippet(false);
          updateDataSet('Answers', 'ADD', data.saberSnippet);

          item.questionData.snippetRef = data.saberSnippet;
        }
      );
    }
  };

  const regenerateAnswer = async (question, questionRef) => {
    setIsRegeneratingAnswer(true);
    await API.call(
      HttpMethod.PUT,
      "/tasks/regenerate-answer",
      { question, questionRef: questionRef._id, taskId: item._id },
      ({ _task, newUserQuestion }) => {
        item.questionData.retries.push(newUserQuestion);
        determineRefsForQuestion(newUserQuestion, (data) => setRetryRefs(data));
        setIsRegeneratingAnswer(false);
      }
    );
  };

  if (item === null) {
    return getEmptyState();
  }

  const { userName, createdAt, assignedUser } = item;
  const { isThumbsUp, feedbackReason } = item.event;
  const { questionRef, snippetRef, retries } = item.questionData;
  const { question, confidenceScore, answer, resources, source, category } =
    questionRef;

  const latestRetry = retries.length > 0 ? retries[retries.length - 1] : null; // todo all retries are currently populated, inefficient

  return (
    <Item>
      <ItemContent>
        <PathSection>
          <Path>
            <Point></Point>
          </Path>
          <PointContent>
            <Details>
              <div>
                {userName}, {dayjs(createdAt).fromNow()}
              </div>
              <div>
                via{" "}
                <IconWrapper size={20}>
                  {source === "webapp" ? (
                    <BrandMarkContainer>{logos["webapp"]}</BrandMarkContainer>
                  ) : (
                    <SmallLogoContainer>{logos["slack"]}</SmallLogoContainer>
                  )}
                </IconWrapper>
                {" | "}
                {category}
              </div>
            </Details>
            <QuestionText>{question}</QuestionText>
          </PointContent>
        </PathSection>

        {item.type !== "FeedbackAnalytics" && (
          <PathSection>
            <Path>
              <Point color="red">
                <IconExclamationCircle />
              </Point>
            </Path>
            <PointContent>
              <TitlePill color="red">
                Saber could not generate an answer to this question
              </TitlePill>
            </PointContent>
          </PathSection>
        )}

        {item.type === "FeedbackAnalytics" &&
          getElement(
            answer,
            refs,
            navigate,
            logos,
            isThumbsUp,
            reasons,
            feedbackReason,
            userName
          )}

        {latestRetry && (
          <>
            <PathSection>
              <Path>
                <Point></Point>
              </Path>
              <PointContent>
                <Details>
                  <div>
                    {"Regenerated response"},{" "}
                    {dayjs(latestRetry.createdAt).fromNow()}
                  </div>
                </Details>

                <AnswerParagraph>
                  <Markdown>{latestRetry.answer}</Markdown>
                  {/* {latestRetry.answer} */}
                </AnswerParagraph>

                <ReferencesList refs={retryRefs} logos={logos} />
              </PointContent>
            </PathSection>
          </>
        )}
      </ItemContent>
      <ItemAction>
        <ItemActionBackground>
          {snippetRef && snippetRef.answer ? (
            <>
              <Answer answer={snippetRef} showQuestion={false} />

              <TextareaContainer>
                <SmallText>
                  The answer above will be used to generate future responses to
                  this question
                </SmallText>
                <ButtonContainer>
                  <Button
                    type="ui"
                    size="small"
                    title={
                      isRegeneratingAnswer
                        ? "Regenerating Response..."
                        : "Regenerate Response"
                    }
                    iconBefore={<IconSparkles />}
                    onClick={() => regenerateAnswer(question, questionRef)}
                    disabled={isRegeneratingAnswer}
                  />
                  <Button
                    type="ui"
                    size="small"
                    iconBefore={<IconPencil />}
                    onClick={() => {
                      navigate(
                        `/${organization.slug}/answers/${snippetRef._id}`
                      );
                    }}
                  >
                    Edit Answer
                  </Button>
                </ButtonContainer>
              </TextareaContainer>
            </>
          ) : (
            <>
              {showTextarea && (
                <Textarea
                  placeholder="Answer this question..."
                  onChange={(e) => setSnippet(e.target.value)}
                />
              )}

              <TextareaContainer>
                <SmallText>
                  {snippet.length === 0 && showTextarea
                    ? `The answer you will provide above will be used to generate future responses to
                  this question`
                    : showTextarea
                    ? `Hit the save button to save your answer`
                    : `Mark this task as resolved or choose to add a more specific answer to this question`}
                </SmallText>
                <ButtonContainer>
                  {/* <Button
                    type="ui"
                    size="small"
                    title={
                      isRegeneratingAnswer
                        ? "Regenerating Response..."
                        : "Regenerate Response"
                    }
                    iconBefore={<IconSparkles />}
                    onClick={() => regenerateAnswer(question, questionRef)}
                    disabled={isRegeneratingAnswer}
                  /> */}
                  <Button
                    type={showTextarea ? "primary" : "ui"}
                    size="small"
                    iconBefore={<IconArrowUp />}
                    title={
                      isAddingSnippet
                        ? "Saving answer..."
                        : showTextarea
                        ? "Save Answer"
                        : "Add an Answer"
                    }
                    disabled={
                      isAddingSnippet || (snippet.length < 1 && showTextarea)
                    }
                    onClick={() =>
                      showTextarea
                        ? submitSnippet(question, questionRef, snippet)
                        : setShowTextarea(true)
                    }
                  />
                </ButtonContainer>
              </TextareaContainer>
            </>
          )}
        </ItemActionBackground>
      </ItemAction>
    </Item>
  );
};

function getElement(
  answer,
  refs,
  logos,
  isThumbsUp,
  reasons,
  reason,
  userName
) {
  return (
    <>
      <PathSection>
        <Path>
          <Point></Point>
        </Path>
        <PointContent>
          <Details>Saber generated the following answer:</Details>
          <AnswerParagraph>{answer}</AnswerParagraph>
          <ReferencesList refs={refs} logos={logos} />
        </PointContent>
      </PathSection>

      {isThumbsUp && (
        <PathSection>
          <Path>
            <Point></Point>
          </Path>
          <PointContent>
            <TitlePill color="green">
              {" "}
              This answer was liked
              <div>👍</div>
            </TitlePill>
          </PointContent>
        </PathSection>
      )}

      {!isThumbsUp && (
        <PathSection>
          <Path>
            <Point color="red">
              <IconExclamationCircle />
            </Point>
          </Path>
          <PointContent>
            <TitlePill color="red">
              {answer
                ? reasons[reason]
                  ? reasons[reason]
                  : `${userName} provided negative feedback with a custom note: ${reason}`
                : "No answer was generated"}
            </TitlePill>
          </PointContent>
        </PathSection>
      )}
    </>
  );
}

function getEmptyState() {
  return (
    <Item>
      <EmptyState>
        <EmptyDescription>
          <Heading size="h3">Inbox</Heading>
          <Paragraph>
            Reactions to answers, tasks to help you keep your content up to date
            and more will show up here. Select an item on the left to get
            started.
          </Paragraph>
        </EmptyDescription>
        <Column>
          <Row alignment="center">
            <Icon color={`#D1F0DF`}>
              <div>👍</div>
            </Icon>
            Positive Feedback
          </Row>
          <Row alignment="center">
            <Icon color={`#fff0f0`}>
              <div>👎</div>
            </Icon>
            Negative Feedback
          </Row>
          <Row alignment="center">
            <Icon>
              <IconCubeTransparent />
            </Icon>
            Content Gap
          </Row>
        </Column>
      </EmptyState>
    </Item>
  );
}

export default QueueItem;
