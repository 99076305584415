import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { styled } from "styled-components";
import {
  Avatar,
  Card,
  Heading,
  Paragraph,
} from "@saberapp/react-component-library";
import { IconWrapper } from "@saberapp/icons";

import { ReactComponent as BrandMark } from "../../../icons/saber/brandmark.svg";
import { ReactComponent as IconGoogleDrive } from "../../../icons/connectors/google-drive.svg";
import { ReactComponent as IconGoogleMail } from "../../../icons/connectors/google-mail.svg";
import { ReactComponent as IconGoogleCalendar } from "../../../icons/connectors/google-calendar.svg";
import { ReactComponent as IconSlack } from "../../../icons/connectors/slack.svg";
import { ReactComponent as IconSalesforce } from "../../../icons/connectors/salesforce.svg";
import { ReactComponent as IconPipedrive } from "../../../icons/connectors/pipedrive.svg";
import { ReactComponent as IconNotion } from "../../../icons/connectors/notion.svg";

import { useAuth } from "../../../../hooks/useAuth";
import { useApi } from "../../../../hooks/useApi";
import { HttpMethod } from "@saberapp/enums";
import EmptyState from "../../../layouts/EmptyState";
import LoadingState from "../../../layouts/LoadingState";
import { useData } from "../../../../hooks/useData";

const FeedContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 8px;
`;

const FeedList = styled.div`
  margin-top: 8px;
  height: 100%;

  & > div {
    height: 100%;
    box-sizing: border-box;
    max-height: calc(100vh - 180px);
  }
`;

const Table = styled.table`
  width: 100%;
  border-spacing: 0;
`;
const Tr = styled.tr`
  &:nth-child(even) {
    background: var(--card-border);
  }
`;
const Td = styled.td`
  padding: 8px;
`;
const TdCentered = styled.td`
  padding: 8px;
  text-align: center;
`;
const TdRight = styled.td`
  padding: 8px;
  text-align: right;
`;

const SmallLogoContainer = styled.div`
  display: flex;
  width: 20px;
  height: 20px;
  padding: 4px;
  border-radius: 6px;
  background: #fff;
  box-sizing: border-box;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f4b60c;
  border-radius: 6px;
  width: 12px;
  height: 12px;
  padding: 4px;
  color: #0e1016;

  svg {
    width: 10px;
  }
`;

const EntityContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const actionTitles = {
  Q: "Asked a question",
  AN: "Provided an answer",
  F: "Provided feedback",
};

const Feed = () => {
  // const { API } = useApi();

  const { events } = useData();

  const logos = {
    saber: <BrandMark />,
    google_drive: <IconGoogleDrive />,
    google_calendar: <IconGoogleCalendar />,
    salesforce: <IconSalesforce />,
    pipedrive: <IconPipedrive />,
    slack: <IconSlack />,
    notion: <IconNotion />,
    gmail: <IconGoogleMail />,
  };

  // const [eventsLoaded, setEventsLoaded] = useState(false);
  // const [events, setEvents] = useState([]);

  // useEffect(() => {
  //   if (!eventsLoaded && API.isReady) {
  //     getEvents();
  //   }
  // },[API.isReady]);

  // const getEvents = async () => {
  //   await API.call(
  //     HttpMethod.GET,
  //     `/audit-log/`,
  //     {
  //       fields: "summary",
  //     },
  //     (data) => {
  //       setEvents(data);
  //       setEventsLoaded(true);
  //     }
  //   );
  // };

  return (
    <FeedContainer>
      <Heading size="h4">Activity Feed</Heading>
      {events.length > 0 && (
        <Paragraph>
          Feed of all the seller, system and connector activities happening
          across Saber
        </Paragraph>
      )}
      <FeedList>
        <Card padding={16}>
          {/* <FeedStats>
            <FeedTotal>
              <Heading size="h4">Interactions Today</Heading>
              <Heading size="h1">50</Heading>
            </FeedTotal>
            <FeedChart>
              <Heading size="h4">Daily Interactions</Heading>
            </FeedChart>
          </FeedStats> */}

          {events.length > 0 && (
            <Table>
              <tbody>
                <Tr>
                  <Td>
                    <Heading size="h4">Who</Heading>
                  </Td>
                  <Td>
                    <Heading size="h4">Action</Heading>
                  </Td>
                  <Td>
                    <Heading size="h4">Target</Heading>
                  </Td>
                  <Td>
                    <Heading size="h4"></Heading>
                  </Td>
                </Tr>
                {events.map((event, i) => (
                  <Tr key={i}>
                    <Td>
                      <EntityContainer>
                        <IconWrapper size={20}>
                          {event.actor.actorType === "user" ||
                          event.actor.actorType === "slackUser" ? (
                            <>
                            {event.actor.id && <Avatar url={event.actor.id.avatar} />}
                            </>
                          ) : (
                            <>
                              {event.actor.actorType === "system" ? (
                                <LogoContainer>
                                  <BrandMark />
                                </LogoContainer>
                              ) : (
                                <SmallLogoContainer>
                                  {logos[event.actor.slug]}
                                </SmallLogoContainer>
                              )}
                            </>
                          )}
                        </IconWrapper>
                        {event.actor.actorType === "user" ||
                        event.actor.actorType === "slackUser"
                          ? event.actor?.id?.name || "Unknown User"
                          : event.actor.info}
                      </EntityContainer>
                    </Td>
                    <Td>
                      <Heading size="h4">{actionTitles[event.action]}</Heading>
                    </Td>
                    <Td>
                      <EntityContainer>
                        <IconWrapper size={20}>
                          {event.target.targetType === "user" ||
                          event.target.targetType === "slackUser" ? (
                            <>
                              {event.target.id && (
                                <Avatar url={event.target.id.avatar} />
                              )}
                            </>
                          ) : (
                            <>
                              {event.target.targetType === "system" ? (
                                <LogoContainer>
                                  <BrandMark />
                                </LogoContainer>
                              ) : (
                                <SmallLogoContainer>
                                  {logos[event.target.slug]}
                                </SmallLogoContainer>
                              )}
                            </>
                          )}
                        </IconWrapper>
                        {event.target.id?.name || event.target.info || "Unknown User"}
                      </EntityContainer>
                    </Td>
                    <TdRight>{dayjs(event.createdAt).fromNow()}</TdRight>
                  </Tr>
                ))}
              </tbody>
            </Table>
          )}

          {events.length === 0 && (
            <EmptyState text="Saber activities will show up here, like who is asking questions and updating content" />
          )}
        </Card>
      </FeedList>
    </FeedContainer>
  );
};

export default Feed;
