import React from "react";
import DashboardTemplate from "../templates/DashboardTemplate";

import { styled } from "styled-components";

const FlexContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

const ComingSoon = () => {
  return (
    <DashboardTemplate>
      <FlexContainer>Coming soon</FlexContainer>
    </DashboardTemplate>
  );
};

export default ComingSoon;
