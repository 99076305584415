import React, { useContext } from "react";
import styled from "styled-components";

// Component Library
import {
  Heading,
  Paragraph,
  DropdownButton, 
  DropdownLinkWrapper
} from "@saberapp/react-component-library";

// Hooks
import { Resources } from "../../../../hooks/useResources";

import { ReactComponent as IconGroups } from "../../../icons/20/solid/square-3-stack-3d.svg";
import { ReactComponent as IconArrowDown } from "../../../icons/20/solid/chevron-down.svg";

const DropdownLayout = styled.div`
  padding: 4px;
`;

const GroupOptionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding: 8px;
  border-radius: 4px;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
`;
const GroupIconWrapper = styled.div`
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 16px;
  }
`;
const GroupDescContainer = styled.div`
  white-space: nowrap;
  overflow: auto;
  padding-right: 16px;

  p {
    text-overflow: ellipsis;
    overflow: auto;
    white-space: nowrap;
  }
`;

const GroupOptionsDropdown = ({ page }) => {
  const { isGroupedBy, groupOptions, handleGroupingChange } =
    useContext(Resources);

  return (
    <DropdownButton
      type="ui"
      position="bottom-left"
      // iconBefore={<IconGroups />}
      iconAfter={<IconArrowDown />}
      // noborder={"true"}
      title={`Grouped by ${isGroupedBy}`}
    >
      <DropdownLayout>
        {groupOptions.map((group, i) => (
          <DropdownLinkWrapper
            onClick={() => {
              handleGroupingChange(group, [], page);
            }}
            key={i}
          >
            <GroupOptionContainer>
              {/* <GroupIconWrapper>{group.icon}</GroupIconWrapper> */}
              <GroupDescContainer>
                <Heading size="h4">{group.name}</Heading>
              </GroupDescContainer>
            </GroupOptionContainer>
          </DropdownLinkWrapper>
        ))}
      </DropdownLayout>
    </DropdownButton>
  );
};

export default GroupOptionsDropdown;
