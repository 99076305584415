import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";

// Hooks
import { Resources } from "../../../../hooks/useResources";
// import { useTags } from "../../../../hooks/_useTags";

// Component Library
import {
  Loader,
  Tag,
  Button,
  TagPicker,
  Accordian,
} from "@saberapp/react-component-library";

import { EditHeader } from "./components/EditHeader";
import { EditFooter } from "./components/EditFooter";

const ManageTagsContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const LoadOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  padding: 22px;
  padding-bottom: 0;
  gap: 16px;
`;

const TagsList = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;

const ModalHeading = styled.h5`
  font-weight: 500;
  font-size: 12px;
  color: var(--body-text);
  margin: 0;
`;

const Banner = styled.div``;

const EditTags = ({ closeModal, resourceToEdit }) => {
  // const { API } = useApi();
  // const { tags } = useTags();
  const [tags, setTags] = useState([]);
  const { handleUpdate } = useContext(Resources);

  // Loading State
  const [isLoading, setLoading] = useState(false);

  // Resource State
  const [resources, setResources] = useState([]);

  // Tags states
  const [selectedTags, setSelectedTags] = useState([]);
  const [commonTags, setCommonTags] = useState([]);
  const [otherTags, setOtherTags] = useState([]);

  //   const [matchedGeneratedTags, setMatchedGeneratedTags] = useState(null);
  //   const [newSuggestedTags, setNewSuggestedTags] = useState(null);
  //   const [approvedTagsLocal, setApprovedTagsLocal] = useState([]);

  useEffect(() => {
    Array.isArray(resourceToEdit)
      ? setResources(resourceToEdit)
      : setResources([resourceToEdit]);
  }, []);

  // Set the tags when the resources are loaded
  useEffect(() => {
    if (resources.length === 1) {
      setCommonTags(resources[0].tags?.assigned);
      setSelectedTags(resources[0].tags?.assigned);
    } else if (resources.length > 1) {
      const { common, other } = findCommonTags(resources);
      setSelectedTags(common);
      setCommonTags(common);
      setOtherTags(other);
    }
  }, [resources]);

  /**
   * Finds the tags all the selected resources have in common, and the ones they do not
   * @param {*} resources
   * @returns
   */
  const findCommonTags = (resources) => {
    const common = [];
    let other = [];
    const tagCounts = {};

    resources.forEach((resource) => {
      resource.tags?.assigned.forEach((tag) => {
        tagCounts[tag.name] = (tagCounts[tag.name] || 0) + 1;

        if (tagCounts[tag.name] === resources.length && !common.includes(tag)) {
          common.push(tag);
          if (other.some((otherTag) => otherTag._id === tag._id)) {
            other = other.filter((otherTag) => otherTag._id !== tag._id);
          }
        } else if (!other.some((otherTag) => otherTag._id === tag._id)) {
          other.push(tag);
        }
      });
    });

    return { common, other };
  };

  // Used to also be able to add tags to the selected tags from outside of the TagPicker
  const handleTagSelection = (tag) => {
    setSelectedTags((currentTags) => {
      return !currentTags.some((currentTag) => currentTag._id === tag._id)
        ? [...currentTags, tag]
        : currentTags.filter((currntTag) => currntTag._id !== tag._id);
    });
  };


  return (
    <ManageTagsContainer>
      {isLoading && (
        <LoadOverlay>
          <Loader></Loader>
        </LoadOverlay>
      )}

      <EditHeader resources={resources} closeModal={closeModal} />

      <Section>
        <ModalHeading>
          {resources.length > 1
            ? commonTags.length > 0
              ? `Tags these ${resources.length} docs have in common`
              : `Tags to apply to all ${resources.length} docs`
            : "Current Tags"}
        </ModalHeading>
        <TagsList>
          <TagPicker
            availableTags={tags}
            currentTags={selectedTags}
            handleTagSelection={handleTagSelection}
            showSuggestions={false}
            showPrefix={true}
          />
        </TagsList>
      </Section>

      {resources.length > 1 && otherTags?.length > 0 && (
        <Accordian title="Other Tags used for these docs">
          <TagsList>
            {otherTags.map((tag) => {
              console.log(resources[0].tags);
              return (
                <Tag
                  title={tag.name}
                  maxWidth="auto"
                  onClick={() => {
                    handleTagSelection(tag);
                  }}
                />
              );
            })}
          </TagsList>
        </Accordian>
      )}

      {resources.length === 1 && resources[0].tags?.suggested.length > 0 && (
        <Accordian title="Suggested Tags">
          <TagsList>
            {resources[0].tags.suggested.map((tag) => {
              return (
                <Tag
                  title={tag.name}
                  maxWidth="auto"
                  onClick={() => {
                    handleTagSelection(tag);
                  }}
                />
              );
            })}
          </TagsList>
        </Accordian>
      )}

      <EditFooter
        closeModal={closeModal}
        onSave={(e) => {
          setLoading(true);
          const resourceIds = resources.map((resource) => resource._id);
          const selectedTagIds = selectedTags.map((tag) => tag._id);
          handleUpdate(
            resourceIds,
            {
              tags: selectedTagIds,
            },
            (updatedResource) => {
              setLoading(false);
              closeModal(e);
              return { tags: { assigned: updatedResource.tags.assigned } };
            }
          );
        }}
      />

    </ManageTagsContainer>
  );
};

export default EditTags;
