import React from "react";
import styled from "styled-components";

// Component Library
import {
  FreshnessScore,
  Row,
  Heading,
  Paragraph,
  Pill
} from "@saberapp/react-component-library";

const StyledRow = styled(Row)`
align-items: center;
gap: 8px;
`

// Enums
import { FreshnessScoreValues } from "@saberapp/enums";

const FreshnessHeader = ({ groupKey, count }) => {
  let heading;
  let description;
  switch (groupKey) {
    case FreshnessScoreValues.VERIFIED:
      heading = "Good to go";
      description = `These docs were recently reviewed`;
      break;
    case FreshnessScoreValues.NEEDS_REVIEW:
      heading = "Due a Review";
      description =
        "These docs are due for a review";
      break;
    case FreshnessScoreValues.OUTDATED:
      heading = "Outdated";
      description =
        "These docs have not been reviewed for a long time and may be misinforming your team";
      break;
    default:
      heading = "Unknown Freshness";
      description =
        "It's unclear how fresh these docs are, mark them as reviewed or flag them if they need a second look";
      break;
  }
  return (
    <>
      <StyledRow>
        <FreshnessScore score={groupKey} />
        <Heading size="h3">{heading}</Heading>
      </StyledRow>
      {/* <Paragraph>{description}</Paragraph> */}
      <Pill title={count} />
      {description}
      </>
  );
};

export default FreshnessHeader;
