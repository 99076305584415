import React from "react";
import styled from "styled-components";

const IconContainer = styled.div`
  color: var(${(props) => props.color});
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  display: flex;
  align-items: center;
  justify-content: center;

  & > svg {
    width: ${(props) => props.size}px;
  }
`;

const IconWrapper = ({ children, size = 12, color = "--bg-color-4" }) => {
  return <IconContainer size={size} color={color}>{children}</IconContainer>;
};

export default IconWrapper;
