    import { HttpMethod } from '@saberapp/enums';
import { Button, Card, Checkbox, Heading, Input, Paragraph } from '@saberapp/react-component-library';
import React, { useEffect, useState } from 'react';
import { styled } from 'styled-components';
import { useApi } from '../../../../hooks/useApi';

const CardContent = styled.div`
  height: 400px; 
  width: 300px;
  display: flex;
  flex-direction: column;
`;

const FolderList = styled.ul`
  list-style: none;
  width: 280px;
  height: 240px;
  padding-left: 4px;
  overflow-y: auto; 
  overflow-x: hidden;
  flex-grow: 1;
`;

const FolderItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-bottom: 0;
`;

const FolderActions = styled.div`
  button {
    margin-left: 10px;
    padding: 0;
    font-size: 16px;
    border: none;
    background: none;
    box-shadow: none;

    &:hover {
      box-shadow: var(--soft-shadow-2) 0 2px 4px;
    }
  }
`;

const Footer = styled.div`
`;

const FolderPicker = ({onSelectFolder}) => {
    const {API} = useApi();
    const [folders, setFolders] = useState([]);
    const [folderPath, setFolderPath] = useState([]);
    const [selectedFolders, setSelectedFolders] = useState([]);
    const [nextPageToken, setNextPageToken] = useState(null);
    const [newFolderName, setNewFolderName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    function setFolderSelection(folders) {
        setSelectedFolders(folders);
        onSelectFolder(folders);
    }

    useEffect(() => {
        fetchFolders();
    }, []);

    const fetchFolders = async (parentId = null, pageToken = null) => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await API.callPromise(HttpMethod.GET, '/connectors/google-drive/folders', {
                parentId,
                pageToken
            });

            if (pageToken) {
                setFolders(prevFolders => [...prevFolders, ...response.data.files]);
            } else {
                setFolders(response.data.files);
            }
            setNextPageToken(response.data.nextPageToken);
            setIsLoading(false);
        } catch (error) {
            console.error('Failed to fetch folders', error);
            setError('Failed to fetch folders');
            setIsLoading(false);
        }
    };

    const loadMoreFolders = () => {
        if (nextPageToken) {
            fetchFolders(currentParentId, nextPageToken);
        }
    };

    const navigateToFolder = (folder) => {
        setFolderPath([...folderPath, folder]);
        fetchFolders(folder.id);
    };

    const navigateUp = () => {
        if (folderPath.length > 1) {
            const newPath = folderPath.slice(0, -1);
            setFolderPath(newPath);
            fetchFolders(newPath[newPath.length - 1].id);
        } else {
            setFolderPath([]);
            fetchFolders(null);
        }
    };

    const toggleFolderSelection = (folder) => {
        const isSelected = selectedFolders.find(f => f.id === folder.id);
        if (isSelected) {
            setFolderSelection(selectedFolders.filter(f => f.id !== folder.id));
        } else {
            setFolderSelection([...selectedFolders, folder]);
        }
    };
    const createFolder = async () => {
        if (!newFolderName) {
            return;
        }
        setIsLoading(true);
        try {
            const parentId = folderPath.length > 0 ? folderPath[folderPath.length - 1].id : null;
            const response = await API.callPromise(HttpMethod.POST, '/connectors/google-drive/createFolder', {
                folderName: newFolderName,
                parentId
            });
            setFolders([response.data, ...folders]);
            setNewFolderName('');
            setIsLoading(false);
        } catch (error) {
            console.error('Failed to create folder', error);
            setIsLoading(false);
        }
    };

    return (
        <Card padding={16}>
            <h3 style={{marginBottom: '4px', marginTop: 0}}>
                {folderPath.length > 0 ? `Folders in: ${folderPath[folderPath.length - 1].name}` : 'Root Folders'}
            </h3>
            <CardContent>
                {error && <Paragraph style={{ color: 'red' }}>{error}</Paragraph>}
                    <>
                        {folderPath.length > 0 && (
                            <Button type="ui" size="small" onClick={navigateUp}>Back to {folderPath.length > 1 ? folderPath[folderPath.length - 2].name : 'Root'}</Button>
                        )}
                        <FolderList>
                            {isLoading ? <Paragraph>Loading...</Paragraph>: (
                                folders.map(folder => (
                                    <FolderItem key={folder.id}>
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            <Checkbox
                                                checked={selectedFolders.some(selectedFolder => selectedFolder.id === folder.id)}
                                                onChange={(e) => {
                                                    e.stopPropagation(); // Prevent triggering folder navigation
                                                    toggleFolderSelection(folder);
                                                }}
                                            />
                                            <span style={{marginLeft: '8px'}}>{folder.name + `${folder.ownedByMe? '' : ' (Shared)'}`}</span>
                                        </div>
                                        <FolderActions>
                                            <Button type="ui" size="small" onClick={() => navigateToFolder(folder)}>
                                                <svg width="24" height="24" viewBox="0 0 24 24" focusable="false">
                                                    <path d="M7.59 18.59L9 20l8-8-8-8-1.41 1.41L14.17 12"></path>
                                                    <path d="M0 0h24v24H0V0z" fill="none"></path>
                                                </svg>
                                            </Button>
                                        </FolderActions>
                                    </FolderItem>
                                )))}
                        </FolderList>
                        {nextPageToken && <Button type="ui" onClick={loadMoreFolders}>Load More</Button>}
                    </>
                <Footer>
                    <Input
                        type="text"
                        placeholder="New folder name"
                        value={newFolderName}
                        onChange={e => setNewFolderName(e.target.value)}
                    />
                    <Button type="ui" size="small" onClick={createFolder}>Create Folder</Button>
                </Footer>
            </CardContent>
        </Card>
    );
};

export default FolderPicker;
