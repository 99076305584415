import React, { useEffect, useState } from "react";
import styled from "styled-components";

import {
  Button,
  DropdownButton,
  DropdownItem,
} from "@saberapp/react-component-library";

import { FreshnessScoreValues } from "@saberapp/enums";

import { IconChevronDown } from "@saberapp/icons";

import DashboardTemplate from "../../templates/DashboardTemplate";
import TopNavigation from "../../layouts/main-top-bar";
import { useAuth } from "../../../hooks/useAuth";
import { useData } from "../../../hooks/useData";
import { useTheme } from "@saberapp/hooks";
import Answer from "./components/Answer";
import FreshnessHeader from "../inventory/components/FreshnessHeader";
import EmptyState from "../inventory/components/EmptyState";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px;
`;

const Header = styled.div`
  padding: 12px 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  box-sizing: border-box;

  ${({ groupKey, mode }) => {
    if (groupKey === "OUTDATED" || groupKey === "PROCESSING_FAILED") {
      return mode === "light"
        ? `background: #ffd1d1;`
        : `background: var(--card-bg);
      border-left: 4px solid #cf1127;`;
    } else if (groupKey === "NEEDS_REVIEW" || groupKey === "NOT_SUPPORTED") {
      return mode === "light"
        ? `background: #ffead1;`
        : `background: var(--card-bg);
      border-left: 4px solid #f4b60c;`;
    } else {
      return `background: var(--card-bg);`;
    }
  }}
`;

const AnswersContainer = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
`;

const Options = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Showing = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: var(--button-ui-color);
  padding-right: 8px;
  white-space: nowrap;
`;

const SearchBar = styled.input`
  width: 320px;
  border: 0;
  background: var(--button-bg);
  padding: 8px 12px;
  border-radius: 50px;
  outline: none;
  color: var(--heading-text);
`;

const Answers = () => {
  const { organization } = useAuth();
  const { loading, answers } = useData();
  const { currentTheme } = useTheme();

  const [query, setQuery] = useState("");
  const [filteredAnswers, setFilteredAnswers] = useState([]);
  // const [sort, setSort] = useState({ by: "name", order: "ASC" });

  useEffect(() => {
    if (!loading) {
      const groupedAnswers = groupByProperty("freshnessScore", answers);
      setFilteredAnswers(groupedAnswers);
    }
  }, [loading, answers]);

  useEffect(() => {
    const lowerCaseQuery = query.toLowerCase();

    setFilteredAnswers(
      groupByProperty(
        "freshnessScore",
        answers.filter((answer) => {
          return (
            answer.name.toLowerCase().includes(lowerCaseQuery) ||
            answer?.answer?.toLowerCase().includes(lowerCaseQuery)
          );
        })
      )
    );
  }, [query]);

  const groupByProperty = (property, arr = filteredAnswers) => {
    const groupedAnswers = arr.reduce((result, obj) => {
      let key = obj[property] || FreshnessScoreValues.UNKNOWN;
      if (!result[key]) {
        result[key] = {
          answers: [],
        };
      }
      result[key].answers.push(obj);
      return result;
    }, {});

    // SORTING HAPPENS HERE
    // It only sorts by name, but can be expanded to sort by other properties
    // const sortedGroupedAnswers = {};
    // Object.keys(groupedAnswers).forEach((group) => {
    //   const answers = [...groupedAnswers[group].answers]; // Create a shallow copy of the resources array
    //   if (sort.order === "ASC") {
    //     answers.sort((a, b) => a.name.localeCompare(b.name));
    //   } else if (sort.order === "DESC") {
    //     answers.sort((a, b) => b.name.localeCompare(a.name));
    //   } else {
    //     console.error('Invalid sortOrder. Please use "ASC" or "DESC".');
    //     return null;
    //   }
    //   sortedGroupedAnswers[group] = { answers };
    // });

    // We want a specific order for the Freshness Score
    if (property === "freshnessScore") {
      const order = [
        FreshnessScoreValues.OUTDATED,
        FreshnessScoreValues.NEEDS_REVIEW,
        FreshnessScoreValues.UNKNOWN,
        FreshnessScoreValues.VERIFIED,
      ];
      let rearrangedGroupedAnswers = {};
      order.forEach((key) => {
        if (groupedAnswers.hasOwnProperty(key)) {
          rearrangedGroupedAnswers[key] = groupedAnswers[key];
        }
      });

      return rearrangedGroupedAnswers;
    }

    return rearrangedGroupedAnswers;
    // return sortedGroupedAnswers;
  };

  return (
    <DashboardTemplate>
      <TopNavigation
        links={[
          { title: "Content & Data", path: `/${organization.slug}/documents` },
          {
            title: "Questions & Answers",
            path: `/${organization.slug}/answers`,
          },
        ]}
      >
        <Options>
          <Showing>Showing {answers.length} docs</Showing>
          <SearchBar
            type="search"
            placeholder="Search..."
            onChange={(e) => {
              setQuery(e.target.value);
            }}
          />
          <DropdownButton
            type="ui"
            size="small"
            position="bottom-left"
            title="Grouped by Freshness Score"
            iconAfter={<IconChevronDown />}
          >
            <DropdownItem>Freshness Score</DropdownItem>
            <DropdownItem>Author</DropdownItem>
          </DropdownButton>
        </Options>
      </TopNavigation>
      <Container>
        {!loading && answers.length > 0 && (
          <>
            {Object.keys(filteredAnswers).map((groupKey, i) => (
              <>
                {filteredAnswers[groupKey]?.answers.length > 0 && (
                  <>
                    <Header
                      key={Math.random()}
                      groupKey={groupKey}
                      mode={currentTheme.type}
                    >
                      <FreshnessHeader
                        groupKey={groupKey}
                        count={filteredAnswers[groupKey].answers.length}
                      />
                    </Header>

                    <AnswersContainer key={Math.random()}>
                      {filteredAnswers[groupKey].answers.map((answer) => (
                        <Answer key={Math.random()} answer={answer} />
                      ))}
                    </AnswersContainer>
                  </>
                )}
              </>
            ))}
          </>
        )}

        {!loading && answers.length === 0 && <EmptyState page={"answers"} />}
      </Container>
    </DashboardTemplate>
  );
};

export default Answers;
