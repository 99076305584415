import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import { ReactComponent as BrandMark } from "../../../icons/saber/brandmark.svg";
import { ReactComponent as GdprLogo } from "../../../icons/saber/gdpr.svg";
import { ReactComponent as IsoLogo } from "../../../icons/saber/iso27001.svg";
import { ReactComponent as SocLogo } from "../../../icons/saber/soc2.svg";

const Footer = styled.div`
  max-width: 1000px;
  height: 136px;
  margin: 0 auto;
  padding: 32px 0;
  display: flex;
  gap: 16px;
`;

const FooterColumn = styled.div`
  width: 25%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const FooterHeader = styled.div`
  margin-bottom: 16px;
`;

const FooterLink = styled.a`
  color: var(--heading-text);
  cursor: pointer;
`;

const FooterSmallText = styled.div`
  font-size: 12px;
  line-height: 1.5;
`;

const Certification = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  svg {
    height: 40px;
    width: auto;
  }
`;

const Certificates = styled.div`
  display: flex;
  gap: 24px;
`;

const Label = styled.div`
  background: var(--button-ui-border);
  padding: 2px 8px;
  border-radius: 50px;
  font-size: 10px;
`;

const PageFooter = () => {
  const navigate = useNavigate();

  return (
    <Footer>
      <FooterColumn>
        <FooterHeader>Get in touch</FooterHeader>
        <FooterLink href="mailto:sales@saber.app">Contact us</FooterLink>
      </FooterColumn>
      <FooterColumn>
        <FooterHeader>Light reading</FooterHeader>
        <FooterLink
          onClick={(e) => {
            e.preventDefault();
            navigate("/privacy");
          }}
        >
          Privacy Policy
        </FooterLink>
        <FooterLink
          onClick={(e) => {
            e.preventDefault();
            navigate("/terms");
          }}
        >
          Terms of Service
        </FooterLink>
        <FooterLink
          onClick={(e) => {
            e.preventDefault();
            navigate("/dpa");
          }}
        >
          Data Processing Addendum
        </FooterLink>
      </FooterColumn>
      <FooterColumn>
        <FooterHeader>You're in good hands</FooterHeader>

        <Certificates>
          <Certification>
            <GdprLogo />
          </Certification>
          <Certification>
            <IsoLogo />
            <Label>Soon</Label>
          </Certification>
          <Certification>
            <SocLogo />
            <Label>Soon</Label>
          </Certification>
        </Certificates>
      </FooterColumn>
      <FooterColumn>
        <FooterHeader>🌍🌎🌏</FooterHeader>

        <div>&copy; 2024 Saber B.V.</div>
        <FooterSmallText>
          Carefully crafted by people from all over.
        </FooterSmallText>
      </FooterColumn>
    </Footer>
  );
};

export default PageFooter;
