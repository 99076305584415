import React from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";

import { ReactComponent as BrandMark } from "../icons/saber/brandmark.svg";

const BarContainer = styled.div`
  width: 80px;
  background-color: var(--heading-text);
  border-radius: 10px;
`;

const Filler = styled(motion.div)`
  background-color: var(--accent-color);
  height: 4px;
  border-radius: 8px;
`;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const LogoContainer = styled.div`
  width: 40px;
  height: 40px;
  color: var(--heading-text);

  svg {
    width: 100%;
    height: 100%;
  }
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;

const LoadingScreen = ({ progress }) => {
  return (
    <LoadingContainer>
      <AnimatePresence>
        <Box>
          <LogoContainer>
            <BrandMark />
          </LogoContainer>
          {/* {progress} loaded... */}
          <BarContainer>
            <Filler
              initial={{ width: 0 }}
              animate={{ width: `${progress}` }}
              transition={{ duration: 0.5 }}
            />
          </BarContainer>
        </Box>
      </AnimatePresence>
    </LoadingContainer>
  );
};

export default LoadingScreen;
