import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";

// Hooks
import { Resources } from "../../../../hooks/useResources";
// import { useTags } from "../../../../hooks/_useTags";

// Component Library
import { Loader, Select } from "@saberapp/react-component-library";

import { EditHeader } from "./components/EditHeader";
import { EditFooter } from "./components/EditFooter";

const ManageReviewersContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const LoadOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  padding: 22px;
  padding-bottom: 0;
  gap: 16px;
`;

const ModalHeading = styled.h5`
  font-weight: 500;
  font-size: 12px;
  color: var(--body-text);
  margin: 0;
`;

const EditContentType = ({ closeModal, resourceToEdit }) => {
  const { handleUpdate } = useContext(Resources);
  // const { contentTypes } = useTags();
  const [contentTypes, setContentTypes] = useState([]);

  // Loading State
  const [isLoading, setLoading] = useState(false);

  // Resource State
  const [resources, setResources] = useState([]);

  // Tags states
  const [selectedContentType, setSelectedContentType] = useState(null);

  useEffect(() => {
    Array.isArray(resourceToEdit)
      ? setResources(resourceToEdit)
      : setResources([resourceToEdit]);
  }, []);

  // Set the tags when the resources are loaded
  useEffect(() => {
    if (resources.length === 1) {
      handleContentTypeSelection(resources[0].contentType?._id);
      setSelectedContentType(resources[0].contentType?._id || null);
    } else if (resources.length > 1) {
      handleContentTypeSelection(null);
    }
  }, [resources]);

  // Used to also be able to add tags to the selected tags from outside of the TagPicker
  const handleContentTypeSelection = (contentType) => {
    setSelectedContentType(contentType);
  };

  return (
    <ManageReviewersContainer>
      {isLoading && (
        <LoadOverlay>
          <Loader></Loader>
        </LoadOverlay>
      )}

      <EditHeader resources={resources} closeModal={closeModal} />

      <Section>
        <ModalHeading>
          {resources.length > 1
            ? `Apply this content type to these ${resources.length} docs`
            : "Content Type"}
        </ModalHeading>
        <Select
          onChange={(e) => {
            handleContentTypeSelection(e.target.value);
          }}
        >
          <option value="unset">No Content Type</option>
          {contentTypes.map((type) => (
            <option
              value={type._id}
              selected={selectedContentType === type._id}
            >
              {type.name}
            </option>
          ))}
        </Select>
      </Section>

      <EditFooter
        closeModal={closeModal}
        onSave={(e) => {
          setLoading(true);
          const resourceIds = resources.map((resource) => resource._id);
          handleUpdate(
            resourceIds,
            {
              contentType: selectedContentType !== "unset" ? selectedContentType : null,
            },
            (updatedResource) => {
              setLoading(false);
              closeModal(e);
              return { contentType: updatedResource.contentType };
            }
          );
        }}
      />
    </ManageReviewersContainer>
  );
};

export default EditContentType;
