import React from "react";
import { styled } from "styled-components";

// Hooks
import { useAuth } from "../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";

// Component Library
import {
  Card,
  Column,
  Heading,
  Paragraph,
  Line,
  Button,
} from "@saberapp/react-component-library";

import {
  IconWrapper,
  IconUserGroup,
  IconTag,
  IconDocumentText,
} from "@saberapp/icons";

import SettingsTemplate from "../../templates/SettingsTemplate";
import SettingsHeader from "./components/SettingsHeader";

import IntegrationCard from "./components/IntegrationCard";
import { useConnectors } from "../../../hooks/useConnectors";

const CardsList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 32px;
`;

const CardsListThree = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 32px;
`;

const HeadingWithIconRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const CardInner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const SettingsOverview = () => {
  const { organization, userHasRole } = useAuth();
  const { connectorCatalog, isConnectorCatalogLoading } = useConnectors();
  const navigate = useNavigate();

  return (
    <SettingsTemplate width="800">
      <Column gap="s">
        <Heading size="h1">Overview</Heading>
        <Paragraph>Manage organization settings and integrations</Paragraph>
      </Column>

      {!userHasRole("ADMIN") && (
        <>
          <SettingsHeader
            type="small"
            title="Explore features"
            description="These are all the members of your team that have access to Saber."
            line={false}
          />
          TODO
        </>
      )}

      {userHasRole("ADMIN") && (
        <>
          <SettingsHeader
            type="small"
            title="Explore features"
            description="These are all the members of your team that have access to Saber."
            line={false}
          />
          <CardsListThree>
            <Card padding={16}>
              <CardInner>
                <Heading size="h4">
                  <HeadingWithIconRow>
                    <IconWrapper size={16}>
                      <IconUserGroup />
                    </IconWrapper>{" "}
                    Manage your team
                  </HeadingWithIconRow>
                </Heading>
                <Paragraph>
                  Invite your team members and assign them roles
                </Paragraph>
                <Button
                  size="small"
                  type="ui"
                  title="Manage Team"
                  onClick={() => {
                    navigate(`/${organization.slug}/settings/members`);
                  }}
                />
              </CardInner>
            </Card>
            <Card padding={16}>
              <CardInner>
                <Heading size="h4">
                  <HeadingWithIconRow>
                    <IconWrapper size={16}>
                      <IconTag />
                    </IconWrapper>{" "}
                    Manage tags
                  </HeadingWithIconRow>
                </Heading>
                <Paragraph>
                  Generate and manage tags to organise your content
                </Paragraph>
                <Button
                  size="small"
                  type="ui"
                  title="Manage Tags"
                  onClick={() => {
                    navigate(`/${organization.slug}/settings/tags`);
                  }}
                />
              </CardInner>
            </Card>
            <Card padding={16}>
              <CardInner>
                <Heading size="h4">
                  <HeadingWithIconRow>
                    <IconWrapper size={16}>
                      <IconDocumentText />
                    </IconWrapper>{" "}
                    Manage content types
                  </HeadingWithIconRow>
                </Heading>
                <Paragraph>
                  Categorise your content by type, such as Pitch Deck or Case
                  Study
                </Paragraph>
                <Button
                  size="small"
                  type="ui"
                  title="Manage Content Types"
                  onClick={() => {
                    navigate(`/${organization.slug}/settings/content-types`);
                  }}
                />
              </CardInner>
            </Card>
          </CardsListThree>
        </>
      )}

      <Line />

      {userHasRole("ADMIN") && (
        <>
          <Heading size="h3">Connector Catalog</Heading>
          <CardsList>
            {!isConnectorCatalogLoading &&
              connectorCatalog.map((integration, i) => {
                return (
                  <>
                    <IntegrationCard
                      source={integration.source}
                      slug={integration.slug}
                      name={integration.name}
                      description={integration?.description || "TODO"}
                      icon={integration.icon}
                      comingSoon={integration.status === "Coming Soon"}
                      // isEnabled={integrationEnabled}
                      // onConnect={() =>
                      //   paragon.connect(integration.slug, {
                      //     onSuccess: (event, user) => {
                      //       console.log("event", event);
                      //       console.log("user", user);
                      //     },
                      //     onError: (err) => {
                      //       console.error(err);
                      //     },
                      //   })
                      // }
                    />
                  </>
                );
              })}
          </CardsList>
        </>
      )}
    </SettingsTemplate>
  );
};

export default SettingsOverview;
