import React from 'react';

// Component Library
import {
  Button,
} from "@saberapp/react-component-library";

import SettingsTemplate from '../../templates/SettingsTemplate';
import SettingsHeader from './components/SettingsHeader';

const SettingsPreferences = () => {

  return (
    <SettingsTemplate>
      <SettingsHeader
        title="Preferences"
        description="Data sources are how Saber gets access to your sales enablement material"
      />
      <SettingsHeader
        type="small"
        title="Updates Feed"
        description="Allow logins through your SAML identity provider single sign-on functionality."
        button={<Button type="primary" title="Configure" />}
        line={true}
      />
    </SettingsTemplate>
  );
};

export default SettingsPreferences;
