import React from "react";
import styled from "styled-components";

// Component Library
import {
  Column,
  Row,
  Heading,
  Paragraph,
  Button,
} from "@saberapp/react-component-library";
import { useConnectors } from "../../../../hooks/useConnectors";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../hooks/useAuth";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const Content = styled.div`
  max-width: 480px;
`;

const EmptyState = ({ page }) => {
  const { organization } = useAuth();
  const navigate = useNavigate();

  return (
    <Container>
      <Content>
        <Row gap="l">
          <Column>
            {page === "documents" && (
              <>
                <Heading size="h3">No Documents Synced</Heading>
                <Paragraph>
                  Once you've connected your file storage app, like Google
                  Drive, the synced files will appear here. We will categories
                  and organise your content, and help you make sure it all stays
                  fresh.
                </Paragraph>
                <Button
                  type="primary"
                  size="regular"
                  title="Sync with Google Drive"
                  onClick={() => {
                    navigate(`/${organization.slug}/connector/google-drive`);
                  }}
                />
              </>
            )}

            {page === "pages-and-notes" && (
              <>
                <Heading size="h3">No Pages or Notes Synced</Heading>
                <Paragraph>
                  Once you've connected your knowledge base app, like Notion or
                  Confluence, the synced pages and notes will appear here. We
                  will categories and organise your content, and help you make
                  sure it all stays fresh.
                </Paragraph>
                <Button
                  type="primary"
                  size="regular"
                  title="Go to Connectors"
                  onClick={() => {
                    navigate(`/${organization.slug}/settings`);
                  }}
                />
              </>
            )}

            {page === "crm" && (
              <>
                <Heading size="h3">CRM Syncing coming soon</Heading>
                <Paragraph>
                  We're working on the connectors to popular CRMs like
                  Salesforce, Hubspot, Pipedrive and Microsoft Dynamics. With
                  these connectors, Saber will be able to provide even richer
                  answers to questions, as well as automate CRM hygiene, saving
                  you loads of time while also increase CRM data integrity.
                </Paragraph>
              </>
            )}

            {page === "answers" && (
              <>
                <Heading size="h3">No custom answers yet</Heading>
                <Paragraph>
                  When we spot content gaps based on questions your team is
                  asking, we'll send you a task in Inbox. There you can address
                  the content gap by creating a custom answer for the question
                  we weren't able to answer, and those answers will show up
                  here.
                </Paragraph>
              </>
            )}
          </Column>
        </Row>
      </Content>
    </Container>
  );
};

export default EmptyState;
