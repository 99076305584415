import React, { useState } from "react";
import styled from "styled-components";

// Component Library
import { Button, Card, Paragraph } from "@saberapp/react-component-library";

import SettingsTemplate from "../../templates/SettingsTemplate";
import IntegrationsHeader from "./components/IntegrationHeader";

import { ReactComponent as IconGmail } from "../../icons/connectors/google-mail.svg";
import IntegrationsOverview from "./components/IntegrationOverview";
import IntegrationsStatus from "./components/IntegrationStatus";

const RowSpaced = styled.div`
  display: flex;
  gap: 32px;
  justify-content: space-between;
  align-items: center;
`;

const SettingsChromeExtension = () => {
  const [showOverview, setShowOverview] = useState(true);

  return (
    <SettingsTemplate>
      <IntegrationsHeader
        logo={<IconGmail />}
        name="Saber for Gmail Chrome Extension"
        description="Use Saber from within your existing workflow. Our Chrome extension provides a Saber add-on in Gmail in which you can search and get suggested content and actions"
      />

      <IntegrationsStatus source="chrome_extension" name="Chrome Extension" userSpecific={true} />

      <Card padding={16}>
        {showOverview ? (
          <IntegrationsOverview />
        ) : (
          <RowSpaced>
            <Paragraph>
              Want to learn more about our Google Drive integration?
            </Paragraph>{" "}
            <Button
              size="small"
              title="Show integration overview"
              onClick={() => {
                setShowOverview(true);
              }}
            />
          </RowSpaced>
        )}
      </Card>
    </SettingsTemplate>
  );
};

export default SettingsChromeExtension;
