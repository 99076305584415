import React, { useState } from "react";
import { styled } from "styled-components";

import { useUsers } from "../../../../hooks/useUsers";

// Component Library
import {
  Button,
  Avatar,
  DropdownButton,
  Column,
  Heading,
  Paragraph,
  DropdownItem,
  Line,
  Input,
  Select,
  Card,
  Pill,
  Row,
} from "@saberapp/react-component-library";

import SettingsHeader from "../components/SettingsHeader";
import Modal from "../../../layouts/modals";
import { IconEllipsisHorizontal } from "@saberapp/icons";

const UserList = styled.div``;
const UserTitle = styled.h4`
  font-size: 13px;
  font-weight: 500;
`;
const UserTable = styled.table`
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
`;
const UserRow = styled.tr`
  border-bottom: 1px solid var(--card-border);
  color: var(--heading-text);
  box-sizing: border-box;

  &:last-child {
    border: 0;
  }
`;
const HeaderCel = styled.th`
  text-align: left;
  padding: 12px 0;
  font-weight: 500;
`;
const HeaderCelCenter = styled.th`
  text-align: middle;
  padding: 12px 0;
  font-weight: 500;
`;
const UserCel = styled.td`
  padding: 12px 0px;
`;

const CenterCel = styled.td`
  padding: 12px 0px;
  text-align: center;
`;

const UserEmail = styled.p``;
const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;
const FlexRight = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Buttons = styled.div`
  display: flex;
  gap: 8px;
`;

const ModalInner = styled.div``;

const ModalBody = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
`;

const Modalfooter = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid var(--border-color-1);
  padding: 16px;
`;

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const StatusIndicator = styled.div`
  position: relative;
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: ${(props) => props.color};
  margin: 0 auto;
`;

const SettingsMembers = () => {
  const {
    users,
    filteredUsers,
    handleFilter,
    isUserInvited,
    inviteUser,
    removeUsers,
    isRemovingUser,
    roles,
  } = useUsers();

  const [isInviteUserModalVisible, setIsInviteUserModalVisible] =
    useState(false);
  const [emailToInvite, setEmailToInvite] = useState(null);
  const [roleToInvite, setRoleToInvite] = useState(null);

  const handleCloseModal = () => {
    setIsInviteUserModalVisible(false);
  };

  return (
    <>
      {/**
       * Invite User Modal
       */}
      <Modal
        chrome={true}
        isVisible={isInviteUserModalVisible}
        setIsVisible={setIsInviteUserModalVisible}
      >
        <ModalInner>
          <ModalBody>
            <Select
              onChange={(e) => {
                setRoleToInvite(e.target.value);
              }}
            >
              <option value={null}>Select a role</option>
              {roles.map((role) => (
                <option value={role._id}>{role.name}</option>
              ))}
            </Select>
            <Input
              type="text"
              placeholder="Email you want to invite..."
              onChange={(e) => {
                setEmailToInvite(e.target.value);
              }}
            />
            {isUserInvited(emailToInvite) && <div>User already invited</div>}
          </ModalBody>
          <Modalfooter>
            <Button
              title="Cancel"
              onClick={() => {
                handleCloseModal();
              }}
            />
            <Button
              title="Send Invitation"
              onClick={async () => {
                if (!isUserInvited(emailToInvite) && roleToInvite) {
                  console.log(`Inviting ${emailToInvite} as a ${roleToInvite}`);
                  await inviteUser(
                    [
                      {
                        email: emailToInvite,
                        roleId: roleToInvite,
                      },
                    ],
                    (invitedUsers) => {
                      console.log("invitedUsers", invitedUsers);
                      handleCloseModal();
                    }
                  );
                } else {
                  console.log("Email already invited or role is null");
                  // Do something
                }
              }}
            />
          </Modalfooter>
        </ModalInner>
      </Modal>

      <Column gap="s">
        <Heading size="h1">Members &amp; Roles</Heading>
        <Paragraph>Manage organization members and their roles</Paragraph>
      </Column>

      {/* <Line distance="tiny" />

      <SettingsHeader
        type="small"
        title="Manage roles"
        description="We support four roles for Saber Users: Owner, Admin, Seller and Reviewer. [Explain the roles]. While we currently don't support creating custom roles, let us know if you're interested."
        // button={<Button type="primary" size="small" title="Add a Role" />}
        line={true}
      /> */}

      <Line distance="tiny" />

      <Card padding={32}>
        <Flex>
          <SettingsHeader
            type="small"
            title="Manage members"
            description="These are all the members of your team that have access to Saber."
            button={
              <Button
                type="primary"
                title="Invite User"
                onClick={() => {
                  setIsInviteUserModalVisible(true);
                }}
              />
            }
            line={false}
          />

          <Buttons>
            <Input
              type="text"
              placeholder="Filter by name..."
              onChange={(e) => handleFilter(e.target.value)}
            />
          </Buttons>

          <UserList>
            <UserTitle>{users.length} Members</UserTitle>
            <UserTable>
              <UserRow>
                <HeaderCel colSpan={2}>Name</HeaderCel>
                <HeaderCel>Roles</HeaderCel>
                <HeaderCelCenter>Status</HeaderCelCenter>
              </UserRow>
              {filteredUsers.map(function (user, i) {
                return (
                  <UserRow>
                    <UserCel
                      style={{
                        width: 20,
                        verticalAlign: "bottom",
                        paddingRight: 12,
                      }}
                    >
                      <Avatar name={user.name} url={user.avatar} size={20} />
                    </UserCel>
                    <UserCel>
                      <FlexColumn>
                        {/* <Heading size="h4">{user.name}</Heading> */}
                        <Paragraph>{user.email}</Paragraph>
                      </FlexColumn>
                    </UserCel>
                    <UserCel>
                      <Row>
                        {user?.roles.map((role) => (
                          <Pill key={role.name} title={role.name} />
                        ))}
                      </Row>
                    </UserCel>
                    <CenterCel>
                      <StatusIndicator
                        color={
                          user.status === "active" &&
                          !isRemovingUser.some((u) => u._id === user._id)
                            ? "#8dc63f"
                            : "#F26419"
                        }
                      ></StatusIndicator>
                    </CenterCel>
                    <UserCel>
                      <FlexRight>
                        <DropdownButton
                          type="ui"
                          position="bottom-right"
                          noborder="true"
                          iconBefore={<IconEllipsisHorizontal />}
                          id={""}
                        >
                          {/* <DropdownItem onClick={() => {}}>
                            Change {`${user.name || user.email}'s`} role...
                          </DropdownItem>
                          <DropdownItem onClick={() => {}}>
                            Update Email...
                          </DropdownItem> */}
                          <DropdownItem
                            onClick={async () => {
                              !isRemovingUser.some((u) => u._id === user._id) &&
                                (await removeUsers([user._id]));
                            }}
                          >
                            {isRemovingUser.some((u) => u._id === user._id)
                              ? `Removing...`
                              : `Remove ${user.name || user.email}...`}
                          </DropdownItem>
                        </DropdownButton>
                      </FlexRight>
                    </UserCel>
                  </UserRow>
                );
              })}
            </UserTable>
          </UserList>
        </Flex>
      </Card>
    </>
  );
};

export default SettingsMembers;
