import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

// Component Library
import {
  Heading,
  Paragraph,
  Button,
  Tag,
  Accordian,
  User,
  UserGroup,
  FreshnessScore,
  EmptyState,
  Alert,
  FileTypeIcon,
  Card,
  Avatar,
} from "@saberapp/react-component-library";

// Enums
import {
  EventTargetObjects,
  ResourceEvents,
  FreshnessScoreValues,
  ContentTypes,
  VisibilityStatus,
} from "@saberapp/enums";

// Hooks
import { Resources } from "../../../../hooks/useResources";
// import { useCopyToClipboard } from "@saberapp/hooks";
import { useAuth } from "../../../../hooks/useAuth";
// import { useAnalytics } from "../../../../hooks/useAnalytics";

// Icons
import {
  IconWrapper,
  IconArrowTopRightOnSquare as IconNewTab,
  IconPlus,
  IconEye,
  IconCheckBadge as IconCheck,
  IconFlag,
  IconLink,
  IconArchiveBox as IconArchive,
  IconTag,
  IconCursorArrowRipple,
} from "@saberapp/icons";

import BarChart from "../../insights/components/_BarChart";

dayjs.extend(relativeTime);
dayjs.locale("en");

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
`;

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 16px;
`;

const Table = styled.table`
  border-spacing: 0;
`;
const TR = styled.tr``;
const TD = styled.td`
  font-weight: 500;
  padding: 8px 0;
  padding-right: 16px;
  font-size: 12px;
  white-space: nowrap;
  height: 40px;
  box-sizing: border-box;

  &:first-child {
    width: 120px;
  }
`;

const ReviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const TopContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 24px;
  padding-top: 8px;

  border-bottom: 1px solid var(--sidebar-border);
  padding-bottom: 24px;
`;

const AccordianList = styled.div``;

const InlinePill = styled.span`
  background: var(--button-bg-hover);
  border-radius: 4px;
  padding: 2px 4px 3px;
  color: var(--button-text-hover);
  font-weight: 500;
`;

const CardContainer = styled.div`
  margin-top: 16px;
`;

const CardInner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Label = styled.div`
  background: #191d24;
  color: #a0a2a5;
  font-weight: 500;
  padding: 4px 6px;
  font-size: 10px;
  border-radius: 4px;
`;

const SmallParagraph = styled.p`
  font-size: 12px;
  opacity: 0.7;
  font-family: "Inter", sans-serif !important;
  color: var(--body-text);
  margin: 0;
  padding: 0;
  line-height: 1.6;
`;

const PropertyAction = styled.div`
  border-top: ${(props) =>
    props.noborder ? "0px" : "1px solid var(--sidebar-border)"};
  padding: ${(props) => (props.noborder ? "0px" : "16px 0")};
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  gap: 16px;
  margin-top: 12px;


  &:first-of-type {
    margin-top: ${(props) => (props.noborder ? "0px" : "16px")};
  }
`;

const ChartLegend = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 8px;
  margin-top: 16px;
`;

const MetricLegend = styled.div`
  display: flex;
  gap: 8px;
`;
const MetricLegendColor = styled.div`
  width: 8px;
  height: 8px;
  background: #e8c0a0;
  border-radius: 2px;
  margin-top: 6px;
`;

const MetricValue = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const MetricColumn = styled.div``;

const ResourceDetails = ({
  mode,
  layout,
  handleSwitchMode,
  setReviewRequested,
  reviewRequested,
  handleEditVisibility,
  handleEditContentType,
  handleEditTags,
}) => {
  const {
    selectedResources,
    handleMarkAsReviewed,
    handleArchive,
    handleRequestReview,
    handleUpdateFreshnessScore,
  } = useContext(Resources);

  const { organization, userHasRole } = useAuth();
  // const { analytics } = useAnalytics();
  // const { copy } = useCopyToClipboard();

  const [resource, setResource] = useState(null);
  const [engagementData, setEngagementData] = useState(null);
  const [engagementDataIsLoading, setEngagementDataIsLoading] = useState(true);

  useEffect(() => {
    const r = selectedResources[0];
    setResource(r);
    setEngagementDataIsLoading(true);

    // r &&
    //   analytics.getResourceEngagementsById(r._id, (data) => {
    //     console.log("data", data);
    //     setEngagementData(data);
    //     setEngagementDataIsLoading(false);
    //   });
  }, [selectedResources]);

  return (
    <>
      {selectedResources.length === 0 && (
        <EmptyState text="Select a resource to see it's details" />
      )}
      {selectedResources.length > 1 && (
        <>{`${selectedResources.length} selected`}</>
      )}

      {selectedResources.length === 1 && (
        <>
          <Column>
            <TopContent>
            <Heading size="h3">{resource?.name}</Heading>

              {(resource?.freshnessScore ===
                FreshnessScoreValues.NEEDS_REVIEW ||
                resource?.freshnessScore === FreshnessScoreValues.OUTDATED) &&
                !userHasRole("SELLER") && (
                  <CardContainer>
                    <Card padding={16}>
                      <CardInner>
                        <FreshnessScore score={resource?.freshnessScore} />

                        <Paragraph>
                          This doc was last reviewed over{" "}
                          {`${dayjs(resource?.reviewedTime).fromNow()}`} and
                          needs to be checked.
                        </Paragraph>
                        <ButtonRow>
                          <Button
                            iconBefore={<IconCheck />}
                            type="primary"
                            size="small"
                            title="Mark as Reviewed"
                            onClick={() => {
                              handleMarkAsReviewed(resource?._id);
                            }}
                          />
                          {resource?.freshnessScore ===
                            FreshnessScoreValues.OUTDATED && (
                            <Button
                              type="ui"
                              size="small"
                              iconBefore={<IconArchive />}
                              title={`Archive`}
                              onClick={() => {
                                handleArchive(resource?._id);
                              }}
                            />
                          )}
                        </ButtonRow>
                      </CardInner>
                    </Card>
                  </CardContainer>
                )}

              {resource?.freshnessScore !== FreshnessScoreValues.VERIFIED &&
                userHasRole("SELLER") && (
                  <Alert
                    size="small"
                    type={
                      resource?.freshnessScore === FreshnessScoreValues.OUTDATED
                        ? "alert"
                        : "warning"
                    }
                    title="Resource outdated"
                    isVisible={true}
                    closable={false}
                  >
                    <Paragraph>
                      This resource may not be accurate anymore, you can request
                      for it to be reviewed.
                    </Paragraph>
                  </Alert>
                )}

              
              {/* <Paragraph>
                Typically shared during the <InlinePill>Discovery</InlinePill>{" "}
                stage.
                <br />
                Last used 4 days ago.
              </Paragraph> */}

              {/* {engagementData && (
                <>
                  <ChartLegend>
                    <MetricLegend>
                      <MetricColumn>
                        <MetricLegendColor />
                      </MetricColumn>
                      <MetricColumn>
                        <Paragraph>Opens</Paragraph>
                        <MetricValue>
                          <IconWrapper size={12}>
                            <IconCursorArrowRipple />
                          </IconWrapper>
                          <Heading size="h4">
                            {resource?.analytics?.opens}
                          </Heading>
                        </MetricValue>
                      </MetricColumn>
                    </MetricLegend>
                    <MetricLegend>
                      <MetricColumn>
                        <MetricLegendColor />
                      </MetricColumn>
                      <MetricColumn>
                        <Paragraph>Views</Paragraph>
                        <MetricValue>
                          <IconWrapper size={12}>
                            <IconEye />
                          </IconWrapper>
                          <Heading size="h4">
                            {resource?.analytics?.views}
                          </Heading>
                        </MetricValue>
                      </MetricColumn>
                    </MetricLegend>
                    <MetricLegend>
                      <MetricColumn>
                        <MetricLegendColor />
                      </MetricColumn>
                      <MetricColumn>
                        <Paragraph>Links Copied</Paragraph>
                        <MetricValue>
                          <IconWrapper size={12}>
                            <IconLink />
                          </IconWrapper>
                          <Heading size="h4">
                            {resource?.analytics?.linksCopied}
                          </Heading>
                        </MetricValue>
                      </MetricColumn>
                    </MetricLegend>
                  </ChartLegend>

                  <BarChart
                    data={engagementData}
                    isLoading={engagementDataIsLoading}
                    indexBy="day"
                    keys={["views", "opens", "linksCopied"]}
                    format={(value) => {
                      return value === "12 AM" || value === "12 PM"
                        ? value
                        : value.slice(0, -3);
                    }}
                  />
                </>
              )} */}
            </TopContent>

            <AccordianList>
              <Accordian title="Properties" buttonSize="regular">
                <Table>
                  {resource?.freshnessScore !==
                    FreshnessScoreValues.UNKNOWN && (
                    <TR>
                      <TD>Freshness Score</TD>
                      <TD>
                        <FreshnessScore score={resource?.freshnessScore} />
                      </TD>
                    </TR>
                  )}
                  {resource?.contentType &&
                    resource?.contentType?.name !== ContentTypes.UNKNOWN && (
                      <TR>
                        <TD>Content Type</TD>
                        <TD>
                          <Tag
                            title={
                              resource?.contentType?.name !==
                                ContentTypes.UNKNOWN &&
                              resource?.contentType?.name
                            }
                            icon={
                              <FileTypeIcon mimeType={resource?.mimeType} />
                            }
                          />
                        </TD>
                      </TR>
                    )}
                  {resource?.visibility !== VisibilityStatus.UNKNOWN && (
                    <TR>
                      <TD>Visibility</TD>
                      <TD>
                        <Row>
                          <Label>{resource?.visibility}</Label>
                        </Row>
                      </TD>
                    </TR>
                  )}
                  <TR>
                    <TD>Created by</TD>
                    <TD>
                      <Row>
                        <IconWrapper size="20">
                          <Avatar url={resource?.owners[0].avatar} />
                        </IconWrapper>
                        <Paragraph>{resource?.owners[0].name}</Paragraph>
                      </Row>
                    </TD>
                  </TR>
                  <TR>
                    <TD>Last Updated by</TD>
                    <TD>
                      <Row>
                        <IconWrapper size="20">
                          <Avatar url={resource?.owners[0].avatar} />
                        </IconWrapper>
                        <Paragraph>{resource?.owners[0].name}</Paragraph>
                      </Row>
                    </TD>
                  </TR>
                  {!userHasRole("SELLER") && (
                    <TR>
                      <TD>Index Status</TD>
                      <TD>
                        <Row>{resource?.indexStatus}</Row>
                      </TD>
                    </TR>
                  )}
                </Table>

                {!userHasRole("SELLER") && (
                  <>
                    {resource?.freshnessScore ===
                      FreshnessScoreValues.UNKNOWN && (
                      <PropertyAction>
                        <SmallParagraph>
                          It's unknown how fresh this document is, and when it
                          was last reviewed. Review the document and let your
                          team know its safe to use.
                        </SmallParagraph>
                        <Button
                          iconBefore={<IconCheck />}
                          type="ui"
                          size="small"
                          title="Mark as Reviewed"
                          onClick={() => {
                            handleMarkAsReviewed(resource?._id);
                          }}
                        />
                      </PropertyAction>
                    )}

                    {resource?.visibility === VisibilityStatus.UNKNOWN && (
                      <PropertyAction>
                        <SmallParagraph>
                          It's unknown if this doc is safe to share with
                          customers. Update the visibility status and prevent
                          internal documents from being shared.
                        </SmallParagraph>
                        <Button
                          type="ui"
                          size="small"
                          title="Set Visibility"
                          onClick={() => {
                            handleEditVisibility(resource);
                          }}
                        />
                      </PropertyAction>
                    )}

                    {(!resource?.contentType ||
                      resource?.contentType?.name === ContentTypes.UNKNOWN) && (
                      <PropertyAction>
                        <SmallParagraph>
                          The content type has not been set. Assign a content
                          type so its easier to find.
                        </SmallParagraph>
                        <Button
                          type="ui"
                          size="small"
                          title="Add Content Type"
                          onClick={() => {
                            handleEditContentType(resource);
                          }}
                        />
                      </PropertyAction>
                    )}
                  </>
                )}
              </Accordian>
              {/* <Accordian title="Reviews" buttonSize="regular">
                <Table>
                  {resource?.reviewers.length > 0 && (
                    <TR>
                      <TD>Reviewers</TD>
                      <TD>
                        <Button
                          type="ui"
                          title="Change Reviewers"
                          onClick={() => {
                            // TODO: find diff UX
                            // handleEditResource(resource);
                          }}
                        />
                      </TD>
                    </TR>
                  )}
                  {resource?.reviewedBy && (
                    <>
                      <TR>
                        <TD>Last Reviewed By</TD>
                        <TD>
                          <Row>
                            <IconWrapper size="20">
                              <Avatar url={resource?.owners[0].avatar} />
                            </IconWrapper>
                            <Paragraph>{resource?.owners[0].name} </Paragraph>
                          </Row>
                        </TD>
                      </TR>
                      <TR>
                        <TD>Last Reviewed</TD>
                        <TD>
                          <Paragraph>
                            {`${dayjs(resource?.reviewedTime).fromNow()}`}
                          </Paragraph>
                        </TD>
                      </TR>
                    </>
                  )}
                  {resource?.freshnessScore !==
                    FreshnessScoreValues.VERIFIED && (
                    <TR>
                      <TD>Review Requests</TD>
                      <TD>
                        <UserGroup
                          users={
                            resource?.reviewRequests?.map(
                              (request) => request.requestedBy
                            ) || []
                          }
                        />
                      </TD>
                    </TR>
                  )}
                </Table>

                {resource?.reviewers.length === 0 && (
                  <PropertyAction noborder={true}>
                    <SmallParagraph>
                      No Reviewers are assigned to this doc. Assign Reviewers so
                      they can help keep the content in this doc fresh.
                    </SmallParagraph>
                    <Button
                      type="ui"
                      size="small"
                      title="Assign Reviewers"
                      onClick={() => {
                        // TODO
                      }}
                    />
                  </PropertyAction>
                )}
              </Accordian> */}
              <Accordian title="Tags" buttonSize="regular">
                {resource?.tags?.assigned.length === 0 && (
                  <PropertyAction noborder={true}>
                    <SmallParagraph>
                      Tags help us organize all your content and map it more
                      accurately to engagement and prospect data. The more
                      relevant tags per doc, the better.
                    </SmallParagraph>
                    <Button
                      iconBefore={<IconTag />}
                      type="ui"
                      size="small"
                      title="Add Tags"
                      onClick={() => {
                        handleEditTags(resource);
                      }}
                    />
                  </PropertyAction>
                )}

                {resource?.tags?.assigned.length > 0 && (
                  <Column>
                    <Row>
                      {resource?.tags?.assigned.map((tag, index) => (
                        <Tag key={index} title={tag.name} maxWidth="auto" />
                      ))}
                    </Row>
                    <Button
                      type="ui"
                      size="small"
                      iconBefore={<IconPlus />}
                      title="Add more tags"
                      onClick={() => {
                        handleEditTags(resource);
                      }}
                    />
                  </Column>
                )}
              </Accordian>
            </AccordianList>
          </Column>
        </>
      )}
    </>
  );
};

export default ResourceDetails;
