import React, { useState } from "react";
import {
    DropdownButton,
    DropdownItem,
    Checkbox,
    Button,
} from "@saberapp/react-component-library";
import { IconFunnel, IconCheckCircle, IconEyeSlash } from "@saberapp/icons";
import { styled } from 'styled-components';
import { useAuth } from '../../../hooks/useAuth';
import TopNavigation from "../../layouts/main-top-bar";
import { useApi } from '../../../hooks/useApi';

const Options = styled.div`
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 24px;
    height: 60px;
    justify-content: flex-end;
    width: calc(100% - 367px);
`;

const RightPart = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    border-left: 1px solid var(--card-border);
    width: 100%;
    height: 100%;
    justify-content: flex-end;
`;

const TopNavigationComponent = ({
                                    setActiveFilters,
                                    activeFilters,
                                    isItemSelected,
                                    tasks,
                                    setTasks,
                                    taskId
                                }) => {
    const { organization } = useAuth();
    const { API } = useApi();
    const [isUpdatingResolved, setIsUpdatingResolved] = useState(false);
    const [isUpdatingIgnored, setIsUpdatingIgnored] = useState(false);

    const handleFilterChange = (type) => {
        setActiveFilters((prevFilters) => {
            if (prevFilters.indexOf(type) >= 0) {
                return prevFilters.filter((value) => value !== type);
            } else {
                return [...prevFilters, type];
            }
        });
    };

    const handleUpdateTaskStatus = async (status) => {
        try {
            setLoadingState(status, true);

            await API.call(
                'PATCH', // todo create HttpMethod.PATCH,
                `/tasks/${taskId}`,
                { status },
                (_updatedTask) => {
                    // todo for now always filtering out the updated tasks (IGNORED/RESOLVED)
                    setTasks({ data: tasks.data.filter(task => task._id !== taskId), pagination: tasks.pagination });
                }
            );
        } catch (error) {
            console.error('Error updating task:', error);
        } finally {
            setLoadingState(status, false);
        }
    };

    const setLoadingState = (status, state) => {
        if (status === 'RESOLVED') {
            setIsUpdatingResolved(state);
        } else if (status === 'IGNORED') {
            setIsUpdatingIgnored(state);
        }
    };

    return (
        <TopNavigation
            padding={0}
            links={[{ title: "Inbox", path: `/${organization.slug}/insights` }]}
        >
            <Options>
                <DropdownButton
                    type="ui"
                    noborder={true}
                    iconBefore={<IconFunnel />}
                    position="bottom-right"
                >
                    <DropdownItem onClick={() => handleFilterChange('positiveFeedback')}>
                        <Checkbox checked={activeFilters.indexOf('positiveFeedback') === -1} />
                        Positive Feedback
                    </DropdownItem>
                    <DropdownItem onClick={() => handleFilterChange('negativeFeedback')}>
                        <Checkbox checked={activeFilters.indexOf('negativeFeedback') === -1} />
                        Negative Feedback
                    </DropdownItem>
                    <DropdownItem onClick={() => handleFilterChange('contentGaps')}>
                        <Checkbox checked={activeFilters.indexOf('contentGaps') === -1} />
                        Content Gaps
                    </DropdownItem>
                </DropdownButton>

                <RightPart>
                    {isItemSelected && (
                        <>
                            <Button
                                iconBefore={<IconCheckCircle />}
                                type="ui"
                                size="small"
                                title={isUpdatingResolved ? "Marking as Resolved..." : "Mark as Resolved"}
                                disabled={isUpdatingResolved || isUpdatingIgnored}
                                onClick={() => handleUpdateTaskStatus('RESOLVED')}
                            />
                            <Button
                                iconBefore={<IconEyeSlash />}
                                type="ui"
                                size="small"
                                title={isUpdatingIgnored ? "Marking as Ignored..." : "Mark as Ignored"}
                                disabled={isUpdatingIgnored || isUpdatingResolved}
                                onClick={() => handleUpdateTaskStatus('IGNORED')}
                            />
                        </>
                    )}
                </RightPart>
            </Options>
        </TopNavigation>
    );
};

export default TopNavigationComponent;
