import styled from 'styled-components';

export const Icon = styled.div` 
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: ${({ color }) => (color ? color : `var(--card-border)`)};
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 16px;
  }
`;
