import React, { useContext, useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useParams } from "react-router-dom";
import { styled } from "styled-components";

// Component Library
import {
  Button,
  Loader,
  FreshnessScore
} from "@saberapp/react-component-library";

// Enums
import {
  ResourceViewModes,
  ResourceViewOnlyTypes,
  FreshnessScoreValues,
} from "@saberapp/enums";

// Hooks
import { useCopyToClipboard } from "@saberapp/hooks";
import { useAuth } from "../../../hooks/useAuth";
import { useApi } from "../../../hooks/useApi";
import { Resources } from "../../../hooks/useResources";

// Components
import TopNavigation from "../../layouts/main-top-bar";
import Sidebar from "../inventory/components/Sidebar";
import FreshnessScoreAnimation from "../../animations/FreshnessScoreAnimation";

// Icons
import { ReactComponent as IconLink } from "../../icons/20/solid/link.svg";
import { ReactComponent as IconCheck } from "../../icons/20/solid/check-badge.svg";
import { ReactComponent as IconSidebar } from "../../icons/20/solid/arrow-left-on-rectangle.svg";

const Options = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const RelativeContainer = styled.div`
  width: -webkit-fill-available;
  position: relative;
  overflow: hidden;
  overflow-x: auto;

  background: var(--bg-color-1);
`;

const LastReviewed = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  box-sizing: border-box;
  gap: 8px;
  margin-right: 16px;
`;

const RowContainer = styled.div`
  display: flex;
  height: calc(100% - 76px);
  border-top: 1px solid var(--sidebar-border);
`;

const IframeOverlay = styled.div`
  position: absolute;
  background: rgba(0, 0, 0, 0.85);
  backdrop-filter: blur(5px);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadOverlay = styled.div`
  position: absolute;
  background: rgba(0, 0, 0, 0.85);
  backdrop-filter: blur(5px);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Modal = styled.div`
  background: ${(props) => `${props.theme.colors.backgroundColor}`};
  border-radius: 6px;
  width: 400px;
  height: 300px;

  transform: translateY(
    ${(props) => (props.isOpen ? 0 : "-100%")}
  ); /* Initially, translate modal above */
  transition: transform 0.3s ease; /* Add a transition for transform */
`;

// const IframeContainer = styled.div`
//   width: 100%;
//   height: 100%;
//   opacity: ${({isEditing}) => (isEditing ? 0.5 : 1)};
//   transition: opacity 0.2s ease-in-out;
// `;

const Resource = () => {
  const { user, organization, userHasRole } = useAuth();
  const { copy } = useCopyToClipboard();
  const { API } = useApi();
  const { handleSelectedResources } = useContext(Resources);

  const [resource, setResource] = useState({});
  const [mode, setMode] = useState(ResourceViewModes.PREVIEW);
  const [modeUrl, setModeUrl] = useState(null);
  const [viewOnly, setViewOnly] = useState(false);
  const [reviewRequested, setReviewRequested] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isIframeLoaded, setIsIframeLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true); // Track sidebar state

  let { resourceId } = useParams();

  /**
   * On initial load, get the resource, set the state
   */
  useEffect(() => {
    getResource(resourceId);
  }, []);

  const getResource = async (id) => {
    await API.getResource(id, (data) => {
      const resourceData = data.resource;
      setViewOnly(
        ResourceViewOnlyTypes.some((viewOnlyType) =>
          resourceData.mimeType.includes(viewOnlyType)
        )
      );

      setReviewRequested(
        resourceData.reviewRequests.length > 0
          ? resourceData.reviewRequests.some(
              (request) => request.requestedBy === user._id
            )
          : false
      );

      setModeUrl(getModeUrl(resourceData.webViewLink));

      console.log("resourceData", resourceData);
      setResource(resourceData);
      handleSelectedResources("set", resourceData)
      setIsLoading(false);
    });
  };

  /**
   * When the view mode is changed
   */
  useEffect(() => {
    if (!isLoading) {
      setModeUrl(getModeUrl(resource?.webViewLink));
      setIsIframeLoaded(false);
    }
    if (mode === ResourceViewModes.EDIT) {
      handleSidebarToggle(false);
    }
  }, [mode]);

  // view-only files seem to contain /view instead of /preview
  const getModeUrl = (webViewLink) =>
    mode === ResourceViewModes.EDIT
      ? webViewLink.replace("/preview", "/edit").replace("?usp=drivesdk", "")
      : mode === ResourceViewModes.PREVIEW
      ? webViewLink
          .replace("/edit", "/preview")
          .replace("/view", "/preview")
          .replace("?usp=drivesdk", "")
      : webViewLink;

  /**
   * Mark a resource as reviewed
   * @param {String} id
   */
  const handleMarkAsReviewed = async (id = resourceId) => {
    await API.updateResource(
      id,
      {
        freshnessScore: FreshnessScoreValues.VERIFIED,
        reviewedTime: Date.now(),
        reviewedBy: user._id,
      },
      () => {
        const resourceData = response.data.resource;
        setResource(resourceData);
        setIsModalOpen(!isModalOpen);
      }
    );
  };

  const handleSwitchMode = () => {
    setMode(
      mode === ResourceViewModes.EDIT
        ? ResourceViewModes.PREVIEW
        : ResourceViewModes.EDIT
    );
  };

  const handleSidebarToggle = (newState = !isSidebarOpen) => {
    setIsSidebarOpen(newState);
  };

  return (
    <>
        <TopNavigation
          links={[
            { title: "Documents", path: `/${organization.slug}/documents` },
            {
              title: resource?.name || "Loading...",
              path: `/${organization.slug}/documents/this_id`,
            },
          ]}
        >
          <Options>
            {!isSidebarOpen && (
              <>
                <LastReviewed>
                  <FreshnessScore
                    score={resource?.freshnessScore}
                    lastReviewed={resource?.reviewedTime}
                    tooltipPosition="left"
                  />
                </LastReviewed>
                {!viewOnly && (
                  <Button
                    type="ui"
                    size="small"
                    title={`Switch to ${
                      mode === ResourceViewModes.EDIT ? "Preview" : "Edit"
                    } mode`}
                    onClick={() => {
                      handleSwitchMode();
                    }}
                  />
                )}

                <Button
                  iconBefore={<IconLink />}
                  type="ui"
                  size="small"
                  onClick={() => {
                    copy(resource?.webViewLink);
                  }}
                />

                {(userHasRole("ADMIN") || userHasRole("REVIEWER")) && resource?.freshnessScore !== FreshnessScoreValues.VERIFIED && (
                  <Button
                    iconBefore={<IconCheck />}
                    type="primary"
                    size="small"
                    title="Mark as Reviewed"
                    onClick={() => {
                      handleMarkAsReviewed();
                    }}
                  />
                )}
              </>
            )}
            <Button
              type="ui"
              size="small"
              iconBefore={<IconSidebar />}
              onClick={() => handleSidebarToggle()}
            />
          </Options>
        </TopNavigation>

        <RowContainer>
          <RelativeContainer isOpen={isSidebarOpen}>
            <AnimatePresence>
              {isModalOpen && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.2 }}
                >
                  <IframeOverlay isOpen={isModalOpen}>
                    <motion.div
                      initial={{ opacity: 0, translateY: "-10%" }}
                      animate={{ opacity: 1, translateY: 0 }}
                      exit={{ opacity: 0, translateY: "-10%" }}
                      transition={{ duration: 0.2 }}
                    >
                      <Modal isOpen={isModalOpen}>
                        <FreshnessScoreAnimation />
                        Thank you for reviewing! Close or next
                      </Modal>
                    </motion.div>
                  </IframeOverlay>
                </motion.div>
              )}
            </AnimatePresence>

            <AnimatePresence>
              {!isIframeLoaded && (
                <motion.div
                  key="loaded"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.2 }}
                >
                  <LoadOverlay>
                    <Loader />
                  </LoadOverlay>
                </motion.div>
              )}
            </AnimatePresence>

            {!isLoading && (
              <iframe
                title="iframe-doc"
                style={{
                  width: "100%",
                  height: "100%",
                  border: 0,
                }}
                src={`${modeUrl}`}
                onLoad={() => {
                  setIsIframeLoaded(true);
                }}
                onError={() => {
                  console.log("some sort of error");
                }}
                allowfullscreen
              ></iframe>
            )}
          </RelativeContainer>

          {!isLoading && isSidebarOpen && (
            <Sidebar
              isOpen={isSidebarOpen}
              layout={"view"}
              mode={mode}
              handleSwitchMode={handleSwitchMode}
              handleMarkAsReviewed={handleMarkAsReviewed}
              reviewRequested={reviewRequested}
              setReviewRequested={setReviewRequested}
            />
          )}
        </RowContainer>
        </>
  );
};
// src={`https://docs.google.com/presentation/d/1RazZiYn7ZLIec-CQoElrOWecK6Pp1ZwDoKJsxelAIsU/${mode}`}

export default Resource;
