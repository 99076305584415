import React from "react";
import styled from "styled-components";

import { ResponsiveBar } from "@nivo/bar";

const ChartContainer = styled.div`
  min-height: 240px;
  height: 240px;
  position: relative;

  opacity: ${(props) => (props.isLoading ? 0.5 : 1)};
`;

const BarChart = ({
  data,
  keys = ["views", "opens", "linksCopied"],
  indexBy = "date",
  isLoading,
  format = (value, _index) => {
    const date = new Date(value);
    return `${date.toLocaleString("default", {
      month: "short",
    })} ${date.getDate()}`;
  },
}) => {
  const getDataColor = (key) => {
    switch (key) {
      case "views":
        return `var(--accent-color-1000)`;
      case "opens":
        return `var(--accent-color-700)`;
      case "linksCopied":
        return `var(--accent-color-400)`;
      default:
        return `var(--accent-color)`;
    }
  };

  return (
    <ChartContainer isLoading={isLoading}>
      <ResponsiveBar
        data={data}
        keys={keys}
        indexBy={indexBy}
        margin={{ top: 40, right: 0, bottom: 40, left: 0 }}
        padding={0.3}
        innerPadding={0}
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        animate={false}
        // colors={({ id, _data }) => {
        //   return getDataColor(id);
        // }}
        theme={{
          axis: {
            ticks: {
              line: {
                stroke: "var(--sidebar-border)",
              },
              text: {
                fill: "var(--body-text)",
              },
            },
          },
          grid: {
            line: {
              stroke: "var(--sidebar-border)",
            },
          },
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legendPosition: "middle",
          legendOffset: 32,
          truncateTickAt: 0,
          format: (value, index) => {
            return format(value, index);
          },
        }}
        axisLeft={null}
        labelSkipWidth={12}
        labelSkipHeight={12}
        enableLabel={false}
        // labelTextColor={{
        //   from: "color",
        //   modifiers: [["darker", 1.6]],
        // }}
        role="application"
      />
    </ChartContainer>
  );
};

export default BarChart;
