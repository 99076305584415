import React, { useEffect, useState, useContext } from "react";

// Hooks
import { useApi } from "./useApi";
import { useAuth } from "./useAuth";

// Set the context
export const Users = React.createContext();

export const UsersProvider = ({ children }) => {
  const { API } = useApi();
  const { isAuthenticated } = useAuth();

  // Users States
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [isRemovingUser, setIsRemovingUser] = useState([]);

  // Roles States
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    if (!isAuthenticated) return;
    if (API.isReady) {
      const getUsers = async () => {
        await API.getUsers((users) => {
          setUsers(users);
          setFilteredUsers(users);
        });
      };

      getUsers();

      const getRoles = async () => {
        await API.getRoles((roles) => {
          setRoles(roles);
        });
      };

      getRoles();
    }
  }, [isAuthenticated, API.isReady]);

  const handleFilter = (query) => {
    setFilteredUsers(() => {
      return users.filter((user) =>
        user.name.toLowerCase().includes(query.toLowerCase())
      );
    });
  };

  const isUserInvited = (email) => {
    return users.some((user) => user.email === email);
  };

  const inviteUser = async (emailAndRoleArray, callback = () => {}) => {
    await API.inviteUsers(emailAndRoleArray, (invitedUsers) => {
      if (Array.isArray(invitedUsers) && invitedUsers.length > 0) {
        invitedUsers.map((user) => {
          if (user.error) {
            console.log(user.error);
            return;
          } else {
            setUsers((currentUsers) => [...currentUsers, user.invitedUser]);
            setFilteredUsers((currentUsers) => [
              ...currentUsers,
              user.invitedUser,
            ]);
          }
        });
      }
      callback(invitedUsers);
    });
  };

  const removeUsers = async (users, callback = () => {}) => {
    setIsRemovingUser(users);
    await API.removeUsers({ users }, (removedUsers) => {
      if (Array.isArray(removedUsers) && removedUsers.length > 0) {
        removedUsers.forEach((user) => {
          if (user.error) {
            console.log(user.error);
            return;
          } else {
            setUsers((currentUsers) =>
              currentUsers.filter(
                (currentUser) => currentUser._id !== user.userData._id
              )
            );
            setFilteredUsers((currentFilteredUsers) =>
              currentFilteredUsers.filter(
                (currentFilteredUser) =>
                  currentFilteredUser._id !== user.userData._id
              )
            );
            setIsRemovingUser([]);
          }
        });
      }
      callback(removedUsers);
    });
  };

  return (
    <Users.Provider
      value={{
        users,
        roles,
        handleFilter,
        filteredUsers,
        isUserInvited,
        inviteUser,
        removeUsers,
        isRemovingUser
      }}
    >
      {children}
    </Users.Provider>
  );
};

// Custom hook to use the AuthContext
export const useUsers = () => useContext(Users);
