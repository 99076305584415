import React from "react";
import styled from "styled-components";

// Component Library
import {
  Paragraph
} from "@saberapp/react-component-library";

const ModalContent = styled.div`
padding: 16px;
`;

export const ConfirmationModalContent = ({ description, resources }) => {
  return <ModalContent><Paragraph>{description}</Paragraph></ModalContent>;
};
