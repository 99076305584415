import React, { forwardRef, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { motion, useAnimation } from "framer-motion";

// Hooks
import { ContextMenu as ContextMenuContext } from "../../../hooks/useContextMenu";

// Component Library
import {
  Line
} from "@saberapp/react-component-library";

import IconWrapper from "../../icons/IconWrapper";

import { ReactComponent as IconDropdown } from "../../icons/20/solid/play.svg";

const ContextMenuOverlay = styled.div`
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100%;
  inset: 0px;
`;
const ContextMenuClickArea = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  inset: 0px;
`;

const ContextMenuContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 4px;
  position: fixed;
  backdrop-filter: blur(8px);
  background: var(--context-menu-bg);
  border: 1px solid var(--context-menu-border);
  width: 180px;
  max-height: 600px;
  overflow: auto;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 24px;
  box-sizing: border-box;
  ${({ top, left }) => `transform: translate3d( ${left}px, ${top}px, 0px);`}
`;

const StyledMenuItem = styled.div`
  padding: 8px;
  display: flex;
  border-radius: 6px;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  color: var(--heading-text);

  &:hover {
    background: var(--context-menu-item-bg-hover);
  }

  svg {
    color: var(--heading-text);
  }
`;

const Left = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
const Right = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const MenuItem = ({ item }) => {
  const { contextMenu } = useContext(ContextMenuContext);
  // TODO: Evaluate if this should be the norm or if its temp
  // Context: an item can either be a function that takes the data as a param and
  // returns the menu item conditionally, or its just the menu item
  item = typeof item === "function" ? item(contextMenu.data) : item;
  return item !== null ? (
    <StyledMenuItem
      onClick={(e) => {
        console.log("Click on menu item", item.items);
        if (!item.items) {
          item.onClick(e, contextMenu.data);
          contextMenu.close();
        }
      }}
      onMouseEnter={(e) => {
        if (item.items?.length > 0) {
          contextMenu.openSubMenu(e, item.items);
        }
      }}
    >
      <Left>
        {item?.icon && <IconWrapper>{item?.icon}</IconWrapper>}
        {item?.title}
      </Left>
      {item.items?.length > 0 && (
        <Right>
          <IconWrapper>
            <IconDropdown />
          </IconWrapper>
        </Right>
      )}
    </StyledMenuItem>
  ) : (
    <></>
  );
};

const ContextMenu = ({ menuItems }) => {
  const { contextMenu } = useContext(ContextMenuContext);

  const controls = useAnimation();

  useEffect(() => {
    if (contextMenu.isOpen) {
      controls.start({ opacity: 1 });
    } else {
      controls.start({ opacity: 0 });
    }
  }, [contextMenu.isOpen, controls]);

  return (
    contextMenu.isOpen && (
      <ContextMenuOverlay isOpen={contextMenu.isOpen}>
        <ContextMenuClickArea
          onContextMenu={() => {}}
          onMouseDown={() => {
            contextMenu.close();
          }}
        />
        <ContextMenuContainer
          ref={contextMenu.menuRef}
          top={contextMenu.y}
          left={contextMenu.x}
          initial={{ opacity: 0 }}
          animate={controls}
        >
          {menuItems.map((item, i) => {
            if (item.divider) {
              return <Line distance="tiny" />;
            } else {
              return <MenuItem key={i} item={item} />;
            }
          })}
        </ContextMenuContainer>
        {contextMenu.isSubMenuOpen && (
          <ContextMenuContainer
          ref={contextMenu.subMenuRef}
            top={contextMenu.subMenuPosition.y}
            left={contextMenu.subMenuPosition.x}
          >
            {contextMenu.subMenuData.map((item, i) => (
              <MenuItem key={i} item={item} />
            ))}
          </ContextMenuContainer>
        )}
      </ContextMenuOverlay>
    )
  );
};

export default ContextMenu;
