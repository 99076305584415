import React from "react";
import styled from "styled-components";

import { Paragraph } from "@saberapp/react-component-library";

const Container = styled.div`
  width: 100%;
  height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${(props) =>
    props.border === "true" &&
    `border: 1px dashed var(--card-border);border-radius: 6px;`}
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  text-align: center;
  width: 40%;
`;

const LoadingState = ({ text, border = "false" }) => {
  return (
    <Container border={border}>
      <Inner>
        <Paragraph>{text ? text : `Loading...`}</Paragraph>
      </Inner>
    </Container>
  );
};

export default LoadingState;
