import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";

// Hooks
import { Resources } from "../../../../hooks/useResources";
import { useUsers } from "../../../../hooks/useUsers";

// Component Library
import { Loader, TagPicker } from "@saberapp/react-component-library";

import { EditHeader } from "./components/EditHeader";
import { EditFooter } from "./components/EditFooter";

const ManageReviewersContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const LoadOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  padding: 22px;
  padding-bottom: 0;
  gap: 16px;
`;

const TagsList = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;

const ModalHeading = styled.h5`
  font-weight: 500;
  font-size: 12px;
  color: var(--body-text);
  margin: 0;
`;

const EditReviewers = ({ closeModal, resourceToEdit }) => {
  const { handleUpdate } = useContext(Resources);

  const {
    filteredUsers,
  } = useUsers();

  // Loading State
  const [isLoading, setLoading] = useState(false);

  // Resource State
  const [resources, setResources] = useState([]);

  // Users states
  const [existingReviewers, setExistingReviewers] = useState([])
  const [selectedReviewers, setSelectedReviewers] = useState([]);


  useEffect(() => {
    Array.isArray(resourceToEdit)
      ? setResources(resourceToEdit)
      : setResources([resourceToEdit]);
  }, []);

  // Set the reviewers when the resources are loaded
  useEffect(() => {
    if (resources.length === 1) {
      setExistingReviewers(resources[0].reviewers);
      setSelectedReviewers(resources[0].reviewers);
    } else if (resources.length > 1) {
      setExistingReviewers([]);
      setSelectedReviewers([]);
    }
  }, [resources]);

  // Used to also be able to add tags to the selected tags from outside of the TagPicker
  const handleReviewerSelection = (reviewer) => {
    setSelectedReviewers((currentReviewers) => {
        return !currentReviewers.some((currentReviewer) => currentReviewer._id === reviewer._id)
          ? [...currentReviewers, reviewer]
          : currentReviewers.filter((currentReviewer) => currentReviewer._id !== reviewer._id);
    });
  };

  return (
    <ManageReviewersContainer>
      {isLoading && (
        <LoadOverlay>
          <Loader></Loader>
        </LoadOverlay>
      )}

      <EditHeader resources={resources} closeModal={closeModal} />

      <Section>
        <ModalHeading>
          {resources.length > 1
            ? `Assign reviewers to these ${resources.length} docs`
            : "Reviewers"}
        </ModalHeading>
        <TagsList>
          <TagPicker
            name="reviewers"
            availableTags={filteredUsers}
            currentTags={existingReviewers}
            handleTagSelection={handleReviewerSelection}
            showSuggestions={false}
            type="users"
          />
        </TagsList>
      </Section>

      <EditFooter
      closeModal={closeModal}
        onSave={(e) => {
          setLoading(true);
          const resourceIds = resources.map((resource) => resource._id);
          const selectedUserIds = selectedReviewers.map((user) => user._id);

          handleUpdate(
            resourceIds,
            {
              reviewers: selectedUserIds,
            },
            (updatedResource) => {
              setLoading(false);
              closeModal(e);
              return { reviewers: updatedResource.reviewers };
            }
          );
        }}
      />

    </ManageReviewersContainer>
  );
};

export default EditReviewers;
