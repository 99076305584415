import React, { useEffect, useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { useData } from "../../hooks/useData";

import LoadingScreen from "../pages/LoadingScreen";
import SellerHome from "../pages/home/sellerHome";

const RequireAuth = ({ component: Component, ...props }) => {
  const { isAuthenticated, isAdmin, isOwner, isSuperAdmin } = useAuth();
  const { loading, progress } = useData();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(!isAuthenticated);

  useEffect(() => {
    if (!isAuthenticated && !localStorage.getItem("user")) {
      navigate("/sign-in");
    } else {
      setIsLoading(false);
    }
  }, [isAuthenticated]);

  if (!isLoading && !loading) {

    // Dirty solution for now, so Sellers only see their page
    if(!isAdmin && !isOwner){
      return <SellerHome />
    }

    return <Component {...props} />;
  } else {
    return <LoadingScreen progress={progress} />;
  }
};

export default RequireAuth;
