import React from "react";

import { styled } from "styled-components";
import {
  Heading,
  Paragraph,
  Card,
  Avatar,
  FileTypeIcon,
  Tag,
  Button
} from "@saberapp/react-component-library";
import { IconWrapper } from "@saberapp/icons";

import { useAuth } from "../../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";

const StatsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  margin-top: 16px;
`;

const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 8px;
`;

const CardInner = styled.div`
  display: flex;
  gap: 32px;
`;
const Left = styled.div`
  min-width: 80px;
`;
const Right = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const UserContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const Stats = styled.div`
  border-top: 1px solid var(--card-border);
  padding-top: 16px;
`;

const Stat = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Org = () => {
  const { organization, user } = useAuth();
  const navigate = useNavigate();

  return (
    <StatsContainer>
      <Heading size="h4">Hello there, {user.name}! 👋</Heading>
      <Paragraph>You're in the {organization.name} organization</Paragraph>
      {/*<CardsContainer>
        <Stats>
          <CardInner>
            <Left>
              <Heading size="h4">Sellers</Heading>
              <Heading size="h1">15</Heading>
            </Left>
            <Right>
              <Stat>
                <Heading size="h4">Most Active</Heading>
                <UserContainer>
                  <IconWrapper size={20}>
                    <Avatar url="https://placehold.co/16x16" />
                  </IconWrapper>
                  <Paragraph>Prashant Singh</Paragraph>
                </UserContainer>
              </Stat>
            </Right>
          </CardInner>
        </Stats>

        <Stats>
          <CardInner>
            <Left>
              <Heading size="h4">Documents</Heading>
              <Heading size="h1">420</Heading>
            </Left>
            <Right>
              <Stat>
                <Heading size="h4">Most Used</Heading>
                <Tag icon={<FileTypeIcon mimeType={"application/vnd.google-apps.presentation"} />} title="Example Document" />
              </Stat>
              <Stat>
                <Heading size="h4">Most Recent</Heading>
                <Tag icon={<FileTypeIcon mimeType={"application/vnd.google-apps.presentation"} />} title="Example Document" />
              </Stat>
            </Right>
          </CardInner>
        </Stats>
        <Stats>
          <CardInner>

              <Button type="ui" title="View all insights" size="small" onClick={() => {
                navigate(`/${organization.slug}/insights`)
              }} />
          </CardInner>
        </Stats> 
      </CardsContainer>*/}
    </StatsContainer>
  );
};

export default Org;
