import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { IconWrapper } from "@saberapp/icons";
import { useAuth } from "../../../hooks/useAuth";

const SmallLogoContainer = styled.div`
  display: flex;
  width: 20px;
  height: 20px;
  padding: 4px;
  border-radius: 6px;
  background: #fff;
  box-sizing: border-box;
  color: var(--heading-text);

  svg {
    width: 12px;
    height: 12px;
  }
`;

const BrandMarkContainer = styled(SmallLogoContainer)`
  background: #f4b60c;
  color: #0e1016;
`;

const Reference = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--heading-text);
  background: var(--button-bg);
  width: fit-content;
  padding: 4px 6px;
  padding-right: 8px;
  border-radius: 6px;
  font-size: 12px;

  &:hover {
    background: var(--button-bg-hover);
    cursor: pointer;
  }
`;

const Details = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: space-between;

  div {
    display: flex;
    align-items: center;
    gap: 4px;
  }
`;

const References = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;

const ReferencesList = ({ refs, logos }) => {
  const navigate = useNavigate();
  const { organization } = useAuth();

  if (!refs || refs.length === 0) {
    return null;
  }

  return (
    <>
      <Details>
        <div>References used:</div>
      </Details>
      <References>
        {refs.map((ref, index) => (
          <Reference
            key={index}
            onClick={() =>
              ref.docSource === "saber_snippet"
                ? navigate(`/${organization.slug}/answers/${ref._id}`)
                : console.log(ref)
            }
          >
            <IconWrapper size={20}>
              {ref.docSource === "saber_snippet" ? (
                <BrandMarkContainer>{logos["webapp"]}</BrandMarkContainer>
              ) : (
                <SmallLogoContainer>{logos[ref.docSource]}</SmallLogoContainer>
              )}
            </IconWrapper>
            <a href={ref.webViewLink} target="_blank" rel="noopener noreferrer">
              {ref.name}
            </a>
          </Reference>
        ))}
      </References>
    </>
  );
};

export default ReferencesList;
