import React, { useEffect, useState } from "react";
import toast from "react-hot-toast/headless";

// Component Library
import {
  FreshnessScore,
  fileTypesMap,
} from "@saberapp/react-component-library";

// Hooks
import { useApi } from "./useApi";
import { useAuth } from "./useAuth";
// import { useTags } from "./useTags";

// Enums
import { FreshnessScoreValues } from "@saberapp/enums";

// Icons
import { ReactComponent as IconDisplay } from "../components/icons/20/solid/adjustments-horizontal.svg";
import { useData } from "./useData";

// Set the context
export const Resources = React.createContext();

/**
 * Custom Hook: useResources
 * Description: a custom hook to work with all things resources/docs
 * @returns
 */

export const useResources = () => {
  const { API } = useApi();
  const { user, isAuthenticated } = useAuth();
  // const { groups } = useTags();
  const [groups, setGroups] = useState([]); // Quick way to remove tags
  const { resources, setResources } = useData();

  // The hook specific loading states
  const [isLoadingResources, setIsLoadingResources] = useState(true);
  // other loadings states, like for updates

  // The resource data sets
  // const [resources, setResources] = useState([]);
  const [archivedResources, setArchivedResources] = useState([]);
  const [filteredResources, setFilteredResources] = useState([]);
  const [organisedResources, setOrganisedResources] = useState({});
  const [selectedResources, setSelectedResources] = useState([]);

  // Filter States
  const [filter, setFilter] = useState({});
  const [filterOptions, setFilterOptions] = useState([]);
  const [showArchive, setShowArchive] = useState(false);

  // Sorting States
  const [sort, setSort] = useState({ by: "name", order: "ASC" });

  // Grouping States
  const [isGroupedBy, setIsGroupedBy] = useState("");
  const [activeGroup, setActiveGroup] = useState({});
  const [groupOptions, setGroupOptions] = useState([]);

  // Totals & counts
  const [resourcesCount, setResourcesCount] = useState(0);
  const [filterCount, setFilterCount] = useState(0);
  const [needsReviewCount, setNeedsReviewCount] = useState(0);
  const [outdatedCount, setOutdatedCount] = useState(0);

  // On load for the hook
  useEffect(() => {
    if (!isAuthenticated) return;
    getResources();
  }, [isAuthenticated]);

  const getResources = async () => {
    setIsLoadingResources(true);

    // TODO: this function can still be used to run a custom query
    const resourcesFilter = {};

    handleGroupingChange(getGroupOptions()[0]);
    setFilteredResources(
      resources.filter((resource) => resource.isArchived !== true)
    );
    setFilterOptions(getFilterOptions());
    setGroupOptions(getGroupOptions());
    setOrganisedResources(groupByProperty(isGroupedBy, resources));
    setArchivedResources(
      resources.filter((resource) => resource.isArchived === true)
    );
    setIsLoadingResources(false);
  };

  // When the groups are set using useTags, set them as grouping options
  useEffect(() => {
    setGroupOptions(getGroupOptions(groups));
  }, [groups]);

  // When the resources have changed
  useEffect(() => {
    // Honor the grouping... must be a better way
    const g =
      Object.keys(activeGroup).length > 0 ? activeGroup : getGroupOptions()[0];
    handleGroupingChange(g);

    // Set the count every time the resources are updated
    setNeedsReviewCount(
      filteredResources.filter(
        (obj) => obj.freshnessScore === FreshnessScoreValues.NEEDS_REVIEW
      ).length
    );
    setOutdatedCount(
      filteredResources.filter(
        (obj) => obj.freshnessScore === FreshnessScoreValues.OUTDATED
      ).length
    );
    setResourcesCount(filteredResources.length);
  }, [resources]);

  // When the filter changes
  useEffect(() => {
    setFilteredResources(getFilteredResources());
    setFilterCount(getFilterCount());
    if (Object.keys(activeGroup).length > 0) {
      handleGroupingChange(activeGroup, getFilteredResources());
    }
  }, [filter, sort]);

  useEffect(() => {
    handleFilterChange({});
  }, [showArchive]);

  // ---------
  // SELECTING
  // ---------

  /**
   *
   * @param {string} action the action to perform
   * @param {object | array} resource a single resource or an array of resources
   */
  const handleSelectedResources = (action, resource) => {
    let updatedSelectedResources = [...selectedResources];

    // Toggle - this adds or removes the resource(s) depending on if they are in there already or not
    if (action === "toggle") {
      if (selectedResources.some((r) => r._id === resource._id)) {
        updatedSelectedResources = selectedResources.filter(
          (r) => r._id !== resource._id
        );
      } else {
        updatedSelectedResources.push(resource);
      }
    }
    // Set - this clears the selected resources and replaces them with the new resource(s)
    else if (action === "set") {
      updatedSelectedResources = [];
      if (Array.isArray(resource)) {
        updatedSelectedResources = resource;
      } else if (!selectedResources.some((r) => r._id === resource._id)) {
        updatedSelectedResources.push(resource);
      }
    }
    // Clear
    if (action === "clear") {
      updatedSelectedResources = [];
    }

    setSelectedResources(updatedSelectedResources);
  };

  // ---------
  // FILTERING
  // ---------

  const handleShowArchive = (status) => {
    setShowArchive(status);
  };

  /**
   * Gets the possible options a user can filter by
   * @returns
   */
  const getFilterOptions = () => {
    const options = [
      {
        groupName: "freshnessScore",
        options: [
          FreshnessScoreValues.VERIFIED,
          FreshnessScoreValues.NEEDS_REVIEW,
          FreshnessScoreValues.OUTDATED,
          FreshnessScoreValues.UNKNOWN,
        ],
      },
    ];

    return options;
  };

  /**
   * Filters the resources
   * @param {*} fil
   * @param {*} collection
   * @returns
   */
  const getFilteredResources = () => {
    const results = resources.filter((entry) => {
      for (const key in filter) {
        if (key === "tags") {
          let found = false;
          filter[key].map((selectedTag) => {
            if (
              entry.tags.assigned.some((tag) => tag._id === selectedTag.value)
            ) {
              found = true;
            }
          });
          if (found) {
            return false;
          }
        } else if (Array.isArray(filter[key])) {
          if (filter[key].includes(entry[key])) {
            return false;
          }
        } else {
          if (entry[key] === filter[key]) {
            return false;
          }
        }
      }
      return true;
    });

    // SORTING HAPPENS HERE
    // It only sorts by name, but can be expanded to sort by other properties
    const sortedFilteredResources = [...results]; // Create a shallow copy of the resources array

    if (sort.order === "ASC") {
      sortedFilteredResources.sort((a, b) => a.name.localeCompare(b.name));
    } else if (sort.order === "DESC") {
      sortedFilteredResources.sort((a, b) => b.name.localeCompare(a.name));
    } else {
      console.error('Invalid sortOrder. Please use "ASC" or "DESC".');
      return null;
    }

    return sortedFilteredResources;
  };

  /**
   * Handles the filter settings changing in the dropdown
   * @param {String | Object} groupName freshnessScore i.e. If Object, just overwrite the filter
   */
  const handleFilterChange = (groupName) => {
    setFilter(() => {
      const updatedFilter = showArchive
        ? { isArchived: true }
        : { isArchived: false };
      const newFilter = { ...groupName };
      const entries = Object.entries(newFilter);

      entries.forEach(([gn, options]) => {
        if (Array.isArray(options)) {
          options.map((option) => {
            if (!updatedFilter[gn]) {
              updatedFilter[gn] = [option];
            } else {
              updatedFilter[gn].push(option);
            }
          });
        } else {
          updatedFilter[gn] = [options];
        }
      });

      return updatedFilter;
    });
    handleSelectedResources("clear");
  };

  /**
   * Gets the count for the number filters being used
   * @returns
   */
  const getFilterCount = () => {
    let count = 0;
    Object.keys(filter).map((key) => {
      if (Array.isArray(filter[key])) {
        count += filter[key].length;
      } else {
        count++;
      }
    });
    return count - 1; // Subtract one for the isArchived filter that we always have
  };

  // -------
  // SORTING
  // -------

  const handleSortChange = () => {
    setSort((currentSort) => {
      return {
        by: "name",
        order: currentSort.order === "ASC" ? "DESC" : "ASC",
      };
    });
  };

  // --------
  // GROUPING
  // --------

  /**
   * Handle grouping change
   * @param {Object} group
   */
  const handleGroupingChange = (
    group,
    res = filteredResources,
    page = "documents"
  ) => {
    // Hacky solution that no one loves
    // TODO: make this not suck
    if (page === "pages-and-notes" && group.slug === "mimeType") {
      setActiveGroup({
        icon: <IconDisplay />,
        name: "Source",
        slug: "docSource",
        type: "property",
      });
      setIsGroupedBy("Source");

      console.log('rset to docsoure')
    } else {
      setActiveGroup(group);
      setIsGroupedBy(group.name);
    }

    if (group.type === "property") {
      setOrganisedResources(groupByProperty(group.slug, res));
    } else if (group.type === "tagGroup") {
      setOrganisedResources(groupByTagGroup(group.slug, res));
    }
  };

  /**
   * This returns all the possible grouping options,
   * used to popluate the group by Dropdown
   * @param {Array} groups
   * @returns
   */
  const getGroupOptions = (groups) => {
    let options = [
      {
        icon: <FreshnessScore score={FreshnessScoreValues.VERIFIED} />,
        name: "Freshness Score",
        slug: "freshnessScore",
        type: "property",
        description: "How recently it was reviewed",
      },
      {
        icon: <IconDisplay />,
        name: "File Type",
        slug: "mimeType",
        type: "property",
        description: "Presentations, sheets, docs,...",
      },
      // {
      //   icon: <IconDisplay />,
      //   name: "Content Type",
      //   slug: "contentType",
      //   type: "property",
      //   description: "Pitch Decks, Case Studies, Press Releases,...",
      // },
      {
        icon: <IconDisplay />,
        name: "Index Status",
        slug: "indexStatus",
        type: "property",
        description: "Indexed, not indexed, skipped,...",
      },
    ];

    // Removed this for now, bring back once we go deeper into tagging

    // if (
    //   groups?.length > 0 &&
    //   groups.map((group) => {
    //     if (group.tags.length > 0) {
    //       let description = "";
    //       group?.tags?.map((tag) => {
    //         description += tag.name + ", ";
    //       });
    //       description = description.slice(0, -2);
    //       options.push({
    //         icon: <IconDisplay />,
    //         name: group.name,
    //         slug: group.slug,
    //         type: "tagGroup",
    //         description: description,
    //       });
    //     }
    //   })
    // );

    return options;
  };

  /**
   * Function to group the resources by a property
   * @param {String} property
   * @param {Array} arr
   * @returns grouped resources in an Array
   */
  const groupByProperty = (property, arr = filteredResources) => {
    const groupedResources = arr.reduce((result, obj) => {
      let key = "Unknown";
      if (property === "mimeType") {
        if (fileTypesMap[obj[property]]) {
          key = fileTypesMap[obj[property]].group;
        } else {
          key = "Unknown";
        }
      } else if (property === "contentType") {
        // TODO: For grouping by tags, can do something similar
        key = obj[property]?.name || "Unknown";
      } else {
        key = obj[property];
      }

      if (!result[key]) {
        result[key] = {
          resources: [],
        };
      }
      result[key].resources.push(obj);
      return result;
    }, {});

    // SORTING HAPPENS HERE
    // It only sorts by name, but can be expanded to sort by other properties
    const sortedGroupedResources = {};
    Object.keys(groupedResources).forEach((group) => {
      const resources = [...groupedResources[group].resources]; // Create a shallow copy of the resources array
      if (sort.order === "ASC") {
        resources.sort((a, b) => a.name.localeCompare(b.name));
      } else if (sort.order === "DESC") {
        resources.sort((a, b) => b.name.localeCompare(a.name));
      } else {
        console.error('Invalid sortOrder. Please use "ASC" or "DESC".');
        return null;
      }
      sortedGroupedResources[group] = { resources };
    });

    // We want a specific order for the Freshness Score
    if (property === "freshnessScore") {
      const order = [
        FreshnessScoreValues.OUTDATED,
        FreshnessScoreValues.NEEDS_REVIEW,
        FreshnessScoreValues.UNKNOWN,
        FreshnessScoreValues.VERIFIED,
      ];
      let rearrangedSortedGroupedResources = {};
      order.forEach((key) => {
        if (sortedGroupedResources.hasOwnProperty(key)) {
          rearrangedSortedGroupedResources[key] = sortedGroupedResources[key];
        }
      });
      return rearrangedSortedGroupedResources;
    }

    return sortedGroupedResources;
  };

  /**
   * Function to group the resources by tag group
   * @param {String} tagGroupSlug
   * @param {Arrat} arr
   * @returns grouped resources in an Array
   */
  const groupByTagGroup = (tagGroupSlug, arr = filteredResources) => {
    return arr.reduce((result, obj) => {
      let foundMatchingTag = false;
      obj.tags.assigned.forEach((tag) => {
        if (tag.tagGroup.slug === tagGroupSlug) {
          const tagKey = tag.name;
          if (!result[tagKey]) {
            result[tagKey] = {
              description: tag.description,
              resources: [],
            };
          }
          result[tagKey].resources.push(obj);
          foundMatchingTag = true;
        }
      });

      // If no matching tag was found, add to the "other" group
      if (!foundMatchingTag) {
        if (!result["Other"]) {
          result["Other"] = [];
        }
        result["Other"].push(obj);
      }

      return result;
    }, {});
  };

  // This function is called when the API call was successful, and aims to update the
  // resources across the different states
  const updateResourcesStates = (
    id,
    updatedResource,
    getUpdatedProps = () => []
  ) => {
    const updatedProps = getUpdatedProps(updatedResource);

    // This function takes the prevResources of the different states and returns an update array
    // of resources, which in turn is used to update the state
    const getUpdatedResources = (prevResources) => {
      const ids = typeof id === "string" ? [id] : id;
      if (updatedProps.length === 0) {
        return prevResources.filter((resource) => !ids.includes(resource._id));
      } else {
        const updatedResources = prevResources.map((resource) => {
          if (ids.includes(resource._id)) {
            return {
              ...resource,
              ...updatedProps,
            };
          }
          return resource;
        });
        return updatedResources;
      }
    };

    // Update everywhere this resource (or resources) exist
    updateStates((prevResources) => getUpdatedResources(prevResources));
  };
  const updateStates = (func) => {
    setResources(func);
    setFilteredResources(func);
    setSelectedResources(func);
  };

  /**
   * This function is used to update one or more resources, and also makes sure the resources are
   * updated in the local state which is used to populate all the views
   * @param {String | Array} id If an array is passed instead of an ID string, we use the bulk update instead
   * @param {Object} data This is the data the resources should be updated with
   * @param {Function} §§getUpdatedProps Optional - This function returns the props that need to be updated in the state
   * @param {Function} cb Optional - This is a callback function that can be passed
   */
  const handleUpdate = async (
    id,
    data,
    getUpdatedProps = () => {},
    cb = () => {}
  ) => {
    // Depending on if id is a string or an array, perform the appropriate API call and call the two main callbacks:
    // updateResourcesStates and cb, which can be passed to this function
    if (typeof id === "string") {
      await API.updateResource(id, data, (updatedResource) => {
        updateResourcesStates(id, updatedResource, getUpdatedProps);
        cb(updatedResource);
      });
    } else if (Array.isArray(id)) {
      const ids = id;
      await API.bulkUpdateResources(
        {
          resourcesIds: ids,
          data: data,
        },
        (updatedResources) => {
          // TODO: does not seem right, accepts a single id, needs to accept in array
          updatedResources.updatedResources.map((updatedResource) => {
            updateResourcesStates(
              updatedResource._id,
              updatedResource,
              getUpdatedProps
            );
          });
          cb(updatedResources);
        }
      );
    }
  };

  // todo replace with handleUpdate?
  const updateContactsForResource = async (resourceId, data) =>
    await API.updateShareResourceContacts(resourceId, data);

  const getShareContactsForResource = async (resourceId) =>
    await API.getSharedContacts(resourceId);

  // todo this approve flow is currently unused. We need to determine what approach we want to take.
  const handleUpdateTags = async (id, approvedTags, cb = () => {}) => {
    await API.approveResourceTags(id, approvedTags, (updatedResource) => {
      updateStates((prevResources) => {
        return prevResources.map((resource) => {
          if (updatedResource._id === resource._id) {
            return { ...resource, tags: updatedResource.tags };
          }
          return resource;
        });
      });
    });
  };

  /**
   * Handler for updating one resource
   * TODO: Add support for many
   * @param {Object} formData data extracted from the edit form
   */
  const handleResourceEdit = async (formData) => {
    const id = formData.get("resourceId");
    const tagPickerValues = JSON.parse(formData.get("hiddenTagPickerValues"));
    const contentType = JSON.parse(formData.get("contentType"));
    const reviewers = JSON.parse(formData.get("reviewers"));
    const visibility = formData.get("visibility");

    const tagIds = tagPickerValues.map((tag) => tag.value);
    const reviewerIds = reviewers.map((reviewer) => reviewer.value);

    handleUpdate(
      id,
      {
        tags: tagIds,
        visibility: visibility,
        contentType: contentType[0].value,
        reviewers: reviewerIds,
      },
      (updatedResource) => {
        return {
          tags: updatedResource.resource.tags,
          visibility: visibility,
          contentType: contentType[0].value,
          reviewers: updatedResource.resource.reviewers,
        };
      }
    );
  };

  const handleUpdateVisibility = async (id, visibility) => {
    handleUpdate(
      id,
      {
        visibility: visibility,
      },
      (updatedResource) => {
        return {
          visibility: updatedResource.visibility,
        };
      }
    );
  };

  const handleUpdateContentType = async (id, contentType) => {
    handleUpdate(
      id,
      {
        contentType: contentType,
      },
      () => {
        return {
          contentType: contentType,
        };
      }
    );
  };

  /**
   * Handler for marking one or many resources as reviewed
   * @param {String | Array} id  Either a string ID or an array of string IDs
   * @param {Function} cb Optional - This is a callback function that can be passed
   */
  const handleMarkAsReviewed = async (id, cb = () => {}) => {
    handleUpdate(
      id,
      {
        freshnessScore: FreshnessScoreValues.VERIFIED,
        reviewedTime: Date.now(),
        reviewedBy: user._id,
      },
      () => {
        return {
          freshnessScore: FreshnessScoreValues.VERIFIED,
        };
      },
      cb
    );
  };

  const handleUpdateFreshnessScore = async (id, score, cb = () => {}) => {
    handleUpdate(
      id,
      {
        freshnessScore: score,
        reviewedTime: Date.now(),
        reviewedBy: user._id,
      },
      () => {
        return {
          freshnessScore: score,
        };
      },
      cb
    );
  };

  /**
   * Handler for archiving or unarchiving one or many resources
   * @param {String | Array} id  Either a string ID or an array of string IDs
   * @param {Boolean} toArchive If set to false, it will unarchive the resource
   */
  const handleArchive = async (id, toArchive = true, cb = () => {}) => {
    const options = toArchive
      ? {
          isArchived: true,
          archivedMeta: {
            archivedBy: user._id,
          },
        }
      : { isArchived: false };
    handleUpdate(
      id,
      options,
      () => {
        return [];
      },
      cb
    );
  };

  /**
   * Request a resource to be reviewed
   * @param {String} id
   */
  const handleRequestReview = async (resource, cb = () => {}) => {
    handleUpdate(
      resource._id,
      {
        reviewRequests: [...resource.reviewRequests, { requestedBy: user._id }],
      },
      () => {
        return {
          reviewRequests: [
            ...resource.reviewRequests,
            { requestedBy: user._id },
          ],
        };
      },
      cb
    );
  };

  /**
   * Handler for bulk actions. This wrapper makes sure bulk actions can only be performed
   * after confirming the action using a modal
   * @param {String} action Which action needs to be performed
   * @param {Boolean} confirmed Has the bulk action been confirmed
   * @param {Boolean} targetSelectedResources Whether to use the selectedResources instead of resources
   */
  const handleBulkAction = (action, targetSelectedResources = false) => {
    // Clear the modal data

    if (action === "archive") {
      const resourcesToArchive = targetSelectedResources
        ? [...selectedResources].map((obj) => obj._id)
        : resources
            .filter(
              (obj) => obj.freshnessScore === FreshnessScoreValues.OUTDATED
            )
            .map((obj) => obj._id);

      handleArchive(resourcesToArchive, true, (updatedResources) => {
        toast.success(
          `${resourcesToArchive.length} docs archived successfully`
        );
      });
    } else if (action === "unarchive") {
      const resourcesToArchive = targetSelectedResources
        ? [...selectedResources].map((obj) => obj._id)
        : resources
            .filter(
              (obj) => obj.freshnessScore === FreshnessScoreValues.OUTDATED
            )
            .map((obj) => obj._id);

      handleArchive(resourcesToArchive, false, (updatedResources) => {
        toast.success(
          `${resourcesToArchive.length} docs unarchived successfully`
        );
      });
    } else if (action === "review") {
      const resourcesToMarkAsReviewed = targetSelectedResources
        ? [...selectedResources].map((obj) => obj._id)
        : resources
            .filter(
              (obj) => obj.freshnessScore === FreshnessScoreValues.NEEDS_REVIEW
            )
            .map((obj) => obj._id);

      handleMarkAsReviewed(resourcesToMarkAsReviewed, (updatedResources) => {
        toast.success(
          `${resourcesToMarkAsReviewed.length} docs marked as reviewed`
        );
      });
    }
  };

  // Possible temporary solution
  const getOrganisedResourcesByPage = (page) => {
    return groupByProperty(
      activeGroup.slug,
      getFilteredResourcesByPage(page)
    );
  };

  const getFilteredResourcesByPage = (page) => {
    // const shallowCopy = [...filteredResources];
    return page === "documents"
      ? filteredResources.filter(
          (resource) => resource.docSource === "google_drive"
        )
      : filteredResources.filter(
          (resource) =>
            resource.docSource === "confluence" ||
            resource.docSource === "notion"
        );
  };

  return {
    // Loading states
    isLoadingResources,
    // Resource collections
    resources,
    organisedResources,
    filteredResources,
    selectedResources,
    setSelectedResources,
    archivedResources,
    // Handlers
    handleSelectedResources,
    handleShowArchive,
    handleFilterChange,
    handleSortChange,
    handleGroupingChange,
    handleUpdate,
    handleUpdateTags,
    updateContactsForResource,
    handleResourceEdit,
    handleMarkAsReviewed,
    handleArchive,
    handleRequestReview,
    handleBulkAction,
    handleUpdateFreshnessScore,
    handleUpdateVisibility,
    handleUpdateContentType,
    // Counts
    resourcesCount,
    filterCount,
    needsReviewCount,
    outdatedCount,
    // Other
    getResources,
    getShareContactsForResource,
    filter,
    filterOptions,
    activeGroup,
    isGroupedBy,
    groupOptions,
    sort,
    getOrganisedResourcesByPage,
    getFilteredResourcesByPage,
  };
};

export const ResourcesProvider = ({ children }) => {
  const resourcesHook = useResources();
  return (
    <Resources.Provider value={resourcesHook}>{children}</Resources.Provider>
  );
};
