import React, { useEffect, useState, useContext } from "react";
import { paragon } from "@useparagon/connect";

// Enums
import { HttpMethod } from "@saberapp/enums";

// Hooks
import { useApi } from "./useApi";
import { useAuth } from "./useAuth";

// Icons
import { ReactComponent as IconGoogleDrive } from "../components/icons/connectors/google-drive.svg";
import { ReactComponent as IconGoogleMail } from "../components/icons/connectors/google-mail.svg";
import { ReactComponent as IconGoogleMeet } from "../components/icons/connectors/google-meet.svg";
import { ReactComponent as IconGoogleCalendar } from "../components/icons/connectors/google-calendar.svg";
import { ReactComponent as IconPipedrive } from "../components/icons/connectors/pipedrive.svg";
import { ReactComponent as IconSlack } from "../components/icons/connectors/slack.svg";
import { ReactComponent as IconSalesforce } from "../components/icons/connectors/salesforce.svg";
import { useData } from "./useData";

// Set the context
export const Connectors = React.createContext();

export const ConnectorsProvider = ({ children }) => {
  const { API } = useApi();
  const { user, organization, isParagonAuthenticated } = useAuth();
  const { connectors, setConnectors, reload } = useData();

  // const [isConnectorsLoading, setIsConnectorsLoading] = useState(false);
  const [isConnectorsUpdating, setIsConnectorsUpdating] = useState(false);
  const [isConnectorCatalogLoading, setIsConnectorCatalogLoading] =
    useState(true);

  const [isConnecting, setIsConnecting] = useState(false);
  const [isDisconnecting, setIsDisconnecting] = useState(false);

  const [connectorCatalog, setConnectorCatalog] = useState([]);
  // const [connectors, setConnectors] = useState([]);

  // useEffect(() => {
  //   // if (!isAuthenticated) return;
  //   // if (API.isReady) {
  //   //   (async () => {
  //   //     await getConnectors();
  //   //   })();
  //   // }

  // }, [isAuthenticated, API.isReady]);

  useEffect(() => {
    if (isParagonAuthenticated) {
      (async () => {
        getConnectorCatalog();
      })();
    }
  }, [isParagonAuthenticated]);

  const getConnectorCatalog = async () => {
    const connectorCategories = {
      CONTENT: "Content Integrations",
      CRM: "CRM Integrations",
      WORKFLOW: "Workflow Integrations",
    };

    const connectorStatus = {
      COMING_SOON: "Coming Soon",
    };

    const nativeCatalog = [
      {
        name: "Google Drive",
        source: "google_drive",
        slug: "google-drive",
        description: "Sync with your sales content in Google Drive",
        icon: <IconGoogleDrive />,
        category: connectorCategories.CONTENT,
        native: true,
      },
      {
        name: "Slack",
        source: "slack",
        slug: "slack",
        description: "Ask questions and get recommendations through Slack",
        icon: <IconSlack />,
        category: connectorCategories.WORKFLOW,
        native: true,
      },
      // {
      //   name: "Salesforce",
      //   source: "salesforce",
      //   slug: "salesforce",
      //   description:
      //     "Sync and automate your Salesforce account and opportunity data",
      //   icon: <IconSalesforce />,
      //   category: connectorCategories.CRM,
      //   native: true,
      // },
      // {
      //   name: "Pipedrive",
      //   source: "pipedrive",
      //   slug: "pipedrive",
      //   description:
      //     "Sync and automate your Pipedrive account and opportunity data",
      //   icon: <IconPipedrive />,
      //   category: connectorCategories.CRM,
      //   native: true,
      // },
      // {
      //   name: "Hubspot",
      //   source: "hubspot",
      //   slug: "hubspot",
      //   icon: "",
      //   category: connectorCategories.CRM,
      //   native: true,
      // },
    ];

    const paragonCatalog = paragon.getIntegrationMetadata();
    const transformedParagonCatalog = [];

    paragonCatalog.map((integration) => {
      transformedParagonCatalog.push({
        name: integration.name,
        source: integration.type,
        slug: integration.type,
        icon: integration.icon,
        description:
          integration?.description ||
          `Sync and automate sales-related data in ${integration.name}`,
        category: connectorCategories.CONTENT,
        native: false,
      });
    });

    setConnectorCatalog([...nativeCatalog, ...transformedParagonCatalog]);
    setIsConnectorCatalogLoading(false);

    // paragon.getIntegrationMetadata().map((integration) => {
    //   const paragonUser = paragon.getUser();
    //   const integrationEnabled =
    //     paragonUser?.integrations?.[integration.type]?.enabled;
    // });
  };

  // const getConnectors = async () => {
  //   await API.call(HttpMethod.GET, "/connectors/", {}, (sources) => {
  //     setConnectors(sources.connectors);
  //     setIsConnectorsLoading(false);
  //   });
  // };

  const doesConnectorExist = (name) => {
    return connectors.some((connector) => connector.source === name);
  };

  const getConnectorFromCatalog = (slug) => {
    return connectorCatalog.find((connector) => connector.slug === slug);
  };

  const getConnectorByName = (name) => {
    return doesConnectorExist(name)
      ? connectors.filter((connector) => connector.source === name)[0]
      : null;
  };

  const doesUserHaveConnector = (name) => {
    return connectors.some(
      (connector) =>
        connector.source === name && connector.enabledBy._id === user._id
    );
  };

  const getUserConnectorByName = (name) => {
    return doesConnectorExist(name)
      ? connectors.filter(
          (connector) =>
            connector.source === name && connector.enabledBy._id === user._id
        )[0]
      : null;
  };

  const connect = async (connectorId, slug, params) => {
    setIsConnectorsUpdating(true);
    setIsConnecting(true);
    try {
      await API.call(
        HttpMethod.POST,
        `/connectors/${slug}/connect`,
        {
          connector_id: connectorId,
          organization_id: organization._id,
          params
        },
        (data) => {
          updateConnectors("update", data.updatedConnector);
        }
      );
    } catch (error) {
      console.error("Error fetching data-soruces:", error);
    }
  };

  const connectParagon = async (paragonConnector) => {
    setIsConnectorsUpdating(true);
    setIsConnecting(true);
    try {
      await API.call(
        HttpMethod.POST,
        `/connectors/paragon/connect`,
        {
          name: paragonConnector.name,
          source: paragonConnector.source,
          integrationId: paragonConnector.integrationId,
        },
        (data) => {
          if (data.updatedConnector) {
            data.updatedConnector.enabledBy = data.user;
            updateConnectors("update", data.updatedConnector);
          } else if (data.newConnector) {
            data.newConnector.enabledBy = data.user;
            updateConnectors("add", data.newConnector);
          }
        }
      );
    } catch (error) {
      console.error("Error fetching data-soruces:", error);
    }
  };

  const disconnectParagon = async (paragonConnectorId) => {
    setIsConnectorsUpdating(true);
    setIsDisconnecting(true);

    try {
      await API.call(
        HttpMethod.POST,
        `/connectors/paragon/disconnect`,
        {
          id: paragonConnectorId,
        },
        (data) => {
          console.log("data", data);
          updateConnectors("update", data.updatedConnector);
        }
      );
    } catch (error) {
      console.error("Error fetching data-soruces:", error);
    }
  };

  /**
   * Disconnects a data source
   * TODO: should probably be nicer than a dynamic endpoint?
   * @param {*} connectorId
   * @param {*} slug
   */
  const disconnect = async (connectorId, slug) => {
    setIsConnectorsUpdating(true);
    setIsDisconnecting(true);
    try {
      await API.call(
        HttpMethod.POST,
        `/connectors/${slug}/disconnect`,
        {
          connector_id: connectorId,
          organization_id: organization._id,
        },
        (data) => {
          updateConnectors("update", data.updatedConnector);
        }
      );
    } catch (error) {
      console.error("Error fetching data-sources:", error);
    }
  };

  const update = async (connectorId, connectorName, data) => {
    try {
      await API.call(
        HttpMethod.PUT,
        `/connectors/${connectorName}/`,
        {
          ...data,
          connector_id: connectorId,
          organization_id: organization._id,
        },
        (data) => {
          updateConnectors("update", data.updatedConnector);
        }
      );
    } catch (error) {
      console.error("Error fetching data-sources:", error);
    }
  };

  const updateConnectors = (action, data) => {
    setIsConnectorsUpdating(true);
    if (action === "add") {
      setConnectors((currentConnectors) => [...currentConnectors, data]);
    } else if (action === "update") {
      setConnectors((currentConnectors) => {
        return currentConnectors.map((connector) => {
          if (connector._id === data._id) {
            return data;
          }
          return connector;
        });
      });
    }
    setIsDisconnecting(false);
    setIsConnecting(false);
    setIsConnectorsUpdating(false);
  };

  const pollConnectorCallbackPopup = (_popup) => {
    const poll = setInterval(() => {
      if (!_popup || _popup.closed) {
        clearInterval(poll);
        setIsConnecting(false);
        reload("Connectors");
      }
    }, 100);
  };

  return (
    <Connectors.Provider
      value={{
        // isConnectorsLoading,
        isConnectorCatalogLoading,
        connectorCatalog,
        connectors,
        doesConnectorExist,
        getConnectorByName,
        getConnectorFromCatalog,
        connect,
        connectParagon,
        disconnect,
        disconnectParagon,
        update,
        updateConnectors,
        isConnectorsUpdating,
        setIsConnectorsUpdating,
        isConnecting,
        setIsConnecting,
        isDisconnecting,
        setIsDisconnecting,
        doesUserHaveConnector,
        getUserConnectorByName,
        pollConnectorCallbackPopup,
      }}
    >
      {children}
    </Connectors.Provider>
  );
};

// Custom hook to use the AuthContext
export const useConnectors = () => useContext(Connectors);
