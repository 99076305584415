import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import DashboardTemplate from "../../templates/DashboardTemplate";
import TopNavigation from "../../layouts/main-top-bar";
import SearchBox from "../../layouts/search";
import { useAuth } from "../../../hooks/useAuth";

import { Button } from "@saberapp/react-component-library";
import { IconArrowUp } from "@saberapp/icons";

import Sidebar from "./components/Sidebar";
import EmptyState from "./components/EmptyState";
import { useApi } from "../../../hooks/useApi";
import Question from "./components/Question";

const Container = styled.div`
  display: flex;
  box-sizing: border-box;
  height: calc(100% - 62px);
`;

const Item = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

const ItemContent = styled.div`
  box-sizing: border-box;
  padding: 40px 48px 40px 42px;
  padding-bottom: 240px;
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const ItemContentColumn = styled.div`
  width: 80%;
  min-height: 100%;
  margin: 0 auto;
`;

const ItemAction = styled.div`
  width: 100%;
  padding: 20px 40px;
  box-sizing: border-box;

  position: absolute;
  bottom: 0;
  z-index: 1;
`;

const ItemActionBackground = styled.div`
  background: var(--main-bg);
  background: color-mix(in srgb, var(--main-bg) 50%, transparent);
  backdrop-filter: blur(5px);
  padding: 8px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 80%;
  margin: 0 auto;
`;

const Options = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const TextareaContainer = styled.div`
  box-sizing: border-box;
  //   border: 1px solid var(--card-border);
  //   background: var(--sidebar-bg);
  border-radius: 6px;
  padding: 16px;
  //   padding-right: 0;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
`;

const Textarea = styled.textarea`
  min-height: 100px;
  width: 100%;
  resize: none;
  color: var(--heading-text);
  outline: none;
  background: var(--main-bg);
  border: 1px solid var(--card-border);
  border-radius: 6px;
  box-sizing: border-box;
  padding: 16px;
`;

const SmallText = styled.div`
  font-size: 11px;
  width: 280px;
  display: flex;
  align-items: center;
  line-height: 1.3;
`;

const Sandbox = () => {
  const { organization } = useAuth();
  const { API } = useApi();

  const [textareaQuestion, setTextareaQuestion] = useState("");
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (question.length > 0) {
      setAnswer({});
      setIsLoading(true);
      (async () => {
        await API.promptSearch({ query: { q: question } }, (data) => {
          console.log(data);
          setAnswer(data);
          setIsLoading(false);
        });
      })();
    }
  }, [question]);

  return (
    <DashboardTemplate>
      <TopNavigation
        links={[{ title: "Sandbox", path: `/${organization.slug}/sandbox` }]}
      >
        <Options></Options>
      </TopNavigation>

      <SearchBox pageSpecificActions={[]} />

      <Container>
        <Item>
          <ItemContent>
            <ItemContentColumn>
              {!question && <EmptyState setQuestion={setQuestion} />}

              {question && (
                <Question
                  question={question}
                  answer={answer}
                  isLoading={isLoading}
                />
              )}
            </ItemContentColumn>
          </ItemContent>
          <ItemAction>
            <ItemActionBackground>
              <Textarea
                placeholder="Ask a question..."
                value={textareaQuestion}
                onChange={(e) => setTextareaQuestion(e.target.value)}
              />
              <TextareaContainer>
                <SmallText>
                  {`Saber can make mistakes. Give us feedback to help us constantly improve.`}
                </SmallText>
                <ButtonContainer>
                  <Button
                    type={"primary"}
                    size="small"
                    iconBefore={<IconArrowUp />}
                    title={"Ask a question"}
                    disabled={textareaQuestion.length < 5}
                    onClick={() => {
                      setQuestion(textareaQuestion);
                      setTextareaQuestion("");
                    }}
                  />
                </ButtonContainer>
              </TextareaContainer>
            </ItemActionBackground>
          </ItemAction>
        </Item>

        <Sidebar />
      </Container>
    </DashboardTemplate>
  );
};

export default Sandbox;
