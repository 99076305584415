import React from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";

// Hooks
import { useAuth } from "../../../../hooks/useAuth";

// Icons
import { ReactComponent as BrandMark } from "../../../icons/saber/brandmark.svg";

const StyledSidebarHeader = styled.div`
  height: 44px;
  margin-bottom: 16px;
  padding: 0 16px;
  box-sizing: border-box;
  vertical-align: middle;
  font-size: 13px;
  font-weight: 500;
  display: flex;
  gap: 8px;
  flex-direction: row;
  align-items: center;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f4b60c;
  border-radius: 6px;
  width: 16px;
  height: 16px;
  padding: 6px;
  color: #0e1016;

  svg {
    width: 14px;
  }
`;
const OrgName = styled.div`
  color: var(--heading-text);
`;

const SidebarHeader = () => {
  const { organization } = useAuth();

  return (
    <StyledSidebarHeader>
      <LogoContainer>
        <BrandMark />
      </LogoContainer>
      <OrgName>Saber</OrgName>
    </StyledSidebarHeader>
  );
};

export default SidebarHeader;
