import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";

import { useData } from "../../../hooks/useData";

import DashboardTemplate from "../../templates/DashboardTemplate";
import Queue from "./Queue";
import QueueItem from "./QueueItem";
import TopNavigationComponent from "./TopNavigation";
import { useApi } from "../../../hooks/useApi";
import { HttpMethod } from "@saberapp/enums";
import { useAuth } from "../../../hooks/useAuth";

dayjs.extend(duration);
dayjs.locale("en");

const Container = styled.div`
  display: flex;
  box-sizing: border-box;
  height: calc(100% - 62px);
`;

const InboxRefactored = () => {
  const { reload, tasks, setTasks } = useData();
  const { API } = useApi();
  let { taskId } = useParams();
  const navigate = useNavigate();
  const { organization } = useAuth();

  const [hasMoreTasks, setHasMoreTasks] = useState(false);
  const [queue, setQueue] = useState([]);
  const [filteredQueue, setFilteredQueue] = useState([]);
  const [activeFilters, setActiveFilters] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(null);

  const [snippet, setSnippet] = useState("");

  useEffect(() => {
    console.log('Reloading Tasks data...');
    reload('Tasks');
  }, [])

  useEffect(() => {
    prepareQueue();
  }, [tasks.data]);

  const prepareQueue = () => {
    setHasMoreTasks(tasks.pagination.totalPages > tasks.pagination.page);
    setQueue(tasks.data);
    setFilteredQueue(tasks.data);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowUp") {
        goToPreviousEntry();
      } else if (event.key === "ArrowDown") {
        goToNextEntry();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [filteredQueue]);

  const goToPreviousEntry = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : filteredQueue.length - 1
    );
  };

  const goToNextEntry = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % filteredQueue.length);
  };

  useEffect(() => {
    setFilteredQueue(() => {
      let newFilteredQueue = queue;
      if (activeFilters.indexOf("positiveFeedback") >= 0) {
        newFilteredQueue = newFilteredQueue.filter(
          (item) =>
            !(item.type === "FeedbackAnalytics" && item.event.isThumbsUp)
        );
      }
      if (activeFilters.indexOf("negativeFeedback") >= 0) {
        newFilteredQueue = newFilteredQueue.filter(
          (item) =>
            !(item.type === "FeedbackAnalytics" && !item.event.isThumbsUp)
        );
      }
      if (activeFilters.indexOf("contentGaps") >= 0) {
        newFilteredQueue = newFilteredQueue.filter(
          (item) => item.type === "FeedbackAnalytics"
        );
      }
      return newFilteredQueue;
    });

    if (taskId) {
      setCurrentIndex(tasks.data.findIndex((task) => task._id === taskId));
    }
  }, [queue, activeFilters]);

  useEffect(() => {
    if (currentIndex !== null) {
      navigate(`/${organization.slug}/inbox/${tasks.data[currentIndex]?._id}`);
    } else {
      navigate(`/${organization.slug}/inbox/`);
    }
  }, [currentIndex]);

  const loadMoreTasks = async () => {
    const nextPage = tasks.pagination.page + 1;

    await API.call(
      HttpMethod.GET,
      `/tasks`,
      { page: nextPage, limit: 10 },
      ({ data, pagination }) => {
        setTasks({ data: [...tasks.data, ...data], pagination });
      }
    );
  };

  return (
    <DashboardTemplate>
      <TopNavigationComponent
        setActiveFilters={setActiveFilters}
        activeFilters={activeFilters}
        isItemSelected={currentIndex !== null}
        tasks={tasks}
        setTasks={setTasks}
        taskId={filteredQueue[currentIndex]?._id}
      />

      <Container>
        <Queue
          queueItems={filteredQueue}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          hasMoreTasks={hasMoreTasks}
          loadMoreTasks={loadMoreTasks}
        />
        <QueueItem
          item={filteredQueue[currentIndex] || null}
          setSnippet={setSnippet}
          snippet={snippet}
        />
      </Container>
    </DashboardTemplate>
  );
};

export default InboxRefactored;
