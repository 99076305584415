import React from "react";
import { styled } from "styled-components";

// Component Library
import {
  Heading,
  Paragraph
} from "@saberapp/react-component-library";

const Column = styled.div`
display: flex;
flex-direction: column;
gap: 4px;
  &:nth-child(2) {
    margin-left: auto;
    display: flex;
    align-items: center;
  }
`;

const Row = styled.div`
  display: flex;
  gap: 32px;
`;

const SettingsHeader = ({ type, title, description, button, line }) => {
  line = line !== false ? true : line;
  return (
      <Row>
        <Column>
          <Heading size="h3">{title}</Heading>
          <Paragraph>{description}</Paragraph>
        </Column>
        {button && <Column>{button}</Column>}
      </Row>
  );
};

export default SettingsHeader;
