import React from "react";
import ReactDOM from "react-dom/client";
import "./css/index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import axios from "axios";
import { AuthProvider } from "./hooks/useAuth";
import { auth } from "./util/firebase";
import { onAuthStateChanged } from "firebase/auth";
import { DataProvider } from "./hooks/useData";

window.baseurl = process.env.REACT_APP_API_BASE_URL || "http://localhost:8080"; // todo is there a better way to pass the ENV var to AuthContext?
axios.defaults.baseURL = window.baseurl;
axios.defaults.withCredentials = true;

onAuthStateChanged(auth, (user) => {
  if (user && auth.currentUser) {
    auth.currentUser
      .getIdToken()
      .then((idToken) => {
        axios.defaults.headers.common["Authorization"] = `Bearer ${idToken}`;
      })
      .catch((error) => {
        showApiError(error.message || "Failed to get user token");
        signOut();
      });
  }
});

const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );
root.render(
  <AuthProvider>
    <DataProvider>
      <App />
    </DataProvider>
  </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
