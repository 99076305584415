import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { styled } from "styled-components";
import { useNavigate } from "react-router-dom";

// Component Library
import {
  Button,
  Card, Checkbox,
  Heading,
  Paragraph,
  FeLabel
} from '@saberapp/react-component-library';
import { IconArrowTopRightOnSquare } from "@saberapp/icons";

// Enums
import { HttpMethod } from "@saberapp/enums";

// Hooks
import { useAuth } from "../../../../hooks/useAuth";
import { useConnectors } from "../../../../hooks/useConnectors";
import { useApi } from "../../../../hooks/useApi";
import FolderPicker from '../components/GoogleDriveFolderPicker';


dayjs.extend(relativeTime);
dayjs.locale("en");

const Column = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`;

const Row = styled.div`
  display: flex;
  gap: 32px;
`;

const ColumnRight = styled(Column)`
  margin-left: auto;
`;

const CardDescription = styled.div`
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid var(--button-border);

  ul {
    margin: 0;
  }
`;

const Table = styled.table`
  width: 100%;
  border-spacing: 0;
`;

const Tr = styled.tr`
  &:nth-child(even) {
    background: var(--card-border);
  }
`;

const Td = styled.td`
  padding: 8px;
`;

const SelectedFolders = styled.td`
  padding-top: 44px;
  flex-grow: 1;
`;

const Loader = styled.div`

  width: 12px;
  height: 12px;
  border: 2px solid var(--tag-text);
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  margin-right: 4px;

  @keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
`;

// const TemporaryCheckboxContainer = styled.label`
//   margin-left: 140px;
//   display: flex;
//   align-items: end;
//   gap: 10px;
// `;

const SettingsDataSources = () => {
  const { API } = useApi();
  const { organization, getGoogleSignInURL } = useAuth();
  const navigate = useNavigate();

  const {
    isConnectorsLoading,
    getConnectorByName,
    isConnecting,
    connect,
  } = useConnectors();

  // const [resetTags, setResetTags] = useState(false);
  const [isSyncing, setIsSyncing] = useState(false);
  const [showSyncResults, setShowSyncResults] = useState(false);

  const [totalDocCount, setTotalDocCount] = useState(null);
  const [syncDocCount, setSyncDocCount] = useState(null);
  const [failedDocCount, setFailedDocCount] = useState(null);
  const [notSupportedDocCount, setNotSupportedDocCount] = useState(null);
  // const [exportDocCount, setExportDocCount] = useState(null);
  const [indexDocCount, setIndexDocCount] = useState(null);
  const [selectedFolders, setSelectedFolders] = useState([]);


  // on mount check if sync is active
  useEffect(() => {
    return startPollingStatus();
  }, []);

  function startPollingStatus() {
    let timeoutId;
    function pollStatus() {
      API.call(HttpMethod.GET, `/connectors/google-drive/status`, {}, (data) => {
        const {
          pendingDocuments,
          completedDocuments,
          failedDocuments,
          notSupportedDocuments
        } = data;
        if (totalDocCount == null) {
          const totalDocCount = pendingDocuments + completedDocuments + failedDocuments + notSupportedDocuments;
          setTotalDocCount(totalDocCount);
        }
        setSyncDocCount(completedDocuments);
        setFailedDocCount(failedDocuments);
        setNotSupportedDocCount(notSupportedDocuments);
        setIndexDocCount(pendingDocuments);
        setShowSyncResults(pendingDocuments > 0 || completedDocuments > 0 || failedDocuments > 0);

        setIsSyncing(data.isSyncing);

        if (data.isSyncing) {
          timeoutId = setTimeout(pollStatus, 3000);
        }
      }).catch(error => {
        console.error("Polling error:", error);
        clearInterval(intervalId);
      });
    }

    pollStatus();

    return () => clearTimeout(timeoutId);
  }

  const syncDrive = async () => {
    resetSyncState();
    setIsSyncing(true);

    try {
      await API.call(HttpMethod.POST, `/connectors/google-drive/sync`, {
        connector_id: getConnectorByName("google_drive")._id,
        organization_id: organization._id
      });
      startPollingStatus();
    } catch (error) {
      console.error("Error fetching data-soruces:", error);
    }
  };

  function resetSyncState() {
    setTotalDocCount(null);
    setSyncDocCount(null);
    // setExportDocCount(null);
    setIndexDocCount(null);
    setFailedDocCount(null)
  }

  return (
  <>
      {!isConnectorsLoading && (
        <>
          {getConnectorByName("google_drive")?.status === "ready-to-connect" && (
            <Card padding={16}>
              <Row>
                {/*<Column>*/}
                {/*  {getConnectorByName("google_drive").folderId?.length > 0 ? (*/}
                {/*    <>*/}
                {/*      <Heading size="h4">*/}
                {/*        Reconnect the Google Drive folder*/}
                {/*      </Heading>*/}
                {/*      <Paragraph>*/}
                {/*        Saber already had a folder on record you can connect to*/}
                {/*      </Paragraph>*/}
                {/*    </>*/}
                {/*  ) : (*/}
                {/*    <>*/}
                {/*      <Heading size="h4">Create Google Drive folder</Heading>*/}
                {/*      <Paragraph>*/}
                {/*        Saber will create a dedicated folder within your Google*/}
                {/*        Drive*/}
                {/*      </Paragraph>*/}
                {/*    </>*/}
                {/*  )}*/}
                {/*</Column>*/}
                <Column>
                  <Button
                    type="primary"
                    onClick={() => {

                      if(!isConnecting && getConnectorByName("google_drive").folderIds?.length > 0){
                        resetSyncState();
                        console.log('resetSyncState()')
                      }

                      connect(
                        getConnectorByName("google_drive")._id,
                        "google-drive",
                        {folders: selectedFolders}
                      );
                    }}
                    disabled={isConnecting || selectedFolders.length === 0}
                    title={
                      !isConnecting
                        ?
                          // getConnectorByName("google_drive").folderId?.length >
                          // 0
                          // ? "Reconnect to Saber folder" :
                          "Connect to folder(s)"
                        : "Connecting..."
                    }
                  />
                    <FolderPicker onSelectFolder={setSelectedFolders}/>
                </Column>
                <Column>
                  <SelectedFolders>
                    <h3>Selected Folders:</h3>
                    <ul>
                      {selectedFolders.map(folder => (
                          <li key={folder.id}>{folder.name}</li>
                      ))}
                    </ul>
                  </SelectedFolders>
                </Column>
              </Row>
              <CardDescription>
                <Paragraph>
                  The dedicated folder will make sure that:
                  <ul>
                    <li>
                      Every file placed in this folder can be synchronized with
                      Saber, centralising your file management and access.
                    </li>
                    <li>
                      Files outside of this folder will not be synced, making
                      sure you only sync the content you want your team to have
                      access to.
                    </li>
                  </ul>
                </Paragraph>
              </CardDescription>
            </Card>
          )}

          {getConnectorByName("google_drive")?.status === "connected" && (
            <>
              <Card padding={16}>
                <Row>
                  <Column>
                    <Heading size="h4">Content Sync</Heading>
                    <Paragraph>
                    {isSyncing
                        ? `Sync is in progress`
                        : getConnectorByName("google_drive")?.lastSynced ? `Last synced 
                      ${dayjs(
                        getConnectorByName("google_drive")?.lastSynced
                      ).fromNow()}` : `Never synced`}
                    </Paragraph>
                  </Column>
                  <Column>
                    {/*<TemporaryCheckboxContainer>*/}
                      {/*<Checkbox*/}
                      {/*    onChange={e => setResetTags(e.target.checked)}*/}
                      {/*    type="checkbox"*/}
                      {/*    value={false}*/}
                      {/*    id={"resetTags"}*/}
                      {/*    name={"resetTags"}*/}
                      {/*    defaultChecked={false}*/}
                      {/*/>*/}
                      {/*<FeLabel htmlFor={"resetTags"}>*/}
                      {/*  Reset all tags*/}
                      {/*</FeLabel>*/}
                    {/*</TemporaryCheckboxContainer>*/}
                  </Column>
                  <ColumnRight>
                    <Button
                        type="ui"
                        size="small"
                        disabled={isSyncing}
                        title={isSyncing ? "Syncing..." : "Sync Content"}
                        onClick={syncDrive}
                    />
                  </ColumnRight>
                </Row>
                {(isSyncing || showSyncResults) && (
                  <>
                    <CardDescription>
                      <Table>
                        <Tr style={{ paddingBottom: 16 }}>
                          <Td>
                            <Heading size="h4">Action</Heading>
                          </Td>
                          <Td>
                            <Heading size="h4">Result</Heading>
                          </Td>
                        </Tr>
                        <Tr>
                          <Td>Finding files to sync</Td>
                          <Td>
                            {!indexDocCount && isSyncing && <Loader />}
                            {!totalDocCount ? (
                              <>Looking...</>
                            ) : (
                              `Found ${totalDocCount} files`
                            )}
                          </Td>
                        </Tr>
                        <Tr>
                          <Td>Syncing file data</Td>
                          <Td>
                            {isSyncing && <Loader />}
                            {!syncDocCount ? (
                              <>Pending...</>
                            ) : (
                              `Synced ${syncDocCount} files`
                            )}
                          </Td>
                        </Tr>
                        <Tr>
                          <Td>Indexing file content</Td>
                          <Td>
                            {isSyncing && <Loader />}
                            {!indexDocCount && isSyncing ? <>Pending...</> : `Indexing ${indexDocCount || 0} files`}
                          </Td>
                        </Tr>
                        <Tr>
                          <Td>Files failed to index</Td>
                          <Td>
                            {failedDocCount == null && isSyncing && <Loader />}
                            {`${failedDocCount || 0} files failed`}
                          </Td>
                        </Tr>
                        <Tr>
                          <Td>Unsupported files</Td>
                          <Td>
                            {notSupportedDocCount == null && isSyncing && <Loader />}
                            {`${notSupportedDocCount || 0} files skipped`}
                          </Td>
                        </Tr>
                        {/* <Tr>
                          <Td>Creating and updating Shareable Links</Td>
                          <Td>
                            {isSyncing && <Loader />}
                            {!exportDocCount && isSyncing ? <>Pending...</> : `Updated ${exportDocCount || 0} links`}
                          </Td>
                        </Tr> */}
                      </Table>
                    </CardDescription>
                    {!isSyncing && (
                      <CardDescription>
                        <Row>
                          <Column>
                            <Button
                              title="Go to Docs"
                              type="primary"
                              size="small"
                              onClick={()=> navigate(`/${organization.slug}/documents`)}
                            />
                          </Column>
                        </Row>
                      </CardDescription>
                    )}
                  </>
                )}
              </Card>
              <Card padding={16}>
                <Row>
                  <Column>
                    <Heading size="h4">Saber Folder in Drive</Heading>
                    <Paragraph>
                      This is the folder within your drive Saber syncs with
                    </Paragraph>
                  </Column>
                  <ColumnRight>
                    <Button
                      type="ui"
                      size="small"
                      iconBefore={<IconArrowTopRightOnSquare />}
                      title="Go to Folder"
                      onClick={() => {
                        window.open(
                          `https://drive.google.com/drive/folders/${
                            getConnectorByName("google_drive").folderId
                          }`,
                          "_blank"
                        );
                      }}
                    />
                  </ColumnRight>
                </Row>
              </Card>
            </>
          )}
        </>
      )}
  </>);
};

export default SettingsDataSources;
