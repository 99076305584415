import React, { useState, useEffect, useRef } from "react";
import { styled } from "styled-components";
import Markdown from "react-markdown";
import ReferencesList from "../../inbox/ReferencesList";

import { ReactComponent as IconConfluence } from "../../../icons/connectors/confluence.svg";
import { ReactComponent as IconGoogleCalendar } from "../../../icons/connectors/google-calendar.svg";
import { ReactComponent as IconGoogleDrive } from "../../../icons/connectors/google-drive.svg";
import { ReactComponent as IconGoogleMail } from "../../../icons/connectors/google-mail.svg";
import { ReactComponent as IconNotion } from "../../../icons/connectors/notion.svg";
import { ReactComponent as IconPipedrive } from "../../../icons/connectors/pipedrive.svg";
import { ReactComponent as IconSalesforce } from "../../../icons/connectors/salesforce.svg";
import { ReactComponent as IconSlack } from "../../../icons/connectors/slack.svg";
import { ReactComponent as BrandMark } from "../../../icons/saber/brandmark.svg";

const QuestionText = styled.div`
  font-size: 24px;
  color: var(--heading-text);
  font-weight: 600;
  line-height: 1.5;
`;

const Path = styled.div`
  position: relative;

  &:before {
    content: "";
    display: block;
    width: 2px;
    background: var(--card-border);
    position: absolute;
    top: 0;
    left: 9px;
    height: calc(100% + 24px);
    z-index: 0;
  }
`;

const Point = styled.div`
  width: 20px;
  height: 20px;
  background: var(--card-border);
  border-radius: 50%;
  z-index: 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ color }) =>
    color === "red" &&
    `
    background: #fe4a49;
    color: #fff0f0;
  `};

  svg {
    width: 12px;
  }
`;

const PointContent = styled.div`
  padding-bottom: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const PathSection = styled.div`
  display: flex;
  gap: 16px;
  padding-bottom: 24px;

  &:last-child div:before {
    display: none;
  }
`;

const Details = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: space-between;

  div {
    display: flex;
    align-items: center;
    gap: 4px;
  }
`;

const AnswerParagraph = styled.div`
  color: var(--heading-text);
  margin: 0;
  // font-size: 16px;
  line-height: 1.5;

  p,
  ol,
  ul,
  li {
    margin: 0;
  }

  a {
    color: #0ca8f4;
  }
`;

const Question = ({ question, answer, isLoading }) => {
  const [elapsedTime, setElapsedTime] = useState(0);
  const intervalRef = useRef(null);

  const logos = {
    google_drive: <IconGoogleDrive />,
    google_calendar: <IconGoogleCalendar />,
    salesforce: <IconSalesforce />,
    pipedrive: <IconPipedrive />,
    slack: <IconSlack />,
    notion: <IconNotion />,
    gmail: <IconGoogleMail />,
    confluence: <IconConfluence />,
    webapp: <BrandMark />,
  };

  useEffect(() => {
    if (isLoading) {
      intervalRef.current = setInterval(() => {
        setElapsedTime((prevTime) => prevTime + 0.1);
      }, 100);
    } else {
      clearInterval(intervalRef.current);
      setElapsedTime(0);
    }
    return () => clearInterval(intervalRef.current);
  }, [isLoading]);

  return (
    <>
      <PathSection>
        <Path>
          <Point></Point>
        </Path>
        <PointContent>
          <Details>
            <div>You just asked:</div>
          </Details>
          <QuestionText>{question}</QuestionText>
        </PointContent>
      </PathSection>

      <PathSection>
        <Path>
          <Point></Point>
        </Path>
        <PointContent>
          {isLoading && `Loading (${Math.round(elapsedTime * 10) / 10}s)`}

          {!isLoading && answer && (
            <>
              <Details>
                <div>The answer a user would get:</div>
                <div>{answer.answer?.durationText}</div>
              </Details>
              <AnswerParagraph>
                <Markdown>{answer.answer?.text}</Markdown>
              </AnswerParagraph>
              <ReferencesList refs={answer.answer?.resources} logos={logos} />
            </>
          )}
        </PointContent>
      </PathSection>

      {/* {question} <div>{answer.answer.text}</div>
      <div>{answer.answer.durationText}</div>
      <div>{answer.answer.totalResources}</div>
      <div>{answer.answer.totalResources > 0 && answer.answer.resources.map((ref) => console.log(ref))}</div> */}
    </>
  );
};

export default Question;
