import { Paragraph } from "@saberapp/react-component-library";
import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  gap: 32px;
  padding-left: ${props => props.indent}px;
`;

const ListItem = ({ bullet, paragraph, indent = 0 }) => {
  return (
    <Container indent={indent}>
      <Paragraph>{bullet}</Paragraph>
      <Paragraph>{paragraph}</Paragraph>
    </Container>
  );
};

export default ListItem;
