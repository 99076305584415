import React, { useEffect, useState } from "react";
import IntegrationSync from "../components/IntegrationSync";

const SettingsNotion = ({ connector }) => {
  const [isSyncEnabled, setIsSyncEnabled] = useState(false);

  useEffect(() => {
    setIsSyncEnabled(connector?.status === 'connected');
    console.log('setting enabled..', connector);
  }, [connector]);

  return (
    <IntegrationSync
      connector={connector}
      heading={`Sync your Notion pages`}
      isSyncEnabled={true}
    />
  );
};

export default SettingsNotion;
