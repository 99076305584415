import React, { useContext, useEffect, useState } from "react";
import { styled } from "styled-components";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { motion, useAnimation } from "framer-motion";

// Component Library
import {
  FileTypeIcon,
  FreshnessScore,
} from "@saberapp/react-component-library";

import { ReactComponent as IconSelected } from "../icons/20/solid/check.svg";
import { ContextMenu } from "../../hooks/useContextMenu";
// import {useApi} from '../../hooks/useApi';

import { ReactComponent as IconNotion } from "../icons/connectors/notion.svg";
import { ReactComponent as IconConfluence } from "../icons/connectors/confluence.svg";

dayjs.extend(relativeTime);
dayjs.locale("en");

const ResourceTileContainer = styled.div`
  border: 1px solid var(--button-ui-border);
  border-radius: 6px;
  overflow: hidden;
  aspect-ratio: 200 / 170;
  position: relative;

  &:hover {
    border: 1px solid var(--mid-shadow-2);
    background: var(--border-color-2);
    cursor: pointer;
  }
`;

const Row = styled.div`
  display: flex;
  font-weight: 500;
  gap: 8px;
  padding: 0 8px;
  height: 32px;
  box-sizing: border-box;
  align-items: center;
`;

const Column = styled(motion.div)`
  display: flex;
  flex-direction: column;
  font-weight: 500;
  overflow: hidden;
  padding: 4px 0;
  background: var(--main-bg);

  position: absolute;
  bottom: -32px;
  width: 100%;
`;

const ResourceName = styled(motion.div)`
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 12px;
  color: var(--heading-text);
`;

const ResourceUpdated = styled.div`
  display: flex;
  font-size: 12px;
  color: var(--body-text);
  align-items: center;
  gap: 8px;

  div {
    padding: 0;
  }

  svg {
    width: 20px;
    height: auto;
  }
`;

const Preview = styled.div`
  background: var(--context-menu-item-bg-hover);
  height: calc(100% - 36px);
  background-position: center;
  background-size: cover;
`;

const IconWrapper = styled.div`
  width: ${(props) => (props.size ? `${props.size}px` : `16px`)};
  min-width: ${(props) => (props.size ? `${props.size}px` : `16px`)};
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
  }
`;

const SelectedIcon = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  width: 12px;
  height: 12px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--bg-color-5);
  padding: 4px;
  border: 1px solid var(--border-color-2);
  color: var(--border-color-2);

  svg {
    width: 100%;
  }
`;

const IconBackground = styled.div`
  background: #fff;
  padding: 4px;
  border-radius: 6px;
`;

const ResourceTile = ({ resource, onClick, selected, onContextMenu }) => {
  // const {API} = useApi();
  // const [isExpanded, setIsExpanded] = useState(false);
  const [thumbnail, setThumbnail] = useState();
  const [icon, setIcon] = useState();
  const controls = useAnimation();

  const { contextMenu } = useContext(ContextMenu);

  const handleMouseEnter = () => {
    controls.start({ bottom: "0px" }); // Expand the column
  };

  const handleMouseLeave = () => {
    controls.start({ bottom: -32 }); // Collapse the column
  };

  // const fetchImageAsBase64 = async () => {
  //   const blob = (await API.getResourceThumbnail(resource._id)).data;
  //   return new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.onloadend = () => resolve(reader.result);
  //     reader.onerror = reject;
  //     reader.readAsDataURL(blob);
  //   });
  // };

  // useEffect(() => {
  //   const loadThumbnail = async () => {
  //     try {
  //       setThumbnail(await fetchImageAsBase64());
  //     } catch (error) {
  //       console.error('Failed to load thumbnail', error);
  //     }
  //   };

  //   loadThumbnail();
  // }, []);

  const sources = {
    notion: <IconNotion />,
    confluence: <IconConfluence />,
  };

  useEffect(() => {
    if (resource.docSource === "google_drive") {
      setIcon(
        <IconWrapper>
          <FileTypeIcon mimeType={resource?.mimeType} />
        </IconWrapper>
      );
    } else {
      setIcon(
        <IconBackground>
          <IconWrapper size={12}>{sources[resource?.docSource]}</IconWrapper>
        </IconBackground>
      );
    }
  }, [resource]);

  return (
    <ResourceTileContainer
      onClick={(e) => {
        onClick(e);
      }}
      onContextMenu={(e) => {
        e.preventDefault();
        contextMenu.open(resource, {
          x: e.pageX,
          y: e.pageY,
        });
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {selected === "true" && (
        <SelectedIcon>
          <IconSelected />
        </SelectedIcon>
      )}
      <Preview style={{ backgroundImage: `url(${thumbnail}` }}></Preview>

      <Column
        initial={{ bottom: -32 }}
        animate={controls}
        exit={{ bottom: -32 }}
        transition={{ duration: 0.1 }}
      >
        <Row>
          {icon}

          <ResourceName>{resource?.name}</ResourceName>
        </Row>
        <Row>
          <IconWrapper>
            <FreshnessScore score={resource?.freshnessScore} />
          </IconWrapper>
          <ResourceUpdated>
            {`Reviewed ${dayjs(resource?.reviewedTime).fromNow()}`}
          </ResourceUpdated>
        </Row>
      </Column>
    </ResourceTileContainer>
  );
};

export default ResourceTile;
