import React from "react";
import DashboardTemplate from "../../templates/DashboardTemplate";

import { styled } from "styled-components";
import Connectors from "./components/Connectors";
import Feed from "./components/Feed";
import Stats from "./components/Stats";
import TopNavigation from "../../layouts/main-top-bar";
import { useAuth } from "../../../hooks/useAuth";
import { Button, Paragraph } from "@saberapp/react-component-library";
import { IconBell, IconArrowDownOnSquare } from "@saberapp/icons";
import Org from "./components/Org";

// Layouts
import SearchBox from "../../layouts/search";

const FlexContainer = styled.div`
  width: 100%;
  padding-bottom: 64px;
  margin: auto;
  display: flex;
  padding: 0 16px;
  padding-bottom: 64px;
  box-sizing: border-box;
  justify-content: center;
`;

const ProfileColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 25%;
  min-width: 320px;
  padding: 16px;
  gap: 8px;
`;

const OverviewColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 75%;
  gap: 8px;
`;

const Options = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const TopFiller = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Greeting = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Home = () => {
  const { organization, user } = useAuth();

  return (
    <DashboardTemplate>
      <TopNavigation
        links={[
          {
            title: "Home",
            path: `/${organization.slug}/`,
          },
        ]}
      >
        {/* <TopFiller>
          <Greeting>
            <Paragraph>Everything is running smooth 🦾</Paragraph>
          </Greeting>
          <Options>
            <Button
              type="ui"
              size="small"
              iconBefore={<IconBell />}
              title="Notifications"
              onClick={() => {}}
            />
            <Button
              type="ui"
              size="small"
              iconBefore={<IconArrowDownOnSquare />}
              onClick={() => {}}
            />
          </Options>
        </TopFiller> */}
      </TopNavigation>

      <SearchBox pageSpecificActions={[]} />

      <FlexContainer>
        <OverviewColumn>
          <Org />
          <Feed />
          <Connectors />
        </OverviewColumn>
      </FlexContainer>
    </DashboardTemplate>
  );
};

export default Home;
