import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { useData } from "../../../../hooks/useData";

import { ReactComponent as BrandMark } from "../../../icons/saber/brandmark.svg";
import { ReactComponent as IconGoogleDrive } from "../../../icons/connectors/google-drive.svg";
import { ReactComponent as IconGoogleMail } from "../../../icons/connectors/google-mail.svg";
import { ReactComponent as IconGoogleCalendar } from "../../../icons/connectors/google-calendar.svg";
import { ReactComponent as IconSlack } from "../../../icons/connectors/slack.svg";
import { ReactComponent as IconSalesforce } from "../../../icons/connectors/salesforce.svg";
import { ReactComponent as IconPipedrive } from "../../../icons/connectors/pipedrive.svg";
import { ReactComponent as IconNotion } from "../../../icons/connectors/notion.svg";
import { ReactComponent as IconConfluence } from "../../../icons/connectors/confluence.svg";
import { ReactComponent as IconHubspot } from "../../../icons/connectors/hubspot.svg";
import { IndexStatus } from "@saberapp/enums";

const SidebarContainer = styled.div`
  background: var(--sidebar-bg);
  border-left: 1px solid var(--sidebar-border);
  min-width: 280px;
  max-width: 280px;
  padding: 32px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Heading = styled.h3`
  font-size: 13px;
  margin: 0;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;
`;

const ListItem = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SmallText = styled.div`
  font-size: 12px;
  line-height: 1.5;
`;

const SmallLogoContainer = styled.div`
  display: flex;
  width: 20px;
  height: 20px;
  padding: 4px;
  border-radius: 6px;
  background: #fff;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;

  ${({ color }) =>
    color === "yellow" &&
    `
    color: #0e1016;
    background: #f4b60c;

    svg {
        width: 10px;
    }
  `}
`;

const Connector = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
`;

const Sidebar = () => {
  const { connectors, resources, answers } = useData();

  const [activeConnectors, setActiveConnectors] = useState([]);
  const [indexedResources, setIndexedResources] = useState([]);

  const connectorMeta = {
    saber: <BrandMark />,
    google_drive: <IconGoogleDrive />,
    google_calendar: <IconGoogleCalendar />,
    salesforce: <IconSalesforce />,
    pipedrive: <IconPipedrive />,
    slack: <IconSlack />,
    notion: <IconNotion />,
    gmail: <IconGoogleMail />,
    confluence: <IconConfluence />,
    hubspot: <IconHubspot />,
  };

  useEffect(() => {
    setActiveConnectors(
      connectors.filter((connector) => connector.status === "connected")
    );
  }, [connectors]);

  useEffect(() => {
    setIndexedResources(
      resources.filter(
        (resource) => resource.indexStatus === IndexStatus.INDEXED
      )
    );
  }, [resources]);

  return (
    <SidebarContainer>
      {indexedResources.length > 0 && (
        <SmallText>
          {indexedResources.length} resources are used by Saber to generate
          answers and guidance:
        </SmallText>
      )}

      {indexedResources.length === 0 && (
        <SmallText>
          You have no indexed documents. Make sure you have connectors connected
          and that they're syncing.
        </SmallText>
      )}

      {activeConnectors.length > 0 && (
        <>
          <Heading>
            <span>{activeConnectors.length} Connectors</span>
            <span>Refs</span>
          </Heading>

          <List>
            {activeConnectors.map((connector) => {
              const potentialRefs = resources.filter(
                (resource) =>
                  resource.docSource === connector.source &&
                  resource.indexStatus === IndexStatus.INDEXED
              );

              return (
                <ListItem>
                  <Connector>
                    <SmallLogoContainer>
                      {connectorMeta[connector.source]}
                    </SmallLogoContainer>
                    {connector.name}
                  </Connector>

                  <span>{potentialRefs.length}</span>
                </ListItem>
              );
            })}
          </List>
        </>
      )}

      {resources.length - indexedResources.length > 0 && (
        <SmallText>
          There are{" "}
          <a href="#">
            {resources.length - indexedResources.length} resources that aren't
            indexed
          </a>
          . These can't be used to generate answers.
        </SmallText>
      )}

      {answers.length > 0 && (
        <>
          <Heading>
            <span>Saber</span>
            <span>Refs</span>
          </Heading>

          <List>
            <ListItem>
              <Connector>
                <SmallLogoContainer color="yellow">
                  <BrandMark />
                </SmallLogoContainer>
                Answers
              </Connector>

              <span>{answers.length}</span>
            </ListItem>
          </List>
        </>
      )}
    </SidebarContainer>
  );
};

export default Sidebar;
