import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import io from "socket.io-client";
import { styled } from "styled-components";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

// Component Library
import {
  Button,
  Card,
  Heading,
  Paragraph,
} from "@saberapp/react-component-library";
import { IconArrowTopRightOnSquare } from "@saberapp/icons";

// Enums
import { HttpMethod } from "@saberapp/enums";

// Hooks
import { useAuth } from "../../../../hooks/useAuth";
import { useConnectors } from "../../../../hooks/useConnectors";
import { useApi } from "../../../../hooks/useApi";

import SettingsTemplate from "../../../templates/SettingsTemplate";

import { ReactComponent as IconSlack } from "../../../icons/connectors/slack.svg";
import IntegrationsHeader from "../components/IntegrationHeader";
import IntegrationsStatus from "../components/IntegrationStatus";
import IntegrationsOverview from "../components/IntegrationOverview";

import SettingsSalesforce from "./Salesforce";
import SettingsSlack from "./Slack";
import { paragon } from "@useparagon/connect";
import SettingsDataSources from "./GoogleDrive";
import SettingsConfluence from "./Confluence";
import SettingsNotion from "./Notion";

dayjs.extend(relativeTime);
dayjs.locale("en");

const Column = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;

  &:last-child {
    margin-left: auto;
  }
`;

const Row = styled.div`
  display: flex;
  gap: 32px;
`;

const RowSpaced = styled.div`
  display: flex;
  gap: 32px;
  justify-content: space-between;
  align-items: center;
`;

const CardDescription = styled.div`
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid var(--button-border);

  ul {
    margin: 0;
  }
`;

const Table = styled.table`
  width: 100%;
  border-spacing: 0;
`;
const Tr = styled.tr`
  &:nth-child(even) {
    background: var(--card-border);
  }
`;
const Td = styled.td`
  padding: 8px;
`;

const ExplanationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid var(--button-border);
`;
const Explanation = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const SettingsConnector = () => {
  let { connectorSlug } = useParams();
  const {
    connectors,
    getConnectorFromCatalog,
    connectorCatalog,
    pollConnectorCallbackPopup,
    isConnectorsLoading,
    isConnectorCatalogLoading,
    getConnectorByName,
    doesConnectorExist,
    setIsConnecting,
    setIsDisconnecting,
    disconnect,
    connectParagon,
    disconnectParagon,
  } = useConnectors();
  const { getGoogleSignInURL } = useAuth();
  const { API } = useApi();

  const [connector, setConnector] = useState({});
  const [showOverview, setShowOverview] = useState(false);
  const [authUrl, setAuthUrl] = useState(null);
  const [isUrlLoading, setIsUrlLoading] = useState(true);

  const dedicatedPages = {
    salesforce: <SettingsSalesforce />,
    slack: <SettingsSlack />,
    "google-drive": <SettingsDataSources />,
    confluence: <SettingsConfluence connector={connector} />,
    notion: <SettingsNotion connector={connector} />,
  };

  useEffect(() => {
    (async () => {
      if (!isConnectorCatalogLoading) {
        let result = await getConnectorFromCatalog(connectorSlug);

        const existingConnector = getConnectorByName(result.source);
        if (existingConnector) {
          result = { ...result, ...existingConnector };
        }
        setConnector(result);
      }
    })();
  }, [isConnectorCatalogLoading, connectorSlug, connectors]);

  useEffect(() => {
    if (connector) {
      setShowOverview(!doesConnectorExist(connector.source));
      if (connector?.native) {
        setupAuth();
      }
    }
  }, [connector]);

  const setupAuth = () => {
    const generateAuthUrl = async () => {
      try {
        console.log(connector);
        if (
          connector.source === "google_calendar" ||
          connector.source === "google_drive"
        ) {
          const url = await getGoogleSignInURL(`basic ${connector.source}`); // When set to true, we ask extended permissions
          setAuthUrl(url);
          setIsUrlLoading(false);
        } else {
          API.call(
            "GET",
            `/connectors/${connectorSlug}/generate-auth-url`,
            {},
            (data) => {
              setAuthUrl(data.authUrl);
              setIsUrlLoading(false);
            }
          );
        }
      } catch (error) {
        console.error("Error fetching URL:", error);
      }
    };

    generateAuthUrl();
  };

  const openAuthWindow = () => {
    if (!isUrlLoading) {
      setIsConnecting(true);
      // Define the size and position of the pop-up window
      const width = 600;
      const height = 700;
      const left = (window.innerWidth - width) / 2;
      const top = (window.innerHeight - height) / 2;

      // Window features
      const windowFeatures = `width=${width},height=${height},top=${top},left=${left},status=yes,toolbar=no,menubar=no,location=yes`;

      // Open a new window for the authentication URL
      pollConnectorCallbackPopup(
        window.open(authUrl, `${connectorSlug}-auth`, windowFeatures)
      );
    }
  };

  return (
    <>
      <SettingsTemplate>
        {connector && (
          <>
            <IntegrationsHeader
              icon={connector.icon}
              name={connector.name}
              description={connector.description}
            />

            <IntegrationsStatus
              connector={connector}
              // source={connector.source}
              // slug={connector.slug}
              // name={connector.name}
              onConnect={
                connector.native
                  ? openAuthWindow
                  : async () => {
                      try {
                        await paragon.installIntegration(connector.slug, {
                          onOpen: () => {
                            setIsConnecting(true);
                          },
                          onClose: () => {
                            setIsConnecting(false);
                          },
                          onInstall: async (event) => {
                            await connectParagon({
                              name: connector.name,
                              source: connector.source,
                              integrationId: event.integrationId,
                            });
                          },
                          showPortalAfterInstall: false,
                        });
                      } catch (e) {
                        if (e.toString().indexOf("already installed")) {
                          // This is when its already installed on the Paragon side, but not on the Saber side
                          setIsConnecting(true);
                          // We create the connector on our side, without the integrationId
                          await connectParagon({
                            name: connector.name,
                            source: connector.source,
                          });
                          setIsConnecting(false);
                        }
                      }
                    }
              }
              onDisconnect={
                connector.native
                  ? () => {
                      disconnect(
                        getConnectorByName(connector.source)._id,
                        connector.slug
                      );
                    }
                  : async () => {
                      console.log("Disconnecting Paragon Connector...");
                      setIsDisconnecting(true);
                      try {
                        await paragon.uninstallIntegration(connector.slug);
                      } catch (e) {
                        if (e.toString().indexOf("is not installed")) {
                          // This is when Paragon says the connector is not installed
                          // So we disconnect it on our side just in case
                          await disconnectParagon(
                            getConnectorByName(connector.source)._id,
                            connector.slug
                          );
                        }
                      }
                      await disconnectParagon(
                        getConnectorByName(connector.source)._id,
                        connector.slug
                      );
                    }
              }
            />

            {doesConnectorExist(connector.source) &&
              dedicatedPages[connectorSlug] &&
              dedicatedPages[connectorSlug]}

            <Card padding={16}>
              {showOverview ? (
                <IntegrationsOverview
                  slug={connector.slug}
                  name={connector.name}
                />
              ) : (
                <RowSpaced>
                  <Paragraph>
                    Want to learn more about our {connector.name} integration?
                  </Paragraph>{" "}
                  <Button
                    size="small"
                    title="Show integration overview"
                    onClick={() => {
                      setShowOverview(true);
                    }}
                  />
                </RowSpaced>
              )}
            </Card>
          </>
        )}
      </SettingsTemplate>
    </>
  );
};

export default SettingsConnector;
