import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import styled from "styled-components";

// Hooks
import { useAuth } from "../../../../hooks/useAuth";
import { useConnectors } from "../../../../hooks/useConnectors";
import { useApi } from "../../../../hooks/useApi";

// Enums
import { HttpMethod } from "@saberapp/enums";

// Components
import { Card, Heading } from "@saberapp/react-component-library";

// Icons & Logos
import { ReactComponent as IconPipedrive } from "../../../icons/connectors/pipedrive.svg";
import { ReactComponent as IconGoogleCalendar } from "../../../icons/connectors/google-calendar.svg";
import { ReactComponent as IconGoogleDrive } from "../../../icons/connectors/google-drive.svg";
import { ReactComponent as IconSlack } from "../../../icons/connectors/slack.svg";
import { ReactComponent as IconSalesforce } from "../../../icons/connectors/salesforce.svg";

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  height: 100%;
  flex-wrap: wrap;
`;

const Inner = styled.div`
  height: fit-content;
`;

const CardInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

const LogoContainer = styled.div`
  display: flex;
  width: 56px;
  height: 56px;
  border-radius: 8px;
  background: #fff;
  padding: 12px;
  box-sizing: border-box;
`;

export const ConnectorCallbackHandler = () => {
  const { API } = useApi();
  const { user, isAuthenticated } = useAuth();
  const { updateConnectors, doesConnectorExist, doesUserHaveConnector } = useConnectors();
  const { connectorName } = useParams();
  const [searchParams, _setSearchParams] = useSearchParams();
  const [isValidating, setIsValidating] = useState(false);

  // Determine which supported connector is redirecting here, and set the appropriate search params
  const connectors = {
    pipedrive: {
      displayName: "Pipedrive",
      searchParams: ["code"],
      logo: <IconPipedrive />,
    },
    'google-calendar': {
      displayName: "Google Calendar",
      searchParams: ["code"],
      logo: <IconGoogleCalendar />, 
    },
    'google-drive': {
      displayName: "Google Drive",
      searchParams: ["code"],
      logo: <IconGoogleDrive />, 
    },
    'slack': {
      displayName: "Slack",
      searchParams: ["code"/*, "state"*/],
      logo: <IconSlack />, 
    },
    'salesforce': {
      displayName: "Salesforce",
      searchParams: ["code"],
      logo: <IconSalesforce />, 
    }
  };

  let connector = connectors[connectorName];
  let connectorParams = {};
  let paramsValid = true;

  for (const param of connector.searchParams) {
    const paramValue = searchParams.get(param);
    if (paramValue){
      connectorParams[param] = paramValue;
    } else {
      paramsValid = false;
    }
  }
  useEffect(() => {
    if (!isAuthenticated) return
    if (!isValidating) {
      if (paramsValid) {
        setIsValidating(true);
        handleAuthCallback(connectorParams);
      } else {
        console.error(`Callback data missing for ${connectorName} connector`);
      }
    }
  }, [isAuthenticated]);

  const handleAuthCallback = async (connectorParams) => {
    try {
      await API.call(
        HttpMethod.POST,
        `/connectors/${connectorName}/callback?code=${connectorParams.code}`,
        { user, ...connectorParams },
        (data) => {
          console.log(data);
          if (!doesConnectorExist(connectorName) && !doesUserHaveConnector(connectorName)) {
            updateConnectors("add", data.newConnector);
          } else {
            updateConnectors("update", data.updatedConnector);
          }

          // Close the pop-up window
          window.close();
        }
      );
    } catch (error) {
      console.error(
        `Error during getting ${connectorName} permissions:`,
        error
      );
    }
  };

  return (
    <Center>
      <Inner>
        <Card>
          <CardInner>
            <LogoContainer>{connector.logo}</LogoContainer>
            {paramsValid ? (
              <Heading size="h4">
                Connecting to {connector.displayName}...
              </Heading>
            ) : (
              <Heading size="h4">Missing search params</Heading>
            )}
          </CardInner>
        </Card>
      </Inner>
    </Center>
  );
};
