import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

import { styled } from "styled-components";

// Component Library
import {
  Button,
  Card,
  Heading,
  Paragraph,
} from "@saberapp/react-component-library";

// Enums
import { HttpMethod } from "@saberapp/enums";

// Hooks
import { useConnectors } from "../../../../hooks/useConnectors";
import { useApi } from "../../../../hooks/useApi";

dayjs.extend(relativeTime);
dayjs.locale("en");

const Column = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;

  &:last-child {
    margin-left: auto;
  }
`;

const Row = styled.div`
  display: flex;
  gap: 32px;
`;

const RowSpaced = styled.div`
  display: flex;
  gap: 32px;
  justify-content: space-between;
  align-items: center;
`;

const CardDescription = styled.div`
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid var(--button-border);

  ul {
    margin: 0;
  }
`;

const Table = styled.table`
  width: 100%;
  border-spacing: 0;
`;
const Tr = styled.tr`
  &:nth-child(even) {
    background: var(--card-border);
  }
`;
const Td = styled.td`
  padding: 8px;
`;

const ExplanationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid var(--button-border);
`;
const Explanation = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const SettingsSlack = () => {
  const { API } = useApi();

  const { isConnectorsLoading, getConnectorByName, doesConnectorExist } =
    useConnectors();

  const [isSyncing, setIsSyncing] = useState(false);
  const [showSyncResults, setShowSyncResults] = useState(false);
  const [syncResults, setSyncResults] = useState({});

  useEffect(() => {
    if (!isConnectorsLoading) {
      if (
        !doesConnectorExist("slack") ||
        getConnectorByName("slack")?.status === "disconnected"
      ) {
      } else {
        // TODO: Figure out why this isn't working
        // console.log('Socket set up')
        // // Establish WebSocket connection and send organization ID
        // const socket = io(process.env.REACT_APP_API_BASE_URL, {
        //   query: { organizationId: organization._id },
        // });
        // socket.on("slack_sync_update", (data) => {
        //   console.log('anything', data)
        //   if (data.action === "slack_sync_start") {
        //     console.log('Receiving event..')
        //     setIsSyncing(true);
        //   } else if (data.action === "slack_sync_users_found") {
        //   } else if (data.action === "slack_sync_completed") {
        //     setSyncResults(data.results);
        //     setIsSyncing(false);
        //     setShowSyncResults(true);
        //   }
        // });
        // // Cleanup on unmount
        // return () => {
        //   socket.disconnect();
        // };
      }
    }
  }, [isConnectorsLoading]);

  const syncSlack = async () => {
    setIsSyncing(true);
    try {
      await API.call(HttpMethod.GET, `/connectors/slack/sync`, {}, (data) => {
        setIsSyncing(false);
        setSyncResults(data.results);
        setShowSyncResults(true);
      });
    } catch (error) {
      console.error("Error fetching data-soruces:", error);
    }
  };

  return (
    <>
      {!isConnectorsLoading && (
        <>
          {getConnectorByName("slack")?.status === "connected" && (
            <>
              <Card padding={16}>
                <Row>
                  <Column>
                    <Heading size="h4">Slack User Directory Sync</Heading>
                    <Paragraph>
                      {isSyncing
                        ? `Sync is in progress`
                        : getConnectorByName("slack")?.lastSynced
                        ? `Last synced 
                      ${dayjs(
                        getConnectorByName("slack")?.lastSynced
                      ).fromNow()}`
                        : `Never synced`}
                    </Paragraph>
                  </Column>
                  <Column>
                    <Button
                      type="ui"
                      size="small"
                      disabled={isSyncing}
                      title={isSyncing ? "Syncing..." : "Sync Slack"}
                      onClick={() => {
                        syncSlack();
                      }}
                    />
                  </Column>
                </Row>
                {showSyncResults && (
                  <>
                    <CardDescription>
                      <Table>
                        <Tr style={{ paddingBottom: 16 }}>
                          <Td>
                            <Heading size="h4">Result Type</Heading>
                          </Td>
                          <Td>
                            <Heading size="h4">Number of Users</Heading>
                          </Td>
                        </Tr>

                        <Tr>
                          <Td>Newly Synced Users</Td>
                          <Td>{syncResults.newUsers}</Td>
                        </Tr>
                        <Tr>
                          <Td>Existing Saber Users</Td>
                          <Td>{syncResults.existingUsers}</Td>
                        </Tr>
                        <Tr>
                          <Td>Non-Saber Users</Td>
                          <Td>{syncResults.suggestedUsers}</Td>
                        </Tr>
                        <Tr>
                          <Td>Deactivated Users</Td>
                          <Td>{syncResults.deactivatedUsers}</Td>
                        </Tr>
                      </Table>

                      <ExplanationsContainer>
                        <Explanation>
                          <Heading size="h4">Non-Saber Users</Heading>
                          <Paragraph>
                            These are users exist in your Slack workspace, but
                            don't have a Saber account. We've collected these
                            for you, so you can easily invite them in{" "}
                            <a href="#">Members & Roles</a>.
                          </Paragraph>
                        </Explanation>
                        <Explanation>
                          <Heading size="h4">Deactivated Users</Heading>
                          <Paragraph>
                            Deactivated users are users that are deactivated in
                            your Workspace. We simply ignore these.
                          </Paragraph>
                        </Explanation>
                      </ExplanationsContainer>
                    </CardDescription>
                  </>
                )}
              </Card>
            </>
          )}
        </>
      )}
    </>
  );
};

export default SettingsSlack;
