import React from "react";
import { styled } from "styled-components";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { motion, useAnimation } from "framer-motion";

import { useAuth } from "../../../../hooks/useAuth";

import { Avatar, FreshnessScore } from "@saberapp/react-component-library";
import { IconWrapper } from "@saberapp/icons";

dayjs.extend(relativeTime);
dayjs.locale("en");

const Container = styled.div`
  border: 1px solid var(--card-border);
  border-radius: 6px;
  overflow: hidden;
  position: relative;

  &:hover {
    cursor: pointer;
    border: 1px solid var(--mid-shadow-2);
  }
`;

const AnswerContent = styled.div`
  background: var(--card-bg);
  padding: 16px;
  padding-bottom: 52px;
  display: flex;
  flex-direction: column;
`;

const AnswerFooter = styled(motion.div)`
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background: var(--main-bg);
  box-sizing: border-box;
  font-size: 12px;

  position: absolute;
  bottom: -26px;
  width: 100%;
`;
const By = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  line-height: 1.5;
`;
const When = styled.div``;
const Path = styled.div`
  position: relative;

  &:before {
    content: "";
    display: block;
    width: 2px;
    background: var(--card-border);
    position: absolute;
    top: 0;
    left: 9px;
    height: 100%;
    z-index: 0;
  }
`;
const Letter = styled.div`
  background: var(--card-border);
  border-radius: 50%;
  display: flex;
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 12px;
`;
const Q = styled.div`
  line-height: 1.5;
  font-weight: 500;
  color: var(--heading-text);
  padding-bottom: 16px;
`;
const A = styled.div`
  line-height: 1.5;
  height: auto;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  min-height: 60px;
`;
const QASection = styled.div`
  display: flex;
  gap: 12px;
`;

const DirtyIconWrapper = styled.div`
  color: inherit;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;
`;

const Answer = ({ answer, showQuestion = true }) => {
  const navigate = useNavigate();
  const { organization } = useAuth();

  const controls = useAnimation();

  const handleMouseEnter = () => {
    controls.start({ bottom: "0px" });
  };

  const handleMouseLeave = () => {
    controls.start({ bottom: -26 });
  };

  return (
    <Container
      onClick={() => {
        navigate(`/${organization.slug}/answers/${answer._id}`);
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <AnswerContent>
        {showQuestion && <QASection>
          <Path>
            <Letter />
          </Path>
          <Q>{answer.name}</Q>
        </QASection>}
        <QASection>
          {/* <Letter>A</Letter> */}
          <DirtyIconWrapper>
            <Avatar url={answer.creator.avatar} />
          </DirtyIconWrapper>
          <A>{answer.answer}</A>
        </QASection>
      </AnswerContent>
      <AnswerFooter
        initial={{ bottom: -26 }}
        animate={controls}
        exit={{ bottom: -26 }}
        transition={{ duration: 0.1 }}
      >
        <By>
          {/* {answer.creator.name}
        on Saber */}
          <IconWrapper>
            <FreshnessScore score={answer?.freshnessScore} />
          </IconWrapper>

          {`Reviewed ${dayjs(answer?.reviewedTime).fromNow()}`}
        </By>

        <When>Added {dayjs(answer.createdAt).fromNow()} on Saber</When>
      </AnswerFooter>
    </Container>
  );
};

export default Answer;
