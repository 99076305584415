import React from "react";
import { styled } from "styled-components";
import { AnimatePresence } from "framer-motion";

// Icons
import { ReactComponent as IconClose } from "../../icons/20/solid/x-mark.svg";

// Component Library
import { Heading, Button, Overlay } from "@saberapp/react-component-library";

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  z-index: 9000;

  backdrop-filter: blur(6px);
`;

const StyledModal = styled.div`
  margin-top: 64px;
  height: fit-content;
  width: 640px;
  backdrop-filter: blur(2px);
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background: var(--modal-bg);
  border: 1px solid var(--modal-border);
  box-shadow: var(--mid-shadow-2) 0px 16px 70px;
`;

const ModalHeader = styled.div`
  display: flex;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--border-color-1);
`;

const ModalHeaderButtons = styled.div`
  display: flex;
`;

const Modalfooter = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid var(--border-color-1);
  padding: 16px;
`;

const Modal = ({
  chrome = false,
  children,
  title = "Modal Title Placeholder",
  buttonTitle = "Save Changes",
  width = 600,
  isVisible = false,
  setIsVisible,
  handleSubmit = () => {},
}) => {
  const closeModal = (e) => {
    e && e.preventDefault();
    setIsVisible(false);
  };

  const submit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    handleSubmit(formData, e);
    closeModal();
  };

  return (
    <AnimatePresence>
      {isVisible && (
        <Overlay>
          <ModalContainer>
            <StyledModal width={width}>
              <form>
              {!chrome && (
                <ModalHeader>
                  <Heading size="h4">{title}</Heading>
                  <ModalHeaderButtons>
                    <Button
                      type="ui"
                      size="small"
                      iconBefore={<IconClose />}
                      noborder="true"
                      onClick={(e) => {
                        closeModal(e);
                      }}
                    />
                  </ModalHeaderButtons>
                </ModalHeader>
              )}

              {React.Children.map(children, (child) =>
                React.cloneElement(child, { closeModal: closeModal })
              )}

              {!chrome && (
                <Modalfooter>
                  <Button
                    type="ui"
                    title="Cancel"
                    size="regular"
                    onClick={(e) => {
                      closeModal(e);
                    }}
                  />
                  <Button
                    type="primary"
                    title={buttonTitle}
                    size="regular"
                    onClick={(e) => {
                      submit(e);
                    }}
                  />
                </Modalfooter>
              )}
              </form>
            </StyledModal>
          </ModalContainer>
        </Overlay>
      )}
    </AnimatePresence>
  );
};

export default Modal;
