import React from "react";
import styled from "styled-components";

// Component Library
import { Heading, Pill } from "@saberapp/react-component-library";
import IconWrapper from "../../../icons/IconWrapper";
import { ReactComponent as IconNotion } from "../../../icons/connectors/notion.svg";
import { ReactComponent as IconConfluence } from "../../../icons/connectors/confluence.svg";

const Row = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;

  svg {
    width: 20px;
  }
`;

const IconBackground = styled.div`
  background: #fff;
  padding: 4px;
  border-radius: 6px;
`;

const DocSourceHeader = ({ groupKey, count }) => {
  const sources = {
    notion: {
      name: "Notion",
      icon: <IconNotion />,
    },
    confluence: {
      name: "Confluence",
      icon: <IconConfluence />,
    },
  };

  console.log('groupKey', groupKey)

  return (
    <>
      <Row>
        <IconBackground>
          <IconWrapper size="16">{sources[groupKey]?.icon}</IconWrapper>
        </IconBackground>
        <Heading size="h3">{sources[groupKey]?.name}</Heading>
        <Pill title={`${count}`} />
      </Row>
      {/* <Paragraph>TEST: {fileGroupsMap[groupKey]?.description}</Paragraph> */}
    </>
  );
};

export default DocSourceHeader;
