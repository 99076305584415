import React, { useEffect, useState } from "react";
import styled from "styled-components";
import IntegrationSync from "../components/IntegrationSync";
import {
  Button,
  Checkbox,
  Heading,
  Paragraph,
} from "@saberapp/react-component-library";
import { useApi } from "../../../../hooks/useApi";
import { HttpMethod } from "@saberapp/enums";

const SpacesConfig = styled.div`
  width: 100%;
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid var(--button-border);
`;

const SpacesConfigFooter = styled.div`
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid var(--button-border);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
`;

const Table = styled.table`
  width: 100%;
  border-spacing: 0;
  background: var(--main-bg);
  border-radius: 6px;
`;
const Tr = styled.tr`
  line-height: 24px;
  &:nth-child(even) {
    background: var(--card-border);
  }
`;
const Td = styled.td`
  padding: 8px;
`;

const Centered = styled.div`
  display: flex;
  justify-content: center;
`;

const SettingsConfluence = ({ connector }) => {
  const { API } = useApi();

  const [spaces, setSpaces] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSyncEnabled, setIsSyncEnabled] = useState(false);
  const [selectedSpaces, setSelectedSpaces] = useState([]);

  useEffect(() => {
    if (API.isReady) {
      (async () => {
        getSpaces();
      })();
    }
  }, [API.isReady]);

  useEffect(() => {
    setIsSyncEnabled(connector?.metaData?.spaces?.length > 0);
    setSelectedSpaces(connector?.metaData?.spaces || []);
  }, [connector]);

  const getSpaces = async () => {
    setIsLoading(true);
    try {
      await API.call(
        HttpMethod.GET,
        `/connectors/confluence/spaces`,
        {},
        (data) => {
          setIsLoading(false);
          const merged = mergeArraysByKeys(data, selectedSpaces);
          setSpaces(merged);
        }
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const updateSelectedSpaces = (space) => {

    setSelectedSpaces((currentSpaces) => {
      const exists = currentSpaces.some(
        (existingSpace) => existingSpace.key === space.key
      );

      if (exists) {
        return currentSpaces.filter(
          (existingSpace) => existingSpace.key !== space.key
        );
      } else {
        const updatedSpace = { ...space, selected: true };
        return [...currentSpaces, updatedSpace];
      }
    });
  };

  useEffect(() => {
    if (selectedSpaces?.length > 0) {
      setIsSyncEnabled(true);
    } else {
      setIsSyncEnabled(false);
    }
  }, [selectedSpaces]);

  function mergeArraysByKeys(array1, array2) {
    array2 = Array.isArray(array2) ? array2 : [];
    const mergeMap = new Map();
    array1.forEach((obj) => mergeMap.set(obj.key, obj));
    array2.forEach((obj) => mergeMap.set(obj.key, obj));
    return Array.from(mergeMap.values());
  }

  return (
    <IntegrationSync
      connector={connector}
      heading={`Sync your Confluence pages and spaces`}
      isSyncEnabled={isSyncEnabled}
      disabledMessage={`Select Confluence Spaces`}
      disabledDescription={`We'll only sync with the selected spaces`}
      customConfigButtonTitle={isSyncEnabled ? `Edit Spaces` : `Set Spaces`}
      customConfigButtonTitleHide={`Hide Spaces`}
      customConfigVisibility={spaces?.length > 0}
      customConfigData={selectedSpaces}
      customConfig={
        <SpacesConfig>
          {!isLoading && spaces?.length > 0 && (
            <Table>
              <Tr style={{ paddingBottom: 16 }}>
                <Td width={24}></Td>
                <Td>
                  <Heading size="h4">Name</Heading>
                </Td>
                <Td>
                  <Heading size="h4">Key</Heading>
                </Td>
                <Td>
                  <Heading size="h4">Status</Heading>
                </Td>
              </Tr>

              {spaces.map((space) => {
                return (
                  <Tr key={space.id}>
                    <Td>
                      <Centered>
                        <Checkbox
                          checked={
                            selectedSpaces.some(
                              (selectedSpace) => selectedSpace.key === space.key
                            )
                              ? true
                              : false
                          }
                          onChange={() => {
                            updateSelectedSpaces(space);
                          }}
                        />
                      </Centered>
                    </Td>
                    <Td>{space.name}</Td>
                    <Td>{space.key}</Td>
                    <Td>{space.confluenceStatus}</Td>
                  </Tr>
                );
              })}
            </Table>
          )}

          <SpacesConfigFooter>
            <Paragraph>Can't see the space you're looking for?</Paragraph>
            <Button
              type="ui"
              size="small"
              title="Refresh Spaces"
              onClick={() => {
                getSpaces();
              }}
            />
          </SpacesConfigFooter>
        </SpacesConfig>
      }
    />
  );
};

export default SettingsConfluence;
