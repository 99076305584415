import React from "react";
import styled from "styled-components";

import Page from "./components/Page";
import PageHeader from "./components/PageHeader";
import PageContent from "./components/PageContent";
import PageFooter from "./components/PageFooter";

import { Heading, Paragraph } from "@saberapp/react-component-library";

const Container = styled.div`
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  height: calc(100vh - 280px);
`;

const Button = styled.a`
  background: var(--accent-color);
  display: inline-block;
  width: fit-content;
  padding: 12px 16px;
  color: var(--button-cta-text);
  font-weight: 600;
  font-size: 16px;
  border-radius: 8px;
`;

const HomePage = () => {
  return (
    <>
      <PageHeader />
      <Page>
        <PageContent>
          <Container>
            <Heading size="h1">
              We're building the next generation of sales enablement and
              productivity tools.
            </Heading>
            <Button href="mailto:sales@saber.app">Book a demo</Button>
          </Container>
        </PageContent>
        <PageFooter />
      </Page>
    </>
  );
};

export default HomePage;
