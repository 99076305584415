import React, { useContext, useEffect, useState } from "react";
import { styled } from "styled-components";

// Hooks
import { useAuth } from "../../../hooks/useAuth";
import { Search } from "../../../hooks/useSearch";

// Component Library
import { SidebarHeading, SidebarLink } from "@saberapp/react-component-library";

import SidebarHeader from "./components/SidebarHeader";
import SidebarFooter from "./components/SidebarFooter";

// Icons
import {
  IconHome,
  IconBookOpen,
  IconMegaphone,
  IconBolt,
  IconUserCircle,
  IconExclamationCircle,
  IconTableCells as IconTable,
  IconChartPie as IconReporting,
  IconInbox,
} from "@saberapp/icons";

import { useData } from "../../../hooks/useData";
import { FreshnessScoreValues } from "@saberapp/enums";

const StyledSidebar = styled.nav`
  width: 204px;
  min-width: 204px;
  height: calc(100% - 32px);
  display: flex;
  flex-direction: column;
  padding: 16px;
  background: var(--sidebar-bg);
  border-right: 1px solid var(--sidebar-border);

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
`;

const SidebarNavigation = () => {
  const { organization } = useAuth();
  const { resources, tasks } = useData();

  // const { searchBox } = useContext(Search);

  const [documentCount, setDocumentCount] = useState(0);
  const [pagesCount, setPagesCount] = useState(0);
  const [snippetCount, setSnippetCount] = useState(0);

  useEffect(() => {
    const counts = resources.reduce(
      (acc, resource) => {
        const isGoogleDrive = resource.docSource === "google_drive";

        const isNotionOrConfluence =
          resource.docSource === "notion" ||
          resource.docSource === "confluence";

        const isSnippet = resource.docSource === "saber_snippet";

        const needsCounting =
          resource.freshnessScore === FreshnessScoreValues.OUTDATED ||
          resource.freshnessScore === FreshnessScoreValues.NEEDS_REVIEW;

        if (isGoogleDrive && needsCounting) {
          acc.docCount++;
        }
        if (isNotionOrConfluence && needsCounting) {
          acc.pagesCount++;
        }
        if (isSnippet && needsCounting) {
          acc.snippetCount++;
        }

        return acc;
      },
      { docCount: 0, pagesCount: 0, snippetCount: 0 }
    );

    setDocumentCount(counts.docCount);
    setPagesCount(counts.pagesCount);
    setSnippetCount(counts.snippetCount);
  }, [resources]);

  return (
    <StyledSidebar>
      <SidebarHeader />
      <ul>
        <SidebarLink
          icon={<IconHome />}
          path={`/${organization.slug}/`}
          title={`Home`}
        />
        <SidebarLink
          icon={<IconInbox />}
          path={`/${organization.slug}/inbox`}
          title={`Inbox`}
          count={tasks.data?.length}
        />

        <SidebarLink
          icon={<IconBolt />}
          path={`/${organization.slug}/sandbox`}
          title="Sandbox"
        />

        {/* <SidebarLink
          icon={<IconBolt />}
          onClick={() => {
            searchBox.open();
          }}
          title="Command"
        /> */}
      </ul>
      <SidebarHeading title="Insights" />
      <ul>
        <SidebarLink
          icon={<IconMegaphone />}
          path={`/${organization.slug}/insights/engagement`}
          title={`Engagement`}
        />
        <SidebarLink
          icon={<IconReporting />}
          path={`/${organization.slug}/insights/trends`}
          title={`Trends`}
        />
      </ul>
      <SidebarHeading title="Content &amp; Data" />
      <ul>
        <SidebarLink
          icon={<IconTable />}
          path={`/${organization.slug}/documents`}
          title="Documents"
          count={documentCount}
        />
        <SidebarLink
          icon={<IconBookOpen />}
          path={`/${organization.slug}/pages-and-notes`}
          title="Pages & Notes"
          count={pagesCount}
        />
        <SidebarLink
          icon={<IconUserCircle />}
          path={`/${organization.slug}/crm`}
          title="CRM"
        />
        <SidebarLink
          icon={<IconExclamationCircle />}
          path={`/${organization.slug}/answers`}
          title="Q&A"
          count={snippetCount}
        />
      </ul>
      <SidebarFooter />
    </StyledSidebar>
  );
};

export default SidebarNavigation;
