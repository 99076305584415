import React from "react";

import { styled } from "styled-components";
import { Heading, Paragraph } from "@saberapp/react-component-library";

const StatsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 25%;
  min-width: 320px;
  padding: 32px;
  gap: 8px;
`;

const Stats = () => {
  return (
    <StatsContainer>
      <Heading size="h4">Stats</Heading>
      <Paragraph>Summaries and </Paragraph>
    </StatsContainer>
  );
};

export default Stats;
