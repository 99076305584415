import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { auth } from "../util/firebase";
import {
  onAuthStateChanged,
  GoogleAuthProvider,
  signOut as signOutFromGoogle,
  signInWithPopup,
  signInWithCustomToken,
} from "firebase/auth";
import { paragon } from "@useparagon/connect";
import posthog from "posthog-js";

// Create a context
export const AuthContext = createContext();

// Create a provider for the AuthContext
export const AuthProvider = ({ children }) => {
  const getLocalStorageData = (key) => {
    try {
      return localStorage.getItem(key)
        ? JSON.parse(localStorage.getItem(key))
        : null;
    } catch (e) {
      return null;
    }
  };

  const [isLoading, setIsLoading] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isParagonAuthenticated, setIsParagonAuthenticated] = useState(false);
  const [user, setUser] = useState(() => getLocalStorageData("user"));
  const [organization, setOrganization] = useState(() =>
    getLocalStorageData("organization")
  );
  const [token, setToken] = useState(() => getLocalStorageData("token"));

  const [viewingRole, setViewingRole] = useState("ADMIN");
  const [isOwner, setIsOwner] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (firebaseUser) => {
      if (firebaseUser) {
        setIsAuthenticated(true);

        if (auth.currentUser) {
          auth.currentUser
            .getIdTokenResult()
            .then(({ claims }) => {
              setIsOwner(claims.isSaberOwner);
              setIsAdmin(claims.isSaberAdmin);
              setIsSuperAdmin(claims.isSuperAdmin);
            })
            .catch((error) => {
              showApiError(error.message || "Failed to get user token");
              signOut();
            });
        }
      } else {
        setIsAuthenticated(false);
        setIsAdmin(false);
        setIsSuperAdmin(false);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [auth.currentUser, token]);

  // Set up a few things when logged in
  useEffect(() => {
    if (token) {
      (async () => {
        try {
          const projectId =
            process.env.REACT_APP_SABER_ENV === "production"
              ? "8b6fab00-4fc0-4651-a815-241cfc5a224c" // Production Project ID
              : process.env.REACT_APP_SABER_ENV === "staging"
              ? "65373e69-c0c8-4989-890e-475120a94119" // Staging Project ID
              : "49143d77-da1a-452a-8077-eea3d81290fc"; // Development Project ID
          await paragon.authenticate(projectId, token);
          setIsParagonAuthenticated(true);

          await paragon.setUserMetadata({
            Organization: organization.name,
            OrganizationId: organization._id,
          });
        } catch (error) {
          console.error(error);
        }
      })();

      // Posthog is only available in production, for now
      // Multiple projects requires us to upgrade the account
      if (process.env.REACT_APP_SABER_ENV === "production") {
        posthog.init("phc_SM2ZYlbJvlPFGisJfIvRmCiYLA2ZQw8Gs4mgZtQldvH", {
          api_host: "https://eu.i.posthog.com",
        });

        posthog.identify(user._id, {
          email: user.email,
          name: user.name,
          organization: organization._id,
          organizationName: organization.name,
        });
      }
    }
  }, [token, user, organization]);

  function updateUserData(updatedUser, organization, token) {
    setToken(token);
    localStorage.setItem("token", JSON.stringify(token));

    updateUserProfile(updatedUser);
    updateOrganization(organization);
  }

  function updateUserProfile(updatedUser) {
    updatedUser = { ...user, ...updatedUser };
    console.log(user, updatedUser);
    setUser(updatedUser);
    localStorage.setItem("user", JSON.stringify(updatedUser));
  }

  const updateOrganization = (organization) => {
    setOrganization(organization);
    localStorage.setItem("organization", JSON.stringify(organization));
  };

  const signOut = (navigate) => {
    signOutFromGoogle(auth)
      .then(() => {
        updateUserData(null, null);
        if (navigate) navigate("/sign-in");
      })
      .catch((error) => {
        console.error("Error during sign-out:", error);
      });
  };

  // getters

  const userHasRole = (roleName) => {
    if (viewingRole) {
      return viewingRole === roleName;
    }
    return user?.roles.length > 0
      ? user.roles.some((role) => role.name === roleName)
      : false;
  };

  const viewAsRole = (roleName) => {
    setViewingRole(roleName);
  };

  const fetchUserAndOrganization = async (navigate) => {
    setIsLoading(true);
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const {
      data: { organization, user, token },
    } = await axios.get(`/self?timeZone=${timeZone}`);

    updateUserData(user, organization, token);
    setIsLoading(false);
    if (navigate) {
      // console.log("NAVIGATING");
      navigate(`/${organization.slug}/`);
    }
  };

  const signInWithGoogle = (navigate) => {
    setIsLoading(true);
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        // console.log("RESULT: ", result);
        // The signed-in user info.
        const user = result.user;

        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${user.accessToken}`;

        // fetch user and organization
        return fetchUserAndOrganization(navigate);
      })
      .catch((error) => {
        // Handle Errors here.
        // ...
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(`Error Code: ${errorCode}`);
        console.log(`Error Message: ${errorMessage}`);
      })
      .finally(() => {
        setIsLoading(false);
        // Set the loading state to false
      });
  };
  const signInUsingCustomToken = (token, navigate) => {
    setIsLoading(true);
    signInWithCustomToken(auth, token)
      .then((userCredential) => {
        const user = userCredential.user;
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${user.accessToken}`;
        // fetch user and organization
        return fetchUserAndOrganization(navigate);
      })
      .catch((error) => {
        // Handle Errors here.
        // ...
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(`Error Code: ${errorCode}`);
        console.log(`Error Message: ${errorMessage}`);
      })
      .finally(() => {
        setIsLoading(false);
        // Set the loading state to false
      });
  };

  const getGoogleSignInURL = async (permissions) => {
    try {
      const response = await axios.get(
        `/organizations/${organization._id}/generate-auth-url`,
        {
          params: { permissions },
        }
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.error("Error fetching Google Sign-In URL:", error);
    }
  };

  // const permissionScopes = {
  //   "google_calendar": ['https://www.googleapis.com/auth/calendar.readonly'],
  //   "google_drive": [
  //     'https://www.googleapis.com/auth/drive.file',
  //     'https://www.googleapis.com/auth/drive.readonly',
  //     'https://www.googleapis.com/auth/drive.appdata'
  //   ],
  // }

  // const signInWithGoogleWithAdditionalScope = (forScope) => {
  //   if (!permissionScopes.hasOwnProperty(forScope)) {
  //     throw new Error(`Invalid scope: ${forScope}`);
  //   }

  //   const provider = new GoogleAuthProvider()

  //   provider.setCustomParameters({
  //     access_type: 'offline',
  //   })

  //   permissionScopes[forScope].forEach(scope => {
  //     provider.addScope(scope)
  //   })
  //   const action = isAuthenticated ? reauthenticateWithPopup : signInWithPopup
  //   return action(isAuthenticated ? auth.currentUser : auth, provider).then((result) => {
  //     console.log('RESULT: ', result)
  //     const credential = GoogleAuthProvider.credentialFromResult(result);
  //     console.log('CREDENTIAL: ', credential)
  //     const token = credential.accessToken;
  //     return token
  //   }).catch((error) => {
  //     // Handle Errors here.
  //     // ...
  //     const errorCode = error.code;
  //     const errorMessage = error.message;
  //     console.log(`Error Code: ${errorCode}`);
  //     console.log(`Error Message: ${errorMessage}`);
  //     return null
  //   })
  // }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isParagonAuthenticated,
        signInWithGoogle,
        signInUsingCustomToken,
        getGoogleSignInURL,
        fetchUserAndOrganization,
        isLoading,
        signOut,
        user,
        organization,
        userHasRole,
        viewAsRole,
        viewingRole,
        isOwner,
        isAdmin,
        isSuperAdmin,
        updateUserProfile,
        updateOrganization,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to use the AuthContext
export const useAuth = () => useContext(AuthContext);
