import React from "react";
import { UsersProvider } from "../../../../hooks/useUsers";
import SettingsTemplate from "../../../templates/SettingsTemplate";
import SettingsMembers from "./SettingsMembers";

const SettingsMembersIndex = () => {
  return (
    <UsersProvider>
      <SettingsTemplate>
        <SettingsMembers />
      </SettingsTemplate>
    </UsersProvider>
  );
};

export default SettingsMembersIndex;
