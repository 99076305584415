import React, { useEffect } from "react";

import { styled } from "styled-components";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

import { useConnectors } from "../../../../hooks/useConnectors";
import { Card, Heading, Paragraph } from "@saberapp/react-component-library";

import { ReactComponent as IconGoogleDrive } from "../../../icons/connectors/google-drive.svg";
import { ReactComponent as IconGoogleMail } from "../../../icons/connectors/google-mail.svg";
import { ReactComponent as IconGoogleCalendar } from "../../../icons/connectors/google-calendar.svg";
import { ReactComponent as IconSlack } from "../../../icons/connectors/slack.svg";
import { ReactComponent as IconSalesforce } from "../../../icons/connectors/salesforce.svg";
import { ReactComponent as IconPipedrive } from "../../../icons/connectors/pipedrive.svg";
import { ReactComponent as IconNotion } from "../../../icons/connectors/notion.svg";
import { IconPlus, IconSquaresPlus, IconWrapper } from "@saberapp/icons";

import { IntegrationStatusIndicator } from "../../../pages/settings/components/IntegrationStatusIndicator";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../hooks/useAuth";
import LoadingState from "../../../layouts/LoadingState";
import EmptyState from "../../../layouts/EmptyState";

const ConnectorsContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 320px;
  padding: 16px;
  gap: 8px;
`;

const ConnectorsList = styled.div`
  margin-top: 8px;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(auto-fit, minmax(160px, calc(33% - 8px)));
`;

const LogoContainer = styled.div`
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background: #fff;
  padding: 8px;
  box-sizing: border-box;
`;

const CardInner = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  position: relative;
`;
const Right = styled.div``;

const StatusContainer = styled.div`
  top: 0;
  position: absolute;
  right: 0;
`;

const Connectors = () => {
  const { connectors } = useConnectors();
  const { organization } = useAuth();
  const navigate = useNavigate();

  useEffect(()=>{ console.log(connectors) },[connectors])

  const logos = {
    google_drive: <IconGoogleDrive />,
    google_calendar: <IconGoogleCalendar />,
    salesforce: <IconSalesforce />,
    pipedrive: <IconPipedrive />,
    slack: <IconSlack />,
    notion: <IconNotion />,
    gmail: <IconGoogleMail />,
  };

  return (
    <ConnectorsContainer>
      <Heading size="h4">Connectors</Heading>

      {connectors.length > 0 && (
        <>
          <Paragraph>All your connected applications are healthy.</Paragraph>
          <ConnectorsList>
            {connectors.map((connector, i) => {
              const urlPath = connector.name.toLowerCase().replace(/\s+/g, "-");
              return (
                <Card
                  padding={16}
                  key={i}
                  onClick={() => {
                    navigate(`/${organization.slug}/connector/${urlPath}`);
                  }}
                >
                  <CardInner>
                    <StatusContainer>
                      <IntegrationStatusIndicator
                        pulse="false"
                        status={connector.status}
                      />
                    </StatusContainer>
                    <LogoContainer>{logos[connector.source]}</LogoContainer>
                    <Right>
                      <Heading size="h4">{connector.name}</Heading>
                      <Paragraph>
                        {connector.lastSynced
                          ? `Last activity ${dayjs(
                              connector.lastSynced
                            ).fromNow()}`
                          : "Never synced"}
                      </Paragraph>
                    </Right>
                  </CardInner>
                </Card>
              );
            })}
          </ConnectorsList>
        </>
      )}

      {connectors.length === 0 && (
        <EmptyState
          border="true"
          text="You don't have any connectors set up yet."
          buttonIcon={<IconSquaresPlus />}
          buttonText="Set up your first App"
          buttonOnClick={() => navigate(`/${organization.slug}/settings`)}
        />
      )}

    </ConnectorsContainer>
  );
};

export default Connectors;
