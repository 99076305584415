import React, { createContext, useContext, useState, useEffect } from "react";

import { HttpMethod } from "@saberapp/enums";

import { useAuth } from "./useAuth";
import { useApi } from "./useApi";

const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const { isLoading, isAuthenticated } = useAuth();
  const { API } = useApi();

  const [loading, setLoading] = useState(true);
  const [loaded, setLoaded] = useState(0);
  const [progress, setProgress] = useState(0);

  const [connectors, setConnectors] = useState([]);
  const [resources, setResources] = useState([]);
  const [events, setEvents] = useState([]);
  const [gaps, setGaps] = useState([]);
  const [feedback, setFeedback] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [tasks, setTasks] = useState([]);

  const dataToLoad = [
    {
      name: "Connectors",
      path: `/connectors/`,
      setter: setConnectors,
      value: connectors,
    },
    {
      name: "Resources",
      path: `/resources/`,
      setter: setResources,
      value: resources,
    },
    { name: "Events", path: `/audit-log/`, setter: setEvents, value: events },
    {
      name: "Gaps",
      path: `/content-gap/questions`,
      setter: setGaps,
      value: gaps,
    },
    {
      name: "Feedback",
      path: `/feedback-analytics/answers/`,
      setter: setFeedback,
      value: feedback,
    },
    {
      name: "Answers",
      path: `/saber-snippet/`,
      setter: setAnswers,
      value: answers,
    },
    { name: "Tasks", path: `/tasks/`, setter: setTasks, value: tasks },
  ];

  useEffect(() => {
    const fetchData = async () => {
      if (!isLoading && isAuthenticated && API.isReady) {
        try {
          (async () => {
            try {
              const fetchPromises = dataToLoad.map((set) => getData(set));
              await Promise.all(fetchPromises);
              setLoading(false);
            } catch (error) {
              console.error("Failed to fetch data", error);
              setLoading(false);
            }
          })();
        } catch (error) {
          console.error("Failed to fetch data", error);
        }
      }
    };

    fetchData();
  }, [isAuthenticated, API.isReady]);

  useEffect(() => {
    const percentage = (loaded / dataToLoad.length) * 100 + "%";
    setProgress(percentage);
  }, [loaded]);

  const getData = async ({ name, path, setter }) => {
    await API.call(HttpMethod.GET, path, {}, (data) => {
      if (name === "Connectors") {
        // TODO: Dirty fix for now
        setter(data.connectors);
      } else if (name === "Resources") {
        // TODO: Dirty fix for now
        setter(data.resources);
      } else {
        setter(data);
      }
      setLoaded((prevLoaded) => {
        const newLoaded = prevLoaded + 1;
        return newLoaded;
      });
    }).catch((e) => {
      console.error(e);
    });
  };

  const reload = async (name) => {
    const dataSet = dataToLoad.find((set) => set.name === name);
    await getData(dataSet);
  };

  const updateDataSet = (name, method, record) => {
    const dataSet = dataToLoad.find((set) => set.name === name);
    if (!dataSet) {
      console.error("Local data set not found");
      return;
    }

    if (method === "UPDATE") {
      // Find the record and replace it with the updated version
      dataSet.setter((prevValues) => {
        return prevValues.map((prevRecord) =>
          prevRecord._id === record._id
            ? { ...prevRecord, ...record }
            : prevRecord
        );
      });
    } else if (method === "REMOVE") {
      // Find the record in the dataset and remove it
      dataSet.setter((prevValues) => {
        return prevValues.filter((item) => item._id !== record._id);
      });
    } else if (method === "ADD") {
      // Add the record to the data set
      dataSet.setter((prevValues) => {
        return [record, ...prevValues];
      });
    } else {
      console.error(
        `Method '${method}' is not recognised; use UPDATE, REMOVE or ADD`
      );
      return;
    }
  };

  return (
    <DataContext.Provider
      value={{
        loading,
        loaded,
        progress,
        reload,
        updateDataSet,
        connectors,
        setConnectors,
        resources,
        setResources,
        events,
        setEvents,
        gaps,
        setGaps,
        feedback,
        setFeedback,
        answers,
        setAnswers,
        tasks,
        setTasks,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

// Custom hook to use the AuthContext
export const useData = () => useContext(DataContext);
