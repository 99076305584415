import React from "react";
import styled from "styled-components";
import DashboardTemplate from "../../templates/DashboardTemplate";
import EmptyState from "../inventory/components/EmptyState";
import TopNavigation from "../../layouts/main-top-bar";
import { useAuth } from "../../../hooks/useAuth";

const RowContainer = styled.div`
  display: flex;
  height: calc(100% - 62px);
  max-width: 100%;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
`;

const CRM = () => {
  const { organization } = useAuth();

  return (
    <DashboardTemplate>
      <TopNavigation
        links={[
          { title: "Content & Data", path: `/${organization.slug}/documents` },
          { title: "CRM", path: `/${organization.slug}/crm` },
        ]}
      ></TopNavigation>
      <RowContainer>
        <Column>
        <EmptyState page={"crm"} />
        </Column>
      </RowContainer>
      
    </DashboardTemplate>
  );
};

export default CRM;
