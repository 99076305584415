import React from "react";

// Context
import { ResourcesProvider } from "../../../hooks/useResources";
import { ContextMenuProvider } from "../../../hooks/useContextMenu";

// Template
import DashboardTemplate from "../../templates/DashboardTemplate";

// Page
import Resources from "./Resources";

const ResourcesIndex = () => {
  return (
    <DashboardTemplate>
      <ContextMenuProvider>
        <ResourcesProvider>
          <Resources />
        </ResourcesProvider>
      </ContextMenuProvider>
    </DashboardTemplate>
  );
};

export default ResourcesIndex;
