import React from "react";
import styled from "styled-components";

// Component Library
import { Button } from "@saberapp/react-component-library";

const ModalFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 22px;
  gap: 8px;
`;

export const EditFooter = ({ closeModal, onSave = () => {}, saveTitle = 'Save'  }) => {
  return (
    <ModalFooter>
      <Button
        type="ui"
        title="Cancel"
        onClick={(e) => closeModal(e)}
      />
      <Button
        type="ui"
        title={saveTitle}
        onClick={(e) => onSave(e)}
      />
    </ModalFooter>
  );
};

