import React from "react";
import styled from "styled-components";

const Background = styled.div`
  background: ${(props) => `${props.theme.colors.backgroundColor}`};
  height: 100%;
`;

const Container = styled.div`
  text-align: center;
  height: 100%;
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const AuthTemplate = ({ children }) => {
  return (
    <Background>
      <Container>
        <Center>
          {children}
        </Center>
      </Container>
    </Background>
  );
};

export default AuthTemplate;
