import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

import { styled } from "styled-components";

// Component Library
import {
  Button,
  Card,
  Heading,
  Paragraph,
} from "@saberapp/react-component-library";

// Enums
import { HttpMethod } from "@saberapp/enums";

// Hooks
import { useAuth } from "../../../../hooks/useAuth";
import { useConnectors } from "../../../../hooks/useConnectors";
import { useApi } from "../../../../hooks/useApi";

import SettingsTemplate from "../../../templates/SettingsTemplate";

import { ReactComponent as IconGoogleCalendar } from "../../../icons/connectors/google-calendar.svg";
import IntegrationsHeader from "../components/IntegrationHeader";
import IntegrationsStatus from "../components/IntegrationStatus";
import IntegrationsOverview from "../components/IntegrationOverview";
import connectSocket from "../../../../helpers/socketHelper";

dayjs.extend(relativeTime);
dayjs.locale("en");

const Column = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;

  &:last-child {
    margin-left: auto;
  }
`;

const Row = styled.div`
  display: flex;
  gap: 32px;
`;

const RowSpaced = styled.div`
  display: flex;
  gap: 32px;
  justify-content: space-between;
  align-items: center;
`;

const CardDescription = styled.div`
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid var(--button-border);

  ul {
    margin: 0;
  }
`;

const Table = styled.table`
  width: 100%;
  border-spacing: 0;
`;
const Tr = styled.tr`
  &:nth-child(even) {
    background: var(--dropdown-bg);
  }
`;
const Td = styled.td`
  padding: 8px;
`;
const TdCentered = styled.td`
  padding: 8px;
  text-align: center;
`;

const Loader = styled.div`

  width: 12px;
  height: 12px;
  border: 2px solid var(--tag-text);
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  margin-right: 4px;

  @keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
`;

const Webhooks = styled.div`
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid var(--button-ui-border);
`;

const Elipsis = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  text-wrap: nowrap;
  max-width: 120px;
`;

const SettingsGoogleCalendar = () => {
  const { API } = useApi();
  const { getGoogleSignInURL, organization } = useAuth();

  const {
    updateConnectors,
    isConnectorsLoading,
    pollConnectorCallbackPopup,
    getUserConnectorByName,
    doesUserHaveConnector,
    setIsConnecting,
  } = useConnectors();

  const [authUrl, setAuthUrl] = useState(null);
  const [isUrlLoading, setIsUrlLoading] = useState(true);
  const [showOverview, setShowOverview] = useState(false);

  const [isSyncing, setIsSyncing] = useState(false);
  const [showSyncResults, setShowSyncResults] = useState(false);
  const [webhooks, setWebhooks] = useState([]);
  const [isSettingUpWebhooks, setIsSettingUpWebhooks] = useState(false);

  const [events, setEvents] = useState([]);

  useEffect(() => {
    if (!isConnectorsLoading) {
      if (
        !doesUserHaveConnector("google_calendar") ||
        getUserConnectorByName("google_calendar")?.status === "disconnected"
      ) {
        setupAuth();
      } else {
        setWebhooks(getUserConnectorByName("google_calendar")?.webhooks);
      }

      let cleanUpFunction = () => {};

      // Establish WebSocket connection and send organization ID
      connectSocket(organization._id)
        .then((socket) => {
          socket.on("google_connector_installation", (response) => {
            setIsConnecting(false);
            setShowOverview(false);
            if (response.ok) {
              if (!doesUserHaveConnector("google_calendar")) {
                updateConnectors("add", response.connector);
              } else {
                updateConnectors("update", response.connector);
              }
            }
            syncCalendar();
            setupWebhooks();
          });

          // Cleanup on unmount
          cleanUpFunction = () => {
            socket.disconnect();
          };
        })
        .catch((error) => {
          console.log(error);
        });

      // Cleanup on unmount
      return () => cleanUpFunction();
    }
  }, [isConnectorsLoading]);

  useEffect(() => {
    if (getUserConnectorByName("google_calendar")?.status === "disconnected") {
      setupAuth();
    }
  }, [getUserConnectorByName("google_calendar")?.status]);

  const setupAuth = () => {
    const generateAuthUrl = async () => {
      try {
        const url = await getGoogleSignInURL("basic google_calendar"); // When set to true, we ask extended permissions
        setAuthUrl(url);
        setIsUrlLoading(false);
      } catch (error) {
        console.error("Error fetching URL:", error);
      }
    };

    if (getUserConnectorByName("google_calendar")?.status !== "disconnected") {
      setShowOverview(true);
    }
    generateAuthUrl();
  };

  const openAuthWindow = () => {
    if (!isUrlLoading) {
      setIsConnecting(true);
      // Define the size and position of the pop-up window
      const width = 600;
      const height = 700;
      const left = (window.innerWidth - width) / 2;
      const top = (window.innerHeight - height) / 2;

      // Window features
      const windowFeatures = `width=${width},height=${height},top=${top},left=${left},status=yes,toolbar=no,menubar=no,location=yes`;

      // Open a new window for the authentication URL
      pollConnectorCallbackPopup(window.open(authUrl, "GoogleCalendarAuth", windowFeatures));
    }
  };

  const syncCalendar = async () => {
    setIsSyncing(true);
    try {
      await API.call(
        HttpMethod.GET,
        `/connectors/google-calendar/sync`,
        {},
        (data) => {
          setEvents(data.events);
          setIsSyncing(false);
          setShowSyncResults(true);
        }
      );
    } catch (error) {
      console.error("Error fetching data-soruces:", error);
    }
  };

  const setupWebhooks = async () => {
    setIsSettingUpWebhooks(true);
    try {
      await API.call(
        HttpMethod.POST,
        `/connectors/google-calendar/setupWebhooks`,
        {},
        (data) => {
          setIsSettingUpWebhooks(false);
          setWebhooks(data.webhooks);
        }
      );
    } catch (error) {
      console.error("Error fetching data-soruces:", error);
    }
  };

  useEffect(() => {
    if (getUserConnectorByName("google_calendar")?.status === "connected") {
      setShowOverview(false);
      setEvents(getUserConnectorByName("google_calendar")?.upcoming_events || []);
      if (getUserConnectorByName("google_calendar")?.upcoming_events?.length) {
        setShowSyncResults(true);
      }
    }
  }, [getUserConnectorByName("google_calendar")]);

  return (
    <SettingsTemplate>
      <IntegrationsHeader
        logo={<IconGoogleCalendar />}
        name="Google Calendar"
        description="Sync your upcoming calls to match them with CRM data and get the best suggested content"
      />

      <IntegrationsStatus
        source="google_calendar"
        slug="google-calendar"
        name="Google Calendar"
        openAuthWindow={openAuthWindow}
        userSpecific={true}
      />

      {!isConnectorsLoading && (
        <>
          {getUserConnectorByName("google_calendar")?.status ===
            "connected" && (
            <>
              <Card padding={16}>
                <Row>
                  <Column>
                    <Heading size="h4">Calendar Sync</Heading>
                    <Paragraph>
                      {isSyncing
                        ? `Sync is in progress`
                        : getUserConnectorByName("google_calendar")?.lastSynced
                        ? `Last synced 
                      ${dayjs(
                        getUserConnectorByName("google_calendar")?.lastSynced
                      ).fromNow()}`
                        : `Never synced`}
                    </Paragraph>
                  </Column>
                  <Column>
                    <Button
                      type="ui"
                      size="small"
                      disabled={isSyncing}
                      title={isSyncing ? "Syncing..." : "Sync Calendar"}
                      onClick={syncCalendar}
                    />
                  </Column>
                </Row>
                {(events.length > 0 && showSyncResults) && (
                  <>
                    <CardDescription>
                      <Table>
                        <Tr style={{ paddingBottom: 16 }}>
                          <Td>
                            <Heading size="h4">Date</Heading>
                          </Td>
                          <Td>
                            <Heading size="h4">Event</Heading>
                          </Td>
                          <Td>
                            <Heading size="h4">Opportunity</Heading>
                          </Td>
                          {/* <Td>
                            <Heading size="h4">Participants</Heading>
                          </Td> */}
                        </Tr>
                        {events.map((event) => (
                          <Tr>
                            <Td>
                              {dayjs(event.start.dateTime).format("MMMM D, hA")}
                            </Td>
                            <Td>{event.summary}</Td>
                            <Td></Td>
                            {/* <Td>{event.attendees.map((attendee) => attendee.email)}</Td> */}
                          </Tr>
                        ))}
                      </Table>
                    </CardDescription>
                  </>
                )}
              </Card>
              <Card padding={16}>
                <Row>
                  <Column>
                    <Heading size="h4">Webhooks</Heading>
                    <Paragraph>
                      This webhook fires for different events that happen in
                      your Pipedrive instance, including when Deals are updated,
                      or when Notes are added.
                    </Paragraph>
                  </Column>
                  <Column>
                    <Button
                      type="ui"
                      size="small"
                      disabled={isSettingUpWebhooks}
                      title={
                        isSettingUpWebhooks
                          ? "Checking..."
                          : "Check Webhook Health"
                      }
                      onClick={() => {
                        setupWebhooks();
                      }}
                    />
                  </Column>
                </Row>
                {webhooks?.length > 0 && (
                  <Webhooks>
                    <Table>
                      <Tr style={{ paddingBottom: 16 }}>
                        <Td width={56}>
                          <Heading size="h4">Webhook</Heading>
                        </Td>
                        <Td width={136}>
                          <Heading size="h4">Last Delivery</Heading>
                        </Td>
                        <TdCentered width={96}>
                          <Heading size="h4">Status</Heading>
                        </TdCentered>
                      </Tr>
                      {webhooks.map((webhook, i) => (
                        <Tr>
                          <Td>
                            <Elipsis>{webhook.subscriptionUrl}</Elipsis>
                          </Td>
                          <Td>
                            {webhook.lastDeliveryTime
                              ? dayjs(webhook.lastDeliveryTime).fromNow()
                              : "Never"}{" "}
                            - {webhook.lastDeliveryStatus}
                          </Td>
                          <TdCentered>{webhook.status}</TdCentered>
                        </Tr>
                      ))}
                    </Table>
                  </Webhooks>
                )}
              </Card>
            </>
          )}

          <Card padding={16}>
            {showOverview ? (
              <IntegrationsOverview />
            ) : (
              <RowSpaced>
                <Paragraph>
                  Want to learn more about our Google Calendar integration?
                </Paragraph>{" "}
                <Button
                  size="small"
                  title="Show integration overview"
                  onClick={() => {
                    setShowOverview(true);
                  }}
                />
              </RowSpaced>
            )}
          </Card>
        </>
      )}
    </SettingsTemplate>
  );
};

export default SettingsGoogleCalendar;
