import React, { useEffect, useState } from "react";
import { styled } from "styled-components";

// Component Library
import {
  Row,
  Column,
  Line,
  Heading,
  Paragraph,
  FreshnessScore,
  Alert,
  DropdownButton, 
  DropdownItem,
  Select, 
  Toggle
} from "@saberapp/react-component-library";

// Enums
import { FreshnessScoreValues } from "@saberapp/enums";

// Hooks
import { useAuth } from "../../../hooks/useAuth";
import { useApi } from "../../../hooks/useApi";

import SettingsTemplate from "../../templates/SettingsTemplate";
import SettingsHeader from "./components/SettingsHeader";

import { ReactComponent as IconArrowDown } from "../../icons/20/solid/chevron-down.svg";


const StyledList = styled.ul`
  margin: 0;
  padding: 0;

  & li {
    display: flex;
    gap: 16px;
    margin: 16px 0px;

    &:last-child {
      margin-bottom: 16px;
    }
  }

  & b {
    font-weight: 500;
    color: var(--heading-text);
  }
`;

const Threshold = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 8px;
  align-items: center;
  background: var(--bg-color-1);
  border-radius: 6px;
  border: 1px solid var(--border-color-1);
  padding: 16px;
`;

const SettingsResources = () => {
  const { API } = useApi();

  const [isLoading, setIsLoading] = useState(true);
  const [isInfoVisible, setIsInfoVisible] = useState(true);
  const [settings, setSettings] = useState({});
  const [degradationToNeedReview, setDegradationToNeedReview] = useState("?");
  const [degradationToOutdated, setDegradationToOutdated] = useState("?");

  /**
   * Load the initial settings for this page
   */
  useEffect(() => {
    getSettings();
  }, []);

  const getSettings = async () => {
    await API.getFreshnessSettings((response) => {
      setSettings(response);
      setDegradationToNeedReview(response.degradationToNeedReview);
      setDegradationToOutdated(response.degradationToOutdated);
    });
  };

  const updateSettings = async () => {
    await API.updateFreshnessSettings(settings);
  };

  useEffect(() => {
    Object.keys(settings).length > 0 && setIsLoading(false); // So it doesn't make an update call on first render and on first load
    if (!isLoading) {
      updateSettings();
    }
  }, [settings]);

  /**
   * Toggle showing the info block
   * TODO: Store this somewhere server-side
   * @param {Boolean} show
   */
  const handleShowInfo = (show = true) => {
    setIsInfoVisible(show);
  };

  /**
   * Triggerd when a setting gets updated
   * @param {String} setting
   * @param {any} newState
   */
  const handleSettingChanged = (setting, newState) => {
    setting === "degradationToNeedReview" &&
      setDegradationToNeedReview(newState);
    setting === "degradationToOutdated" && setDegradationToOutdated(newState);

    setSettings((prevSettings) => {
      let updatedSettings = { ...prevSettings };
      updatedSettings[setting] = newState;
      return updatedSettings;
    });
  };

  return (
    <SettingsTemplate>
      <Column gap="s">
        <Heading size="h1">Content Quality</Heading>
        <Paragraph>
          Make sure your docs and content remain relevant and up-to-date.{" "}
          {!isInfoVisible && (
            <a
              onClick={() => {
                handleShowInfo();
              }}
            >
              What are Freshness Scores?
            </a>
          )}
        </Paragraph>
      </Column>

      <Alert
        type="info"
        title="What is the Freshness Indicator?"
        isVisible={isInfoVisible}
        closeCallback={() => {
          handleShowInfo(false);
        }}
      >
        <Paragraph>
          The <b>Freshness Indicator</b> is a visual cue both sellers and
          enablement teams can use to determine how fresh the content still is.
        </Paragraph>
        <StyledList>
          <li>
            <FreshnessScore score={FreshnessScoreValues.VERIFIED} size={16} />
            <Paragraph>
              A <b>green</b> indicator shows that the content was recently
              created or reviewed
            </Paragraph>
          </li>
          <li>
            <FreshnessScore score={FreshnessScoreValues.NEEDS_REVIEW} size={16} />
            <Paragraph>
              A <b>yellow</b> indicator shows that some significant time has
              gone by since the last review or update. As soon as a doc goes
              from green to yellow, the enablement team gets a notification and
              is prompted to review the document.
            </Paragraph>
          </li>
          <li>
            <FreshnessScore score={FreshnessScoreValues.OUTDATED} size={16} />
            <Paragraph>
              A <b>red</b> indicator shows that you should use this content at
              your own discretion.
            </Paragraph>
          </li>
        </StyledList>
        <Paragraph>
          This way we make sure sellers always have access to most relevant
          content, and don't accidentally distribute dated content.
        </Paragraph>
      </Alert>

      <Line distance="tiny" />

      <Heading size="h3">Degradation</Heading>
      <SettingsHeader
        type="small"
        title="Freshness Threshold"
        description="Set how long a resource stays fresh and when it degrades to yellow and red"
        line={false}
      />

      <Row>
        <Threshold>
          <FreshnessScore score={FreshnessScoreValues.VERIFIED} size={16} />
          <Heading size="h4">Up to date</Heading>
        </Threshold>
        <Threshold>
          <FreshnessScore score={FreshnessScoreValues.NEEDS_REVIEW} size={16} />
          <Heading size="h4">Needs Review</Heading>
          <DropdownButton
            title={`After ${degradationToNeedReview} days`}
            type="ui"
            position="bottom-left"
            iconAfter={<IconArrowDown />}
          >
            <DropdownItem
              onClick={() => {
                handleSettingChanged("degradationToNeedReview", 2);
              }}
            >
              After 2 days
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                handleSettingChanged("degradationToNeedReview", 30);
              }}
            >
              After 30 days
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                handleSettingChanged("degradationToNeedReview", 90);
              }}
            >
              After 90 days
            </DropdownItem>
          </DropdownButton>
        </Threshold>
        <Threshold>
          <FreshnessScore score={FreshnessScoreValues.OUTDATED} size={16} />
          <Heading size="h4">Outdated</Heading>
          <DropdownButton
            title={`After ${degradationToOutdated} days`}
            type="ui"
            position="bottom-left"
            iconAfter={<IconArrowDown />}
          >
            <DropdownItem
              onClick={() => {
                handleSettingChanged("degradationToOutdated", 4);
              }}
            >
              After 4 days
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                handleSettingChanged("degradationToOutdated", 60);
              }}
            >
              After 60 days
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                handleSettingChanged("degradationToOutdated", 180);
              }}
            >
              After 180 days
            </DropdownItem>
          </DropdownButton>
        </Threshold>
      </Row>

      <Line distance="tiny" />

      <Heading size="h3">Display</Heading>
      <SettingsHeader
        type="small"
        title="Show on Docs"
        description="Show the Freshness Indicator on your seller-facing sales enablement docs"
        button={
          <Toggle
            on={settings?.showIconOnDocs}
            onChange={(newState) => {
              handleSettingChanged("showIconOnDocs", newState);
            }}
          />
        }
        line={true}
      />
      <SettingsHeader
        type="small"
        title="Show on Pages"
        description="Show the Freshness Indicator on your seller-facing pages"
        button={
          <Toggle
            on={settings?.showIconOnPages}
            onChange={(newState) => {
              handleSettingChanged("showIconOnPages", newState);
            }}
          />
        }
        line={true}
      />

      <Line distance="tiny" />

      <Heading size="h3">Automation</Heading>
      <SettingsHeader
        type="small"
        title="De-index Outdated Docs"
        description="Automatically de-index outdated docs"
        button={
          <Toggle
            on={settings?.automaticallyDeIndexWhenOutdated}
            onChange={(newState) => {
              handleSettingChanged(
                "automaticallyDeIndexWhenOutdated",
                newState
              );
            }}
          />
        }
        line={true}
      />
      <SettingsHeader
        type="small"
        title="Modified docs"
        description="Automatically reset the freshness score if a resource has been manually modified"
        button={
          <Toggle
            on={settings?.automaticallyResetIfModified}
            onChange={(newState) => {
              handleSettingChanged("automaticallyResetIfModified", newState);
            }}
          />
        }
        line={true}
      />
    </SettingsTemplate>
  );
};

export default SettingsResources;
