import { useEffect, useState } from "react";
import { useAuth } from "./useAuth";
import { HttpMethod } from "@saberapp/enums";
// import { useNavigate } from "react-router-dom";
import axios from "axios";
import { auth } from "../util/firebase";

export const useApi = () => {
  const { organization, isAuthenticated } = useAuth();
  // const navigate = useNavigate();

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    organization !== null && isAuthenticated && setIsReady(true);
  }, [organization, isAuthenticated]);

  function getAxiosInstance() {
    if(!organization){
      return
    }
    const organizationBaseUrl = `/organizations/${organization._id}`;

    const instance = axios.create({
      baseURL: `${window.baseurl}${organizationBaseUrl}`
    });
    // This will intercept all requests to server and set the valid auth token
    instance.interceptors.request.use(async config => {
          const user = auth.currentUser;
          if (user) {
            const token = await user.getIdToken();
            config.headers.Authorization = `Bearer ${token}`;
          }
          return config;
        },
        error => {
          return Promise.reject(error);
        });
    return instance;
  }

// TODO: Make this return a promise, so its easier and cleaner to manage loading states per API call
  const call = async (method, url, options = {}, callback = () => {}) => {
    setIsLoading(true);

    const instance = getAxiosInstance();
    if(!instance){
      console.log('Axios request ignore before organization is set')
      return null;
    }

    try {
      const response = await instance.request({
        method,
        url,
        data: options,
        params: {
          filter: JSON.stringify(options),
        },
      });
      setIsLoading(false);
      callback(response.data);
    } catch (error) {
      // if (error.response?.status === 401) {
      //   navigate("/sign-in");
      // }
      setError(error);
      setIsLoading(false);

      // TODO: Send errors to a monitoring service, maybe sentry.io
      console.error(error);
    }
  };
  const callPromise = async (method, url, options = {}, responseType='json') => {
    setIsLoading(true);

    const instance = getAxiosInstance();
    if(!instance){
      console.log('Axios request ignore before organization is set')
      return null;
    }

    const response = await instance
      .request({
        method,
        responseType,
        url,
        data: options,
        params: {
          filter: JSON.stringify(options),
        },
      })
      .catch((error) => {
        // if (error.response?.status === 401) {
        //   navigate("/sign-in");
        // }
        setError(error);
        setIsLoading(false);

        // TODO: Send errors to a monitoring service, maybe sentry.io
        console.error(error);

        throw error;
      });
    setIsLoading(false);
    return response;
  };

  const API = {
    call,
    callPromise,
    isLoading,
    isReady,
    error,
    // Search
    semanticSearch: (options = {}, callback) =>
      call(HttpMethod.POST, "/search/semantic", options, callback),
    promptSearch: (options = {}, callback) =>
      call(HttpMethod.POST, "/search/prompt", options, callback),

    // Resources
    getResources: (options = {}, callback) =>
      call(HttpMethod.GET, "/resources/", options, callback),
    getResource: (id, callback) =>
        call(HttpMethod.GET, `/resources/${id}`, {}, callback),
    getResourceThumbnail: (id,) =>
        callPromise(HttpMethod.GET, `/resources/${id}/thumbnail`, {}, 'blob'),
    updateResource: (id, data, callback) =>
      call(HttpMethod.PUT, `/resources/${id}`, data, callback),
    bulkUpdateResources: (data, callback) =>
      call(HttpMethod.PUT, `/resources/`, data, callback),
    determineResourceTags: (id, callback) =>
      call(HttpMethod.GET, `/resources/${id}/tags`, {}, callback),
    approveResourceTags: (id, data, callback) =>
      call(HttpMethod.PUT, `/resources/${id}/tags`, data, callback),

    // SharedContacts
    getSharedContacts: (id) =>
      callPromise(HttpMethod.GET, `/resources/${id}/contacts`, {}),
    updateShareResourceContacts: (id, data) =>
      callPromise(HttpMethod.PUT, `/resources/${id}/share`, data),

    // Tags
    getTags: (callback) => call(HttpMethod.GET, "/tags/", {}, callback),
    createTag: (options = {}, callback) =>
      call(HttpMethod.POST, `/tags/`, options, callback),
    updateTag: (tagId, options = {}, callback) =>
      call(HttpMethod.PUT, `/tags/${tagId}`, options, callback),
    deleteTag: (tagId, callback) =>
      call(HttpMethod.DELETE, `/tags/${tagId}`, {}, callback),

    // Users
    getUsers: (callback) => call(HttpMethod.GET, "/users/", {}, callback),
    inviteUsers: (options = {}, callback) =>
      call(HttpMethod.POST, "/users/invites/", options, callback),
    cancelUserInvitation: () => {},
    updateUser: (id, data) =>
        callPromise(HttpMethod.PUT, `/users/user/${id}`, data),
    removeUsers: (options = {}, callback) =>
      call(HttpMethod.PUT, "/users/remove/", options, callback),

    // Roles
    getRoles: (callback) => call(HttpMethod.GET, "/roles/", {}, callback),

    // Settings
    getFreshnessSettings: (callback) =>
      call(HttpMethod.GET, "/settings/freshnessScoreSettings", {}, callback),
    updateFreshnessSettings: (data, callback) =>
      call(HttpMethod.PUT, "/settings/freshnessScoreSettings", data, callback),
  };

  return { API, call, isLoading, error };
};
