import React from "react";
import styled from "styled-components";

// Component Library
import {
  Heading,
  Paragraph,
  Pill,
  fileGroupsMap,
} from "@saberapp/react-component-library";
import IconWrapper from "../../../icons/IconWrapper";

const Row = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;

  svg {
    width: 20px;
  }
`;

const FileTypeHeader = ({ groupKey, count }) => {
  console.log('hello', groupKey)
  return (
    <>
      <Row>
        <IconWrapper size="16">{fileGroupsMap[groupKey]?.icon}</IconWrapper>
        <Heading size="h3">{groupKey}</Heading>
        <Pill title={`${count}`} />
      </Row>
      {/* <Paragraph>TEST: {fileGroupsMap[groupKey]?.description}</Paragraph> */}
    </>
  );
};

export default FileTypeHeader;
