import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import "./css/App.css";

// Auth
import { useAuth } from "./hooks/useAuth";

// Context Providers
import { ThemeProvider, useTheme } from "@saberapp/hooks";
import { UsersProvider } from "./hooks/useUsers";
import { ConnectorsProvider } from "./hooks/useConnectors";
import { InsightsProvider } from "./hooks/useInsights";

// Utilities
import RequireAuth from "./components/util/RequireAuth";

// Public Pages
import HomePage from "./components/pages/public/HomePage";
import SignIn from "./components/pages/public/SignIn";
import PrivacyPolicy from "./components/pages/public/PrivacyPolicy";
import TermsOfService from "./components/pages/public/TermsOfService";
import Dpa from "./components/pages/public/Dpa";

// Pages
import Home from "./components/pages/home";
import Inbox from "./components/pages/inbox";
import Resource from "./components/pages/resource";
import Inventory from "./components/pages/inventory";
import CRM from "./components/pages/crm";
import Answers from "./components/pages/answers";
import Answer from "./components/pages/answers/Answer";

// Settings
import SettingsOverview from "./components/pages/settings/SettingsOverview";
import SettingsMembers from "./components/pages/settings/members";
import SettingsContentQuality from "./components/pages/settings/SettingsContentQuality";
import SettingsPreferences from "./components/pages/settings/SettingsPreferences";
import SettingsThemes from "./components/pages/settings/SettingsThemes";
import SettingsProfile from "./components/pages/settings/SettingsProfile";
import SettingsChromeExtension from "./components/pages/settings/SettingsChromeExtension";
import SettingsConnector from "./components/pages/settings/connectors";
import SettingsGoogleCalendar from "./components/pages/settings/connectors/GoogleCalendar";
import SettingsOrganization from "./components/pages/settings/general";

import ComingSoon from "./components/pages/ComingSoon";

import { ConnectorCallbackHandler } from "./components/pages/settings/connectors/ConnectorCallbackHandler";
import Sandbox from "./components/pages/sandbox";

const AppRoutes = () => {
  const { isAuthenticated, organization, user } = useAuth();
  const { handleThemeChange } = useTheme();
  const [path, setPath] = useState("/");

  // const org = organization || JSON.parse(localStorage.getItem('organization'))
  // const usr = user || JSON.parse(localStorage.getItem('user'))

  // function getInitialNavigateTo() {
  // 	// If the user is signed in and has disabled flag, redirect to disabled page. and give option to sign out there
  // 	// If the user is signed in and has isValid false, redirect to processing page where they can hit refresh to check again and do show that it can take upto 5 mins
  // 	if (!isAuthenticated) return '/sign-in'
  // 	const navTo = org && org.slug ? `/${org.slug}` : '/sign-in'
  // 	return navTo
  // }

  useEffect(() => {
    if (user?.theme) {
      handleThemeChange(String(user.theme.name).toLowerCase());
    }
  }, [user]);

  useEffect(() => {
    if (isAuthenticated && organization) {
      setPath(`/${organization.slug}`);
    } else {
      setPath("/");
    }
  }, [isAuthenticated]);

  return (
    <Routes>
      {/* Legal pages */}
      <Route path="/privacy" element={<PrivacyPolicy />} />
      <Route path="/terms" element={<TermsOfService />} />
      <Route path="/dpa" element={<Dpa />} />

      {/* Sign in */}
      <Route path="/sign-in" element={<SignIn />} />
      <Route path="/auth/callback" element={<SignIn />} />

      <Route
        path="/:organization/"
        element={<RequireAuth component={Home} />}
      />
      <Route
        path="/:organization/inbox"
        element={<RequireAuth component={Inbox} />}
      />
      <Route
        path="/:organization/inbox/:taskId"
        element={<RequireAuth component={Inbox} />}
      />
      <Route
        path="/:organization/sandbox"
        element={<RequireAuth component={Sandbox} />}
      />
      <Route
        path="/:organization/documents"
        element={<RequireAuth component={Inventory} />}
      />
      <Route
        path="/:organization/documents/:resourceId"
        element={<RequireAuth component={Resource} />}
      />
      <Route
        path="/:organization/pages-and-notes"
        element={<RequireAuth component={Inventory} />}
      />
      <Route
        path="/:organization/crm"
        element={<RequireAuth component={CRM} />}
      />
      <Route
        path="/:organization/answers"
        element={<RequireAuth component={Answers} />}
      />
      <Route
        path="/:organization/answers/:answerId"
        element={<RequireAuth component={Answer} />}
      />
      <Route
        path="/:organization/archive"
        element={<RequireAuth component={Inventory} />}
      />
      <Route
        path="/:organization/archive/:resourceId"
        element={<RequireAuth component={Resource} />}
      />
      {/* <Route
        path="/:organization/opportunities"
        element={<RequireAuth component={Opportunities} />}
      />
      <Route
        path="/:organization/opportunities/:opportunityId"
        element={<RequireAuth component={Opportunity} />}
      />
      <Route
        path="/:organization/updates"
        element={<RequireAuth component={ComingSoon} />}
      /> */}
      {/* <Route
        path="/:organization/insights"
        element={<RequireAuth component={Insights} />}
      />
      <Route
        path="/:organization/insights/feedback"
        element={<RequireAuth component={Insights} />}
      />
      <Route
        path="/:organization/insights/content-gaps"
        element={<RequireAuth component={Insights} />}
      /> */}
      {/* <Route
        path="/:organization/insights/team"
        element={<RequireAuth component={InsightsTeam} />}
      /> */}

      <Route
        path="/:organization/insights/engagement"
        element={<RequireAuth component={ComingSoon} />}
      />

      <Route
        path="/:organization/insights/trends"
        element={<RequireAuth component={ComingSoon} />}
      />

      {/* Settings pages */}
      <Route
        path="/:organization/settings"
        element={<RequireAuth component={SettingsOverview} />}
      />
      <Route
        path="/:organization/settings/general"
        element={<RequireAuth component={SettingsOrganization} />}
      />
      <Route
        path="/:organization/settings/members"
        element={<RequireAuth component={SettingsMembers} />}
      />
      {/* <Route
        path="/:organization/settings/content-types"
        element={<RequireAuth component={SettingsContentTypes} />}
      /> */}
      {/* <Route
        path="/:organization/settings/tags"
        element={<RequireAuth component={SettingsTags} />}
      /> */}
      <Route
        path="/:organization/settings/content-quality"
        element={<RequireAuth component={SettingsContentQuality} />}
      />

      {/**
       * New connector page
       */}
      <Route
        path="/:organization/connector/:connectorSlug"
        element={<RequireAuth component={SettingsConnector} />}
      />

      {/**
       * These are all the connector settings pages we support
       */}
      <Route
        path="/:organization/settings/google-calendar"
        element={<RequireAuth component={SettingsGoogleCalendar} />}
      />
      <Route
        path="/:organization/settings/chrome-extension"
        element={<RequireAuth component={SettingsChromeExtension} />}
      />

      {/**
       * This path is used to handle all connector OAuth callbacks
       */}
      <Route
        path="/callback/connector/:connectorName"
        element={<RequireAuth component={ConnectorCallbackHandler} />}
      />

      {/**
       * These paths are for all personal settings and preferences
       */}
      <Route
        path="/:organization/settings/profile"
        element={<RequireAuth component={SettingsProfile} />}
      />
      <Route
        path="/:organization/settings/profile/preferences"
        element={<RequireAuth component={SettingsPreferences} />}
      />
      <Route
        path="/:organization/settings/appearance"
        element={<RequireAuth component={SettingsThemes} />}
      />

      <Route
        path="/"
        element={isAuthenticated ? <Navigate to={path} /> : <HomePage />}
      />
    </Routes>
  );
};

const App = () => {
  return (
    <Router>
      <ThemeProvider>
        <UsersProvider>
          <ConnectorsProvider>
            <InsightsProvider>
              <AppRoutes />
            </InsightsProvider>
          </ConnectorsProvider>
        </UsersProvider>
      </ThemeProvider>
    </Router>
  );
};

export default App;
