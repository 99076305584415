import React from "react";
import { styled } from "styled-components";
import { motion, AnimatePresence } from "framer-motion";

// Hooks
import { useToaster } from "react-hot-toast/headless";
import { SearchProvider } from "../../hooks/useSearch";

// Components
import SidebarNavigation from "../layouts/main-menu";
import { useAuth } from "../../hooks/useAuth";

import {
  Paragraph,
  DropdownButton,
  DropdownItem,
} from "@saberapp/react-component-library";
import { IconChevronDown } from "@saberapp/icons";
import { useNavigate } from "react-router-dom";

const App = styled.div`
  display: flex;
  height: 100%;
`;

const AppContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: calc(100% - 176px);
`;

const AppContent = styled.div`
  background: var(--bg-main);
  color: ${(props) => `${props.theme.colors.backgroundTextColor}`};
  height: 100%;
  overflow: auto;
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
`;

const ToastsContainer = styled.div`
  position: absolute;
  width: 100%;
`;
const Toast = styled(motion.div)`
  position: absolute;
  top: 24px;
  left: 50%;
  transform: translateX(-50%);
  background: var(--context-menu-item-bg-hover);
  color: var(--heading-text);
  font-weight: 500;
  padding: 6px 10px;
  border-radius: 8px;
  z-index: 9999;
`;

const AdminBanner = styled.div`
  // padding-top: 2px;

  // background: repeating-linear-gradient(
  //   -45deg,
  //   #f2a417,
  //   #f2a417 15px,
  //   #141617 15px,
  //   #141617 30px
  // );
`;

const AdminBannerContent = styled.div`
  background: var(--button-ui-border);
  border-bottom: 1px solid var(--context-menu-item-bg-hover);
  padding: 0px 16px;
  display: flex;
  gap: 8px;
  align-items: center;
`;

const DashboardTemplate = ({ children }) => {
  const { organization, viewAsRole, viewingRole } = useAuth();
  const navigate = useNavigate();
  const { toasts, handlers } = useToaster();
  const { startPause, endPause } = handlers;

  const handleRoleChange = (roleName) => {
    viewAsRole(roleName)
    navigate(`/${organization.slug}/`);
  }

  return (
    <SearchProvider>

      {/* <AdminBanner>
        <AdminBannerContent>
          <Paragraph>
            You are seeing this because you are an Admin. View Saber as
          </Paragraph>

          <DropdownButton
            type="ui"
            size="small"
            position="bottom"
            title={viewingRole || "ADMIN"}
            iconAfter={<IconChevronDown />}
          >
            <DropdownItem onClick={() => handleRoleChange("ADMIN")}>
              Admin
            </DropdownItem>
            <DropdownItem onClick={() => handleRoleChange("SELLER")}>
              Seller
            </DropdownItem>
            <DropdownItem onClick={() => handleRoleChange("REVIEWER")}>
              Reviewer
            </DropdownItem>
          </DropdownButton>
        </AdminBannerContent>
      </AdminBanner> */}

      <App>
        <ToastsContainer onMouseEnter={startPause} onMouseLeave={endPause}>
          {toasts.map((toast) => (
            <AnimatePresence key={toast.id}>
              {toast.visible && (
                <Toast
                  key={toast.id}
                  visible={toast.visible}
                  {...toast.ariaProps}
                  initial={{ top: -100, opacity: 0 }}
                  animate={{ top: 24, opacity: 1 }}
                  exit={{ top: 24, opacity: 0 }}
                >
                  {toast.message}
                </Toast>
              )}
            </AnimatePresence>
          ))}
        </ToastsContainer>

        <SidebarNavigation />

        <AppContentContainer>
          <AppContent>{children}</AppContent>
        </AppContentContainer>
      </App>
    </SearchProvider>
  );
};

export default DashboardTemplate;
