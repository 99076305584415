import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useParams, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

import {
  Heading,
  Button,
  Avatar,
  FreshnessScore
} from "@saberapp/react-component-library";
import { IconWrapper, IconTrash } from "@saberapp/icons";

import DashboardTemplate from "../../templates/DashboardTemplate";
import TopNavigation from "../../layouts/main-top-bar";
import { useAuth } from "../../../hooks/useAuth";
import { useData } from "../../../hooks/useData";
import { useApi } from "../../../hooks/useApi";
import { HttpMethod, FreshnessScoreValues } from "@saberapp/enums";

dayjs.extend(relativeTime);
dayjs.locale("en");

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 48px;
`;

const Options = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const AnswerContainer = styled.div`
  width: 600px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Column = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  line-height: 1.5;
`;
const MetadataContainer = styled.div`
  display: flex;
  border-top: 1px solid var(--sidebar-border);
  padding-top: 24px;
  margin-top: 12px;
`;

const DirtyIconWrapper = styled.div`
  color: inherit;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;
`;

const AnswerTextArea = styled.textarea`
  width: 100%;
  resize: none;
  background: none;
  border: none;
  line-height: 1.5;
  outline: none;
  font-size: 16px;
  color: var(--heading-text);
  min-height: 6lh;
  field-sizing: content;
`;

const Divider = styled.div`
background: var(--card-bg);
    width: 2px;
    height: 20px;
    margin: 0 8px;
`;

const Answer = () => {
  const { organization, user } = useAuth();
  const { loading, updateDataSet, answers } = useData();
  const { API } = useApi();
  let { answerId } = useParams();
  const navigate = useNavigate();

  const [answer, setAnswer] = useState(null);
  const [answerExtended, setAnswerExtended] = useState(null);
  const [isAnswerSet, setIsAnswerSet] = useState(false);
  const [isAnswerExtendedSet, setIsAnswerExtendedSet] = useState(false);
  const [originalAnswerContent, setOriginalAnswerContent] = useState(null);
  const [answerContent, setAnswerContent] = useState(null);
  const [isUpdatingAnswer, setIsUpdatingAnswer] = useState(false);
  const [isMarkingAsReviewed, setIsMarkingAsReviewed] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    if (!loading && answerId) {
      const match = answers.find((a) => a._id === answerId);
      if (match) {
        setAnswer(match);
        setIsAnswerSet(true);
        setAnswerContent(match.answer + "...");
      }

      (async () => {
        await API.call(
          HttpMethod.GET,
          `/saber-snippet/${answerId}`,
          {},
          (extended) => {
            setAnswerExtended(extended);
            setIsAnswerExtendedSet(true);
            setAnswerContent(extended.content);
            setOriginalAnswerContent(extended.content);
          }
        );
      })();
    }
  }, [loading, answerId]);

  const updateAnswer = async () => {
    setIsUpdatingAnswer(true);
    await API.call(
      HttpMethod.PUT,
      `/saber-snippet/${answerId}`,
      {
        content: answerContent,
      },
      () => {
        setIsUpdatingAnswer(false);
      }
    );
  };

  const markAsReviewed = async () => {
    setIsMarkingAsReviewed(true);
    await API.updateResource(
      answer.resource,
      {
        freshnessScore: FreshnessScoreValues.VERIFIED,
        reviewedTime: Date.now(),
        reviewedBy: user._id,
      },
      (record) => {
        // TODO: Update Answers data set using updateDataSet
        setIsMarkingAsReviewed(false);
      }
    );
  };

  const deleteAnswer = async () => {
    setIsDeleting(true);
    await API.call(
      HttpMethod.DELETE,
      `/saber-snippet/${answerId}`,
      {},
      () => {
        updateDataSet('Answers', 'REMOVE', { _id: answerId });
        setIsDeleting(false);
        navigate(`/${organization.slug}/answers`);
      }
    );
  };

  return (
    <>
      <DashboardTemplate>
        <TopNavigation
          links={[
            {
              title: "Content & Data",
              path: `/${organization.slug}/documents`,
            },
            {
              title: "Questions & Answers",
              path: `/${organization.slug}/answers`,
            },
            {
              title: answer ? answer.name : "Loading...",
              path: `/${organization.slug}/answers/here`,
            },
          ]}
        >
          <Options>
          <Button
              type="ui"
              size="small"
              title={isDeleting ? "Deleting..." : "Delete"}
              iconBefore={
                <IconTrash />
              }
              disabled={!isAnswerExtendedSet || isDeleting}
              onClick={() => deleteAnswer()}
            />
            <Divider />
            <Button
              type="ui"
              size="small"
              title={isMarkingAsReviewed ? "Marking..." : "Mark as Reviewed"}
              iconBefore={
                <FreshnessScore score={FreshnessScoreValues.VERIFIED} />
              }
              disabled={!isAnswerExtendedSet || isMarkingAsReviewed}
              onClick={() => markAsReviewed()}
            />
            <Button
              type="primary"
              size="small"
              disabled={
                !isAnswerExtendedSet ||
                originalAnswerContent === answerContent || answerContent.length < 2 ||
                isUpdatingAnswer
              }
              title={isUpdatingAnswer ? "Saving..." : "Update answer"}
              onClick={() => updateAnswer()}
            />
          </Options>
        </TopNavigation>

        <Container>
          <AnswerContainer>
            {!loading && isAnswerSet && (
              <>
                <Heading size="h2">{answer.name}</Heading>

                <AnswerTextArea
                  value={answerContent}
                  disabled={!isAnswerExtendedSet || isUpdatingAnswer}
                  onChange={(e) => {
                    setAnswerContent(e.target.value);
                  }}
                />

                <MetadataContainer>
                  <Column>
                    <Row>Added {dayjs(answer.createdAt).fromNow()}</Row>

                    <Row>
                      <DirtyIconWrapper>
                        <Avatar url={answer.creator.avatar} />
                      </DirtyIconWrapper>
                      {answer.creator.name}
                    </Row>
                  </Column>
                  <Column>
                    <Row>Last updated {dayjs(answer.updatedAt).fromNow()} </Row>
                    <Row>
                      <IconWrapper>
                        <FreshnessScore score={answer?.freshnessScore} />
                      </IconWrapper>
                      {`Reviewed ${dayjs(answer?.reviewedTime).fromNow()}`}
                    </Row>
                  </Column>
                </MetadataContainer>
              </>
            )}

            {loading && <>Loading...</>}

            {!loading && !answer && <>Answer does not exist</>}
          </AnswerContainer>
        </Container>
      </DashboardTemplate>
    </>
  );
};

export default Answer;
