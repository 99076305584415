import React, { useEffect, useState } from "react";
import styled from "styled-components";

// Enums
import { HttpMethod } from "@saberapp/enums";

// Hooks
import { useApi } from "../../../../hooks/useApi";
import { useConnectors } from "../../../../hooks/useConnectors";

// Component Library
import {
  Card,
  Button,
  Paragraph,
  Heading,
} from "@saberapp/react-component-library";

const Column = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;

  &:last-child {
    margin-left: auto;
  }
`;

const Row = styled.div`
  display: flex;
  gap: 32px;
`;

const SalesStages = styled.div`
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid var(--button-ui-border);
`;

const Table = styled.table`
  width: 100%;
  border-spacing: 0;
`;
const Tr = styled.tr`
  &:nth-child(even) {
    background: var(--dropdown-bg);
  }
`;
const Td = styled.td`
  padding: 8px;
`;
const TdCentered = styled.td`
  padding: 8px;
  text-align: center;
`;

const SettingsSalesforce = () => {
  const { API } = useApi();
  const {
    isConnectorsLoading,
    getConnectorByName,
    update,
  } = useConnectors();

  const [salesStages, setSalesStages] = useState([]);

  const getSalesStages = async () => {
    await API.call(
      HttpMethod.GET,
      "/connectors/salesforce/sales-stages",
      {},
      (data) => {
        setSalesStages(data);
        update(getConnectorByName("salesforce")?._id, "salesforce", {
          salesStages: data,
        });
      }
    );
  };

  useEffect(() => {
    if (getConnectorByName("salesforce")?.status === "connected") {
      setSalesStages(getConnectorByName("salesforce")?.salesStages);
    }
  }, [getConnectorByName("salesforce")]);

  return (
    <>

      {!isConnectorsLoading && (
        <>
          {getConnectorByName("salesforce")?.status === "connected" && (
            <Card padding={16}>
              <Row>
                <Column>
                  <Heading size="h4">Sales Stages</Heading>
                  <Paragraph>
                    The insights Saber generates are mapped to the sales stages
                    you have defined in your Salesforce instance.
                  </Paragraph>
                </Column>
                <Column>
                  <Button
                    type="ui"
                    size="small"
                    title="Sync Sales Stages"
                    onClick={() => {
                      getSalesStages();
                    }}
                  />
                </Column>
              </Row>
              {salesStages?.length > 0 && (
                <SalesStages>
                  <Table>
                    <Tr style={{ paddingBottom: 16 }}>
                      <TdCentered width={56}>
                        <Heading size="h4">Order</Heading>
                      </TdCentered>
                      <Td width={120}>
                        <Heading size="h4">Name</Heading>
                      </Td>
                      <TdCentered width={96}>
                        <Heading size="h4">Probability</Heading>
                      </TdCentered>
                      <Td>
                        <Heading size="h4">Description</Heading>
                      </Td>
                    </Tr>
                    {salesStages.map((stage, i) => (
                      <Tr key={i}>
                        <TdCentered>{i}</TdCentered>
                        <Td>{stage.MasterLabel}</Td>
                        <TdCentered>{stage.DefaultProbability}%</TdCentered>
                        <Td>{stage.Description || `-`}</Td>
                      </Tr>
                    ))}
                  </Table>
                </SalesStages>
              )}
            </Card>
          )}
        </>
      )}
    </>
  );
};

export default SettingsSalesforce;
