import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

// Icons
import { ReactComponent as BrandMark } from "../../../icons/saber/brandmark.svg";
import { ReactComponent as Logo } from "../../../icons/saber/logo.svg";

const Header = styled.header`
  height: 80px;
  display: flex;
  justify-content: center;
  // border-bottom: 1px solid var(--card-bg);
  padding: 12px 0;

  position: fixed;
  width: 100%;
`;

const Container = styled.div`
  max-width: 1000px;
  width: 1200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LogoWrapper = styled.div`
  color: var(--heading-text);
  width: 100px;

  svg {
    width: 100%;
    height: auto;
  }

  &:hover {
    cursor: pointer;
  }
`;

const Left = styled.div``;
const Right = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  background: var(--context-menu-bg);
  backdrop-filter: blur(2px);
  padding: 12px 16px;
  border-radius: 8px;
`;

const Questions = styled.div`
  a {
    font-weight: 500;
    color: var(--heading-text);

    &:hover {
      color: var(--button-cta-bg-hover);
    }
  }
`;

const HeaderLink = styled.a`
  color: var(--heading-text);
  cursor: pointer;
  font-weight: 500;
`;

const PageHeader = () => {
  const navigate = useNavigate();

  return (
    <Header>
      <link rel="preconnect" href="https://fonts.googleapis.com"></link>
      <link
        rel="preconnect"
        href="https://fonts.gstatic.com"
        crossorigin
      ></link>
      <link
        href="https://fonts.googleapis.com/css2?family=Bungee&display=swap"
        rel="stylesheet"
      ></link>
      <Container>
        <Left>
          <LogoWrapper
            onClick={() => {
              navigate("/");
            }}
          >
            <Logo />
          </LogoWrapper>
        </Left>
        <Right>
          <HeaderLink>Home</HeaderLink>
          <HeaderLink
            onClick={(e) => {
              e.preventDefault();
              navigate("/sign-in");
            }}
          >
            Sign In
          </HeaderLink>
          <Questions>
            Questions? <a href="mailto:sales@saber.app">Contact us</a>
          </Questions>
        </Right>
      </Container>
    </Header>
  );
};

export default PageHeader;
