import io from "socket.io-client";
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const connectSocket = async (organizationId) => {
  try {
    // Wait for the Auth state to be confirmed
    const auth = getAuth();
    const waitForAuth = new Promise((resolve, reject) => {
      onAuthStateChanged(auth, user => {
        if (user) {
          resolve(user);
        } else {
          reject('No user logged in');
        }
      });
    });

    const user = await waitForAuth;
    const authToken = await user.getIdToken();
    
    const socket = io(process.env.REACT_APP_API_BASE_URL, {
      query: { organizationId, authToken },
      transports: ['websocket', 'polling'],
    });

    return socket;
  } catch (err) {
    console.error('Error connecting to socket:', err);
    return null;
  }
};

export default connectSocket;
