import React from "react";
import styled from "styled-components";

// Component Library
import {
  Column,
  Row,
  Heading,
  Paragraph,
  Button
} from "@saberapp/react-component-library";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const Content = styled.div`
  max-width: 480px;
`;

const Illustration = styled.div`
  min-width: 146px;
`;

const NoResults = ({ handleFilterChange }) => {
  return (
    <Container>
      <Content>
        <Row gap="l">
          {/* <Illustration>

          </Illustration> */}
          <Column>
            <Heading size="h3">No documents found</Heading>
            <Paragraph>
              No documents were found matching your search or filter criteria.
              Try clearing the filter or selecting other filter criteria.
            </Paragraph>
            <Button type="primary" size="small" title="Clear filter" onClick={()=>{ handleFilterChange({}) }} />
          </Column>
        </Row>
      </Content>
    </Container>
  );
};

export default NoResults;
