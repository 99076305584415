import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  getGroupedRowModel,
  getExpandedRowModel,
  flexRender,
} from "@tanstack/react-table";

// Component Library
import {
  Column,
  Heading,
  Paragraph,
  Button,
  Tag,
  FreshnessScore,
  Tooltip,
  Pill,
  Avatar,
  LoadingCard,
  FileTypeIcon,
  fileTypesMap,
  Checkbox,
  Card,
} from "@saberapp/react-component-library";

// Enums
import {
  ContentTypes,
  FreshnessScoreValuesReadable,
  VisibilityStatus,
} from "@saberapp/enums";

// Hooks
import { Resources } from "../../../../hooks/useResources";
import { ContextMenu } from "../../../../hooks/useContextMenu";

// Components
import NoResults from "./NoResults";

// Icons
import {
  IconCursorArrowRipple,
  IconEye,
  IconLink,
  IconWrapper,
} from "@saberapp/icons";
import { ReactComponent as IconNotion } from "../../../icons/connectors/notion.svg";
import { ReactComponent as IconConfluence } from "../../../icons/connectors/confluence.svg";
// import { useAnalytics } from "../../../../hooks/useAnalytics";
import { useAuth } from "../../../../hooks/useAuth";

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const StyledTableRow = styled.tr`
  width: 100%;
  justify-content: flex-start;
  padding: 12px 16px;
  color: var(--heading-text);
  box-sizing: border-box;
  cursor: pointer;
  transition: background-color 0.2s ease;
  background: ${(props) =>
    props.selected === true ? "var(--tablerow-bg-active)" : "var(--main-bg)"};
  border-bottom: ${(props) =>
    props.selected === true
      ? "1px solid transparent)"
      : "1px solid var(--tablerow-border)"};

  &:last-child {
    border-bottom: 0;
  }

  &:hover {
    background: ${(props) =>
      props.selected === true
        ? "var(--tablerow-bg-active-hover)"
        : "var(--tablerow-bg-hover)"};
  }
`;

const StyledTableCell = styled.td`
  overflow: visible;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 34px;
  padding: 4px 8px;
  height: 40px;

  &:first-child {
    padding-left: 16px;
    padding-right: 8px;
    width: 32px;
  }

  &:nth-child(2) {
    padding-right: 4px;
  }
  &:nth-child(3) {
    padding-left: 4px;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const FlexContainerRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
`;

const Label = styled.div`
  background: #191d24;
  color: #a0a2a5;
  font-weight: 500;
  padding: 4px 6px;
  font-size: 10px;
  border-radius: 4px;
`;

const FilterDescription = styled.div`
  border-top: 1px solid var(--border-color-1);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding: 8px;
  font-size: 12px;
`;

const LoadingRow = styled.div`
  padding: 0 16px;

  &:first-child {
    padding-top: 16px;
  }
`;

const StyledGroupRow = styled.tr`
  background: var(--tableheader-bg);
`;

const StyledGroupCell = styled.td`
  padding: 12px 24px;
`;

const GroupRow = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const PaddedLabel = styled.label`
  padding: 8px;
`;

const CardInner = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  padding: 0 8px;
`;

const Metric = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const IconBackground = styled.div`
  background: #fff;
  padding: 4px;
  border-radius: 6px;
`;

const ResourcesTable = ({ layout, handleResourceClick, page }) => {
  const {
    isLoadingResources,
    activeGroup,
    resourcesCount,
    filteredResources,
    getFilteredResourcesByPage,
    filterCount,
    selectedResources,
    setSelectedResources,
    handleFilterChange,
    handleSelectedResources,
  } = useContext(Resources);

  const { contextMenu } = useContext(ContextMenu);

  const [resources, setResources] = useState([]);
  const [data, setData] = useState([]);

  // const { analytics } = useAnalytics();
  const { isAuthenticated } = useAuth();

  // useEffect(() => {
  //   if (!isAuthenticated) return;
  //   analytics.getResourceEngagements((data) => {
  //     console.log("HERE", data);
  //   });
  // }, [isAuthenticated]);

  useEffect(() => {
    const r = getFilteredResourcesByPage(page);
    setData(r);
  }, [filteredResources, page]);

  const columnHelper = createColumnHelper();

  const columns = [
    // Hidden columns only used for Grouping
    columnHelper.accessor("contentType", {
      getGroupingValue: (row) => row.contentType?.name,
    }),
    columnHelper.accessor("indexStatus", {}),
    columnHelper.accessor("mimeType", {}),
    columnHelper.accessor("docSource", {}),
    columnHelper.accessor("freshnessScore", {}),
    // Actual columns
    columnHelper.accessor("checkbox", {
      cell: (info) => (
        <FlexContainer>
          <PaddedLabel>
            <Checkbox
              onChange={() => {
                handleSelectedResources("toggle", info.row.original);
              }}
              checked={
                selectedResources.some(
                  (selectedResource) =>
                    selectedResource._id === info.row.original._id
                )
                  ? true
                  : false
              }
            />
          </PaddedLabel>
        </FlexContainer>
      ),
    }),
    columnHelper.accessor("name", {
      cell: (info) => (
        <FlexContainer>
          <FreshnessScore
            score={info.row.original.freshnessScore}
            lastReviewed={info.row.original.reviewedTime}
          />
          <Tag
            key={info.row.original._id}
            title={
              info.row.original.contentType?.name !== ContentTypes.UNKNOWN &&
              info.row.original.contentType?.name
            }
            icon={<FileTypeIcon mimeType={info.row.original.mimeType} />}
          />
          {info.row.original.visibility !== VisibilityStatus.UNKNOWN && (
            <Label>{info.row.original.visibility}</Label>
          )}
          {info.getValue()}
        </FlexContainer>
      ),
    }),
    // TODO: Add back in when engagements are also sent with the resources API, using stream
    // columnHelper.accessor("engagements", {
    //   cell: (info) => (
    //     <FlexContainer>
    //       {(info.row.original.analytics.views > 0 ||
    //         info.row.original.analytics.opens > 0 ||
    //         info.row.original.analytics.linksCopied > 0) && (
    //         <Card key={info.row.original._id} padding={4}>
    //           <CardInner>
    //             {info.row.original.analytics.views > 0 && (
    //               <Metric>
    //                 <IconWrapper>
    //                   <IconEye />
    //                 </IconWrapper>

    //                 <Paragraph>{info.row.original.analytics.views}</Paragraph>
    //               </Metric>
    //             )}
    //             {console.log(info.row.original.analytics)}
    //             {info.row.original.analytics.opens > 0 && (
    //               <Metric>
    //                 <IconWrapper>
    //                   <IconCursorArrowRipple />
    //                 </IconWrapper>

    //                 <Paragraph>{info.row.original.analytics.opens}</Paragraph>
    //               </Metric>
    //             )}

    //             {info.row.original.analytics.linksCopied > 0 && (
    //               <Metric>
    //                 <IconWrapper>
    //                   <IconLink />
    //                 </IconWrapper>

    //                 <Paragraph>
    //                   {info.row.original.analytics.linksCopied}
    //                 </Paragraph>
    //               </Metric>
    //             )}
    //           </CardInner>
    //         </Card>
    //       )}
    //     </FlexContainer>
    //   ),
    // }),
    columnHelper.accessor("tags", {
      cell: (info) => (
        <FlexContainerRight>
          {info.row.original?.tags?.assigned?.length > 0 ? (
            <>
              {info.row.original.tags?.assigned
                .slice(0, 1)
                .map((tag, index) => (
                  <Tag key={index} title={tag.name} />
                ))}
              {info.row.original.tags?.assigned.length > 1 && (
                <span className="more-tags">
                  <Tooltip
                    text={info.row.original.tags?.assigned
                      .slice(1)
                      .map((tag) => tag.name)
                      .join(", ")}
                    position="top"
                  >
                    {info.row.original.tags?.assigned.length - 1} more
                  </Tooltip>
                </span>
              )}
            </>
          ) : (
            <Paragraph>No tags</Paragraph>
          )}
        </FlexContainerRight>
      ),
    }),
    columnHelper.accessor("owners", {
      cell: (info) => (
        <IconWrapper size={20}>
          <Avatar url={info.row.original.owners[0]?.avatar} />
        </IconWrapper>
      ),
    }),
  ];

  const [grouping, setGrouping] = useState(
    page === "pages-and-notes" ? ["docSource"] : ["mimeType"]
  );

  const table = useReactTable({
    data,
    columns,
    state: {
      grouping,
      expanded: true,
    },
    getSubRows: (row) => row.subRows,
    onGroupingChange: setGrouping,
    getExpandedRowModel: getExpandedRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getCoreRowModel: getCoreRowModel(),
  });

  useEffect(() => {
    setGrouping(
      page === "pages-and-notes" ? ["docSource"] : [activeGroup.slug]
    );
  }, [activeGroup, page]);

  function isRowSelected(row) {
    return !!selectedResources.some(({ _id }) => _id === row.original._id);
  }

  return (
    <>
      {!isLoadingResources ? (
        <>
          {data.length > 0 ? (
            <StyledTable layout={layout}>
              <tbody>
                {table.getRowModel().rows.map((row, i) => {
                  if (row.getCanExpand()) {
                    if (row.groupingColumnId === "docSource") {
                      return (
                        <DocSourceGroupHeader
                          value={row.groupingValue}
                          count={row.subRows.length}
                          key={row.groupingColumnId + row.original._id}
                        />
                      );
                    } else if (row.groupingColumnId === "contentType") {
                      return (
                        <ContentTypeGroupHeader
                          value={row.groupingValue}
                          count={row.subRows.length}
                          key={row.groupingColumnId + row.original._id}
                        />
                      );
                    } else if (row.groupingColumnId === "mimeType") {
                      return (
                        <MimeTypeGroupHeader
                          value={row.groupingValue}
                          count={row.subRows.length}
                          key={row.groupingColumnId + row.original._id}
                        />
                      );
                    } else if (row.groupingColumnId === "freshnessScore") {
                      return (
                        <FreshnessGroupHeader
                          value={row.groupingValue}
                          count={row.subRows.length}
                          key={row.groupingColumnId + row.original._id}
                        />
                      );
                    } else if (row.groupingColumnId === "indexStatus") {
                      return (
                        <IndexStatusGroupHeader
                          value={row.groupingValue}
                          count={row.subRows.length}
                          key={row.groupingColumnId + row.original._id}
                        />
                      );
                    }
                  } else {
                    return (
                      <StyledTableRow
                        key={row.id}
                        onMouseDown={(event) =>
                          handleResourceClick(row.original, event)
                        }
                        onContextMenu={(e) => {
                          e.preventDefault();

                          // if row not one of selected resource, set resource selection to row that is
                          if (!isRowSelected(row)) {
                            setSelectedResources([row.original]);
                          }

                          contextMenu.open(row.original, {
                            x: e.pageX,
                            y: e.pageY,
                          });
                        }}
                        selected={isRowSelected(row)}
                      >
                        {row.getVisibleCells().map((cell) => {
                          if (
                            cell.column.id === "contentType" ||
                            cell.column.id === "mimeType" ||
                            cell.column.id === "docSource" ||
                            cell.column.id === "indexStatus" ||
                            cell.column.id === "freshnessScore"
                          ) {
                            return null;
                          }
                          return (
                            <StyledTableCell key={cell.id}>
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </StyledTableCell>
                          );
                        })}
                      </StyledTableRow>
                    );
                  }
                })}
              </tbody>
            </StyledTable>
          ) : (
            <>
              <NoResults handleFilterChange={handleFilterChange} />
            </>
          )}
          {filterCount > 0 && data.length > 0 && (
            <FilterDescription>
              {resourcesCount - data.length} docs hidden by filters{" "}
              <Button
                type="ui"
                title="Clear Filters"
                size="small"
                onClick={() => {
                  handleFilterChange({});
                }}
              />
            </FilterDescription>
          )}
        </>
      ) : (
        <Column>
          <LoadingRow>
            <LoadingCard height="20px;" />
          </LoadingRow>
          <LoadingRow>
            <LoadingCard height="20px;" />
          </LoadingRow>
          <LoadingRow>
            <LoadingCard height="20px;" />
          </LoadingRow>
          <LoadingRow>
            <LoadingCard height="20px;" />
          </LoadingRow>
        </Column>
      )}
    </>
  );
};

const FreshnessGroupHeader = ({ value, count }) => {
  return (
    <StyledGroupRow>
      <StyledGroupCell colSpan={5}>
        <GroupRow>
          <FreshnessScore score={value} />
          <Heading size="h4">{FreshnessScoreValuesReadable[value]}</Heading>
          <Pill title={count} />
        </GroupRow>
      </StyledGroupCell>
    </StyledGroupRow>
  );
};

const MimeTypeGroupHeader = ({ value, count }) => {
  return (
    <StyledGroupRow>
      <StyledGroupCell colSpan={5}>
        <GroupRow>
          <IconWrapper>
            <FileTypeIcon mimeType={value} />
          </IconWrapper>
          <Heading size="h4">
            {fileTypesMap[value] ? fileTypesMap[value].title : value}
          </Heading>
          <Pill title={count} />
        </GroupRow>
      </StyledGroupCell>
    </StyledGroupRow>
  );
};

const ContentTypeGroupHeader = ({ value, count }) => {
  return (
    <StyledGroupRow>
      <StyledGroupCell colSpan={5}>
        <GroupRow>
          <Heading size="h4">
            {value !== "undefined" ? value : "Content Type not set"}
          </Heading>
          <Pill title={count} />
        </GroupRow>
      </StyledGroupCell>
    </StyledGroupRow>
  );
};

const IndexStatusGroupHeader = ({ value, count }) => {
  const title = value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  return (
    <StyledGroupRow>
      <StyledGroupCell colSpan={5}>
        <GroupRow>
          <Heading size="h4">{title}</Heading>
          <Pill title={count} />
        </GroupRow>
      </StyledGroupCell>
    </StyledGroupRow>
  );
};

const DocSourceGroupHeader = ({ value, count }) => {
  const title = value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();

  const logos = {
    notion: <IconNotion />,
    confluence: <IconConfluence />,
  };

  return (
    <StyledGroupRow>
      <StyledGroupCell colSpan={5}>
        <GroupRow>
          <IconBackground>
            <IconWrapper>{logos[value]}</IconWrapper>
          </IconBackground>
          <Heading size="h4">{title}</Heading>
          <Pill title={count} />
        </GroupRow>
      </StyledGroupCell>
    </StyledGroupRow>
  );
};

export default ResourcesTable;
